import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Modal, Text, Button } from '../../components';

interface LeaveConfirmationModalProps {
  onApprove: () => void;
  onAbort: () => void;
}

const LeaveConfirmationModal:FC<LeaveConfirmationModalProps> = ({ onApprove, onAbort }) => {
  const { t } = useTranslation();

  return (
    <Modal open onClose={() => {}}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center',
      }}
      >
        <Text
          fontLevel="title"
          fontSize="large"
          sx={{ color: (theme) => theme.palette.neutral.variation40 }}
        >
          {t('cloud_dvir__leave_confirmation_title')}
        </Text>
        <Text>{t('cloud_dvir__leave_confirmation_description')}</Text>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button isFilled size="small" onClick={onApprove}>{t('cloud_dvir__leave_confirmation_approve')}</Button>
          <Button isFilled viewType="secondary" size="small" onClick={onAbort}>{t('cloud_dvir__leave_confirmation_abort')}</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LeaveConfirmationModal;
