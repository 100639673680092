import React from 'react';
import { LaunchDataContextProvider } from '../context/LaunchDataContext';
import ContentSwitcher from './ContentSwitcher';
import AuthenticationProvider from './context/AuthenticationProvider';
import { MechanicURLDataPoints } from './constants';
import { DVIR_MODE } from '../constants';

const MechanicDVIR = () => (
  <LaunchDataContextProvider dataPoints={MechanicURLDataPoints} mode={DVIR_MODE.MECHANIC}>
    <AuthenticationProvider>
      <ContentSwitcher />
    </AuthenticationProvider>
  </LaunchDataContextProvider>
);

export default MechanicDVIR;
