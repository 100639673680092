import React, {
  memo, createContext, useMemo, useContext,
} from 'react';
import { DvirFailedSubmissionRetrier } from '../services/submission-retrier';
import { EventsManagerContext } from './EventsManagerContext';
import { DriverLaunchDataContext } from './LaunchDataContext';

interface DvirFailedSubmissionRetrierProviderProps {
  children: React.ReactNode
}

export interface DvirFailedSubmissionRetrierContext {
  dvirScheduler: DvirFailedSubmissionRetrier | null;
}

export const DvirFailedSubmissionRetrierContext = createContext<DvirFailedSubmissionRetrierContext>({ dvirScheduler: null });

export const DvirFailedSubmissionRetrierProvider = memo(({ children }: DvirFailedSubmissionRetrierProviderProps) => {
  const { driverData, vin } = useContext(EventsManagerContext);
  const { submissionUrl, context } = useContext(DriverLaunchDataContext);

  const dvirScheduler = useMemo(() => {
    if (driverData?.driverId && vin) {
      return new DvirFailedSubmissionRetrier({
        driverId: driverData.driverId,
        vin,
        submissionUrl,
        context,
      });
    }

    return null;
  }, [driverData, vin]);

  const value = useMemo(() => ({ dvirScheduler }), [dvirScheduler]);

  return (
    <DvirFailedSubmissionRetrierContext.Provider value={value}>{children}</DvirFailedSubmissionRetrierContext.Provider>
  );
});
