import React, { FC, createContext, useRef } from 'react';
import { RecordWithOptionalKeysAsValues } from '../types/helpers';
import VersionManager from '../utils/VersionManager';
import { DriverURLDataPoints } from '../driver/constants';
import { MechanicURLDataPoints } from '../mechanic/constants';
import { DVIR_MODE, DVIRMode } from '../constants';

const isUrlKey = (key: string) => key.toLowerCase().includes('url');

interface LaunchDataContextProviderProps {
  children: React.ReactNode;
  dataPoints: typeof DriverURLDataPoints | typeof MechanicURLDataPoints;
  mode: DVIRMode
}

export type LaunchData<T> = T extends typeof DriverURLDataPoints
    ? RecordWithOptionalKeysAsValues<typeof DriverURLDataPoints, string>
    : RecordWithOptionalKeysAsValues<typeof MechanicURLDataPoints, string>;

export const DriverLaunchDataContext = createContext<LaunchData<typeof DriverURLDataPoints>>({});
export const MechanicLaunchDataContext = createContext<LaunchData<typeof MechanicURLDataPoints>>({});

export const LaunchDataContextProvider:FC<LaunchDataContextProviderProps> = ({ children, dataPoints, mode }) => {
  const launchData = useRef<LaunchData<typeof dataPoints>>(null);

  if (!launchData.current) {
    const urlParams = new URLSearchParams(window.location.search);

    const dataParams = Object.values(dataPoints).reduce((res, dataPoint) => {
      if (isUrlKey(dataPoint)) {
        const encodedTemplateUrl = decodeURIComponent(urlParams.get(dataPoint));

        return {
          ...res,
          [dataPoint]: encodedTemplateUrl,
        };
      }

      return {
        ...res,
        [dataPoint]: urlParams.get(dataPoint),
      };
    }, {} as LaunchData<typeof dataPoints>);

    VersionManager.init(dataParams.auxVersion ?? '0');
    launchData.current = dataParams;
  }

  const Context = mode === DVIR_MODE.DRIVER ? DriverLaunchDataContext : MechanicLaunchDataContext;

  return (
    <Context.Provider value={launchData.current}>
      {children}
    </Context.Provider>
  );
};
