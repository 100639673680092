import { styled } from '@mui/material/styles';
import { TextField, TextFieldProps } from '@mui/material';

const Input = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '48px',
  },
  '& .MuiInputBase-input': {
    height: '48px',
    backgroundColor: theme.palette.neutral.variation100,
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 50px white inset !important',
    },
    '&:-webkit-autofill:hover': {
      WebkitBoxShadow: '0 0 0 50px white inset !important',
    },
    '&:-webkit-autofill:focus': {
      WebkitBoxShadow: '0 0 0 50px white inset !important',
    },
    '&:-webkit-autofill:active': {
      WebkitBoxShadow: '0 0 0 50px white inset !important',
    },
  },
  '& .MuiFormLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    transform: 'translate(14px, 50%) scale(1)',
  },
}));

export default Input;
