const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Erneut Versuchen';
_fc_props.default = 'Standard';
_fc_props.origin = 'Ausgangspunkt';
_fc_props.loading = 'Ladung';
_fc_props.list = 'Liste';
_fc_props.send = 'Senden';
_fc_props.error = 'Fehler';
_fc_props.save = 'Speichern';
_fc_props.delete = 'Löschen';
_fc_props.message = 'Nachricht';
_fc_props.logout = 'Abmelden';
_fc_props.add = 'Hinzufügen';
_fc_props.remove = 'Entfernen';
_fc_props.done = 'Erledigt';
_fc_props.other = 'Andere';
_fc_props.interval = 'Intervall';
_fc_props.time = 'Zeit';
_fc_props.speed = 'Geschwindigkeit';
_fc_props.longitude = 'Längengrad';
_fc_props.latitude = 'Breitengrad';
_fc_props.x_minutes = '{0} Min';
_fc_props.general = 'Allgemein';
_fc_props.messages = 'Nachrichten';
_fc_props.gps_fixes = 'GPS Verbesserungen';
_fc_props.device_id = 'Gerät ID';
_fc_props.vehicle = 'Fahrzeug';
_fc_props.employee = 'Mitarbeiter';
_fc_props.version = 'Version';
_fc_props.statuses = 'Status';
_fc_props.edit = 'Bearbeiten';
_fc_props.cancel = 'Stornieren';
_fc_props.yes = 'Ja';
_fc_props.none = 'Kein';
_fc_props.never = 'Nie';
_fc_props.name = 'Name';
_fc_props.true = 'Richtig';
_fc_props.status = 'Status';
_fc_props.distance = 'Distanz';
_fc_props.ok = 'Ok';
_fc_props.login = 'Anmelden';
_fc_props.next = 'Nächste';
_fc_props.menu = 'Menü';
_fc_props.not_available = 'N/Z';
_fc_props.back = 'Zurück';
_fc_props.select = 'Auswählen';
_fc_props.dispatch = 'Beförderung';
_fc_props.messaging = 'Nachrichten';
_fc_props.plants = 'Werke';
_fc_props.last_plants = 'Letzte Werke';
_fc_props.vehicle_types = 'Fahrzeugstypen';
_fc_props.employee_types = 'Mitarbeitertypen';
_fc_props.employee_groups = 'Mitarbeitergruppen';
_fc_props.equipment_groups = 'Gerätgruppen';
_fc_props.job = 'Arbeit';
_fc_props.order = 'Bestellung';
_fc_props.ticket = 'Fahrkarte #';
_fc_props.small = 'Klein';
_fc_props.medium = 'Medium';
_fc_props.large = 'Groß';
_fc_props.reports = 'Berichte';
_fc_props.payroll = 'Gehaltsliste';
_fc_props.support = 'Unterstützung';
_fc_props.username = 'Benutzername';
_fc_props.all = 'Alle';
_fc_props.question = 'Frage';
_fc_props.select_employee = 'Mitarbeiter Wählen';
_fc_props.phone = 'Telefon';
_fc_props.january = 'Januar';
_fc_props.february = 'Februar';
_fc_props.march = 'März';
_fc_props.april = 'April';
_fc_props.may = 'Mai';
_fc_props.june = 'Juni';
_fc_props.july = 'Juli';
_fc_props.august = 'August';
_fc_props.september = 'September';
_fc_props.october = 'Oktober';
_fc_props.november = 'November';
_fc_props.december = 'Dezember';
_fc_props.are_you_sure_you_want_to_delete = 'Sind Sie sicher, dass Sie es löschen möchten?';
_fc_props.unknown = 'Unbekannt';
_fc_props.select_all = 'Alle Wählen';
_fc_props.equipment = 'Gerät';
_fc_props.region = 'Region';
_fc_props.no_data = 'Kein Data';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Adresse';
_fc_props.retrieving_address = 'Adresse wiederherstellen';
_fc_props.address_not_found = 'Adresse nicht gefunden';
_fc_props.active_time = 'Aktive Zeit';
_fc_props.destination_time = 'Zeit beim Ziel';
_fc_props.complete_time = 'Gesamtzeit';
_fc_props.status_report = 'Statusbericht';
_fc_props.export_report = 'Bericht Exportieren';
_fc_props.download_report = 'Bericht Herunterladen';
_fc_props.view_report = 'Bericht Anschauen';
_fc_props.duration = 'Dauer';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Startdatum/Zeit';
_fc_props.select_start_date = 'Startdatum Wählen';
_fc_props.end_date_time = 'Enddatum/Zeit';
_fc_props.select_end_date = 'Enddatum Wählen';
_fc_props.trip_time = 'Reisezeit';
_fc_props.travel_time = 'Reisezeit';
_fc_props.description = 'Beschreibung';
_fc_props.map_controls = 'Kartenkontroll';
_fc_props.road = 'Weg';
_fc_props.aerial = 'Aerial';
_fc_props.hybrid = 'Hybrid';
_fc_props.trip = 'Reise';
_fc_props.map = 'Karte';
_fc_props.plant = 'Anlage';
_fc_props.select_date = 'Datum Wählen';
_fc_props.save_settings = 'Einstellungen Speichern';
_fc_props.false = 'Falsch';
_fc_props.delete_confirmation = 'Bestätigung Löschen';
_fc_props.last_name = 'Nachname';
_fc_props.none_selected = 'Kein Ausgewählt';
_fc_props.start_date = 'Startdatum';
_fc_props.group_by = 'Gruppierung nach';
_fc_props.equipment_employee = 'Gerät / Mitarbeiter';
_fc_props.inactive = 'Inaktiv';
_fc_props.active = 'Aktiv';
_fc_props.note_checking_may_affect_performance = 'Notiz: Die Prüfung kann die Leistung beeinflussen';
_fc_props.show_distancefuel_used = 'Distanz/Benzin Anzeigen';
_fc_props.mins = 'Minuten';
_fc_props.threshold_time = 'Schwellenzeit';
_fc_props.include_voided_tickets = 'Bitte die aufgehobene Tickets einbeziehen';
_fc_props.note_all_times_listed_are_in_minutes = 'Notiz: Alle aufgelistete Zeiten sind in Minuten';
_fc_props.please_select_a_job_order_or_equipment = 'Bitte wählen Sie eine Stelle, einen Auftrag, eine Anlage, eine Ausrüstung oder einen Mitarbeiter aus';
_fc_props.device = 'Apparat';
_fc_props.select_equipment = 'Gerät Wählen';
_fc_props.all_equipment_types = 'Alle Gerättypen';
_fc_props.units = {};
_fc_props.units.liters = 'Liter';
_fc_props.type = 'Typ';
_fc_props.no_data_available = 'Kein Data ist verfügbar';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Distanz-und Kraftstoff nach Region';
_fc_props.menu_settings_title_settings = 'Einstellungen';
_fc_props.menu_settings_title_alerts = 'Warnungen';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Ausrüstung';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots und Regionen';
_fc_props.days = '{0} Tage';
_fc_props.home = 'Home';
_fc_props.find = 'Finden';
_fc_props.close_this_panel = 'dieses Panel schließen';
_fc_props.start = 'Beginnen';
_fc_props.end = 'Enden';
_fc_props.location_details = 'Standorteinzelheiten';
_fc_props.first_name = 'Vorname';
_fc_props.material_per_hour = 'Material pro Stunde';
_fc_props.equipment_pound = 'Gerät #';
_fc_props.equipment_type = 'Gerättyp';
_fc_props.options = 'Optionen';
_fc_props.zoom_on_cursor_position = 'Zoom auf Kursurposition';
_fc_props.display_equipment_identifier = 'Gerätbezeichner Anzeigen';
_fc_props.view_equipment = 'Gerät Anschauen';
_fc_props.view_hotspots = 'Hotspot Anschauen';
_fc_props.view_regions = 'Regionen Anschauen';
_fc_props.use_standard_filters = 'Standard Filter Verwenden';
_fc_props.home_plant = 'Home-Anlage';
_fc_props.last_plant = 'Letzte Werke';
_fc_props.equip_types = 'Gerät Typen';
_fc_props.equip_groups = 'Gerät Gruppe';
_fc_props.orders = 'Bestellungen';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Gerätfilter Verwenden';
_fc_props.all_selected = 'Alle Ausgewählt';
_fc_props.of = 'Von';
_fc_props.your_map_session_has_expired = 'Ihre Kartensession ist abgelaufen, bitte aktualisieren Sie die Seite.';
_fc_props.equipment_short = 'Gerät';
_fc_props.job_info_for = 'Arbeitsinfo für';
_fc_props.order_info_for = 'Bestellungsinfo für';
_fc_props.ticket_info_for_vehicle = 'Ticketinfo für Fahrzeug';
_fc_props.inbox = 'Inbox';
_fc_props.outbox = 'Outbox';
_fc_props.alerts = 'Warnungen';
_fc_props.critical = 'Kritisch';
_fc_props.important = 'Wichtig';
_fc_props.informational = 'Informationell';
_fc_props.view_routes = 'Route Anschauen';
_fc_props.hours_size = 'Dienststunden';
_fc_props.used_count = 'Verwendete Anzahl';
_fc_props.last_login = 'Letze Anmeldung';
_fc_props.last_vehicle = 'Letzes Fahrzeug';
_fc_props.regions = 'Regionen';
_fc_props.driver = 'Fahrer';
_fc_props.js_delete = 'Gelöscht';
_fc_props.move = 'Verschieben';
_fc_props.edit_hotspot = 'Hotspot Bearbeiten';
_fc_props.adjust_shape = 'Form Anpassen';
_fc_props.edit_region = 'Region Bearbeiten';
_fc_props.enter_a_start_address = 'Bitte eine Start-Adresse eingeben';
_fc_props.select_a_start_hotspot = 'Bitte einen Start-Hotspot wählen';
_fc_props.failed_to_find_start_hotspot = 'Start-Hotspot ist nicht gefunden';
_fc_props.invalid_latitudelongitude = 'Ungültige Breite / Länge';
_fc_props.enter_an_end_address = 'Bitte eine Ende-Adresse eingeben';
_fc_props.select_an_end_hotspot = 'Bitte einen Ende-Hotspot wählen';
_fc_props.failed_to_find_end_hotspot = 'Ende-Hotspot konnte nicht gefunden werden.';
_fc_props.print = 'Drucken';
_fc_props.these_directions_are_for_informational_purposes = 'Diese Angaben dienen nur zur Informationszwecken. Keine Garantie kann auf Vollständigkeit und Richtigkeit übernommen werden. Bauvorhaben, Verkehr, oder andere Ereignisse können die tatsächlichen Bedingungen von diesen Ergebnissen beeinflussen.';
_fc_props.enter_an_address = 'Bitte eine Adresse eingeben';
_fc_props.select_a_hotspot = 'Bitte einen Hotspot wählen';
_fc_props.failed_to_find_hotspot = 'Hotspot ist nicht gefunden';
_fc_props.select_a_vehicle = 'Bitte ein Fahrzeug wählen';
_fc_props.failed_to_find_vehicle = 'Fahrzeug zu finden ist gescheitert.';
_fc_props.failed_to_find_address = 'Adresse zu finden ist gescheitert.';
_fc_props.failed_to_find_vehicles = 'Fahrzeug in Suchkriterien zu finden ist gescheitert.';
_fc_props.get_address = 'Adresse Erhalten';
_fc_props.results_for = 'Ergebnisse für';
_fc_props.error_retrieving_results = 'Fehler beim Abrufen der Ergebnisse';
_fc_props.no_results_found = 'Keine Ergebnisse Gefunden';
_fc_props.address_is_required = 'Adresse ist erforderlich';
_fc_props.city_is_required = 'Stadt ist erforderlich';
_fc_props.set_location = 'Standort Einstellen';
_fc_props.set_area_of_interest = 'Interessenbereich Einstellen';
_fc_props.center_map_here = 'Karte Hier Zentrieren';
_fc_props.zoom_to_street_level = 'Zoom auf Straßenebene';
_fc_props.set_start = 'Start Einstellen';
_fc_props.set_end = 'Ende Einstellen';
_fc_props.delete_node = 'Knotenpunkt Löschen';
_fc_props.create_a_hotspot = 'Hotspot Erstellen';
_fc_props.create_a_region = 'Region Erstellen';
_fc_props.toggle_shapes = 'Formen Umschalten';
_fc_props.refresh_map = 'Karte aktualisieren';
_fc_props.more_options = 'Mehrere Optionen';
_fc_props.recenter_map = 'Karte  Re-Zentrieren';
_fc_props.zoom_to_vehicle = 'Zoom auf Fahrzeug';
_fc_props.apply_filters = 'Filter Verwenden';
_fc_props.grid_view = 'Gitteransicht';
_fc_props.list_view = 'Listenansicht';
_fc_props.click_to_sort = 'Klick zum Sortieren';
_fc_props.message_history = 'Nachrichtenverläufe';
_fc_props.equipment_types = 'Gerättypen';
_fc_props.send_message = 'Nachricht senden';
_fc_props.last_load = 'Letzte Fuhre';
_fc_props.last_load_on = 'Letzte Fuhre am';
_fc_props.manual_logout = 'Manuelles Abmelden';
_fc_props.manual_login = 'Manuelles Anmelden';
_fc_props.no_employees_logged_in = 'Kein Mitarbeiter ist angemeldet';
_fc_props.select_phone = 'Telefon wählen';
_fc_props.you_must_select_a_phone = 'Sie müssen Telefon, Gerät und Mitarbeiter wählen';
_fc_props.are_you_sure_you_want_to_logout = 'Sind Sie sicher, dass Sie abmelden möchten';
_fc_props.fix_time = 'Fix Zeit';
_fc_props.to_next_fix = 'zu Nächstem Fix';
_fc_props.minutes_to_next_fix = 'Minuten Zu Nächstem Fix';
_fc_props.liters = 'Liter';
_fc_props.destination = 'Ziel';
_fc_props.return = 'Zurück';
_fc_props.product = 'Produkt';
_fc_props.no_records_found = 'Kein Aufzeichnungen wurden gefunden.';
_fc_props.vehicle_type = 'Fahrzeugstyp';
_fc_props.view_in_map = 'Alle in der Mappe Anschauen';
_fc_props.permanent = 'Beständig';
_fc_props.expire_time = 'Verlaufszeit';
_fc_props.please_select_x_for_tickets_marked_active = 'Bitte wählen Sie {0} für Tickets, die AKTIV markiert sind';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Mehrere Tickets wurden als AKTIV für das gleiche {0}markiert';
_fc_props.failure_connecting_to_servlet = 'Konnte keine Verbindung mit Servlet herstellen.';
_fc_props.status_time_summary = 'Statuszeitüberblick';
_fc_props.view_in_map_one = 'In der Mappe Anschauen';
_fc_props.question_report = 'Fragebericht';
_fc_props.end_date = 'Enddatum';
_fc_props.trailer_number = 'Trailer-Nummer';
_fc_props.region_panel = 'Regionpanel';
_fc_props.hotspot_panel = 'Hotspot-Panel';
_fc_props.shape = 'Form';
_fc_props.circle = 'Kreis';
_fc_props.polygon = 'Vieleck';
_fc_props.click_the_map_to_set_the_location = 'Klicken Sie auf die Karte, um den Standort auszuwählen.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Klicken Sie auf die Karte, um das Hotspot-Center einzustellen.';
_fc_props.click_the_map_to_set_the_region_center = 'Klicken Sie auf die Karte, um das Region-Center einzustellen.';
_fc_props.click_and_drag_points = 'Klicken und bewegen Sie die Punkte, um die Form zu bearbeiten. Klicken Sie auf SPEICHERN, um die Änderung zu speichern.';
_fc_props.size = 'Größe';
_fc_props.are_you_sure_you_want_to_move_this = 'Sind Sie sicher, dass Sie es bewegen möchten?';
_fc_props.are_you_sure_you_want_to_delete_this = 'Sind Sie sicher, dass Sie es löschen möchten?';
_fc_props.please_enter_a_valid_size = 'Bitte geben Sie eine gültige Größe';
_fc_props.failed_to_save = 'Speicherung Gescheitert.';
_fc_props.failed_to_move = 'Bewegung Gescheitert.';
_fc_props.failed_to_delete = 'Löschung gescheitert.';
_fc_props.x_hours = '{0} stunden';
_fc_props.hide_hotspots = 'Hotspot Verstecken';
_fc_props.show_regions = 'Regionen Anzeigen';
_fc_props.show_hotspots = 'Hotspots Anzeigen';
_fc_props.hide_regions = 'Regionen Verstecken';
_fc_props.numeric = 'Numerisch';
_fc_props.message_type = 'Nachrichtentyp';
_fc_props.text = 'Text';
_fc_props.yes_no = 'Ja/Nein';
_fc_props.responses = 'Rückmeldungen';
_fc_props.disabled = 'Deaktiviert';
_fc_props.group_name = 'Gruppenname';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Sind Sie sicher, dass Sie das Format \'{0}\'  löschen möchten?';
_fc_props.phone_number = 'Telefonnummer';
_fc_props.interface_pound = 'Interface #';
_fc_props.field = 'Felder';
_fc_props.default_label = 'Default Etikett';
_fc_props.custom_label = 'Kundenetikett';
_fc_props.you_must_enter_a_label = 'Sie müssen ein Etikett eingeben.';
_fc_props.enabled = 'Aktiviert';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Ticket Integration';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence Aus offene Hotspots';
_fc_props.settings.associate_open_hotspots_wticket = 'Offene Hotspots/Tickets Zuordnen';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Geofence nur für offene Hotspots oder für Hotspots mit zugeordneten Fahrertickets zu erlauben';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Offene Hotspots mit Tickets zuzuordnen oder nicht';
_fc_props.settings.void_ticket_without_destination = 'Das Ticket ohne Ziele aufzuheben';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'Wenn ein Ticket erhalten wird, während ein anderes aktiv ist und wenn das aktive Ticket keinen Ziel hat, wird das Ticket ungültig sein.';
_fc_props.settings.autocreate_hotspots = 'Hotspot automatisch erstellen';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Hotspots unabhängig oder nicht unabhängig automatisch von der Zieladresse, wenn ein Ticket empfangen wird, zu erstellen';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Ticket beim Empfang automatisch aktivieren';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Ist das Tikcet aktive, wenn es vom TrackIt empfangen ist (Standard ‚wahr‘)';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Ticket beim Empfang automatisch schließen';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Wie lange sollte das aktuell aktivierte Ticket aktiv sein, bevor es automatisch geschlossen wird (Standard ‚‘)';
_fc_props.settings.ticket_max_activate_age_minutes = 'Ticket Max. Aktivierte Alter in Minuten';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Wie alt ein Ticket in Minuten sein kann, wenn es in TrackIt aktiviert wird (Standard“)';
_fc_props.settings.max_hotspot_unused_days = 'Max. Hotspots Unbenutzte Tage';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Bestimmt die maximale unbenutzte Tage eines Hotspots bis es erneurt werden kann';
_fc_props.settings.radius_multiplier = 'Radius Multiplier';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Den Radius multiplizieren, wenn ein Ticket aus dem Versandsystem erhalten wird';
_fc_props.settings.ticket_message_date_format = 'Ticketnachricht Datumformat';
_fc_props.am = 'am';
_fc_props.pm = 'pm';
_fc_props.settings.ticket_message = 'Ticketnachricht';
_fc_props.settings.add_ticket_element = 'Ticketelement Hinzufügen';
_fc_props.driver_performance = 'Fahrerleistung';
_fc_props.update_failed_can_not_connect = 'Update Gescheitert: Konnte keine Verbindung mit Servlet herstellen.';
_fc_props.unknown_error = 'Unbekannte Fehler';
_fc_props.selected = 'Ausgewählt';
_fc_props.web_service = 'Webservice';
_fc_props.error_processing_request = 'Fehler beim Anforderungsprozess.';
_fc_props.email_address = 'Email-Adresse';
_fc_props.id = 'ICH WÜRDE';
_fc_props.last_used = 'Letzte Verwendung';
_fc_props.error_invalid_vehiclenum = 'Fehler Ungültige Fahrzeugnummer';
_fc_props.error_vehicle_is_not_logged_in = 'Fehler: Fahrzeug ist nicht angemeldet';
_fc_props.users = 'Benutzer';
_fc_props.you_dont_have_access_to_this_page = 'Sie haben keinen Zugriff, um diese Seite anzuzeigen. Bitte wenden Sie sich für den Zugriff an Ihren Systemadministrator.';
_fc_props.previous_week = 'Vorherige Woche';
_fc_props.status_breakdown = 'Statusaufteilung';
_fc_props.select_plant_or_employee_group = 'Sie müssen eine Anlage oder eine Mitarbeitergruppe wählen.';
_fc_props.cannot_select_plant_and_employee_group = 'Sie können sowohl die Anlage und die Mitarbeitergruppe nicht wählen.';
_fc_props.odometer_must_be_a_number = 'Der Beginn-Kilometerzähler muss eine Nummer sein.';
_fc_props.ending_odometer_must_be_a_number = 'Der End-Kilometerzähler muss eine Nummer sein.';
_fc_props.beginning_hour_meter_must_be_a_number = 'Der Beginn-Stundenzähler muss eine Nummer sein.';
_fc_props.ending_hour_meter_must_be_a_number = 'Der End-Stundenzähler muss eine Nummer sein.';
_fc_props.error_loading_status_change_page = 'Fehler bei der Ladung von Statusänderung-Seite.';
_fc_props.x_is_an_invalid_time_format = '{0} ist ein ungültiges Zeitformat, es soll YYYY-MM-DD HH:MM:SS sein';
_fc_props.x_for_schedule_login = '{0} für Anmeldung planen';
_fc_props.x_for_schedule_logout = '{0} für Ausmeldung Planen';
_fc_props.x_for_actual_login = '{0} für aktuelle Anmeldung';
_fc_props.x_for_actual_logout = '{0} für aktuelle Ausmeldung';
_fc_props.by_refreshing_the_time_card_detail = 'Mit der Aktualisierung der Zeitkarteneinzelheiten werden Sie alle Änderungen verlieren, setzen Sie damit fort?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Sind Sie sicher, dass Sie {0} löschen möchten?';
_fc_props.settings.command_mobile_ticket_url = 'Command Mobile Ticket URL';
_fc_props.year = 'Jahr';
_fc_props.make = 'Machen';
_fc_props.model = 'Modell';
_fc_props.settings.command_mobile_ticket_password = 'Befehls mobilen Ticket Passwort';
_fc_props.settings.command_mobile_ticket_auth_token = 'Befehls Mobil Ticket Auth Token';
_fc_props.target = 'Ziel';
_fc_props.number_of_digits = 'Anzahl der Stellen';
_fc_props.number_of_integer_digits = 'Anzahl der Vorkommastellen';
_fc_props.number_of_fractional_digits = 'Anzahl der Nachkommastellen';
_fc_props.search = 'Suche';
_fc_props.settings.command_mobile_ticket_date_order = 'Befehls Mobil Ticket Datum Sortieren';
_fc_props.settings.command_mobile_ticket_date_length = 'Befehls Mobil Ticket Datum Länge';
_fc_props.settings.command_mobile_ticket_date_separator = 'Befehls Mobil Ticket Datumstrenn';
_fc_props.settings.command_mobile_ticket_slash = '/ (Schrägstrich)';
_fc_props.settings.command_mobile_ticket_dash = '- (Bindestrich)';
_fc_props.settings.command_mobile_ticket_period = '. (Punkt)';
_fc_props.settings.command_mobile_ticket_space = '(Leerzeichen)';
_fc_props.settings.command_mobile_ticket_time_length = 'Befehls mobilen Ticket Zeit Länge';
_fc_props.settings.command_mobile_ticket_time_separator = 'Befehls Mobil Ticket Zeit Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (Doppelpunkt)';
_fc_props.settings.command_mobile_ticket_time_format = 'Befehls Mobil Ticket Zeitformat';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Verwenden Schnittstelle Anzahl';
_fc_props.mechanic = 'Mechaniker';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Poll-Intervall (Sekunden)';
_fc_props.driving_time = 'Fahrzeit';
_fc_props.find_near_plants = 'Finden in der Nähe von Pflanzen';
_fc_props.please_create_hot_spot_first = 'Bitte erstellen Sie zuerst Hotspot';
_fc_props.please_enter_hot_spot_description = 'Bitte geben Sie Hotspot Beschreibung';
_fc_props.please_select_a_hot_spot_type = 'Bitte wählen Sie ein Hot-Spot-Typ';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Senden Sie E-Mail-Treiber';
_fc_props.directions_not_found = 'Ungültige Adresse {0}. Anfahrt nicht gefunden.';
_fc_props.ws_user = 'Benutzer';
_fc_props.ws_service = 'Bedienung';
_fc_props.ws_method = 'Verfahren';
_fc_props.ws_params = 'params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Herkunft und Ziel';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Hilfe';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Mit Alt Mitarbeiternummer';
_fc_props.settings.command_mobile_ticket_android_url = 'Android Handy Ticket-URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Ticket Geheimnis';
_fc_props.employee_x_has_no_logout_on_y = 'Mitarbeiter {0} hat keine Abmeldung für eine Verschiebung ab {1}.';
_fc_props.phone_number_has_duplicates = 'Telefonnummern haben doppelte Einträge';
_fc_props.ounce = 'Unze';
_fc_props.water_extra = 'Wasser extra';
_fc_props.always = 'Immer';
_fc_props.work_types_must_be_unique = 'Arbeitstypen muss eindeutig sein';
_fc_props.manufacturer = 'Hersteller';
_fc_props.need_to_enter_seconds = 'Sie müssen Sekunden eingeben.';
_fc_props.copy = 'Kopieren';
_fc_props.create_in_existing_required = 'Erstellen in vorhandener muss ausgewählt werden, um Roaming-Hotspot zu erstellen.';
_fc_props.break = 'Unterbrechung';
_fc_props.add_export_data_type = 'Datentyp hinzufügen';
_fc_props.edit_export_data_type = 'Bearbeiten Export Datentyp';
_fc_props.delete_export_data_type = 'Löschen des Datentyps löschen';
_fc_props.export_data_type = 'Datentyp importieren';
_fc_props.error_updating_export_data_type = 'Fehler beim Aktualisieren des Exportdatentyps';
_fc_props.settings.address_key = 'Adressschlüssel';
_fc_props.settings.address_key_hover = 'Adressschlüsseloptionen';
_fc_props.settings.address_key_options = 'Adressschlüsseloptionen';
_fc_props.more_filter_options = 'Weitere Filteroptionen';
_fc_props.unauthorized_mapit_app = 'Diese Version von MapIt ist nicht autorisiert, bitte aktualisieren Sie MapIt so schnell wie möglich.';
_fc_props.zello_timeout = 'Timeout trat bei der Kontaktaufnahme mit Zello auf.';
_fc_props.user_agreement_text = 'Mit der Anmeldung stimmst du dem zu';
_fc_props.user_agreement = 'Nutzungsbedingungen';
_fc_props.x_mins_left = '{0} min (s) übrig';
_fc_props.continuous_location_tracking_colon = 'Kontinuierliche Standortverfolgung:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Hauler kann kein neues Gerät registrieren.';
_fc_props.timecard_does_not_contain_shift = 'Employee Time Card enthält nicht die angegebenen Schichtdaten (Time Card Info)';
_fc_props.timecard_not_found = 'Mitarbeiter-Zeitkarte nicht gefunden';
_fc_props.vehicle_must_be_assigned_for_clt = 'Das Fahrzeug muss den Orten zugeordnet sein.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Das Fahrzeug wurde nicht zugewiesen. Die kontinuierliche Verfolgung wird deaktiviert.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Verbindung mit EDC verloren. Bitte überprüfen Sie, ob WLAN und / oder Bluetooth aktiviert sind.';
_fc_props.acknowledge_all = 'Bestätigen Sie alle';
_fc_props.expand = 'Erweitern';
_fc_props.collapse = 'Zusammenbruch';
_fc_props.expand_all = 'Alle erweitern';
_fc_props.collapse_all = 'Alles ausblenden';
_fc_props.last_update = 'Letztes Update';
_fc_props.acknowledge_alert = 'Alarm bestätigen';
_fc_props.acknowledge_message = 'Nachricht bestätigen';
_fc_props.acknowledge_all_alerts = 'Alle Alarme bestätigen';
_fc_props.acknowledge_all_messages = 'Alle Nachrichten bestätigen';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Toleranz muss eine gültige ganze Zahl zwischen -99 und 99 sein (beide enthalten)';
_fc_props.gps_permission_required = 'GPS-Genehmigung erforderlich';
_fc_props.permissions_required = 'Erforderliche Berechtigungen';
_fc_props.change_user = 'Benutzer wechseln';
_fc_props.account_not_registered_with_carrier = 'Dieses Konto ist nicht bei einem Carrier registriert';
_fc_props.slump_loss = 'Einbruch Verlust';
_fc_props.slump_loss_help = 'Slump-Verlusthilfe';
_fc_props.did_not_receive_any_edc_devices = 'Ich habe keine EDC-Geräte erhalten.';
_fc_props.edc_record_not_found = 'EDC-Datensatz wurde nicht gefunden';
_fc_props.disable_hotspot_prompt = 'Tippen Sie auf "OK", wenn Sie dazu aufgefordert werden, um Ihren Hotspot zu deaktivieren.';
_fc_props.edc_device_is_in_use = 'EDC {0} wird verwendet.';
_fc_props.an_unknown_exception_has_occurred = 'Eine unbekannte Ausnahme ist aufgetreten';
_fc_props.login_has_timed_out = 'Login ist abgelaufen';
_fc_props.use_paper_logs = 'Verwenden Sie Papierprotokolle';
_fc_props.eld_login_failed = 'Anmeldung während der Geschäftszeiten fehlgeschlagen';
_fc_props.setup_time_off_type = 'Bitte legen Sie eine Freizeitart fest.';
_fc_props.view_load_properties = 'Ladeeigenschaften anzeigen';
_fc_props.couldnt_download_edc_firmware = 'EDC-Firmware konnte nicht heruntergeladen werden.';
_fc_props.password_incorrect = 'Falsches Passwort';
_fc_props.edc_configuration_password_prompt = 'Aufgrund des Status dieses Geräts müssen Sie das zu konfigurierende Installationskennwort eingeben.';
_fc_props.edc_configuration_check_failed = 'Die Validierung der EDC-Server-Datensätze ist fehlgeschlagen. Bitte überprüfen Sie die Fehlermeldungen auf Details.';
_fc_props.configuration_issues_prompt = 'Die folgenden Probleme wurden beim Konfigurieren des ausgewählten EDC für den angegebenen Stapler festgestellt';
_fc_props.checking_password = 'Passwort wird überprüft';
_fc_props.reset_password_instructions_sent = 'Anweisungen zum Zurücksetzen Ihres Passworts an die E-Mail-Adresse {0} gesendet.';
_fc_props.reset_password_failed = 'Passwort zurücksetzen fehlgeschlagen, versuchen Sie es erneut.';
_fc_props.forgot_password = 'Passwort vergessen?';
_fc_props.enter_valid_email = 'Ungültige E-Mail-Adresse. Geben Sie eine gültige E-Mail-Adresse ein und versuchen Sie es erneut.';
_fc_props.issues_while_updating_edc_record = 'Der EDC wurde erfolgreich konfiguriert. Es gibt jedoch Probleme mit den Datensätzen für den EDC auf dem TrackIt-Server, die dazu führen können, dass er nicht funktioniert. Bitte rufen Sie den Support an, um die Informationen zu korrigieren.';
_fc_props.sending = 'Senden ...';
_fc_props.minimum_ratio = 'Mindestverhältnis';
_fc_props.vin = 'FIN';
_fc_props.probe_rpm_not_in_range = 'Trommeldrehzahl nicht im Bereich';
_fc_props.probe_failure = 'Sondenfehler';
_fc_props.unknown_probe_data_failure = 'Unbekannter Testdatenfehler';
_fc_props.no_loaded_status_meaning = 'Kein geladener Status';
_fc_props.no_depart_status_meaning = 'Kein Abflugstatus';
_fc_props.no_arrive_status_meaning = 'Kein Ankunftsstatus';
_fc_props.no_begin_work_status_meaning = 'Nein Arbeitsbeginn Status';
_fc_props.no_fully_mixed_status_meaning = 'Kein vollständig gemischter Status';
_fc_props.no_begin_work_or_at_job_status = 'Arbeitsbeginn- oder Arbeitsstatus nicht vorhanden';
_fc_props.driver_didnt_follow_procedure = 'Fahrer hat die Prozedur nicht befolgt (Trommel nicht verlangsamt)';
_fc_props.probe_malfunction = 'Fehlfunktion der Sonde (Aufbau, interner Fehler usw.)';
_fc_props.no_data_from_probe = 'Keine Daten von der Sonde (Datenverlust)';
_fc_props.missing_probe_calibration = 'Keine Sondenkalibrierungsdaten';
_fc_props.probe_pressure_not_in_range = 'Sondendruck nicht im Bereich';
_fc_props.arrival = 'Ankunft';
_fc_props.small_load = 'KLEINE LAST';
_fc_props.dry_slump = 'DRY SLUMP';
_fc_props.probe_remixing_turns = 'Remixen';
_fc_props.turns_needed_to_remix = 'Wasser hinzugefügt - zusätzliches Mischen erforderlich';
_fc_props.probe_remixing_finished = 'Remix fertig';
_fc_props.probe_battery_low_alert = 'Niedriger Alarm bei Sondenbatterie';
_fc_props.probe_battery_low_message = 'Sondenbatterie schwach';
_fc_props.probe_battery_low = 'Sondenbatterie schwach';
_fc_props.alert_when_probe_battery_low = 'Alarm, wenn die Sondenbatterie schwach ist';
_fc_props.probe_sensor_fatal_failure = 'Tödlicher Ausfall des Sensors';
_fc_props.probe_thermistor_failure = 'Thermistorfehler';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor in Beton eingeklebt';
_fc_props.probe_sensor_over_weight = 'Sensor übergewichtig';
_fc_props.probe_batteries_low = 'Batterie schwach';
_fc_props.no_probe_data = 'Keine Sondendaten:';
_fc_props.check_probe = 'Überprüfen Sie die Sonde';
_fc_props.service_probe = 'Service Probe';
_fc_props.below_x = 'Unter {0}';
_fc_props.above_x = 'Über {0}';
_fc_props.probe_serial_number_invalid_format = 'Die Probenseriennummer hat kein gültiges Format, wie im Platzhalter für das Feld angezeigt';
_fc_props.talk_dnd_permission_prompt = 'Für die Diskussion ist die Berechtigung "NICHT STÖREN" erforderlich.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Status wurde zu schnell geändert';
_fc_props.is_empty = 'Ist leer';
_fc_props.slump_increased_plasticizer_question = 'Wurden Weichmacher zugesetzt?';
_fc_props.slump_increased_water_added_question = 'Wie viel ungemessenes Wasser wurde hinzugefügt?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Probe- und Lieferzyklusüberwachung werden auf demselben Gerät nicht zusammen unterstützt.';
_fc_props.no_data_from_sensor = 'Keine Daten vom Sensor (Datenverlust)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Systemtyp';
_fc_props.magnet_count = 'Magnetzahl';
_fc_props.sensors_setup = 'Sensoreinrichtung';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'Für DCM müssen die MAC-Adresse der E / A-Box, der Trommeltyp, der DCM-Typ und die Magnetanzahl eingestellt sein.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Ticketed Volume';
_fc_props.drs_current_volume = 'Aktuelle Lautstärke';
_fc_props.drs_current_water_cement_ratio = 'Aktuelles Wasser / Zement-Verhältnis';
_fc_props.drs_target_water_cement_ratio = 'Ziel-Wasser / Zement-Verhältnis';
_fc_props.drs_total_revolutions = 'Gesamt';
_fc_props.drs_current_volume_lp_small = 'Aktuell';
_fc_props.drum_revolutions = 'Trommelumdrehungen';
_fc_props.drs_total_revolutions_lp_small = 'Gesamt';
_fc_props.drs_charging_revolutions_lp_small = 'Aufladen';
_fc_props.drs_discharging_revolutions_lp_small = 'Entladen';
_fc_props.drs_target_wc_lp_small = 'Ziel W / C.';
_fc_props.drs_current_wc_lp_small = 'Aktuelle W / C.';
_fc_props.drs_ticketed_volume_lp_small = 'Ticketed';
_fc_props.android_location_disclosure = 'TrackIt verwendet feine und grobe Standortdaten, um Standortdaten im Hintergrund zu sammeln, auch wenn sie nicht verwendet werden, um eine automatische Statusanzeige und Kartensichtbarkeit in Echtzeit für den Versand zu ermöglichen.';
_fc_props.android_location_usage = 'Standortangabe';
_fc_props.end_pour_trigger_threshold = 'End Pour Trigger Threshold';
_fc_props.drs_wiring_error = 'Verdrahtungsfehler';
_fc_props.drs_wiring_pulse_error = 'Verdrahtungsimpulsfehler';
_fc_props.drs_wiring_magnet_error = 'Verdrahtungsmagnetfehler';
_fc_props.drs_magnet_missing_error = 'Magnet fehlt Fehler';
_fc_props.android_location_disclosure_3p = 'TrackIt sammelt Standortdaten im Hintergrund, um die automatische Statusbestimmung und Echtzeit-Kartensichtbarkeit für den Versand zu ermöglichen. Beim Ausstempeln vom Versender wird der Standort nicht freigegeben.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Bitte wählen Sie entweder Full MDM oder Trackit Remote Support';
_fc_props.move_up = 'Nach oben gehen';
_fc_props.move_down = 'Sich abwärts bewegen';
_fc_props.driver_not_found = 'Treiber nicht gefunden.';
_fc_props.multiple = 'Mehrere';
_fc_props.water_meter_installed = 'Wasserzähler installiert';
_fc_props.delete_waypoint = 'Wegpunkt löschen';
_fc_props.wash_sensor_installed = 'Waschsensor installiert';
_fc_props.washing_start_time = 'Waschstartzeit';
_fc_props.end_washing = 'Waschen beenden';
_fc_props.variable_rpms = 'Var U / min';
_fc_props.no_slump = 'Kein Einbruch';
_fc_props.end_washing_duration = 'Endwaschdauer';
_fc_props.privacy_policy = 'Datenschutz-Bestimmungen';
_fc_props.privacy_policy_text = 'Ich habe das gelesen und akzeptiert';
_fc_props.login_fail_accept_privacy_policy = 'Datenschutzbestimmungen müssen akzeptiert werden, um fortzufahren.';
_fc_props.rotation_sensor = 'Rotationssensor';
_fc_props.pto_sensor = 'Zapfwellensensor';
_fc_props.pto_sensor_installed = 'Zapfwellensensor installiert';
_fc_props.polarity_reversed = 'Umgekehrte Polarität';
_fc_props.pto_sensor_type = 'Zapfwellensensortyp';
_fc_props.sensor_type = 'Sensorart';
_fc_props.prompt_select_pto_sensor_type = 'Sie müssen einen "PTO-Sensortyp" auswählen, wenn Sie einen "PTO-Sensor" als Hauptsensortyp ausgewählt haben.';
_fc_props.priming_turns_at_full_load = 'Ansaugen dreht sich bei Volllast';
_fc_props.priming_turns_at_ten_percent_load = 'Ansaugen dreht sich bei 10 % Last';
_fc_props.weight_units = 'Gewichtseinheiten';
_fc_props.settings.send_status = 'Status/Standort an Event Bridge senden';
_fc_props.volume_difference = 'Lautstärkeunterschied';
_fc_props.mobile_ticket_iframe_url = 'IFrame-URL für mobiles Ticket';
_fc_props.mobile_ticket_user = 'Benutzer von Mobile-Tickets';
_fc_props.mobile_ticket_password = 'Handy-Ticket-Passwort';
_fc_props.current_truck_status = 'Aktueller LKW-Status';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Ausgelöst durch maximale Umdrehungen';
_fc_props.alerts_by_type = 'Benachrichtigungen nach Typ';
_fc_props.water_flow_meter = 'Wasserdurchflussmesser';
_fc_props.flow_meter_no_pulses_warning = 'Keine Impulse - Warnung';
_fc_props.flow_meter_no_pulses_critical = 'Keine Impulse - Kritisch';
_fc_props.flow_meter_excessive_pulses = 'Übermäßige Impulse';
_fc_props.flow_meter_constantly_flowing = 'Ständig fließend';
_fc_props.flow_meter_good = 'Gut';
_fc_props.whats_new = 'Was gibt\'s Neues';
_fc_props.whats_new_widget_text = 'Wussten Sie, dass wir TrackIt mindestens einmal im Monat aktualisieren? Holen Sie sich die neuesten Updates und Tipps.';
_fc_props.get_the_scoop = 'Holen Sie sich die Schaufel';
_fc_props.trackit_insights = 'Trackit-Einblicke';
_fc_props.business_analytics_blurb = 'Business Analytics, die Ihr Endergebnis verbessern, jetzt in TrackIt . enthalten';
_fc_props.find_out_more = 'Finde mehr heraus';
_fc_props.daily_breakdown = 'Tägliche Aufschlüsselung';
_fc_props.question_summary = 'Fragenzusammenfassung';
_fc_props.trip_summary = 'Reisezusammenfassung';
_fc_props.manage_employees = 'Mitarbeiter verwalten';
_fc_props.manage_hotspots = 'Hotspots verwalten';
_fc_props.manage_devices = 'Geräte verwalten';
_fc_props.manage_equipment = 'Ausrüstung verwalten';
_fc_props.manage_users = 'Benutzer verwalten';
_fc_props.manage_statuses = 'Status verwalten';
_fc_props.manage_alerts = 'Benachrichtigungen verwalten';
_fc_props.popular_report_links = 'Beliebte Berichtslinks';
_fc_props.popular_settings_links = 'Links zu beliebten Einstellungen';
_fc_props.sidemenu_settings = 'Einstellungen';
_fc_props.loads_per_driver = 'Belastungen pro Fahrer';
_fc_props.please_select_application = 'Bitte wählen Sie mindestens eine Anwendung aus';
_fc_props.external_app_processing = 'Verarbeitung auf externer App';
_fc_props.external_app_sent = 'An externe App gesendet';
_fc_props.external_app_received = 'Von externer App empfangen';
_fc_props.wait_time_at_job = 'Wartezeit bei Job';
_fc_props.wait_time_at_plant = 'Wartezeit im Werk';
_fc_props.loading_time = 'Ladezeit';
_fc_props.unloading_time = 'Entladezeit';
_fc_props.pre_post_trip = 'Pre/Post Trip';
_fc_props.update_thresholds = 'Schwellenwerte aktualisieren';
_fc_props.green = 'Grün';
_fc_props.yellow = 'Gelb';
_fc_props.red = 'rot';
_fc_props.between = 'Zwischen';
_fc_props.num_of_loads = 'Anzahl der Ladungen';
_fc_props.num_of_drivers = 'Anzahl der Fahrer';
_fc_props.status_meaning_not_setup = 'Status Bedeutung nicht eingerichtet';
_fc_props.or_less = 'Oder weniger';
_fc_props.or_greater = 'Oder größer';
_fc_props.probe_self_diagnostic_state = 'Selbstdiagnosestatus der Sonde';
_fc_props.update_current_truck_status_settings = 'Aktuelle Lkw-Statuseinstellungen aktualisieren';
_fc_props.default_behavior = 'Standard (Top 10 Status)';
_fc_props.combine_similar_statuses = 'Kombinieren Sie gleiche Status aus allen Typen (Schleifen)';
_fc_props.use_other_category = '\'Andere\' Kategorie verwenden';
_fc_props.selected_statuses = 'Ausgewählte Status';
_fc_props.ideas = 'Ideen';
_fc_props.air_content = 'Luftinhalt';
_fc_props.air_measured_time = 'Luft gemessene Zeit';
_fc_props.ten_max = '10 max';
_fc_props.loads_per_plant = 'Lasten pro Pflanze';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Ungültiger Impuls des Durchflussmessers pro Volumen.';
_fc_props.coming_soon_title = 'Weitere Widgets folgen in Kürze';
_fc_props.submit_your_ideas = 'Senden Sie Ihre Ideen über die Glühbirne in Ihrem linken Navigationsmenü an unser Ideenportal.';
_fc_props.selected_plants = 'Ausgewählte Pflanzen';
_fc_props.update_loads_per_plant_settings = 'Laden pro Werk Einstellungen aktualisieren';
_fc_props.default_top_10_plants = 'Standard (Top 10 Pflanzen)';
_fc_props.phone_number_will_be_uploaded = 'Die Geräte-ID wird zu Registrierungszwecken hochgeladen.';
_fc_props.include_deleted = 'Einschließen gelöscht';
_fc_props.primary_status_type = 'Primärer Statustyp';
_fc_props.additional_status_trigger = 'Zusätzliche Statusauslöser';
_fc_props.update_tickets_destination = 'Aktualisieren Sie das Ziel auf allen zugehörigen Tickets';
_fc_props.settings.send_destination_changes_to_connex = 'Zieländerungen an Connex senden';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Ist das Ticket aktiv, wenn es von TrackIt empfangen wird (Standard \'immer\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Automatisches Aktivieren des nächsten Tickets bei Abschluss des vorherigen Tickets';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Soll das nächste Ticket nach Abschluss des Tickets automatisch aktivieren (Standard \'true\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status in Ruhezustand, wenn kein aktives Ticket';
_fc_props.break_down = 'Abbauen';
_fc_props.idle = 'Leerlauf';
_fc_props.pto_work_maximum_speed_limit = 'Maximale Arbeitsgeschwindigkeit';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Verknüpfen Sie eine Statusänderung, die ein Ticket mit diesem Ticket abschließt';
_fc_props.approaching = 'Annäherung';
_fc_props.performing_task = 'Aufgabe ausführen';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Möchten Sie Geogate {0} wirklich löschen?';
_fc_props.trackit_destination_changed_to_0 = 'Ticket {0} aktualisieren. Ziel geändert.';
_fc_props.trackit_return_destination_changed_to_0 = 'Ticket {0} aktualisieren. Rückgabe geändert.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Geogate hinzufügen';
_fc_props.driver_enabled = 'Treiber aktiviert';
_fc_props.dispatcher_enabled = 'Dispatcher aktiviert';
_fc_props.runsheet_number = 'Runsheet-Nummer';
_fc_props.manual_load = 'Manuelles Laden';
_fc_props.edit_geogate = 'Geogate bearbeiten';
_fc_props.handling = 'Handhabung';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate-Panel';
_fc_props.vehicle_is_not_logged_in = 'Nachricht nicht gesendet (Fahrzeug ist nicht angemeldet)';
_fc_props.air = 'Luft';
_fc_props.create_a_geogate = 'Erstellen Sie ein Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Klicken Sie auf die Karte, um die Geogate-Punkte festzulegen.';
_fc_props.clock_out = 'Ausstempeln';
_fc_props.handling_operation = 'Handhabungsbetrieb';
_fc_props.view_geogates = 'Geogates anzeigen';
_fc_props.select_a_geogate = 'Bitte wählen Sie ein Geogate aus';
_fc_props.failed_to_find_geogate = 'Geogate konnte nicht gefunden werden';
_fc_props.adjust_geogate = 'Geogate anpassen';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate-Ablaufdatum Uhrzeit Standardmäßige zusätzliche Tage';
_fc_props.menu_settings_title_retention = 'Vorratsdatenspeicherung';
_fc_props.category = 'Kategorie';
_fc_props.telemetry = 'Telemetrie';
_fc_props.ticket_data = 'Ticketdaten';
_fc_props._30_days = '30 Tage';
_fc_props._6_months = '6 Monate';
_fc_props._1_year = '1 Jahr';
_fc_props._3_years = '3 Jahre';
_fc_props.new_group = 'Neue Gruppe';
_fc_props.selected_vehicles = 'Ausgewählte Fahrzeuge';
_fc_props.group_name_is_required_field = 'Der Gruppenname ist ein Pflichtfeld';
_fc_props.at_least_vehicle_should_be_selected = 'Mindestens 1 Fahrzeug sollte ausgewählt werden';
_fc_props.groups = 'Gruppen';
_fc_props.trucks = 'Lastwagen';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate wurde entfernt. Bitte aktualisieren Sie die Seite.';
_fc_props.air_timestamp = 'Flugzeitstempel';
_fc_props.fail = 'SCHEITERN';
_fc_props.stuck = 'GESTECKT';
_fc_props.dirty = 'SCHMUTZIG';
_fc_props.seasonal = 'Saisonal';
_fc_props.category_details = 'Kategoriedetails';
_fc_props.alert_events = 'Alert-Ereignisse';
_fc_props.driver_performance_infractions = 'Verstöße gegen die Fahrerleistung';
_fc_props.telemetry_details = 'Standortdaten, Hotspots, Zeit in Hotspots, Zeit in Regionen, DCM-Daten, Sondendaten, Engine-Daten';
_fc_props.ticket_data_details = 'Ticketdaten';
_fc_props.general_details = 'Statusänderungen, Zeitkarten, Zeitpläne';
_fc_props.wash = 'Waschen';
_fc_props.filter_options = 'Filteroptionen';
_fc_props.home_plants = 'Heimische Pflanzen';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'Für DCM PTO muss der PTO-Sensortyp eingestellt werden.';
_fc_props.invalid_io_box_mac_address = 'Ungültige E/A-Box-MAC-Adresse';
_fc_props.access_denied = 'Zugriff abgelehnt';
_fc_props.wash_events = 'Waschen Sie Ereignisse';
_fc_props.min_speed = 'Mindestgeschwindigkeit';
_fc_props.temperature_change = 'Temperaturänderung';
_fc_props.degrees_cap = 'Grad';
_fc_props.washout_tracking = 'Washout-Verfolgung';
_fc_props.approaching_wash = 'Annäherung an Wash';
_fc_props.arrival_wash = 'Ankunft waschen';
_fc_props.performing_wash = 'Waschen durchführen';
_fc_props.wash_complete = 'Komplett waschen';
_fc_props.must_be_positive_or_zero = 'Muss positiv oder Null sein!';
_fc_props.min_speed_greater_than_max = 'Die Mindestgeschwindigkeit muss kleiner als die Höchstgeschwindigkeit sein!';
_fc_props.clock_in = 'Den Arbeitsbeginn registrieren';
_fc_props.mix_water_at_x_to_y_for_t = 'Mischen Sie Wasser bei {0} bis {1} U/min für {2} Umdrehungen.';
_fc_props.discharge_water_for_x_revolutions = 'Lassen Sie Wasser für {0} Umdrehungen ab.';
_fc_props.eod_drum_wash_complete = 'Trommelwäsche abgeschlossen! <br> Stoppen Sie die Trommel mit der Sonde oben.';
_fc_props.washing_complete = 'Geführtes Auswaschen abgeschlossen';
_fc_props.eod_washout_no_data_available = 'Sensordaten nicht verfügbar, bitte Waschvorgang abschließen.';
_fc_props.eod_washout_no_data_available_title = 'Geführtes Auswaschen nicht verfügbar';
_fc_props.menu_reports_title_ticket_time_summary = 'Zusammenfassung der Ticketzeit';
_fc_props.menu_reports_description_ticket_time_summary = 'Zeigen Sie Ticketzeitinformationen für eine Bestellung oder ein Fahrzeug an';
_fc_props.menu_reports_title_summary = 'Zusammenfassung';
_fc_props.menu_reports_title_ticket_summary = 'Ticketübersicht';
_fc_props.menu_reports_description_summary1 = 'Sehen Sie sich eine Zusammenfassung der Zeit in Hotspots an';
_fc_props.menu_dispatch_title_main = 'Hauptsächlich';
_fc_props.menu_dispatch_title_login_registry = 'Login-Registrierung';
_fc_props.menu_reports_title_main = 'Hauptsächlich';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Sind Sie sicher, dass Sie diesen Exportdatentyp löschen möchten?';
_fc_props.dvir_status_paper_logs = 'Papierprotokolle';
_fc_props.menu_reports_title_billing_report = 'Abrechnungsbericht';
_fc_props.menu_reports_description_billing_report = 'Abrechnungsbericht';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status zum Ticketstatus bei der Aktivierung der Arbeit';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status in „Ticketstatus“ bei Arbeitsaktivierung (Standardeinstellung „False“)';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex-EID';
_fc_props.units_weight_pound_label = 'Pfund';
_fc_props.units_weight_kilogram_label = 'Kilogramm';
_fc_props.settings_weight = 'Gewicht';
_fc_props.units_pounds = 'Pfund';
_fc_props.units_kilograms = 'Kilogramm';
_fc_props.geo_gate_edit_modal_description = 'Beschreibung';
_fc_props.geo_gate_edit_modal_start_point = 'Startpunkt';
_fc_props.geo_gate_edit_modal_end_point = 'Endpunkt';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Ausstattungsart';
_fc_props.geo_gate_edit_modal_heading_south = 'Von Süden nach Norden';
_fc_props.geo_gate_edit_modal_heading_north = 'Von Norden nach Süden';
_fc_props.geo_gate_edit_modal_driver_message = 'Fahrernachricht';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Versandnachricht';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Ablaufdatum und Uhrzeit';
_fc_props.geo_gate_edit_modal_permanent = 'Dauerhaft';
_fc_props.geo_gate_edit_modal_critical = 'Kritisch';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Fehler beim Speichern von Geogate: Das Beschreibungsfeld muss ausgefüllt werden.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Fehler beim Speichern von Geogate: Das Feld „Startpunkt“ muss ausgefüllt werden.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Fehler beim Speichern von Geogate: Das Feld „Startpunkt“ enthält ein ungültiges Datenformat.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Fehler beim Speichern von Geogate: Das Endpunktfeld enthält ein ungültiges Datenformat.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Fehler beim Speichern von Geogate: Das Feld „Ablaufdatum/Uhrzeit“ muss ausgefüllt werden.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Fehler beim Speichern von Geogate: Das Feld „Ablaufdatum/Uhrzeit“ enthält ein ungültiges Datenformat.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Fehler beim Speichern von Geogate: Von Norden nach Süden – Das Feld „Versandnachricht“ muss ausgefüllt sein';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Fehler beim Speichern von Geogate: Keine Änderungen erkannt';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Fehler beim Speichern von Geogate: Das Feld „Ablaufdatum/Uhrzeit“ enthält ein abgelaufenes Datum/Uhrzeit';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Fehler beim Speichern von Geogate: Das Beschreibungsfeld darf nur Buchstaben und Zahlen enthalten';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Fehler beim Speichern von Geogate: Das Feld „Gerätetyp“ muss ausgefüllt sein';
_fc_props.geo_gate_table_message_label_north_to_south = 'Von Norden nach Süden';
_fc_props.geo_gate_table_message_label_south_to_north = 'Von Süden nach Norden';
_fc_props.geo_gate_table_message_label_both = 'Beide';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Breitengrad, Längengrad';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Wenn Sie dies aktivieren, wird die Einrichtung für die Beschränkung der Gerätebewegungsmeldung überschrieben';
_fc_props.geo_gate_edit_modal_seasonal = 'Saisonal';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Fehler beim Speichern von Geogate: Saisonale Daten müssen ausgefüllt werden';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Fehler beim Speichern von Geogate: Saisonale Datumsfelder enthalten ein ungültiges Datenformat';
_fc_props.test_js_test_javascript = 'Testen Sie Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometer';
_fc_props.units_miles = 'Meilen';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Fehler beim Speichern von Geogate: Von Süden nach Norden – das Feld „Fahrermeldung“ muss ausgefüllt sein';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Fehler beim Speichern von Geogate: Von Süden nach Norden – das Feld „Versandnachricht“ muss ausgefüllt sein';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Fehler beim Speichern von Geogate: Von Norden nach Süden – das Feld „Fahrermeldung“ muss ausgefüllt sein';
_fc_props.geo_gate_heading = 'Überschrift';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Fehler beim Speichern von Geogate: Das Feld „Endpunkt“ muss ausgefüllt werden';
_fc_props.units_mile = 'Meile';
_fc_props.units_kilometer = 'Kilometer';
_fc_props.cloud_dvir__exit = 'Ausfahrt';
_fc_props.cloud_dvir__signature = 'Unterschrift';
_fc_props.cloud_dvir__inspection = 'Inspektion';
_fc_props.cloud_dvir__add_photos_notes = 'Fotos/Notizen hinzufügen';
_fc_props.cloud_dvir__odometer_reading = 'Kilometerstand';
_fc_props.cloud_dvir__engine_hours = 'Motorstunden';
_fc_props.cloud_dvir__truck_details = 'LKW-Details';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Bestimmen Sie den Gesamtzustand des Fahrzeugs';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Vollständiger Name und Unterschrift des Fahrers';
_fc_props.cloud_dvir__sign = 'Zeichen';
_fc_props.cloud_dvir__driver_note_from = 'Fahrerhinweis von';
_fc_props.cloud_dvir__add_notes = 'Notizen hinzufügen';
_fc_props.cloud_dvir__driver_notes = 'Treiberhinweise';
_fc_props.cloud_dvir__done = 'Erledigt';
_fc_props.cloud_dvir__needs_repair = 'Benötigt Reparatur';
_fc_props.cloud_dvir__not_applicable = 'Unzutreffend';
_fc_props.cloud_dvir__review_and_sign = 'Überprüfen und unterschreiben';
_fc_props.cloud_dvir__add_photos = 'Foto hinzufügen';
_fc_props.cloud_dvir__upload_photo_limit = 'Sie können bis zu 3 Bilder hochladen';
_fc_props.cloud_dvir__mismatch_title = 'Nichtübereinstimmungswarnung';
_fc_props.cloud_dvir__ok = 'OK';
_fc_props.cloud_dvir_template = 'Cloud-DVIR-Vorlage';
_fc_props.cloud_dvir__needs_review = 'DVIR muss überprüft werden';
_fc_props.cloud_dvir__submitted = 'Eingereicht';
_fc_props.cloud_dvir__completed_by = 'Vervollständigt von';
_fc_props.cloud_dvir__review_btn = 'Rezension';
_fc_props.cloud_dvir__truck_number = 'LKW {0}';
_fc_props.cloud_dvir__pending_review = 'Ausstehende Bewertung';
_fc_props.cloud_dvir__start_dvir = 'Starten Sie DVR';
_fc_props.cloud_dvir__dvir_history = 'DVIR-Geschichte';
_fc_props.cloud_dvir__view_all = 'Alle ansehen';
_fc_props.load_zone = 'Ladezone';
_fc_props.view_load_zones = 'Ladezonen anzeigen';
_fc_props.edit_load_zone = 'Ladezone bearbeiten';
_fc_props.create_a_load_zone = 'Erstellen Sie eine Ladezone';
_fc_props.load_zone_panel = 'Ladezonen-Panel';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Klicken Sie auf die Karte, um den Mittelpunkt der Lastzone festzulegen.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Bei dieser Inspektion wurden Mängel festgestellt und Sie haben entschieden, dass dieses Fahrzeug betriebssicher ist. Bitte bestätigen Sie, bevor Sie fortfahren';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Bei dieser Inspektion wurden keine Mängel festgestellt und Sie haben entschieden, dass der Betrieb dieses Fahrzeugs unsicher ist. Bitte bestätigen Sie, bevor Sie fortfahren.';
_fc_props._90_days = '90 Tage';
_fc_props.cloud_dvir = 'Cloud-DVIR';
_fc_props.cloud_dvir_details = 'Cloud-DVIR-Einreichungen';
_fc_props.show_load_zones = 'Belastungszonen anzeigen';
_fc_props.hide_load_zones = 'Ladezonen ausblenden';
_fc_props.no = 'Nein';
_fc_props.cloud_dvir__reset = 'Zurücksetzen';
_fc_props.cloud_dvir__clear = 'Klar';
_fc_props.mark_read = 'als gelesen markieren';
_fc_props.mark_unread = 'Als ungelesen markieren';
_fc_props.read = 'Lesen';
_fc_props.unread = 'Ungelesen';
_fc_props.air_sensor_serial_number = 'Seriennummer des Luftsensors';
_fc_props.air_sensor_mac_address = 'MAC-Adresse des Luftsensors';
_fc_props.air_sensor_serial_number_invalid_format = 'Die Seriennummer des Luftsensors hat kein gültiges Format: (alphanumerisch und höchstens 32 Zeichen lang mit Bindestrichen)';
_fc_props.air_sensor_mac_address_invalid_format = 'Die MAC-Adresse des Luftsensors weist kein gültiges Hexadezimalformat auf, wie im Platzhalter für das Feld angezeigt';
_fc_props.air_sensor_license = 'Luftsensor';
_fc_props.adjust_route_point = 'Routenpunkt anpassen';
_fc_props.route_point_panel = 'Routenpunkt-Panel';
_fc_props.route_point = 'Routenpunkt';
_fc_props.cloud_dvir__pre_trip = 'Vor der Reise';
_fc_props.cloud_dvir__post_trip = 'Nach der Reise';
_fc_props.cloud_dvir__other_insction_type = 'Andere';
_fc_props.cloud_dvir__dvir_completed = 'Vollendet';
_fc_props.cloud_dvir__awaiting_mechanic = 'Warten auf den Mechaniker';
_fc_props.cloud_dvir__awaiting_driver = 'Warten auf die Freigabe durch den Fahrer';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Die Seriennummer des Luftsensors konnte nicht überprüft werden. Wenden Sie sich für weitere Unterstützung an den Support.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Die Seriennummer dieses Luftsensors existiert bereits. Bitte verwenden Sie eine andere Seriennummer';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Die MAC-Adresse des Luftsensors konnte nicht überprüft werden. Bitte wenden Sie sich an den Support';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Fehler beim Senden der DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Bitte versuche es erneut';
_fc_props.ticket_integration_descr = 'Mit der Ticket-Integration können Sie Einstellungen für die Ticket-Integration sowie den Startbildschirm, den Ladebildschirm und die fest montierte Anzeige festlegen';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR erfolgreich übermittelt';
_fc_props.cloud_dvir__inspection_trip_type = 'Art der Inspektionsfahrt';
_fc_props.menu_dvir_title_mechanic = 'Mechaniker';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Luftsensor-Firmware';
_fc_props.when_no_active_ticket = 'Wenn kein aktives Ticket vorhanden ist';
_fc_props.used_by = 'Benutzt von';
_fc_props.fields = 'Felder';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Sind Sie sicher, dass Sie das Ticketlayout „{0}“ löschen möchten?';
_fc_props.menu_settings_title_ticket_layout = 'Ticketlayout';
_fc_props.add_ticket_layout = 'Ticketlayout hinzufügen';
_fc_props.edit_ticket_layout = 'Ticketlayout bearbeiten';
_fc_props.ticket_layout_edit_modal_name = 'Name';
_fc_props.ticket_layout_edit_modal_default = 'Standard';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Fehler beim Speichern des Ticketlayouts: Das Feld „Name“ muss ausgefüllt werden.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Fehler beim Speichern des Ticketlayouts: Das Namensfeld darf nur Buchstaben und Zahlen enthalten';
_fc_props.ticket_layout_edit_modal_fields = 'Felder';
_fc_props.ticket_layout_edit_modal_usage = 'Verwendung';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Das Ticketlayout wurde entfernt. Bitte aktualisieren Sie die Seite.';
_fc_props.more_lower_case = 'mehr';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Fehler beim Speichern des Ticketlayouts: Ein oder mehrere Felder müssen ausgewählt werden.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Fehler beim Speichern des Ticketlayouts: Es müssen ein oder mehrere Status ausgewählt werden.';
_fc_props.cloud_dvir__ad_hoc = 'Ad hoc';
_fc_props.radius_multiplier_should_be = 'Der Radiusmultiplikator sollte eine Gleitzahl sein';
_fc_props.ticket_max_activate_age_err = '„Ticket Max Activate Age Minutes“ sollte eine Ganzzahl sein.';
_fc_props.max_hotspot_unused_days_errs = '„Max. Hotspot-nicht genutzte Tage“ sollte eine Ganzzahl sein.';
_fc_props.begin_pour_air_content = 'Beginnen Sie mit dem Gießen des Luftgehalts';
_fc_props.arrival_air_content = 'Luftinhalt bei der Ankunft';
_fc_props.fully_mixed_air_content = 'Vollständig gemischter Luftgehalt';
_fc_props.departure_air_content = 'Abfluginhalt';
_fc_props.popup_enabled = 'Popup aktiviert';
_fc_props.popup_disabled = 'Popup deaktiviert';
_fc_props.cloud_dvir__leave_confirmation_title = 'Warnung';
_fc_props.cloud_dvir__leave_confirmation_description = 'Wenn Sie die Seite jetzt verlassen, gehen alle Änderungen verloren.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Bestätigen';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Stornieren';
_fc_props.air_bt_ratio = 'Luft-BT-Verhältnis';
_fc_props.cloud_dvir__view_photos_notes = 'Fotos/Notizen anzeigen';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Durch das Hinzufügen des Status wird eine leere Verwendung in einem anderen Ticketlayout erstellt. Möchten Sie mit der Hinzufügung fortfahren?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Das Fahrzeug verfügt nicht über eine Luftsensorlizenz.';
_fc_props.air_sensor_installed = 'Luftsensor installiert';
_fc_props.load_card = 'Karte laden';
_fc_props.cloud_dvir__inspection_profile = 'Inspektionsprofil';
_fc_props.cloud_dvir__view_details = 'Details anzeigen';
_fc_props.cloud_dvir__certify_repairs = 'Zertifizieren Sie Reparaturen';
_fc_props.cloud_dvir__repairs_made = 'Reparaturen durchgeführt';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparaturen nicht erforderlich';
_fc_props.cloud_dvir__mechanic_note_from = 'Mechaniker-Notiz von';
_fc_props.cloud_dvir__mechanic_assignment = 'Mechanikerauftrag';
_fc_props.cloud_dvir__mechanic_signature_description = 'Markieren Sie unten alle zutreffenden Punkte und unterschreiben Sie, dass das Fahrzeug ordnungsgemäß überprüft wurde.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Der Zustand dieses Fahrzeugs ist zufriedenstellend';
_fc_props.cloud_dvir__defects_corrected_label = 'Mängel wurden behoben';
_fc_props.cloud_dvir__repair_not_required_label = 'Für den sicheren Betrieb des Fahrzeugs ist keine Reparatur erforderlich';
_fc_props.safety = 'Sicherheit';
_fc_props.cloud_dvir__awaiting_sign = 'Wartezeichen';
_fc_props.cloud_dvir__dvir_review = 'DVR-Rezension';
_fc_props.settings.mobile_auto_start_navigation = 'Navigation auf Mobilgeräten automatisch starten';
_fc_props.cloud_dvir__edit = 'Bearbeiten';
_fc_props.delete_custom_label = 'Benutzerdefiniertes Etikett löschen';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Ihre Version von TrackIt ist veraltet und einige DVIR-Funktionen funktionieren möglicherweise nicht richtig. Bitte aktualisieren Sie TrackIt, bevor Sie fortfahren.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Beim Hochladen der Signatur ist ein Fehler aufgetreten. Bitte versuche es erneut';
_fc_props.max_hotspot_unused_days_error = 'Die maximale Anzahl ungenutzter Hotspot-Tage beträgt maximal 1000 Tage.';
_fc_props.menu_dvir_title_admin_templates = 'Vorlagen';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Administrator';
_fc_props.cloud_dvir__admin_templates = 'Vorlagen';
_fc_props.form_name = 'Formularname';
_fc_props.form_status = 'Formularstatus';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Herunterladen';
_fc_props.cloud_dvir__option_view = 'Sicht';
_fc_props.cloud_dvir__inspectortype_driver = 'Treiber';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Benutzerdefinierte DVIR-Formulare und -Übermittlungen anzeigen/bearbeiten/erstellen';
_fc_props.menu.dvir.description.mechanic = 'Umsetzbare DVIR-Anträge anzeigen/bearbeiten';
_fc_props.not_in_use = 'Nicht in Gebrauch';
_fc_props.logged_in = 'eingeloggt';
_fc_props.reviewing_driver = 'Überprüfung des Treibers';
_fc_props.cloud_dvir__report_title = 'Fahrzeuginspektionsbericht des Fahrers';
_fc_props.cloud_dvir__report_signed = 'unterzeichnet';
_fc_props.cloud_dvir__report_page_counter = 'Seite {0} von {1}';
_fc_props.cloud_dvir__report_defects = 'Mängel';
_fc_props.cloud_dvir__report_comments = 'Kommentare';
_fc_props.cloud_dvir__report_inspection_table_title = 'Fahrzeuginspektion';
_fc_props.cloud_dvir__report_driver_table_title = 'Fahrerinformationen';
_fc_props.cloud_dvir__report_carrier = 'Träger';
_fc_props.cloud_dvir__report_truck = 'LKW';
_fc_props.cloud_dvir__report_location = 'Standort';
_fc_props.cloud_dvir__report_odometer = 'Kilometerzähler';
_fc_props.cloud_dvir__report_hours = 'Std';
_fc_props.cloud_dvir__report_no_comments = 'Keine Kommentare';
_fc_props.include_unlicensed_vehicles = 'Einschließlich nicht lizenzierter Fahrzeuge';
_fc_props.cloud_dvir__search_by_form_name = 'Nach Formularnamen suchen';
_fc_props.cloud_dvir__create_dvir = 'Erstellen Sie ein DVIR';
_fc_props.cloud_dvir__update_dvir = 'DVRIR aktualisieren';
_fc_props.cloud_dvir__form_name = 'Formularname';
_fc_props.cloud_dvir__form_description = 'Formularbeschreibung';
_fc_props.cloud_dvir__form_status = 'Formularstatus';
_fc_props.information = 'Information';
_fc_props.cloud_dvir__search_categories = 'Kategorien durchsuchen';
_fc_props.cloud_dvir__create_new_category = 'Neue Kategorie erstellen';
_fc_props.category_name = 'Kategoriename';
_fc_props.sub_category = 'Unterkategorie';
_fc_props.sub_category_name = 'Name der Unterkategorie';
_fc_props.delete_category = 'Kategorie löschen';
_fc_props.cloud_dvir__dvir_information = 'DVIR-Informationen';
_fc_props.cloud_dvir__dvir_choose_categories = 'Wählen Sie Kategorien';
_fc_props.cloud_dvir__create_category = 'Kategorie erstellen';
_fc_props.cloud_dvir__update_category = 'Kategorie aktualisieren';
_fc_props.delete_route_point = 'Routenpunkt löschen';
_fc_props.view_route_points = 'Routenpunkte anzeigen';
_fc_props.ignore_cancel_preload = 'Ignorieren Sie die Stornierung, Sie sind vorgeladen. Dein Homerun wird bald gezeigt';
_fc_props.cloud_dvir__assigned_mechanic = 'Zugewiesener Mechaniker';
_fc_props.cloud_dvir__add_mechanic = 'Fügen Sie einen Mechaniker hinzu';
_fc_props.contact_type = 'Kontaktart';
_fc_props.cloud_dvir__send_email_notifications = 'Senden Sie E-Mail-Benachrichtigungen';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Fehler beim Abrufen der DVIR-Verlaufsdatensätze.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Fehler beim Abrufen des DVIR-Datensatzes.';
_fc_props.cloud_dvir__error_saving_submission = 'Fehler beim Speichern der Übermittlung. Versuchen Sie es erneut.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Fehler beim Abrufen der Mechanikliste.';
_fc_props.cloud_dvir__template_saved = 'Vorlage gespeichert';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Fehler beim Abrufen der Vorlagenliste.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Fehler beim Speichern der Vorlage. Versuchen Sie es erneut.';
_fc_props.dispatch_group = 'Versandgruppe';
_fc_props.save_adjust = 'Routenpunkt speichern und anpassen';
_fc_props.reset_route_point = 'Routenpunkt zurücksetzen';
_fc_props.no_records_stub_adjust_filters = 'Versuchen Sie, Ihre Such- oder Filtereinstellungen anzupassen, um die Anzahl der Ergebnisse zu erhöhen.';
_fc_props.no_dvir_records = 'Alle haben DVIRs eingeholt!';
_fc_props.no_dvir_records_sub_title = 'Du hast viel erreicht';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'Kein Slump-Messwert erhalten für: {0}';
_fc_props.please_select_a_sensor_status_type = 'Bitte wählen Sie die Sensorstatustypen aus, um diese Warnung auszulösen';
_fc_props.settings.master_data_connex_token = 'Stammdaten-Connex-Token';
_fc_props.error_validating_master_data_connex_credentials = 'Fehler beim Validieren der Master Data Connex-Anmeldeinformationen';
_fc_props.settings.master_data_connex_base_url = 'Stammdaten-Connex-Basis-URL';
_fc_props.settings.master_data_connex_exp_time = 'Ablaufzeit der Stammdatenverbindung';
_fc_props.menu_settings_title_dispatch_group = 'Versandgruppen';
_fc_props.settings.dispatch_groups = 'Versandgruppen';
_fc_props.dispatch_group_name = 'Name der Versandgruppe';
_fc_props.dispatch_group_plants = 'Versandgruppenwerke';
_fc_props.dispatch_group_plants_mapping_message = 'Geben Sie an, welche Werke Teil dieser Versandgruppe sein werden.';
_fc_props.dispatch_group_users_mapping_message = 'Geben Sie an, welche Benutzer Teil dieser Dispatch-Gruppe sein werden.';
_fc_props.dispatch_group_plant_disassociation_message = 'Die Anlage sollte jeweils einer Versandgruppe zugeordnet sein';
_fc_props.dispatch_group_search_by_plant = 'Suche nach Pflanzennamen';
_fc_props.dispatch_group_search_by_username = 'Suche nach Benutzername';
_fc_props.search_by_dispatch_group_name = 'Suche nach Dispatch-Gruppenname';
_fc_props.create_dispatch_group = 'Erstellen Sie eine Versandgruppe';
_fc_props.update_dispatch_group = 'Dispatch-Gruppe aktualisieren';
_fc_props.dispatch_group_saved = 'Versandgruppe gespeichert';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Durch das Löschen dieser Dispatch-Gruppe werden Standard-Dispatch-Gruppen für die zugehörigen Werke erstellt.';
_fc_props.error_saving_dispatch_group = 'Fehler beim Speichern der Versandgruppe. Versuchen Sie es erneut.';
_fc_props.please_enter_a_valid_plant_num = 'Bitte geben Sie eine gültige Anlagennummer ein.';
_fc_props.cloud_dvir__report_no_defects = 'Keine Defekte';
_fc_props.shoud_select_dispatch_group = 'Sie müssen mindestens eine Versandgruppe auswählen';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Das Löschen ist nicht möglich, da diese Dispatch-Gruppe Benutzer enthält, die nur dieser Dispatch-Gruppe zugeordnet sind.';
_fc_props.cloud_dvir__username = 'Nutzername';
_fc_props.cloud_dvir__password = 'Passwort';
_fc_props.cloud_dvir__login = 'Anmeldung';
_fc_props.cloud_dvir__mechanic_sign_in = 'Mechaniker anmelden';
_fc_props.delete_dispatch_group = 'Versandgruppe löschen';
_fc_props.cloud_dvir__driver = 'Treiber';
_fc_props.cloud_dvir__equipment_pound = 'Ausrüstung #';
_fc_props.cloud_dvir__mechanic = 'Mechaniker';
_fc_props.dispatch_group_user_only_unassociate_message = 'Benutzer können nicht gelöscht werden, da Benutzer mindestens einer Dispatch-Gruppe zugeordnet sein müssen.';
_fc_props.cloud_dvir__time_period_filter_today = 'Heute';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Gestern';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Letzten 7 Tage';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Letzte 30 Tage';
_fc_props.cloud_dvir__date_range_filter = 'Nach Datum filtern';
_fc_props.cloud_dvir__download = 'Herunterladen';
_fc_props.dispatch_group_is_empty = 'Die Versandgruppe darf nicht leer sein.';
_fc_props.dispatch_group_with_empty_plants = 'Der Versandgruppe muss mindestens ein Werk zugeordnet sein.';
_fc_props.dispatch_group_with_empty_users = 'Der Versandgruppe muss mindestens ein Benutzer zugeordnet sein.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Die Dispatch-Gruppe kann nicht gelöscht werden, da sie Benutzer enthält, die nur dieser Dispatch-Gruppe zugeordnet sind.';
_fc_props.dispatch_group_not_exists = 'Die Versandgruppe existiert nicht.';
_fc_props.dispatch_group_contains_one_plant = 'Das Löschen ist nicht möglich, da diese Dispatch-Gruppe nur ein Werk enthält.';
_fc_props.dispatch_group_users = 'Versenden Sie Gruppenbenutzer';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Fehler beim Abrufen des DVIR-Datensatzes';
_fc_props.cloud_dvir__assign_mechanic = 'Mechaniker zuweisen';
_fc_props.cloud_dvir__submit_review = 'Bewertung abschicken';
_fc_props.error_deleting_dispatch_group = 'Fehler beim Löschen der Versandgruppe.';
_fc_props.error_retrieving_dispatch_groups = 'Fehler beim Abrufen der Liste der Dispatch-Gruppen.';
_fc_props.cloud_dvir__undo_selection = 'Auswahl rückgängig machen';
_fc_props.enable_master_data = 'Stammdaten aktivieren';
_fc_props.manually_sync = 'Manuell synchronisieren';
_fc_props.master_data_connex_token_expiration = 'Ablauf des Stammdaten-Connex-Tokens';
_fc_props.cloud_dvir__time_period_filter_custom = 'Brauch';
_fc_props.settings.zello_session_timeout_minutes = 'Zeitüberschreitung der Zello-Sitzung (Minuten)';
_fc_props.menu_settings_title_master_data = 'Stammdaten';
_fc_props.settings.master_data = 'Stammdaten';
_fc_props.error_retrieving_master_data = 'Fehler beim Abrufen der Stammdaten';
_fc_props.master_data_sync_completed = 'Stammdatensynchronisierung abgeschlossen';
_fc_props.master_data_successfully_updated = 'Daten erfolgreich aktualisiert';
_fc_props.master_data_failed_to_update = 'Speichern fehlgeschlagen.';
_fc_props.master_data_sync_initiated = 'Stammdatensynchronisierung eingeleitet';
_fc_props.master_data_sync_failed = 'Die Stammdatensynchronisierung ist fehlgeschlagen';
_fc_props.enabled_excluding_origin_on_deliveries = 'Richtig (ohne Herkunft auf Lieferscheinen)';
_fc_props.enabled_including_origin_on_deliveries = 'Richtig (einschließlich Herkunft auf Lieferscheinen)';
_fc_props.cloud_dvir__i_will_decide_later = 'Ich werde später entscheiden';
_fc_props.returning = 'Rückkehr';
_fc_props.leftover_concrete = 'Übriggebliebener Beton';
_fc_props.leftover_concrete_on_vehicle_x = 'Betonreste am Fahrzeug {0}';
_fc_props.leftover_concrete_alert = 'Alarm für übrig gebliebenen Beton';
_fc_props.wc_ratio_exceeded = 'W/C-Verhältnis überschritten';
_fc_props.search_by_site_name = 'Suche nach Site-Namen';
_fc_props.sites = 'Websites';
_fc_props.token_expiration = 'Ablauf des Tokens';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API-Schlüsselverwaltung';
_fc_props.settings.connex_api_key_management = 'Connex API-Schlüsselverwaltung';
_fc_props.error_retrieving_connex_api_key_data = 'Fehler beim Abrufen der Connex-API-Schlüsseldaten';
_fc_props.qr_code = 'QR-Code';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informieren von Treiber und Schnittstelle bei Eingabe eines falschen Werks';
_fc_props.enable_driver_message = 'Fahrermeldung aktivieren';
_fc_props.enable_dispatch_message = 'Versandnachricht aktivieren';
_fc_props.enable_sending_message_to_interface = 'Senden von Nachrichten an die Schnittstelle aktivieren';
_fc_props.ticket_qr_code = 'Ticket-QR-Code';
_fc_props.leftover_concrete_alert_description = 'Alarm, wenn nach dem Gießen Betonreste gefunden werden.';
_fc_props.minimum_leftover_concrete_amount = 'Mindestrestbetonmenge';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Fehler beim Validieren der Master Data Connex-Anmeldeinformationen nicht autorisiert';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Fehler beim Validieren der Connex-Anmeldeinformationen für Stammdaten';
_fc_props.error_validating_master_data_connex_url_not_found = 'Fehler beim Validieren der Master Data Connex-URL nicht gefunden';
_fc_props.error_validating_master_data_connex_unknown_error = 'Fehler beim Validieren der Stammdaten Connex Unbekannter Fehler';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Die Synchronisierung der Stammdaten ist fehlgeschlagen. Synchronisieren Sie den Fahrzeugtyp.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Die Synchronisierung der Stammdaten ist fehlgeschlagen. Fahrzeug synchronisieren.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Die Stammdatensynchronisierung ist fehlgeschlagen. Synchronisieren Sie die Anlage.';
_fc_props.master_data_sync_failed_data_base_connection = 'Beim Synchronisieren der Stammdaten ist eine Verbindung zur Datenbank fehlgeschlagen.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Fehler beim Validieren der Stammdaten Connex Credentials Bearer Token Permission ist Insufficient';
_fc_props.settings.back_date_arrive_job = 'Rückdatierter Auftrag';
_fc_props.device_notifications = 'Benachrichtigungen';
_fc_props.loading_number = 'Ladenummer';
_fc_props.leftover_concrete_volume_temp_age = 'Verbleibendes Volumen={0}, Temperatur={1}, Alter={2}';
_fc_props.total_ordered = 'Gesamtbestellmenge';
_fc_props.total_ticketed = 'Geliefert Bestellt';
_fc_props.load_number = 'Anzahl laden';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Min. Restbeton muss eingefüllt werden und größer sein als {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Fehler beim Validieren der Stammdaten. Die Anmeldeinformationen dürfen nicht identisch sein.';
_fc_props.use_aws_for_talk = 'Verwenden Sie AWS für Talk';
_fc_props.air_readings_not_supported = 'Luftmesswerte werden nicht unterstützt';
_fc_props.air_not_supported = 'Luft wird nicht unterstützt';
_fc_props.mix_not_supported = 'Mix nicht unterstützt';
_fc_props.air_default_and_none_not_supported = '„Standard“ und „Nicht unterstützt“ können nicht gleichzeitig ausgewählt werden!';
_fc_props.notifications_enabled = 'Benachrichtigungen aktiviert';
_fc_props.prevent_status_change = 'Statusänderung verhindern';
_fc_props.message_to_interface = 'Nachricht an die Schnittstelle';
_fc_props.prevent_wrong_plant = 'Falsches Pflanzen vermeiden';
_fc_props.previous_tickets = 'Vorherige Tickets';
_fc_props.audit_ticket_number = 'Ticketnummer';
_fc_props.audit_date_created = 'Datum erstellt';
_fc_props.audit_vehicle_number = 'Fahrzeugnummer';
_fc_props.cloud_dvir__table_ok = 'Sicher im Betrieb';
_fc_props.cloud_dvir__table_needs_repair = 'Unsicher im Betrieb';
_fc_props.missing_air_calibration = 'Keine Luftkalibrierungsdaten';
_fc_props.reported_defects = 'Gemeldete Mängel';
_fc_props.address_latitude = 'Adresse Breitengrad';
_fc_props.address_longitude = 'Adresse Längengrad';
_fc_props.routing_latitude = 'Routing-Breitengrad';
_fc_props.routing_longitude = 'Routenlängengrad';
_fc_props.category_name_must_be_unique = 'Der Kategoriename muss eindeutig sein';
_fc_props.subcategory_name_must_be_unique = 'Der Name der Unterkategorie muss eindeutig sein.';
_fc_props.menu_settings_tracking_device = 'Ortungsgerät';
_fc_props.menu_settings_title_tracking_device = 'Ortungsgerät';
_fc_props.menu_settings_title_personal_device_registration = 'Persönliche Geräteregistrierung';
_fc_props.registration_code = 'Registrierungscode';
_fc_props.creation_date = 'Schaffung';
_fc_props.expiration_date = 'Ablauf';
_fc_props.trackitadmin_personal_device_registration = 'Persönliche Geräteregistrierung';
_fc_props.create_registration_code = 'Registrierungscode hinzufügen';
_fc_props.remove_registration_code = 'Registrierungscode entfernen';
_fc_props.add_a_registration_code = 'Einen Registrierungscode hinzufügen';
_fc_props.select_number_of_registration_codes_to_generate = 'Wählen Sie die Anzahl der Registrierungscodes aus, die Sie generieren möchten.';
_fc_props.registration_code_count = 'Anzahl der Registrierungscodes';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Möchten Sie den ausgewählten registrierten Code wirklich löschen?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Möchten Sie die ausgewählten registrierten Codes wirklich löschen?';
_fc_props.add_personal_device = 'Persönliches Gerät hinzufügen';
_fc_props.menu_settings_title_personal_device = 'Persönliches Gerät';
_fc_props.cloud_dvir__approval_critical_alert = 'Das Fahrzeug weist einen kritischen Defekt auf. Kann nicht als betriebssicher gekennzeichnet werden.';
_fc_props.export_registration_codes = 'Registrierungscodes exportieren';
_fc_props.download_format = 'Download-Format';
_fc_props.specify_download_format_x = 'Geben Sie das Downloadformat zum Exportieren von {0} Registrierungscodes an';
_fc_props.personal_device_download = 'Herunterladen';
_fc_props.add_personal_device_input_message = 'Geben Sie unten eine persönliche Geräte-ID ein. Das Ablaufdatum wird automatisch nach dem Speichern angewendet.';
_fc_props.error_creating_personal_device = 'Fehler beim Erstellen eines persönlichen Geräts';
_fc_props.device_already_exists = 'Persönliches Gerät ist bereits vorhanden';
_fc_props.export = 'Export';
_fc_props.error_can_not_change_connex_eid = 'Connex-EID kann nicht geändert werden, da sie derzeit für Stammdaten verwendet wird';
_fc_props.remove_personal_device = 'Persönliches Gerät entfernen';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Möchten Sie das ausgewählte persönliche Gerät wirklich löschen?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Möchten Sie die ausgewählten persönlichen Geräte wirklich löschen?';
_fc_props.error_deleting_personal_device = 'Fehler beim Löschen des persönlichen Geräts';
_fc_props.customerid = 'Kundennummer';
_fc_props.customername = 'Kundenname';
_fc_props.ordernumber = 'Bestellnummer';
_fc_props.orderdescription = 'Bestellbeschreibung';
_fc_props.locationid = 'Standort-ID';
_fc_props.locationdescription = 'Ortsbeschreibung';
_fc_props.productid = 'Produkt ID';
_fc_props.productdescription = 'Produktbeschreibung';
_fc_props.vehicleid = 'Fahrzeugidentifikation';
_fc_props.vehicledescription = 'Fahrzeugbeschreibung';
_fc_props.ticketnumber = 'Fahrkarte #';
_fc_props.ticketdate = 'Ticketdatum/-uhrzeit';
_fc_props.drivername = 'Fahrername';
_fc_props.arriveregion = 'Ankunftsregion (ZEIT)';
_fc_props.leaveregion = 'Region verlassen (ZEIT)';
_fc_props.regionduration = 'Region Dauer';
_fc_props.menu_reports_title_prevailing_wage = 'Üblicher Lohn';
_fc_props.slump_calibration_x_not_found_for_y = 'Slump-Kalibrierung "{0}" für Ticket {1} nicht gefunden';
_fc_props.slump_calibration_not_found = 'Slump-Kalibrierung nicht gefunden';
_fc_props.slump_calibration_not_found_description = 'Alarm, wenn Slump-Kalibrierung nicht gefunden wird';
_fc_props.error_getting_personal_device_list = 'Fehler beim Abrufen der persönlichen Geräteliste';
_fc_props.batch_summary_date_range_note = '*HINWEIS: Dieser Bericht ist auf 3 Tage begrenzt, wenn keine Filter angewendet werden. Der Datumsbereich kann bis zu 31 Tage betragen, wenn nach Ausrüstung (maximal 10), Werk (maximal 1), Produkt (maximal 1) oder Auftrag (maximal 1) gefiltert wird.';
_fc_props.select_registration_code_expiration_time_days = 'Wählen Sie die Anzahl der Tage bis zum Ablauf der Registrierungscodes aus und klicken Sie dann auf „Speichern“.';
_fc_props.add_personal_device_expiration = 'Ablauf (in Tagen)';
_fc_props.cloud_dvir__vehicle_no = 'Fahrzeug Nr';
_fc_props.cloud_dvir__trailer_no = 'Trailer-Nr.';
_fc_props.cloud_dvir__vehicle = 'Fahrzeug';
_fc_props.cloud_dvir__trailer = 'Anhänger';
_fc_props.cloud_dvir__truck_number_label = 'LKW-Nummer';
_fc_props.cloud_dvir__trailer_number = 'Trailernummer';
_fc_props.cloud_dvir__has_trailer = 'Keine Fahrten mit Anhänger';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Geben Sie hier Ihre Anhängernummer ein';
_fc_props.work_order = 'Arbeitsauftrag';
_fc_props.unable_to_determine_slump = 'Slump kann nicht bestimmt werden';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Bestimmen Sie den Gesamtzustand des Anhängers';
_fc_props.defaultTrailer = 'Standard-Trailer';
_fc_props.trailer_type_column_billable = 'Abrechenbar';
_fc_props.trailer_type_column_notes = 'Hinweise';
_fc_props.trailer_type_column_date = 'Datum';
_fc_props.trailer_type_column_order = 'Befehl';
_fc_props.trailer_type_column_ticket = 'Ticket';
_fc_props.trailer_type_column_project = 'Projekt';
_fc_props.trailer_type_column_customer = 'Kunde';
_fc_props.trailer_type_column_location = 'Standort';
_fc_props.trailer_type_column_vehicle_type = 'Fahrzeugtyp';
_fc_props.trailer_type_column_product = 'Produkt';
_fc_props.trailer_type_action_rebill = 'Neu abrechnen';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Die Synchronisierung der Stammdaten ist fehlgeschlagen. Es wurde kein Standardausrüstungstyp festgelegt.';
_fc_props.trailer_type = 'Anhängertyp';
_fc_props.trailer_type_is_required = 'Anhängertyp ist erforderlich';
_fc_props.trailer_number_already_exists = 'Anhängernummer ist bereits vorhanden';
_fc_props.plate_number_already_exists = 'Kennzeichen ist bereits vorhanden';
_fc_props.trailer_vin_already_exists = 'Trailer-Vinylnummer existiert bereits';
_fc_props.trailer_does_not_exist = 'Trailer existiert nicht';
_fc_props.trailer_number_is_required = 'Anhängernummer ist erforderlich';
_fc_props.plate_number_is_required = 'Kennzeichen ist erforderlich';
_fc_props.plate_number = 'Kennzeichen';
_fc_props.menu_settings_title_trailer = 'Anhänger';
_fc_props.add_trailer = 'Trailer hinzufügen';
_fc_props.edit_trailer = 'Trailer bearbeiten';
_fc_props.menu_settings_title_equipment = 'Ausrüstung';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorieren Sie neue Tickets von Command Cloud, die älter als X Minuten sind';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Neue Tickets aus der Command Cloud ignorieren, deren Versanddatum und -zeit älter als X Minuten sind (Standard ist leer und deaktiviert)';
_fc_props.edit_load_card = 'Ladekarte bearbeiten';
_fc_props.default_layout = 'Standardlayout';
_fc_props.load_card_empty_fields = 'Fehler beim Speichern der Ladekarte: Ein oder mehrere Felder müssen ausgewählt werden.';
_fc_props.trailers = 'Trailer';
_fc_props.error_failed_to_save_data = 'Fehler: Daten konnten nicht gespeichert werden';
_fc_props.slump_increased_plasticizer_added_question = 'Wie viel Weichmacher wurde zugegeben?';
_fc_props.add_trailer_type = 'Anhängertyp hinzufügen';
_fc_props.menu_settings_title_trailer_type = 'Anhängertyp';
_fc_props.edit_trailer_type = 'Trailertyp bearbeiten';
_fc_props.single_use_codes = 'Codes zur einmaligen Verwendung';
_fc_props.multiple_use_codes = 'Mehrfach verwendbare Codes';
_fc_props.trailer_type_description_is_required = 'Eine Beschreibung des Anhängertyps ist erforderlich';
_fc_props.trailer_saved = 'Trailer gespeichert';
_fc_props.cloud_dvir__trailer_only_dvir = 'Nur Trailer DVIR';

export default _fc_props;
