const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Intentar de Nuevo';
_fc_props.default = 'Predeterminado';
_fc_props.origin = 'Origen';
_fc_props.loading = 'A Cargar';
_fc_props.list = 'Listar';
_fc_props.send = 'Enviar';
_fc_props.error = 'Error';
_fc_props.save = 'Guardar';
_fc_props.delete = 'Eliminar';
_fc_props.message = 'Mensaje';
_fc_props.logout = 'Desconectar';
_fc_props.add = 'Añadir';
_fc_props.remove = 'Eliminar';
_fc_props.done = 'OK';
_fc_props.other = 'Otro';
_fc_props.interval = 'Intervalo';
_fc_props.time = 'Tiempo';
_fc_props.speed = 'Velocidad';
_fc_props.longitude = 'Longitud';
_fc_props.latitude = 'Latitud';
_fc_props.x_minutes = '{0} Min';
_fc_props.general = 'General';
_fc_props.messages = 'Mensagens';
_fc_props.gps_fixes = 'Posiciones de GPS';
_fc_props.device_id = 'ID do Dispositivo Inválida';
_fc_props.vehicle = 'Vehículo';
_fc_props.employee = 'Empleado';
_fc_props.version = 'Versión';
_fc_props.statuses = 'Estados';
_fc_props.edit = 'Editar';
_fc_props.cancel = 'Cancelar';
_fc_props.yes = 'Sí';
_fc_props.none = 'Ninguno';
_fc_props.never = 'Nunca';
_fc_props.name = 'Nombre';
_fc_props.true = 'Verdadero';
_fc_props.status = 'Estado';
_fc_props.distance = 'Distancia';
_fc_props.ok = 'Ok';
_fc_props.login = 'Login';
_fc_props.next = 'Siguiente';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Atrás';
_fc_props.select = 'Seleccionar';
_fc_props.dispatch = 'Despacho';
_fc_props.messaging = 'Mensajería';
_fc_props.plants = 'Plantas';
_fc_props.last_plants = 'Plantas más recientes';
_fc_props.vehicle_types = 'Tipo de Vehículos';
_fc_props.employee_types = 'Tipos de empleado';
_fc_props.employee_groups = 'Grupos de empleados';
_fc_props.equipment_groups = 'Grupos de equipos';
_fc_props.job = 'Obra';
_fc_props.order = 'Orden';
_fc_props.ticket = 'N° de tiquete';
_fc_props.small = 'Pequeño';
_fc_props.medium = 'Mediano';
_fc_props.large = 'Grande';
_fc_props.reports = 'Reportes';
_fc_props.payroll = 'Nómina';
_fc_props.support = 'Soporte';
_fc_props.username = 'Nombre de usuario';
_fc_props.all = 'Todos';
_fc_props.question = 'Pregunta';
_fc_props.select_employee = 'Seleccionar Empleados';
_fc_props.phone = 'Teléfono';
_fc_props.january = 'Enero';
_fc_props.february = 'Febrero';
_fc_props.march = 'Marzo';
_fc_props.april = 'Abril';
_fc_props.may = 'Mayo';
_fc_props.june = 'Junio';
_fc_props.july = 'Julio';
_fc_props.august = 'Agosto';
_fc_props.september = 'Septiembre';
_fc_props.october = 'Octubre';
_fc_props.november = 'Noviembre';
_fc_props.december = 'Diciembre';
_fc_props.are_you_sure_you_want_to_delete = 'Está seguro de que desea borrar';
_fc_props.unknown = 'Desconocido';
_fc_props.select_all = 'Seleccionar Todos';
_fc_props.equipment = 'Equipo';
_fc_props.region = 'Región';
_fc_props.no_data = 'Sin datos';
_fc_props.hotspot = 'Punto de referencia';
_fc_props.address = 'Dirección';
_fc_props.retrieving_address = 'A recuperar la dirección...';
_fc_props.address_not_found = 'Dirección no encontrada';
_fc_props.active_time = 'Tiempo Activo';
_fc_props.destination_time = 'Tiempo de llegada';
_fc_props.complete_time = 'Tiempo Completo';
_fc_props.status_report = 'Informe de Estado';
_fc_props.export_report = 'Exportar Informe';
_fc_props.download_report = 'Descargar Informe';
_fc_props.view_report = 'Véase Informe';
_fc_props.duration = 'Duración';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Datos/Tiempo Inicio';
_fc_props.select_start_date = 'Seleccionar Datos Inicio';
_fc_props.end_date_time = 'Datos/Tiempo Fin';
_fc_props.select_end_date = 'Seleccionar Datos Fin';
_fc_props.trip_time = 'Tiempo de Viaje';
_fc_props.travel_time = 'Tiempo de Viaje';
_fc_props.description = 'Descripción';
_fc_props.map_controls = 'Controles del mapa';
_fc_props.road = 'Vías';
_fc_props.aerial = 'Aérea';
_fc_props.hybrid = 'Híbrida';
_fc_props.trip = 'Viaje';
_fc_props.map = 'Mapa';
_fc_props.plant = 'Planta';
_fc_props.select_date = 'Seleccionar Datos';
_fc_props.save_settings = 'Guardar Definiciones';
_fc_props.false = 'Falso';
_fc_props.delete_confirmation = 'Borrar Confirmación';
_fc_props.last_name = 'Último Nombre';
_fc_props.none_selected = 'Ninguno';
_fc_props.start_date = 'Fecha Inicio';
_fc_props.group_by = 'Grupo Por';
_fc_props.equipment_employee = 'Equipo / Empleado';
_fc_props.inactive = 'Inactivo';
_fc_props.active = 'Activo';
_fc_props.note_checking_may_affect_performance = 'Nota:La verificación puede afectar el rendimiento';
_fc_props.show_distancefuel_used = 'Mostrar Distancia/Combustible Usado';
_fc_props.mins = 'Mins';
_fc_props.threshold_time = 'Tiempo Limite';
_fc_props.include_voided_tickets = 'Incluir Tarjetas Eliminadas';
_fc_props.note_all_times_listed_are_in_minutes = 'Nota:Todos los tiempos son listados en minutos';
_fc_props.please_select_a_job_order_or_equipment = 'Seleccione un trabajo, pedido, planta, equipo o empleado';
_fc_props.device = 'Dispositivo';
_fc_props.select_equipment = 'Seleccionar Equipo';
_fc_props.all_equipment_types = 'Todos los Tipos de Equipo';
_fc_props.units = {};
_fc_props.units.liters = 'Litros';
_fc_props.type = 'Tipo';
_fc_props.no_data_available = 'Sin datos disponibles';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Distancia y combustible por región';
_fc_props.menu_settings_title_settings = 'Definiciones';
_fc_props.menu_settings_title_alerts = 'Alertas';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Equipo';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots & Regiones';
_fc_props.days = '{0} día(s)';
_fc_props.home = 'Inicio';
_fc_props.find = 'Encontrar';
_fc_props.close_this_panel = 'cerrar este panele';
_fc_props.start = 'Iniciar';
_fc_props.end = 'Fin';
_fc_props.location_details = 'Detalles Local';
_fc_props.first_name = 'Primero Nombre';
_fc_props.material_per_hour = 'Materiales por hora';
_fc_props.equipment_pound = 'Equipo #';
_fc_props.equipment_type = 'Tipo de Equipamento';
_fc_props.options = 'Opciones';
_fc_props.zoom_on_cursor_position = 'Zoom en la Posición del Cursor';
_fc_props.display_equipment_identifier = 'Mostrar identificador de equipo';
_fc_props.view_equipment = 'Ver equipo';
_fc_props.view_hotspots = 'Ver punto de referencia';
_fc_props.view_regions = 'Ver regiones';
_fc_props.use_standard_filters = 'Usar filtros estándar';
_fc_props.home_plant = 'Planta de inicio';
_fc_props.last_plant = 'Planta más reciente';
_fc_props.equip_types = 'Equip. Tipos';
_fc_props.equip_groups = 'Equip. Grupos';
_fc_props.orders = 'Órdenes';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Usar filtro por equipo';
_fc_props.all_selected = 'Seleccionar todos';
_fc_props.of = 'De';
_fc_props.your_map_session_has_expired = 'Su sesión de Mapa ha expirado, P.f.Actualice la Página';
_fc_props.equipment_short = 'Equip.';
_fc_props.job_info_for = 'Info Trabajo para';
_fc_props.order_info_for = 'Info Pedido para';
_fc_props.ticket_info_for_vehicle = 'Info Billete para Vehículo';
_fc_props.inbox = 'Caja de Entrada';
_fc_props.outbox = 'Caja de Salida';
_fc_props.alerts = 'Alertas';
_fc_props.critical = 'Crítico';
_fc_props.important = 'Importante';
_fc_props.informational = 'Informativo';
_fc_props.view_routes = 'Ver Recorridos';
_fc_props.hours_size = 'Tamaño Horas';
_fc_props.used_count = 'Usado Recuento';
_fc_props.last_login = 'Última Entrada';
_fc_props.last_vehicle = 'Último Vehículo';
_fc_props.regions = 'Regiones';
_fc_props.driver = 'Conductor';
_fc_props.js_delete = 'Eliminar';
_fc_props.move = 'Mover';
_fc_props.edit_hotspot = 'Editar Hotspot';
_fc_props.adjust_shape = 'Ajustar Forma';
_fc_props.edit_region = 'Editar Región';
_fc_props.enter_a_start_address = 'P.f. introduzca una Dirección inicial';
_fc_props.select_a_start_hotspot = 'P.f. seleccione un Hotspot inicial';
_fc_props.failed_to_find_start_hotspot = 'Error al encontrar Hotspot inicial.';
_fc_props.invalid_latitudelongitude = 'Latitud/Longitud inválida';
_fc_props.enter_an_end_address = 'P.f. introduce una Dirección final';
_fc_props.select_an_end_hotspot = 'P.f., seleccione un Hotspot final';
_fc_props.failed_to_find_end_hotspot = 'Error al encontrar Hotspot final.';
_fc_props.print = 'Imprimir';
_fc_props.these_directions_are_for_informational_purposes = 'Estas instrucciones son para propósitos informativos solamente. Ninguna garantía con respecto a su integridad o exactitud. Proyectos de construcción, tráfico u otros eventos pueden causar condiciones reales para diferir de los resultados.';
_fc_props.enter_an_address = 'P.f. seleccione una Dirección';
_fc_props.select_a_hotspot = 'P.f. seleccione un Hotspot';
_fc_props.failed_to_find_hotspot = 'Error al encontrar Hotspot.';
_fc_props.select_a_vehicle = 'P.f., seleccione un Vehículo';
_fc_props.failed_to_find_vehicle = 'Error al encontrar el Vehículo.';
_fc_props.failed_to_find_address = 'Error al encontrar la dirección';
_fc_props.failed_to_find_vehicles = 'Error al encontrar vehículos en los criterios de búsqueda.';
_fc_props.get_address = 'Obter Dirección';
_fc_props.results_for = 'Resultados para';
_fc_props.error_retrieving_results = 'Error al Recuperar Resultados';
_fc_props.no_results_found = 'Ningún Resultado Encontrado';
_fc_props.address_is_required = 'Dirección Necesária';
_fc_props.city_is_required = 'Ciudad Necesária';
_fc_props.set_location = 'Definir Localización';
_fc_props.set_area_of_interest = 'Definir Zona de Interés';
_fc_props.center_map_here = 'Centrar Mapa Aquí';
_fc_props.zoom_to_street_level = 'Zoom a Nivel de la Calle';
_fc_props.set_start = 'Definir Inicio';
_fc_props.set_end = 'Definir Fim';
_fc_props.delete_node = 'Eliminar Nodo';
_fc_props.create_a_hotspot = 'Crear un Hotspot';
_fc_props.create_a_region = 'Crear una Región';
_fc_props.toggle_shapes = 'Alternar Formas';
_fc_props.refresh_map = 'Actualizar Mapa';
_fc_props.more_options = 'Más Opciones';
_fc_props.recenter_map = 'Re-centrar Mapa';
_fc_props.zoom_to_vehicle = 'Zoom para Vehículo';
_fc_props.apply_filters = 'Aplicar Filtros';
_fc_props.grid_view = 'Vista de cuadrícula';
_fc_props.list_view = 'Vista de lista';
_fc_props.click_to_sort = 'Haga clicl para pedir';
_fc_props.message_history = 'Historial de mensajes';
_fc_props.equipment_types = 'Tipos de equipo';
_fc_props.send_message = 'Enviar mensaje';
_fc_props.last_load = 'Última carga';
_fc_props.last_load_on = 'Última Carga En';
_fc_props.manual_logout = 'Manual Salida';
_fc_props.manual_login = 'Acceso manual';
_fc_props.no_employees_logged_in = 'Ningún empleado se ha conectado al sistema';
_fc_props.select_phone = 'Seleccionar Telefone';
_fc_props.you_must_select_a_phone = 'Debe seleccionar un Telefóno, Equipo y Empleados';
_fc_props.are_you_sure_you_want_to_logout = 'Está seguro de que desea salir';
_fc_props.fix_time = 'Reparar Tiempo';
_fc_props.to_next_fix = 'para próxima Reparación';
_fc_props.minutes_to_next_fix = 'Minutos para próxima Reparación';
_fc_props.liters = 'Litros';
_fc_props.destination = 'Destino';
_fc_props.return = 'Retorno';
_fc_props.product = 'Producto';
_fc_props.no_records_found = 'Sin registros encontrados.';
_fc_props.vehicle_type = 'Tipo de Vehículo';
_fc_props.view_in_map = 'Ver Todo en el Mapa';
_fc_props.permanent = 'Permanente';
_fc_props.expire_time = 'Tiempo de Expiración';
_fc_props.please_select_x_for_tickets_marked_active = 'P.f. Seleccione {0} para Billetes marcados ACTIVOS';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Múltiplos Bilhetes marcados ACTIVOS para los mismos {0}';
_fc_props.failure_connecting_to_servlet = 'Error a conectar a servlet.';
_fc_props.status_time_summary = 'Resumen de tiempo de estados';
_fc_props.view_in_map_one = 'Ver en Mapa';
_fc_props.question_report = 'Informe de Perguntas';
_fc_props.end_date = 'Fecha de Finalización';
_fc_props.trailer_number = 'Número Trailer';
_fc_props.region_panel = 'Panel de Región';
_fc_props.hotspot_panel = 'Panel Hotspot';
_fc_props.shape = 'Forma';
_fc_props.circle = 'Círculo';
_fc_props.polygon = 'Polígono';
_fc_props.click_the_map_to_set_the_location = 'Haga clic en el mapa para definir la localización deseada.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Haga clic en el mapa para definir el centro hotspot.';
_fc_props.click_the_map_to_set_the_region_center = 'Haga clic en el mapa para definir el centro de la región.';
_fc_props.click_and_drag_points = 'Haga clic y arrastre puntos para editar la forma. Haga clic en GUARDAR para guardar los cambios.';
_fc_props.size = 'Tamaño';
_fc_props.are_you_sure_you_want_to_move_this = '¿Está seguro que desea mover este?';
_fc_props.are_you_sure_you_want_to_delete_this = '¿Está seguro que desea eliminar este?';
_fc_props.please_enter_a_valid_size = 'Por favor, introduzca un tamaño válido';
_fc_props.failed_to_save = 'Error al guardar.';
_fc_props.failed_to_move = 'Error al mover.';
_fc_props.failed_to_delete = 'Falló eliminar.';
_fc_props.x_hours = '{0} horas';
_fc_props.hide_hotspots = 'Ocultar Hotspots';
_fc_props.show_regions = 'Mostrar Regiones';
_fc_props.show_hotspots = 'Mostrar Hotspots';
_fc_props.hide_regions = 'Ocultar Regiones';
_fc_props.numeric = 'Numérico';
_fc_props.message_type = 'Tipo de mensaje';
_fc_props.text = 'Texto';
_fc_props.yes_no = 'Sí/No';
_fc_props.responses = 'Respuestas';
_fc_props.disabled = 'Incapacitado';
_fc_props.group_name = 'Nombre Grupo';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = '¿Está seguro que desea eliminar el formato \'{0}\'?';
_fc_props.phone_number = 'Número de Teléfono';
_fc_props.interface_pound = 'Interfaz #';
_fc_props.field = 'Campo';
_fc_props.default_label = 'Etiqueta padrón';
_fc_props.custom_label = 'Etiqueta Personalizada';
_fc_props.you_must_enter_a_label = 'Debe inserir una Etiqueta.';
_fc_props.enabled = 'Activo';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Integración Billete';
_fc_props.settings.geofence_off_open_hotspots = 'Geocercas fuera de Hotspots abiertos';
_fc_props.settings.associate_open_hotspots_wticket = 'Asociar Hotspots Abiertos c/Billete';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Permitir geofencing fuera de hotspots abiertos o solamente a hotspots asociados a Billete de Conductor';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Asociarse o no a Hotspots abiertos con BIlletes cuando un vehículo entra en ellos';
_fc_props.settings.void_ticket_without_destination = 'Destino sin Billete vacío';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'si un billete es recibido mientras otro está activo, si el billete activo no tiene un destino, el billete será anulado.';
_fc_props.settings.autocreate_hotspots = 'Auto creación de Hotspots';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Crear automáticamente hotspots o no de la dirección de destino cuando se recibe un billete';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Billete auto-activado Al Recepción';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Está el billete activo cuando recibido por TrackIt (padrón \'verdadero\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Auto Cierre del Billete Activo Al Recibir Minutos';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = '¿Cuánto tiempo necesita el billete activo actual que ha estado activo antes de auto-cierre (Padrón \'\')?';
_fc_props.settings.ticket_max_activate_age_minutes = 'Billete Máx Activa Minutos Edad';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = '¿Cuántos años en cuestión de minutos puede ser un billete al activarlo en TrackIt (Padrón \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Hotspot Máx Días No Utilizados';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Define los max días que un Hotspot puede estar sin ser usado y todavía ser revivido';
_fc_props.settings.radius_multiplier = 'Multiplicador Radio';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Lo que se puede multiplicar el radio cuando se recibe un billete en el sistema de envío';
_fc_props.settings.ticket_message_date_format = 'Formato de Fecha Mensaje Billete';
_fc_props.am = 'am';
_fc_props.pm = 'pm';
_fc_props.settings.ticket_message = 'Mensaje Billete';
_fc_props.settings.add_ticket_element = 'Añadir Elemento Billete';
_fc_props.driver_performance = 'Desempeño del conductor';
_fc_props.update_failed_can_not_connect = 'Actualización Falló: Imposible conectar al servlet.';
_fc_props.unknown_error = 'Error Desconocido';
_fc_props.selected = 'Seleccionado';
_fc_props.web_service = 'Servicio de red';
_fc_props.error_processing_request = 'Erro al processar el pedido.';
_fc_props.email_address = 'Dirección Email';
_fc_props.id = 'CARNÉ DE IDENTIDAD';
_fc_props.last_used = 'Último Usado';
_fc_props.error_invalid_vehiclenum = 'Error de número de vehículo no válido';
_fc_props.error_vehicle_is_not_logged_in = 'Error: Vehículo no está conectado.';
_fc_props.users = 'Usuario';
_fc_props.you_dont_have_access_to_this_page = 'Tú no tienes acceso para ver esta página. Comuníquese con el administrador del sistema para obtener acceso.';
_fc_props.previous_week = 'Semana Anterior';
_fc_props.status_breakdown = 'Reporte de estados';
_fc_props.select_plant_or_employee_group = 'Debe seleccionar ya sea una planta o un grupo de empleados.';
_fc_props.cannot_select_plant_and_employee_group = 'No puede seleccionar ambos la planta y un grupo de empleados.';
_fc_props.odometer_must_be_a_number = 'Inicio del Cuentakilómetros debe ser un número';
_fc_props.ending_odometer_must_be_a_number = 'Fín del Cuentakilómetros debe ser un número';
_fc_props.beginning_hour_meter_must_be_a_number = 'Inicio del Cronómetro debe ser un número';
_fc_props.ending_hour_meter_must_be_a_number = 'Fín del Cronómetro debe ser un número';
_fc_props.error_loading_status_change_page = 'Error al cargar la página de Cambio de Estado.';
_fc_props.x_is_an_invalid_time_format = '{0} es un formato de hora no válida, debe ser AAAA-MM-DD HH:MM:SS';
_fc_props.x_for_schedule_login = '{0} para programar entrada';
_fc_props.x_for_schedule_logout = '{0} para programar salida';
_fc_props.x_for_actual_login = '{0} para entrada actual';
_fc_props.x_for_actual_logout = '{0} para salida actual';
_fc_props.by_refreshing_the_time_card_detail = 'By refreshing the Time Card Detail you will lose all changes, continue?';
_fc_props.are_you_sure_you_want_to_delete_0 = '¿Está seguro de que desea eliminar {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Comando Billete Móvil URL';
_fc_props.year = 'Año';
_fc_props.make = 'Hacer';
_fc_props.model = 'Modelo';
_fc_props.settings.command_mobile_ticket_password = 'Ticket Móvil Comando Contraseña';
_fc_props.settings.command_mobile_ticket_auth_token = 'Comando Móvil Ticket de autenticación del token';
_fc_props.target = 'Objetivo';
_fc_props.number_of_digits = 'Número de dígitos';
_fc_props.number_of_integer_digits = 'Número de Dígitos enteros';
_fc_props.number_of_fractional_digits = 'Número de dígitos fraccionarios';
_fc_props.search = 'Búsqueda';
_fc_props.settings.command_mobile_ticket_date_order = 'Ticket Móvil Comando Fecha Orden';
_fc_props.settings.command_mobile_ticket_date_length = 'Ticket Móvil Comando Fecha Duración';
_fc_props.settings.command_mobile_ticket_date_separator = 'Ticket Móvil Comando Fecha Separador';
_fc_props.settings.command_mobile_ticket_slash = '/ (Barra)';
_fc_props.settings.command_mobile_ticket_dash = '- (Guión)';
_fc_props.settings.command_mobile_ticket_period = '. (Período)';
_fc_props.settings.command_mobile_ticket_space = '(Espacio)';
_fc_props.settings.command_mobile_ticket_time_length = 'Mobile Command Tiempo de entradas Longitud';
_fc_props.settings.command_mobile_ticket_time_separator = 'Mobile Command Tiempo de entradas separador';
_fc_props.settings.command_mobile_ticket_colon = ': (Colon)';
_fc_props.settings.command_mobile_ticket_time_format = 'Formato del comando móvil Tiempo de entradas';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Utilizar número Interface';
_fc_props.mechanic = 'Mecánico';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Intervalo de sondeo (segundos)';
_fc_props.driving_time = 'Tiempo de conducción';
_fc_props.find_near_plants = 'Encontrar cerca de las plantas';
_fc_props.please_create_hot_spot_first = 'Por favor, cree hotspot primero';
_fc_props.please_enter_hot_spot_description = 'Por favor, introduzca Descripción punto caliente';
_fc_props.please_select_a_hot_spot_type = 'Por favor, seleccione un tipo de punto caliente';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Enviar Conductor Email';
_fc_props.directions_not_found = 'No válida {0} direcciones. Direcciones no encontrada.';
_fc_props.ws_user = 'Usuario';
_fc_props.ws_service = 'Servicio';
_fc_props.ws_method = 'Método';
_fc_props.ws_params = 'Parámetros';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Origen y Destino';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Ayuda';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Utilice Alt Número de empleado';
_fc_props.settings.command_mobile_ticket_android_url = 'URL móvil androide de entradas';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android móvil de entradas Secret';
_fc_props.employee_x_has_no_logout_on_y = 'Empleado {0} no tiene cierre de sesión de un cambio a partir del {1}.';
_fc_props.phone_number_has_duplicates = 'Los números de teléfono tienen entradas duplicadas';
_fc_props.ounce = 'Onza';
_fc_props.water_extra = 'El exceso de agua';
_fc_props.always = 'Siempre';
_fc_props.work_types_must_be_unique = 'Tipos de trabajo deben ser únicos';
_fc_props.manufacturer = 'Fabricante';
_fc_props.need_to_enter_seconds = 'Necesita ingresar segundos.';
_fc_props.copy = 'Dupdo';
_fc_props.create_in_existing_required = 'Crear en existente debe estar seleccionado para crear punto de acceso móvil.';
_fc_props.break = 'Descanso';
_fc_props.add_export_data_type = 'Añadir tipo de datos de exportación';
_fc_props.edit_export_data_type = 'Editar tipo de datos de exportación';
_fc_props.delete_export_data_type = 'Eliminar tipo de datos de exportación';
_fc_props.export_data_type = 'Tipo de datos de exportación';
_fc_props.error_updating_export_data_type = 'Error al actualizar el tipo de datos de exportación';
_fc_props.settings.address_key = 'Clave de dirección';
_fc_props.settings.address_key_hover = 'Opciones de clave de dirección';
_fc_props.settings.address_key_options = 'Opciones de clave de dirección';
_fc_props.more_filter_options = 'Más opciones de filtrado';
_fc_props.unauthorized_mapit_app = 'Esta versión de MapIt no está autorizada, por favor, actualice MapIt tan pronto como sea posible.';
_fc_props.zello_timeout = 'El tiempo de espera se produjo al contactar a Zello.';
_fc_props.user_agreement_text = 'Al iniciar sesión, usted acepta el';
_fc_props.user_agreement = 'Acuerdo del Usuario';
_fc_props.x_mins_left = '{0} min (s) restantes';
_fc_props.continuous_location_tracking_colon = 'Seguimiento continuo de ubicación:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Hauler no puede registrar un nuevo dispositivo.';
_fc_props.timecard_does_not_contain_shift = 'La tarjeta de tiempo del empleado no contiene el turno (información de la tarjeta de tiempo) provisto';
_fc_props.timecard_not_found = 'Tarjeta de tiempo del empleado no encontrada';
_fc_props.vehicle_must_be_assigned_for_clt = 'El vehículo debe asignarse a ubicaciones de seguimiento.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Se deshabilitó el seguimiento continuo del vehículo sin asignar.';
_fc_props.units_yards_abbr = 'YARDA';
_fc_props.units_meters_abbr = 'METRO';
_fc_props.error_lost_edc_connection = 'Conexión perdida con EDC. Verifique que su WiFi y / o Bluetooth estén habilitados.';
_fc_props.acknowledge_all = 'Reconoce todo';
_fc_props.expand = 'Expandir';
_fc_props.collapse = 'Colapso';
_fc_props.expand_all = 'Expandir todo';
_fc_props.collapse_all = 'Desplegar todo';
_fc_props.last_update = 'Última actualización';
_fc_props.acknowledge_alert = 'Reconocer Alerta';
_fc_props.acknowledge_message = 'Mensaje de confirmación';
_fc_props.acknowledge_all_alerts = 'Reconocer todas las alertas';
_fc_props.acknowledge_all_messages = 'Reconocer todos los mensajes';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'La tolerancia debe ser un número entero válido entre -99 y 99 (ambos incluidos)';
_fc_props.gps_permission_required = 'Permiso de GPS requerido';
_fc_props.permissions_required = 'Permisos requeridos';
_fc_props.change_user = 'Cambiar usuario';
_fc_props.account_not_registered_with_carrier = 'Esta cuenta no está registrada con un transportista';
_fc_props.slump_loss = 'Pérdida de asentamiento';
_fc_props.slump_loss_help = 'Ayuda de Slump Loss';
_fc_props.did_not_receive_any_edc_devices = 'No recibió ningún dispositivo EDC.';
_fc_props.edc_record_not_found = 'No se encontró el registro de EDC';
_fc_props.disable_hotspot_prompt = 'Pulse "Aceptar" cuando se le solicite que deshabilite su punto de acceso.';
_fc_props.edc_device_is_in_use = 'EDC {0} está en uso.';
_fc_props.an_unknown_exception_has_occurred = 'Se ha producido una excepción desconocida.';
_fc_props.login_has_timed_out = 'El inicio de sesión ha caducado';
_fc_props.use_paper_logs = 'Utilizar registros de papel';
_fc_props.eld_login_failed = 'Horario de inicio de sesión del servicio fallido';
_fc_props.setup_time_off_type = 'Por favor, configure un tipo de tiempo libre.';
_fc_props.view_load_properties = 'Ver las propiedades de carga';
_fc_props.couldnt_download_edc_firmware = 'No se pudo descargar el firmware EDC.';
_fc_props.password_incorrect = 'Contraseña incorrecta';
_fc_props.edc_configuration_password_prompt = 'Debido al estado de este dispositivo, debe proporcionar la contraseña del instalador para configurar.';
_fc_props.edc_configuration_check_failed = 'La validación de registros del servidor EDC ha fallado. Por favor, consulte los mensajes de error para más detalles.';
_fc_props.configuration_issues_prompt = 'Se detectaron los siguientes problemas al configurar el EDC seleccionado para el camión especificado';
_fc_props.checking_password = 'Comprobando contraseña';
_fc_props.reset_password_instructions_sent = 'Instrucciones para restablecer su contraseña enviadas a la dirección de correo electrónico {0}.';
_fc_props.reset_password_failed = 'Error al restablecer contraseña, intente nuevamente.';
_fc_props.forgot_password = '¿Olvidó su contraseña?';
_fc_props.enter_valid_email = 'Dirección de correo electrónico no válida, ingrese una dirección de correo electrónico válida e intente nuevamente.';
_fc_props.issues_while_updating_edc_record = 'El EDC se configuró con éxito. Sin embargo, hay problemas con los registros para el EDC en el servidor TrackIt que pueden hacer que no funcione. Por favor llame a soporte para corregir la información.';
_fc_props.sending = 'Enviando...';
_fc_props.minimum_ratio = 'Ratio mínimo';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'RPM del tambor no dentro del rango';
_fc_props.probe_failure = 'Falla de la sonda';
_fc_props.unknown_probe_data_failure = 'Error de datos de sonda desconocido';
_fc_props.no_loaded_status_meaning = 'Sin estado cargado';
_fc_props.no_depart_status_meaning = 'Sin estado de salida';
_fc_props.no_arrive_status_meaning = 'Sin estado de llegada';
_fc_props.no_begin_work_status_meaning = 'No comenzar el estado de trabajo';
_fc_props.no_fully_mixed_status_meaning = 'Sin estado completamente mixto';
_fc_props.no_begin_work_or_at_job_status = 'Los estados de inicio de trabajo o en el trabajo no están presentes';
_fc_props.driver_didnt_follow_procedure = 'El conductor no siguió el procedimiento (no desaceleró el tambor)';
_fc_props.probe_malfunction = 'Mal funcionamiento de la sonda (acumulación, falla interna, etc.)';
_fc_props.no_data_from_probe = 'No hay datos de la sonda (pérdida de datos)';
_fc_props.missing_probe_calibration = 'Sin datos de calibración de la sonda';
_fc_props.probe_pressure_not_in_range = 'La presión de la sonda no está dentro del rango.';
_fc_props.arrival = 'Llegada';
_fc_props.small_load = 'CARGA PEQUEÑA';
_fc_props.dry_slump = 'SLUMP SECO';
_fc_props.probe_remixing_turns = 'Remezclando';
_fc_props.turns_needed_to_remix = 'Agua agregada: se necesita una mezcla adicional';
_fc_props.probe_remixing_finished = 'Remixing terminado';
_fc_props.probe_battery_low_alert = 'Sonda Alerta de batería baja';
_fc_props.probe_battery_low_message = 'Sonda de batería baja';
_fc_props.probe_battery_low = 'Sonda de batería baja';
_fc_props.alert_when_probe_battery_low = 'Alerta cuando la batería de la sonda está baja';
_fc_props.probe_sensor_fatal_failure = 'Sensor de falla fatal';
_fc_props.probe_thermistor_failure = 'Falla del termistor';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor atascado en concreto';
_fc_props.probe_sensor_over_weight = 'Sensor sobre peso';
_fc_props.probe_batteries_low = 'Bateria BAJA';
_fc_props.no_probe_data = 'Sin datos de la sonda:';
_fc_props.check_probe = 'Sonda de verificación';
_fc_props.service_probe = 'Sonda de servicio';
_fc_props.below_x = 'Debajo de {0}';
_fc_props.above_x = 'Por encima de {0}';
_fc_props.probe_serial_number_invalid_format = 'El número de serie de la sonda no tiene un formato válido como se muestra en el marcador de posición para el campo';
_fc_props.talk_dnd_permission_prompt = 'Hablar requiere permiso NO PERTURBAR.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'El estado cambió demasiado rápido';
_fc_props.is_empty = 'Esta vacio';
_fc_props.slump_increased_plasticizer_question = '¿Se añadió plastificante?';
_fc_props.slump_increased_water_added_question = '¿Cuánta agua no medida se añadió?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'La supervisión del ciclo de entrega y la sonda no se admiten juntas en el mismo dispositivo.';
_fc_props.no_data_from_sensor = 'Sin datos del sensor (pérdida de datos)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Tipo de sistema';
_fc_props.magnet_count = 'Recuento de imanes';
_fc_props.sensors_setup = 'Configuración de sensores';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM requiere que se establezcan la dirección MAC de la caja de E / S, el tipo de tambor, el tipo de DCM y el recuento de imanes.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Volumen facturado';
_fc_props.drs_current_volume = 'Volumen actual';
_fc_props.drs_current_water_cement_ratio = 'Relación agua / cemento actual';
_fc_props.drs_target_water_cement_ratio = 'Relación agua / cemento objetivo';
_fc_props.drs_total_revolutions = 'Total';
_fc_props.drs_current_volume_lp_small = 'Actual';
_fc_props.drum_revolutions = 'Revoluciones de tambor';
_fc_props.drs_total_revolutions_lp_small = 'Total';
_fc_props.drs_charging_revolutions_lp_small = 'Cargando';
_fc_props.drs_discharging_revolutions_lp_small = 'Descarga';
_fc_props.drs_target_wc_lp_small = 'Objetivo W / C';
_fc_props.drs_current_wc_lp_small = 'W / C actual';
_fc_props.drs_ticketed_volume_lp_small = 'Ticketed';
_fc_props.android_location_disclosure = 'TrackIt utiliza una ubicación fina y aproximada para recopilar datos de ubicación en segundo plano, incluso cuando no está en uso, para habilitar el estado automático y la visibilidad del mapa en tiempo real para el envío.';
_fc_props.android_location_usage = 'Divulgación de ubicación';
_fc_props.end_pour_trigger_threshold = 'Umbral de activación de vertido final';
_fc_props.drs_wiring_error = 'Error de cableado';
_fc_props.drs_wiring_pulse_error = 'Error de pulso de cableado';
_fc_props.drs_wiring_magnet_error = 'Error del imán de cableado';
_fc_props.drs_magnet_missing_error = 'Error de imán perdido';
_fc_props.android_location_disclosure_3p = 'TrackIt recopila datos de ubicación en segundo plano para habilitar el estado automático y la visibilidad del mapa en tiempo real para el envío. Cuando se marca la salida del remitente, la ubicación no se comparte.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Seleccione Full MDM o Trackit Remote Support';
_fc_props.move_up = 'Ascender';
_fc_props.move_down = 'Mover hacia abajo';
_fc_props.driver_not_found = 'No se encontró el controlador.';
_fc_props.multiple = 'Múltiple';
_fc_props.water_meter_installed = 'Medidor de agua instalado';
_fc_props.delete_waypoint = 'Eliminar waypoint';
_fc_props.wash_sensor_installed = 'Sensor de lavado instalado';
_fc_props.washing_start_time = 'Hora de inicio del lavado';
_fc_props.end_washing = 'Final de lavado';
_fc_props.variable_rpms = 'Var RPM';
_fc_props.no_slump = 'Sin depresión';
_fc_props.end_washing_duration = 'Duración final del lavado';
_fc_props.privacy_policy = 'Política de privacidad';
_fc_props.privacy_policy_text = 'He leído y acepto la';
_fc_props.login_fail_accept_privacy_policy = 'Se debe aceptar la política de privacidad para continuar.';
_fc_props.rotation_sensor = 'Sensor de rotacion';
_fc_props.pto_sensor = 'Sensor de TDF';
_fc_props.pto_sensor_installed = 'Sensor de PTO instalado';
_fc_props.polarity_reversed = 'Polaridad inversa';
_fc_props.pto_sensor_type = 'Tipo de sensor de PTO';
_fc_props.sensor_type = 'Tipo de sensor';
_fc_props.prompt_select_pto_sensor_type = 'Debe seleccionar un "Tipo de sensor de PTO" si ha seleccionado un "Sensor de PTO" como su tipo de sensor principal.';
_fc_props.priming_turns_at_full_load = 'Cebado de vueltas a plena carga';
_fc_props.priming_turns_at_ten_percent_load = 'Cebado de vueltas al 10% de carga';
_fc_props.weight_units = 'Unidades de peso';
_fc_props.settings.send_status = 'Enviar estado / ubicación a Event Bridge';
_fc_props.volume_difference = 'Diferencia de volumen';
_fc_props.mobile_ticket_iframe_url = 'URL de IFrame de ticket móvil';
_fc_props.mobile_ticket_user = 'Usuario de ticket móvil';
_fc_props.mobile_ticket_password = 'Contraseña del boleto móvil';
_fc_props.current_truck_status = 'Estado actual de camiones';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Activado por revoluciones máximas';
_fc_props.alerts_by_type = 'Alertas por tipo';
_fc_props.water_flow_meter = 'Medidor de flujo de agua';
_fc_props.flow_meter_no_pulses_warning = 'Sin pulsos - Advertencia';
_fc_props.flow_meter_no_pulses_critical = 'Sin pulsos - Crítico';
_fc_props.flow_meter_excessive_pulses = 'Pulsos excesivos';
_fc_props.flow_meter_constantly_flowing = 'Fluyendo constantemente';
_fc_props.flow_meter_good = 'Bien';
_fc_props.whats_new = 'Lo nuevo';
_fc_props.whats_new_widget_text = '¿Sabías que actualizamos TrackIt al menos una vez al mes? Obtenga las últimas actualizaciones y sugerencias.';
_fc_props.get_the_scoop = 'Obtener la primicia';
_fc_props.trackit_insights = 'Conozca más de TrackIT';
_fc_props.business_analytics_blurb = 'Análisis de negocios que mejoran sus resultados, ahora incluidos con TrackIt';
_fc_props.find_out_more = 'Saber más';
_fc_props.daily_breakdown = 'Reporte diario';
_fc_props.question_summary = 'Resumen de preguntas';
_fc_props.trip_summary = 'Resumen de viajes';
_fc_props.manage_employees = 'Manejo de empleados';
_fc_props.manage_hotspots = 'Manejo de puntos de referencia';
_fc_props.manage_devices = 'Manejo de dispositivos';
_fc_props.manage_equipment = 'Manejo de equipos';
_fc_props.manage_users = 'Manejo de usuarios';
_fc_props.manage_statuses = 'Manejo de estados';
_fc_props.manage_alerts = 'Manejo de alertas';
_fc_props.popular_report_links = 'Enlaces a reportes más usados';
_fc_props.popular_settings_links = 'Enlaces a ajustes más usados';
_fc_props.sidemenu_settings = 'Ajustes';
_fc_props.loads_per_driver = 'Cargas por conductor';
_fc_props.please_select_application = 'Seleccione al menos una aplicación';
_fc_props.external_app_processing = 'Procesamiento en una aplicación externa';
_fc_props.external_app_sent = 'Enviado a una aplicación externa';
_fc_props.external_app_received = 'Recibido por aplicación externa';
_fc_props.wait_time_at_job = 'Tiempo de espera en obra';
_fc_props.wait_time_at_plant = 'Tiempo de espera en la planta';
_fc_props.loading_time = 'Tiempo de carga';
_fc_props.unloading_time = 'Tiempo de descarga';
_fc_props.pre_post_trip = 'Pre/post viaje';
_fc_props.update_thresholds = 'Actualizar umbrales';
_fc_props.green = 'Verde';
_fc_props.yellow = 'Amarillo';
_fc_props.red = 'rojo';
_fc_props.between = 'Entre';
_fc_props.num_of_loads = '# de cargas';
_fc_props.num_of_drivers = '# de conductores';
_fc_props.status_meaning_not_setup = 'Estado Significado no configurado';
_fc_props.or_less = 'O menos';
_fc_props.or_greater = 'O mayor';
_fc_props.probe_self_diagnostic_state = 'Estado de autodiagnóstico de la sonda';
_fc_props.update_current_truck_status_settings = 'Actualizar la configuración actual del estado del camión';
_fc_props.default_behavior = 'Predeterminado (10 estados principales)';
_fc_props.combine_similar_statuses = 'Combine los mismos estados de todos los tipos (bucles)';
_fc_props.use_other_category = 'Utilice la categoría \'Otro\'';
_fc_props.selected_statuses = 'Estados seleccionados';
_fc_props.ideas = 'Ideas';
_fc_props.air_content = 'Contenido de aire';
_fc_props.air_measured_time = 'Tiempo medido en aire';
_fc_props.ten_max = '10 máx.';
_fc_props.loads_per_plant = 'Cargas por planta';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Pulso de caudalímetro por volumen no válido.';
_fc_props.coming_soon_title = 'Más funciones pronto';
_fc_props.submit_your_ideas = 'Envíe sus sugerencias a nuestro portal de ideas utilizando la bombilla en el menú de navegación a su izquierda';
_fc_props.selected_plants = 'Plantas Seleccionadas';
_fc_props.update_loads_per_plant_settings = 'Actualizar configuraciones de cargas por planta';
_fc_props.default_top_10_plants = 'Predeterminado (10 plantas principales)';
_fc_props.phone_number_will_be_uploaded = 'El ID del dispositivo se cargará para fines de registro.';
_fc_props.include_deleted = 'Incluir eliminado';
_fc_props.primary_status_type = 'Tipo de estado principal';
_fc_props.additional_status_trigger = 'Activadores de estado adicionales';
_fc_props.update_tickets_destination = 'Actualizar destino en todos los boletos asociados';
_fc_props.settings.send_destination_changes_to_connex = 'Enviar cambios de destino a Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = '¿El ticket está activo cuando TrackIt lo recibe (predeterminado \'siempre\')?';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Activar automáticamente el próximo boleto al completar el boleto anterior';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Debería activarse automáticamente el siguiente ticket al completar el ticket (predeterminado \'verdadero\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Estado a estado inactivo cuando no hay ticket activo';
_fc_props.break_down = 'Descomponer';
_fc_props.idle = 'Inactivo';
_fc_props.pto_work_maximum_speed_limit = 'Límite de velocidad máxima de trabajo';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Asociar el cambio de estado que completa un ticket con ese ticket';
_fc_props.approaching = 'Que se acerca';
_fc_props.performing_task = 'Tarea de realización';
_fc_props.are_you_sure_you_want_to_delete_geogate = '¿Está seguro de que desea eliminar Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Actualizar ticket {0}. Destino cambiado.';
_fc_props.trackit_return_destination_changed_to_0 = 'Actualizar ticket {0}. Retorno cambiado.';
_fc_props.menu_settings_title_geogate = 'geopuerta';
_fc_props.add_geogate = 'Añadir geopuerta';
_fc_props.driver_enabled = 'Conductor habilitado';
_fc_props.dispatcher_enabled = 'Despachador habilitado';
_fc_props.runsheet_number = 'Número de hoja de ejecución';
_fc_props.manual_load = 'Carga manual';
_fc_props.edit_geogate = 'Editar geopuerta';
_fc_props.handling = 'Manejo';
_fc_props.geogate_name = 'geopuerta';
_fc_props.geogate_panel = 'Panel de geopuertas';
_fc_props.vehicle_is_not_logged_in = 'Mensaje no enviado (el vehículo no está registrado)';
_fc_props.air = 'Aire';
_fc_props.create_a_geogate = 'Crear un Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Haga clic en el mapa para establecer los puntos de geopuerta.';
_fc_props.clock_out = 'Reloj de salida';
_fc_props.handling_operation = 'Operación de manejo';
_fc_props.view_geogates = 'Ver Geopuertas';
_fc_props.select_a_geogate = 'Seleccione un Geogate';
_fc_props.failed_to_find_geogate = 'Error al encontrar Geogate';
_fc_props.adjust_geogate = 'Ajustar geopuerta';
_fc_props.geogate_expiration_date_time_extra_days = 'Fecha de caducidad de Geogate Hora Días adicionales predeterminados';
_fc_props.menu_settings_title_retention = 'Retención de datos';
_fc_props.category = 'Categoría';
_fc_props.telemetry = 'Telemetría';
_fc_props.ticket_data = 'Datos del boleto';
_fc_props._30_days = '30 dias';
_fc_props._6_months = '6 meses';
_fc_props._1_year = '1 año';
_fc_props._3_years = '3 años';
_fc_props.new_group = 'Nuevo grupo';
_fc_props.selected_vehicles = 'Vehículos seleccionados';
_fc_props.group_name_is_required_field = 'El nombre del grupo es un campo obligatorio';
_fc_props.at_least_vehicle_should_be_selected = 'Se debe seleccionar al menos 1 vehículo';
_fc_props.groups = 'Grupos';
_fc_props.trucks = 'Camiones';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Se ha eliminado el Geogate. Actualice la página.';
_fc_props.air_timestamp = 'Marca de tiempo de aire';
_fc_props.fail = 'FALLAR';
_fc_props.stuck = 'ATASCADO';
_fc_props.dirty = 'SUCIO';
_fc_props.seasonal = 'Estacional';
_fc_props.category_details = 'Detalles de la categoría';
_fc_props.alert_events = 'Eventos de alerta';
_fc_props.driver_performance_infractions = 'Infracciones de rendimiento del conductor';
_fc_props.telemetry_details = 'Datos de ubicación, puntos de acceso, tiempo en puntos de acceso, tiempo en regiones, datos de DCM, datos de sonda, datos del motor';
_fc_props.ticket_data_details = 'Datos del boleto';
_fc_props.general_details = 'Cambios de estado, tarjetas de tiempo, horarios';
_fc_props.wash = 'Lavar';
_fc_props.filter_options = 'Opciones de filtro';
_fc_props.home_plants = 'Plantas caseras';
_fc_props.dcm = 'MCD';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO requiere que se establezca el tipo de sensor de PTO.';
_fc_props.invalid_io_box_mac_address = 'Dirección Mac de caja de E/S no válida';
_fc_props.access_denied = 'Acceso denegado';
_fc_props.wash_events = 'Eventos de lavado';
_fc_props.min_speed = 'Velocidad mínima';
_fc_props.temperature_change = 'Cambio de temperatura';
_fc_props.degrees_cap = 'Grados';
_fc_props.washout_tracking = 'Seguimiento de lavado';
_fc_props.approaching_wash = 'Lavado acercándose';
_fc_props.arrival_wash = 'Lavado de llegada';
_fc_props.performing_wash = 'Realización de lavado';
_fc_props.wash_complete = 'Lavado completo';
_fc_props.must_be_positive_or_zero = 'Debe ser positivo o cero!';
_fc_props.min_speed_greater_than_max = '¡La velocidad mínima debe ser menor que la velocidad máxima!';
_fc_props.clock_in = 'Reloj en';
_fc_props.mix_water_at_x_to_y_for_t = 'Mezcle agua a {0} a {1} RPM durante {2} revoluciones.';
_fc_props.discharge_water_for_x_revolutions = 'Descargue agua durante {0} revoluciones.';
_fc_props.eod_drum_wash_complete = '¡Lavado de tambor completo! <br> Tambor de parada con sonda en la parte superior.';
_fc_props.washing_complete = 'Lavado guiado completo';
_fc_props.eod_washout_no_data_available = 'Los datos del sensor no están disponibles, complete el proceso de lavado.';
_fc_props.eod_washout_no_data_available_title = 'Lavado guiado no disponible';
_fc_props.menu_reports_title_ticket_time_summary = 'Resumen del tiempo del ticket';
_fc_props.menu_reports_description_ticket_time_summary = 'Ver información de tiempo de boleto para un pedido o vehículo';
_fc_props.menu_reports_title_summary = 'Resumen';
_fc_props.menu_reports_title_ticket_summary = 'Resumen de entradas';
_fc_props.menu_reports_description_summary1 = 'Ver un resumen del tiempo en puntos de acceso';
_fc_props.menu_dispatch_title_main = 'Principal';
_fc_props.menu_dispatch_title_login_registry = 'Registro de inicio de sesión';
_fc_props.menu_reports_title_main = 'Principal';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = '¿Está seguro de que desea eliminar este tipo de datos de exportación?';
_fc_props.dvir_status_paper_logs = 'Registros de papel';
_fc_props.menu_reports_title_billing_report = 'Informe de facturación';
_fc_props.menu_reports_description_billing_report = 'Informe de facturación';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Estado para el estado del ticket al activar el trabajo';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Estado para el estado del ticket al activar el trabajo (predeterminado \'Falso\')';
_fc_props.status_change_type_drum_rotation_sensor = 'MCD';
_fc_props.settings_connex_eid = 'EID de conexión';
_fc_props.units_weight_pound_label = 'Libras';
_fc_props.units_weight_kilogram_label = 'kilogramos';
_fc_props.settings_weight = 'Peso';
_fc_props.units_pounds = 'Libras';
_fc_props.units_kilograms = 'kilogramos';
_fc_props.geo_gate_edit_modal_description = 'Descripción';
_fc_props.geo_gate_edit_modal_start_point = 'Punto de partida';
_fc_props.geo_gate_edit_modal_end_point = 'punto final';
_fc_props.geo_gate_edit_modal_vehicle_types = 'tipo de equipo';
_fc_props.geo_gate_edit_modal_heading_south = 'Sur a Norte';
_fc_props.geo_gate_edit_modal_heading_north = 'Norte a Sur';
_fc_props.geo_gate_edit_modal_driver_message = 'Mensaje del conductor';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Mensaje de envío';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Fecha de caducidad Hora';
_fc_props.geo_gate_edit_modal_permanent = 'Permanente';
_fc_props.geo_gate_edit_modal_critical = 'Crítico';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Error al guardar Geogate: el campo de descripción debe completarse.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Error al guardar Geogate: el campo Punto de inicio debe completarse.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Error al guardar Geogate: el campo Punto de inicio contiene un formato de datos no válido.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Error al guardar Geogate: el campo Punto final contiene un formato de datos no válido.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Error al guardar Geogate: se debe completar el campo Fecha de caducidad y hora.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Error al guardar Geogate: el campo Fecha de caducidad y hora contiene un formato de datos no válido.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Error al guardar Geogate: de norte a sur: se debe completar el campo Mensaje de envío';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Error al guardar Geogate: No se detectaron cambios';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Error al guardar Geogate: el campo de fecha y hora de caducidad contiene fecha y hora caducadas';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Error al guardar Geogate: el campo de descripción debe contener solo letras y números';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Error al guardar Geogate: el campo Tipo de equipo debe completarse';
_fc_props.geo_gate_table_message_label_north_to_south = 'Norte a Sur';
_fc_props.geo_gate_table_message_label_south_to_north = 'Sur a Norte';
_fc_props.geo_gate_table_message_label_both = 'Ambos';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Latitud longitud';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Marcar esto anulará la configuración para la restricción de mensajes de movimiento del dispositivo';
_fc_props.geo_gate_edit_modal_seasonal = 'Estacional';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Error al guardar Geogate: se deben completar las fechas de temporada';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Error al guardar Geogate: los campos de fecha estacional contienen un formato de datos no válido';
_fc_props.test_js_test_javascript = 'Prueba JavaScript';
_fc_props.units_miles_per_hour_abbr = 'millas por hora';
_fc_props.units_kilometers_per_hour_abbr = 'kilómetros por hora';
_fc_props.units_kilometers = 'Kilómetros';
_fc_props.units_miles = 'Millas';
_fc_props.units_gallons_abbr = 'GALÓN';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Error al guardar Geogate: de sur a norte: se debe completar el campo Mensaje del conductor';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Error al guardar Geogate: de sur a norte: se debe completar el campo Mensaje de envío';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Error al guardar Geogate: de norte a sur: se debe completar el campo Mensaje del conductor';
_fc_props.geo_gate_heading = 'Título';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Error al guardar Geogate: el campo Punto final debe completarse';
_fc_props.units_mile = 'Milla';
_fc_props.units_kilometer = 'Kilómetro';
_fc_props.cloud_dvir__exit = 'Salida';
_fc_props.cloud_dvir__signature = 'Firma';
_fc_props.cloud_dvir__inspection = 'Inspección';
_fc_props.cloud_dvir__add_photos_notes = 'Agregar fotos/notas';
_fc_props.cloud_dvir__odometer_reading = 'Lectura del cuentakilómetros';
_fc_props.cloud_dvir__engine_hours = 'Horas de motor';
_fc_props.cloud_dvir__truck_details = 'Detalles del camión';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Determinar el estado general del vehículo.';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Nombre completo y firma del conductor';
_fc_props.cloud_dvir__sign = 'Firmar';
_fc_props.cloud_dvir__driver_note_from = 'Nota del conductor de';
_fc_props.cloud_dvir__add_notes = 'Agregar notas';
_fc_props.cloud_dvir__driver_notes = 'Notas del conductor';
_fc_props.cloud_dvir__done = 'Hecho';
_fc_props.cloud_dvir__needs_repair = 'Necesita Reparación';
_fc_props.cloud_dvir__not_applicable = 'No aplica';
_fc_props.cloud_dvir__review_and_sign = 'Revisar y firmar';
_fc_props.cloud_dvir__add_photos = 'Añadir foto';
_fc_props.cloud_dvir__upload_photo_limit = 'Puedes subir hasta 3 fotos';
_fc_props.cloud_dvir__mismatch_title = 'Alerta de discrepancia';
_fc_props.cloud_dvir__ok = 'De acuerdo';
_fc_props.cloud_dvir_template = 'Plantilla DVIR en la nube';
_fc_props.cloud_dvir__needs_review = 'DVIR necesita revisión';
_fc_props.cloud_dvir__submitted = 'Enviado';
_fc_props.cloud_dvir__completed_by = 'Completado por';
_fc_props.cloud_dvir__review_btn = 'Revisar';
_fc_props.cloud_dvir__truck_number = 'Camión {0}';
_fc_props.cloud_dvir__pending_review = 'Revisión pendiente';
_fc_props.cloud_dvir__start_dvir = 'Iniciar DVIR';
_fc_props.cloud_dvir__dvir_history = 'Historia DVIR';
_fc_props.cloud_dvir__view_all = 'Ver todo';
_fc_props.load_zone = 'Zona de carga';
_fc_props.view_load_zones = 'Ver zonas de carga';
_fc_props.edit_load_zone = 'Editar zona de carga';
_fc_props.create_a_load_zone = 'Crear una zona de carga';
_fc_props.load_zone_panel = 'Panel de zona de carga';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Haga clic en el mapa para establecer el centro de la zona de carga.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Esta inspección tiene defectos indicados y usted ha seleccionado que este vehículo es seguro para operar. Valide antes de continuar';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Esta inspección no tiene defectos indicados y usted ha seleccionado que este vehículo no es seguro para operar. Valide antes de continuar.';
_fc_props._90_days = '90 dias';
_fc_props.cloud_dvir = 'DVIR en la nube';
_fc_props.cloud_dvir_details = 'Envíos de DVIR en la nube';
_fc_props.show_load_zones = 'mostrar zonas de carga';
_fc_props.hide_load_zones = 'ocultar zonas de carga';
_fc_props.no = 'No';
_fc_props.cloud_dvir__reset = 'Reiniciar';
_fc_props.cloud_dvir__clear = 'Claro';
_fc_props.mark_read = 'Marcar como leído';
_fc_props.mark_unread = 'Marcar como no leido';
_fc_props.read = 'Leer';
_fc_props.unread = 'No leído';
_fc_props.air_sensor_serial_number = 'Número de serie del sensor de aire';
_fc_props.air_sensor_mac_address = 'Dirección MAC del sensor de aire';
_fc_props.air_sensor_serial_number_invalid_format = 'El número de serie del sensor de aire no tiene un formato válido: (alfanumérico y como máximo 32 caracteres con guiones)';
_fc_props.air_sensor_mac_address_invalid_format = 'La dirección MAC del sensor de aire no tiene un formato hexadecimal válido como se muestra en el marcador de posición del campo';
_fc_props.air_sensor_license = 'Sensor de aire';
_fc_props.adjust_route_point = 'Ajustar punto de ruta';
_fc_props.route_point_panel = 'Panel de puntos de ruta';
_fc_props.route_point = 'Punto de ruta';
_fc_props.cloud_dvir__pre_trip = 'Pre viaje';
_fc_props.cloud_dvir__post_trip = 'Después del viaje';
_fc_props.cloud_dvir__other_insction_type = 'Otro';
_fc_props.cloud_dvir__dvir_completed = 'Terminado';
_fc_props.cloud_dvir__awaiting_mechanic = 'Esperando mecánico';
_fc_props.cloud_dvir__awaiting_driver = 'Esperando la aprobación del conductor';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'No se puede verificar el número de serie del sensor de aire; comuníquese con el soporte para obtener ayuda adicional.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Este número de serie del sensor de aire ya existe; utilice otro número de serie';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'No se puede verificar la dirección MAC del sensor de aire; comuníquese con el soporte para obtener ayuda';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Error al enviar DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Inténtalo de nuevo';
_fc_props.ticket_integration_descr = 'La integración de tickets permite configurar la configuración de integración de tickets, así como la pantalla de inicio/pantalla de carga/pantalla de montaje rígido';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR enviado con éxito';
_fc_props.cloud_dvir__inspection_trip_type = 'Tipo de viaje de inspección';
_fc_props.menu_dvir_title_mechanic = 'Mecánico';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Firmware del sensor de aire';
_fc_props.when_no_active_ticket = 'Cuando no hay ticket activo';
_fc_props.used_by = 'Usado por';
_fc_props.fields = 'Campos';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = '¿Está seguro de que desea eliminar el diseño del ticket "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Diseño de entradas';
_fc_props.add_ticket_layout = 'Agregar diseño de ticket';
_fc_props.edit_ticket_layout = 'Editar diseño de ticket';
_fc_props.ticket_layout_edit_modal_name = 'Nombre';
_fc_props.ticket_layout_edit_modal_default = 'Por defecto';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Error al guardar el diseño del ticket: el campo Nombre debe estar completo.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Error al guardar el diseño del ticket: el campo de nombre debe contener solo letras y números';
_fc_props.ticket_layout_edit_modal_fields = 'Campos';
_fc_props.ticket_layout_edit_modal_usage = 'Uso';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Se eliminó el diseño del ticket. Por favor, actualice la página.';
_fc_props.more_lower_case = 'más';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Error al guardar el diseño del ticket: se deben seleccionar uno o más campos.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Error al guardar el diseño del ticket: se deben seleccionar uno o más estados.';
_fc_props.cloud_dvir__ad_hoc = 'Ad hoc';
_fc_props.radius_multiplier_should_be = 'El multiplicador de radio debe ser un número flotante';
_fc_props.ticket_max_activate_age_err = 'Los minutos de edad máxima de activación del ticket deben ser un número entero.';
_fc_props.max_hotspot_unused_days_errs = 'El número máximo de días no utilizados del hotspot debe ser un número entero.';
_fc_props.begin_pour_air_content = 'Comience a verter el contenido de aire';
_fc_props.arrival_air_content = 'Contenido aéreo de llegada';
_fc_props.fully_mixed_air_content = 'Contenido de aire completamente mezclado';
_fc_props.departure_air_content = 'Contenido del aire de salida';
_fc_props.popup_enabled = 'Ventana emergente habilitada';
_fc_props.popup_disabled = 'Ventana emergente deshabilitada';
_fc_props.cloud_dvir__leave_confirmation_title = 'Advertencia';
_fc_props.cloud_dvir__leave_confirmation_description = 'Salir de la página ahora hará que se pierdan todos los cambios.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Confirmar';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Cancelar';
_fc_props.air_bt_ratio = 'Relación BT de aire';
_fc_props.cloud_dvir__view_photos_notes = 'Ver fotos/notas';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Agregar el estado creará un uso en blanco en otro diseño de ticket. ¿Le gustaría continuar con la adición?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'El vehículo no tiene licencia de sensores de aire.';
_fc_props.air_sensor_installed = 'Sensor de aire instalado';
_fc_props.load_card = 'Cargar tarjeta';
_fc_props.cloud_dvir__inspection_profile = 'Perfil de inspección';
_fc_props.cloud_dvir__view_details = 'Ver detalles';
_fc_props.cloud_dvir__certify_repairs = 'Certificar Reparaciones';
_fc_props.cloud_dvir__repairs_made = 'Reparaciones realizadas';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparaciones no necesarias';
_fc_props.cloud_dvir__mechanic_note_from = 'Nota mecánica de';
_fc_props.cloud_dvir__mechanic_assignment = 'Asignación de mecánico';
_fc_props.cloud_dvir__mechanic_signature_description = 'Marque todos los elementos aplicables a continuación y firme indicando que el vehículo ha sido inspeccionado adecuadamente.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'El estado de este vehículo es satisfactorio.';
_fc_props.cloud_dvir__defects_corrected_label = 'Los defectos han sido corregidos.';
_fc_props.cloud_dvir__repair_not_required_label = 'No se requiere reparación para el funcionamiento seguro del vehículo.';
_fc_props.safety = 'Seguridad';
_fc_props.cloud_dvir__awaiting_sign = 'Esperando señal';
_fc_props.cloud_dvir__dvir_review = 'Revisión DVIR';
_fc_props.settings.mobile_auto_start_navigation = 'Navegación de inicio automático en el móvil';
_fc_props.cloud_dvir__edit = 'Editar';
_fc_props.delete_custom_label = 'Eliminar etiqueta personalizada';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Su versión de TrackIt no está actualizada y es posible que algunas funciones de DVIR no funcionen correctamente. Actualice TrackIt antes de continuar.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Algo salió mal durante la carga de la firma. Inténtalo de nuevo';
_fc_props.max_hotspot_unused_days_error = 'El máximo de días no utilizados del hotspot tiene un máximo de 1000 días.';
_fc_props.menu_dvir_title_admin_templates = 'Plantillas';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Administración';
_fc_props.cloud_dvir__admin_templates = 'Plantillas';
_fc_props.form_name = 'Nombre del formulario';
_fc_props.form_status = 'Estado del formulario';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Descargar';
_fc_props.cloud_dvir__option_view = 'Vista';
_fc_props.cloud_dvir__inspectortype_driver = 'Conductor';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Ver/Editar/Crear formularios y envíos DVIR personalizados';
_fc_props.menu.dvir.description.mechanic = 'Ver/Editar envíos DVIR procesables';
_fc_props.not_in_use = 'No esta en uso';
_fc_props.logged_in = 'conectado';
_fc_props.reviewing_driver = 'Revisión del conductor';
_fc_props.cloud_dvir__report_title = 'Informe de inspección del vehículo del conductor';
_fc_props.cloud_dvir__report_signed = 'firmado';
_fc_props.cloud_dvir__report_page_counter = 'Página {0} de {1}';
_fc_props.cloud_dvir__report_defects = 'Defectos';
_fc_props.cloud_dvir__report_comments = 'Comentarios';
_fc_props.cloud_dvir__report_inspection_table_title = 'Inspeccion de vehiculo';
_fc_props.cloud_dvir__report_driver_table_title = 'Información del conductor';
_fc_props.cloud_dvir__report_carrier = 'Transportador';
_fc_props.cloud_dvir__report_truck = 'Camión';
_fc_props.cloud_dvir__report_location = 'Ubicación';
_fc_props.cloud_dvir__report_odometer = 'Cuentakilómetros';
_fc_props.cloud_dvir__report_hours = 'Horas';
_fc_props.cloud_dvir__report_no_comments = 'Sin comentarios';
_fc_props.include_unlicensed_vehicles = 'Incluir vehículos sin licencia';
_fc_props.cloud_dvir__search_by_form_name = 'Buscar por nombre de formulario';
_fc_props.cloud_dvir__create_dvir = 'Crear un DVIR';
_fc_props.cloud_dvir__update_dvir = 'Actualizar DVIR';
_fc_props.cloud_dvir__form_name = 'Nombre del formulario';
_fc_props.cloud_dvir__form_description = 'Descripción del formulario';
_fc_props.cloud_dvir__form_status = 'Estado del formulario';
_fc_props.information = 'Información';
_fc_props.cloud_dvir__search_categories = 'Categorías de búsqueda';
_fc_props.cloud_dvir__create_new_category = 'Crear nueva categoría';
_fc_props.category_name = 'nombre de la categoría';
_fc_props.sub_category = 'Subcategoría';
_fc_props.sub_category_name = 'Nombre de subcategoría';
_fc_props.delete_category = 'Eliminar categoría';
_fc_props.cloud_dvir__dvir_information = 'Información DVIR';
_fc_props.cloud_dvir__dvir_choose_categories = 'Elija categorías';
_fc_props.cloud_dvir__create_category = 'Crear categoría';
_fc_props.cloud_dvir__update_category = 'Actualizar categoría';
_fc_props.delete_route_point = 'Eliminar punto de ruta';
_fc_props.view_route_points = 'Ver puntos de ruta';
_fc_props.ignore_cancel_preload = 'Ignora la cancelación, estás precargado. Tu jonrón se mostrará pronto';
_fc_props.cloud_dvir__assigned_mechanic = 'Mecanico asignado';
_fc_props.cloud_dvir__add_mechanic = 'Agregar un mecánico';
_fc_props.contact_type = 'Tipo de Contacto';
_fc_props.cloud_dvir__send_email_notifications = 'Enviar notificaciones por correo electrónico';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Error al recuperar los registros del historial DVIR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Error al recuperar el registro DVIR.';
_fc_props.cloud_dvir__error_saving_submission = 'Error al guardar el envío. Intentar otra vez.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Error al recuperar la lista de mecánicas.';
_fc_props.cloud_dvir__template_saved = 'Plantilla guardada';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Error al recuperar la lista de plantillas.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Error al guardar la plantilla. Intentar otra vez.';
_fc_props.dispatch_group = 'Grupo de despacho';
_fc_props.save_adjust = 'Guardar y ajustar el punto de ruta';
_fc_props.reset_route_point = 'Restablecer punto de ruta';
_fc_props.no_records_stub_adjust_filters = 'Intente ajustar la configuración de búsqueda o filtro para aumentar la cantidad de resultados.';
_fc_props.no_dvir_records = '¡Todos se pusieron al día con los DVIR!';
_fc_props.no_dvir_records_sub_title = 'Has logrado mucho';
_fc_props.settings.master_data_connex_x_api_key = 'Datos maestros Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'No se obtuvo lectura de caída para: {0}';
_fc_props.please_select_a_sensor_status_type = 'Seleccione los tipos de estado del sensor para activar esta alerta';
_fc_props.settings.master_data_connex_token = 'Token de conexión de datos maestros';
_fc_props.error_validating_master_data_connex_credentials = 'Error al validar las credenciales de Master Data Connex';
_fc_props.settings.master_data_connex_base_url = 'URL de la base de datos maestros de Connex';
_fc_props.settings.master_data_connex_exp_time = 'Tiempo de vencimiento de conexión de datos maestros';
_fc_props.menu_settings_title_dispatch_group = 'Grupos de despacho';
_fc_props.settings.dispatch_groups = 'Grupos de despacho';
_fc_props.dispatch_group_name = 'Nombre del grupo de despacho';
_fc_props.dispatch_group_plants = 'Plantas del grupo de despacho';
_fc_props.dispatch_group_plants_mapping_message = 'Indique qué plantas formarán parte de este Grupo de Despacho.';
_fc_props.dispatch_group_users_mapping_message = 'Indique qué usuarios formarán parte de este Grupo de Despacho.';
_fc_props.dispatch_group_plant_disassociation_message = 'La planta debe estar bajo un grupo de despacho a la vez.';
_fc_props.dispatch_group_search_by_plant = 'Buscar por nombre de planta';
_fc_props.dispatch_group_search_by_username = 'Buscar por nombre de usuario';
_fc_props.search_by_dispatch_group_name = 'Buscar por nombre de grupo de despacho';
_fc_props.create_dispatch_group = 'Crear un grupo de despacho';
_fc_props.update_dispatch_group = 'Actualizar grupo de despacho';
_fc_props.dispatch_group_saved = 'Grupo de despacho guardado';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Al eliminar este grupo de despacho se crearán grupos de despacho predeterminados para las plantas asociadas.';
_fc_props.error_saving_dispatch_group = 'Error al guardar el grupo de despacho. Intentar otra vez.';
_fc_props.please_enter_a_valid_plant_num = 'Introduzca un número de planta válido.';
_fc_props.cloud_dvir__report_no_defects = 'Sin defectos';
_fc_props.shoud_select_dispatch_group = 'Tienes que seleccionar al menos un grupo de despacho';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'No se puede eliminar porque este grupo de envío contiene usuarios que solo están asociados con este grupo de envío.';
_fc_props.cloud_dvir__username = 'Nombre de usuario';
_fc_props.cloud_dvir__password = 'Contraseña';
_fc_props.cloud_dvir__login = 'Acceso';
_fc_props.cloud_dvir__mechanic_sign_in = 'Mecánico Iniciar sesión';
_fc_props.delete_dispatch_group = 'Eliminar grupo de despacho';
_fc_props.cloud_dvir__driver = 'Conductor';
_fc_props.cloud_dvir__equipment_pound = 'Equipo #';
_fc_props.cloud_dvir__mechanic = 'Mecánico';
_fc_props.dispatch_group_user_only_unassociate_message = 'No se puede eliminar el usuario porque los usuarios deben estar asociados con al menos un grupo de despacho.';
_fc_props.cloud_dvir__time_period_filter_today = 'Hoy';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Ayer';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Los últimos 7 días';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Últimos 30 días';
_fc_props.cloud_dvir__date_range_filter = 'Filtrar por fecha';
_fc_props.cloud_dvir__download = 'Descargar';
_fc_props.dispatch_group_is_empty = 'El grupo de despacho no puede estar vacío.';
_fc_props.dispatch_group_with_empty_plants = 'Al menos una planta debe estar asociada al grupo de despacho.';
_fc_props.dispatch_group_with_empty_users = 'Al menos un usuario debe estar asociado al grupo de despacho.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'No se puede eliminar el grupo de despacho porque contiene usuarios que solo están asociados con este grupo de despacho.';
_fc_props.dispatch_group_not_exists = 'El grupo de despacho no existe.';
_fc_props.dispatch_group_contains_one_plant = 'No se puede eliminar porque este grupo de despacho contiene solo una planta.';
_fc_props.dispatch_group_users = 'Usuarios del grupo de despacho';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Error al recuperar el registro DVIR';
_fc_props.cloud_dvir__assign_mechanic = 'Asignar mecánico';
_fc_props.cloud_dvir__submit_review = 'Enviar opinión';
_fc_props.error_deleting_dispatch_group = 'Error al eliminar el grupo de despacho.';
_fc_props.error_retrieving_dispatch_groups = 'Error al recuperar la lista de grupos de despacho.';
_fc_props.cloud_dvir__undo_selection = 'Deshacer selección';
_fc_props.enable_master_data = 'Habilitar datos maestros';
_fc_props.manually_sync = 'Sincronizar manualmente';
_fc_props.master_data_connex_token_expiration = 'Vencimiento del token de Master Data Connex';
_fc_props.cloud_dvir__time_period_filter_custom = 'Costumbre';
_fc_props.settings.zello_session_timeout_minutes = 'Tiempo de espera de sesión de Zello (minutos)';
_fc_props.menu_settings_title_master_data = 'Datos maestros';
_fc_props.settings.master_data = 'Datos maestros';
_fc_props.error_retrieving_master_data = 'Error al recuperar datos maestros';
_fc_props.master_data_sync_completed = 'Sincronización de datos maestros completada';
_fc_props.master_data_successfully_updated = 'Datos actualizados exitosamente';
_fc_props.master_data_failed_to_update = 'Error al guardar.';
_fc_props.master_data_sync_initiated = 'Sincronización de datos maestros iniciada';
_fc_props.master_data_sync_failed = 'Error en la sincronización de datos maestros';
_fc_props.enabled_excluding_origin_on_deliveries = 'Verdadero (excluyendo el origen en los tickets de entrega)';
_fc_props.enabled_including_origin_on_deliveries = 'Verdadero (incluido el origen en los tickets de entrega)';
_fc_props.cloud_dvir__i_will_decide_later = 'lo decidiré más tarde';
_fc_props.returning = 'Regresando';
_fc_props.leftover_concrete = 'Concreto sobrante';
_fc_props.leftover_concrete_on_vehicle_x = 'Restos de hormigón en el vehículo {0}';
_fc_props.leftover_concrete_alert = 'Alerta de restos de hormigón';
_fc_props.wc_ratio_exceeded = 'Relación W/C excedida';
_fc_props.search_by_site_name = 'Buscar por nombre del sitio';
_fc_props.sites = 'Sitios';
_fc_props.token_expiration = 'Caducidad del token';
_fc_props.menu_settings_title_connex_api_key_management = 'Gestión de claves API de Connex';
_fc_props.settings.connex_api_key_management = 'Gestión de claves API de Connex';
_fc_props.error_retrieving_connex_api_key_data = 'Error al recuperar los datos de la clave API de Connex';
_fc_props.qr_code = 'Código QR';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informar al conductor y a la interfaz cuando se ingresa a una planta incorrecta';
_fc_props.enable_driver_message = 'Habilitar mensaje del conductor';
_fc_props.enable_dispatch_message = 'Habilitar mensaje de envío';
_fc_props.enable_sending_message_to_interface = 'Habilitar el envío de mensajes a la interfaz';
_fc_props.ticket_qr_code = 'Código QR del billete';
_fc_props.leftover_concrete_alert_description = 'Alerta cuando se encuentren restos de hormigón después del vertido.';
_fc_props.minimum_leftover_concrete_amount = 'Cantidad mínima de hormigón sobrante';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Error al validar datos maestros. Credenciales de Connex no autorizadas.';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Error al validar los permisos de credenciales de Master Data Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Error al validar la URL de Master Data Connex no encontrada';
_fc_props.error_validating_master_data_connex_unknown_error = 'Error al validar datos maestros Error desconocido de Connex';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Error en la sincronización de datos maestros. Sincronice el tipo de vehículo.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Error en la sincronización de datos maestros. Sincronice el vehículo.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Error en la sincronización de datos maestros. Sincronizar la planta.';
_fc_props.master_data_sync_failed_data_base_connection = 'La sincronización de datos maestros falló al conectarse a la base de datos.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Error al validar datos maestros Credenciales de Connex El permiso del token del portador es insuficiente';
_fc_props.settings.back_date_arrive_job = 'Fecha de llegada del trabajo atrasada';
_fc_props.device_notifications = 'Notificaciones';
_fc_props.loading_number = 'Número de carga';
_fc_props.leftover_concrete_volume_temp_age = 'Volumen sobrante={0}, temperatura={1}, edad={2}';
_fc_props.total_ordered = 'Cantidad total pedida';
_fc_props.total_ticketed = 'Entregado Ordenado';
_fc_props.load_number = 'Número de carga';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Se debe rellenar el hormigón sobrante mínimo y mayor que {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Error al validar los datos maestros. Las credenciales no pueden ser las mismas.';
_fc_props.use_aws_for_talk = 'Utilice AWS para hablar';
_fc_props.air_readings_not_supported = 'Lecturas de aire no compatibles';
_fc_props.air_not_supported = 'Aire no compatible';
_fc_props.mix_not_supported = 'Mezcla no compatible';
_fc_props.air_default_and_none_not_supported = '¡No se puede seleccionar predeterminado y no compatible al mismo tiempo!';
_fc_props.notifications_enabled = 'Notificaciones habilitadas';
_fc_props.prevent_status_change = 'Prevenir cambio de estado';
_fc_props.message_to_interface = 'Mensaje a la interfaz';
_fc_props.prevent_wrong_plant = 'Prevenir la planta equivocada';
_fc_props.previous_tickets = 'Entradas Anteriores';
_fc_props.audit_ticket_number = 'Numero de ticket';
_fc_props.audit_date_created = 'fecha de creacion';
_fc_props.audit_vehicle_number = 'Número de vehículo';
_fc_props.cloud_dvir__table_ok = 'Seguro de operar';
_fc_props.cloud_dvir__table_needs_repair = 'No es seguro operar';
_fc_props.missing_air_calibration = 'Sin datos de calibración de aire';
_fc_props.reported_defects = 'Defectos reportados';
_fc_props.address_latitude = 'Dirección Latitud';
_fc_props.address_longitude = 'Dirección Longitud';
_fc_props.routing_latitude = 'Latitud de ruta';
_fc_props.routing_longitude = 'Longitud de ruta';
_fc_props.category_name_must_be_unique = 'El nombre de la categoría debe ser único.';
_fc_props.subcategory_name_must_be_unique = 'El nombre de la subcategoría debe ser único.';
_fc_props.menu_settings_tracking_device = 'Dispositivo rastreador';
_fc_props.menu_settings_title_tracking_device = 'Dispositivo rastreador';
_fc_props.menu_settings_title_personal_device_registration = 'Registro de dispositivo personal';
_fc_props.registration_code = 'Código de registro';
_fc_props.creation_date = 'Creación';
_fc_props.expiration_date = 'Vencimiento';
_fc_props.trackitadmin_personal_device_registration = 'Registro de dispositivo personal';
_fc_props.create_registration_code = 'Agregar código de registro';
_fc_props.remove_registration_code = 'Eliminar código de registro';
_fc_props.add_a_registration_code = 'Agregar un código de registro';
_fc_props.select_number_of_registration_codes_to_generate = 'Seleccione la cantidad de códigos de registro que desea generar.';
_fc_props.registration_code_count = 'Recuento de códigos de registro';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = '¿Está seguro de que desea eliminar el código registrado seleccionado?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = '¿Está seguro de que desea eliminar los códigos registrados seleccionados?';
_fc_props.add_personal_device = 'Agregar dispositivo personal';
_fc_props.menu_settings_title_personal_device = 'Dispositivo personal';
_fc_props.cloud_dvir__approval_critical_alert = 'El vehículo tiene un defecto crítico. No se puede marcar como seguro de operar.';
_fc_props.export_registration_codes = 'Códigos de registro de exportación';
_fc_props.download_format = 'Descargar formato';
_fc_props.specify_download_format_x = 'Especifique el formato de descarga para exportar {0} códigos de registro';
_fc_props.personal_device_download = 'Descargar';
_fc_props.add_personal_device_input_message = 'Ingrese una ID de dispositivo personal a continuación. La caducidad se aplicará automáticamente después de guardar';
_fc_props.error_creating_personal_device = 'Error al crear un dispositivo personal';
_fc_props.device_already_exists = 'El dispositivo personal ya existe';
_fc_props.export = 'Exportar';
_fc_props.error_can_not_change_connex_eid = 'No se puede cambiar el eid de conexión porque actualmente se utiliza para datos maestros';
_fc_props.remove_personal_device = 'Eliminar dispositivo personal';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = '¿Está seguro de que desea eliminar el dispositivo personal seleccionado?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = '¿Está seguro de que desea eliminar los dispositivos personales seleccionados?';
_fc_props.error_deleting_personal_device = 'Error al eliminar el dispositivo personal';
_fc_props.customerid = 'Identificación del cliente';
_fc_props.customername = 'Nombre del cliente';
_fc_props.ordernumber = 'Número de orden';
_fc_props.orderdescription = 'Descripción del pedido';
_fc_props.locationid = 'ID de ubicación';
_fc_props.locationdescription = 'Descripción de la ubicación';
_fc_props.productid = 'ID del Producto';
_fc_props.productdescription = 'Descripción del Producto';
_fc_props.vehicleid = 'ID del vehículo';
_fc_props.vehicledescription = 'Descripcion del vehiculo';
_fc_props.ticketnumber = 'Boleto #';
_fc_props.ticketdate = 'Fecha/hora del billete';
_fc_props.drivername = 'Nombre del conductor';
_fc_props.arriveregion = 'Región de llegada (TIEMPO)';
_fc_props.leaveregion = 'Salir de la región (TIEMPO)';
_fc_props.regionduration = 'Duración de la región';
_fc_props.menu_reports_title_prevailing_wage = 'Salario prevaleciente';
_fc_props.slump_calibration_x_not_found_for_y = 'No se encontró la calibración de asentamiento "{0}" para el ticket {1}';
_fc_props.slump_calibration_not_found = 'Calibración de asentamiento no encontrada';
_fc_props.slump_calibration_not_found_description = 'Alerta cuando no se encuentra la calibración de asentamiento';
_fc_props.error_getting_personal_device_list = 'Error al obtener la lista de dispositivos personales';
_fc_props.batch_summary_date_range_note = '*NOTA: Este informe está limitado a 3 días si no se aplican filtros. El rango de fechas puede durar hasta 31 días cuando se filtra por Equipo (10 como máximo), Planta (1 como máximo), Producto (1 como máximo) o Trabajo (1 como máximo).';
_fc_props.select_registration_code_expiration_time_days = 'Seleccione la cantidad de días hasta que caduquen los códigos de registro y luego haga clic en Guardar.';
_fc_props.add_personal_device_expiration = 'Vencimiento (en días)';
_fc_props.cloud_dvir__vehicle_no = 'Número de vehículo';
_fc_props.cloud_dvir__trailer_no = 'Tráiler n.º';
_fc_props.cloud_dvir__vehicle = 'Vehículo';
_fc_props.cloud_dvir__trailer = 'Tráiler';
_fc_props.cloud_dvir__truck_number_label = 'Número de camión';
_fc_props.cloud_dvir__trailer_number = 'Número de remolque';
_fc_props.cloud_dvir__has_trailer = 'No conducir con remolque';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Introduzca aquí el número de su remolque';
_fc_props.work_order = 'Orden de trabajo';
_fc_props.unable_to_determine_slump = 'No se puede determinar la depresión';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Determinar el estado general del remolque.';
_fc_props.defaultTrailer = 'Tráiler predeterminado';
_fc_props.trailer_type_column_billable = 'Facturable';
_fc_props.trailer_type_column_notes = 'Notas';
_fc_props.trailer_type_column_date = 'Fecha';
_fc_props.trailer_type_column_order = 'Orden';
_fc_props.trailer_type_column_ticket = 'Boleto';
_fc_props.trailer_type_column_project = 'Proyecto';
_fc_props.trailer_type_column_customer = 'Cliente';
_fc_props.trailer_type_column_location = 'Ubicación';
_fc_props.trailer_type_column_vehicle_type = 'Tipo de vehículo';
_fc_props.trailer_type_column_product = 'Producto';
_fc_props.trailer_type_action_rebill = 'Refacturación';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Error en la sincronización de datos maestros: no se ha establecido ningún tipo de equipo predeterminado';
_fc_props.trailer_type = 'Tipo de remolque';
_fc_props.trailer_type_is_required = 'Se requiere el tipo de remolque';
_fc_props.trailer_number_already_exists = 'El número de tráiler ya existe';
_fc_props.plate_number_already_exists = 'El número de placa ya existe';
_fc_props.trailer_vin_already_exists = 'Trailer Vin ya existe';
_fc_props.trailer_does_not_exist = 'El trailer no existe';
_fc_props.trailer_number_is_required = 'Se requiere el número de remolque';
_fc_props.plate_number_is_required = 'Se requiere el número de placa';
_fc_props.plate_number = 'Número de placa';
_fc_props.menu_settings_title_trailer = 'Tráiler';
_fc_props.add_trailer = 'Añadir tráiler';
_fc_props.edit_trailer = 'Editar tráiler';
_fc_props.menu_settings_title_equipment = 'Equipo';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorar nuevos tickets de Command Cloud con más de X minutos de antigüedad';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignorar los nuevos tickets de Command Cloud con fecha y hora de despacho anteriores a X minutos (el valor predeterminado está vacío y desactivado)';
_fc_props.edit_load_card = 'Editar tarjeta de carga';
_fc_props.default_layout = 'Disposición predeterminada';
_fc_props.load_card_empty_fields = 'Error al guardar la tarjeta de carga: Se deben seleccionar uno o más campos.';
_fc_props.trailers = 'Remolques';
_fc_props.error_failed_to_save_data = 'Error: No se pudieron guardar los datos';
_fc_props.slump_increased_plasticizer_added_question = '¿Cuánto plastificante se añadió?';
_fc_props.add_trailer_type = 'Agregar tipo de remolque';
_fc_props.menu_settings_title_trailer_type = 'Tipo de remolque';
_fc_props.edit_trailer_type = 'Editar tipo de tráiler';
_fc_props.single_use_codes = 'Códigos de un solo uso';
_fc_props.multiple_use_codes = 'Códigos de usos múltiples';
_fc_props.trailer_type_description_is_required = 'Se requiere descripción del tipo de remolque';
_fc_props.trailer_saved = 'Tráiler guardado';
_fc_props.cloud_dvir__trailer_only_dvir = 'Remolque solamente DVIR';

export default _fc_props;
