import { useContext, useMemo } from 'react';
import { AlertContext } from '../context/AlertContext';

const useAlert = () => {
  const alertContext = useContext(AlertContext);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const alert = useMemo(() => alertContext.current!, [alertContext]);

  return alert;
};

export default useAlert;
