export type FontWeight = {
  normal: number;
  lightBold: number;
  bold: number;
  bolder: number;
}

const fontWeight: FontWeight = {
  normal: 400,
  lightBold: 500,
  bold: 600,
  bolder: 700,
};

export default fontWeight;
