import { InspectionValue, SubmissionDriverData, SubmissionLocation, SubmissionType } from '../../../types/submission';

export interface DvirDefectItem {
  item: string;
  status: string;
  critical: boolean;
}

export interface DvirDefect {
  category: string;
  items: DvirDefectItem[];
}

class SubmitDvirRequest {
  readonly dataModelNumber: number = 3;

  tripType: string;

  safetyStatus: InspectionValue;

  location: SubmissionLocation | { address: string; latitude: number; longitude: number };

  odometer: string;

  engineHours: string;

  submissionType: SubmissionType;

  vin: string;

  defectItemsJson: string;

  driver: SubmissionDriverData;

  constructor(
    tripType: string,
    safetyStatus: InspectionValue,
    location: SubmissionLocation | { address: string; latitude: number; longitude: number },
    odometer: string,
    engineHours: string,
    defectItemsJson: string,
    submissionType: SubmissionType,
    vin: string,
    driver: SubmissionDriverData,
  ) {
    this.tripType = tripType;
    this.safetyStatus = safetyStatus;
    this.location = location;
    this.odometer = odometer;
    this.engineHours = engineHours;
    this.defectItemsJson = defectItemsJson;
    this.submissionType = submissionType;
    this.vin = vin;
    this.driver = driver;
  }
}

export default SubmitDvirRequest;
