import React, { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TFunction } from 'i18next';
import { Text, StatusBadge } from '../../components';
import formatDate from '../../utils/format-date';
import { ISubmissionItem } from '../types';
import { InspectionValue } from '../../types/submission';
import { BadgeStatus } from '../../components/status-badge/StatusBadge';
import { InspectionTripType } from '../../types/types';

const StyledSubmissionButton = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: `${theme.indent}px ${theme.indent * 3}px ${theme.indent}px ${theme.indent * 2}px`,
  backgroundColor: theme.palette.neutral.variation100,
  border: `1px solid ${theme.palette.neutral.variation80}`,
  borderRadius: 8,
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
}));

const getInspectionTripTypeLabel = (t: TFunction, type: InspectionTripType) => {
  if (type === 'PRE_TRIP') {
    return t('cloud_dvir__pre_trip');
  }

  if (type === 'POST_TRIP') {
    return t('cloud_dvir__post_trip');
  }

  return t('cloud_dvir__ad_hoc');
};

const getBadgeConfig = (initialSafetyStatus: InspectionValue): { badgeStatus: BadgeStatus, translationKey: string } => {
  if (initialSafetyStatus === InspectionValue.Ok) {
    return { badgeStatus: 'success', translationKey: 'cloud_dvir__ok' };
  }

  if (initialSafetyStatus === InspectionValue.NeedsRepair) {
    return { badgeStatus: 'error', translationKey: 'cloud_dvir__needs_repair' };
  }

  return { badgeStatus: 'info', translationKey: 'unknown' };
};

interface SubmissionItemProps {
  submission: ISubmissionItem;
  onClick: (submission: ISubmissionItem) => void;
}

const SubmissionItem: FC<SubmissionItemProps> = ({
  onClick = () => { },
  submission,
}) => {
  const { t } = useTranslation();

  const {
    submissionDate, initialSafetyStatus, inspectionTripType, vehicleNum, driver,
  } = submission;

  const submissionTimestamp = useMemo(() => formatDate(new Date(submissionDate)), [submissionDate]);
  const inspectionTripTypeLabel = getInspectionTripTypeLabel(t, inspectionTripType);
  const { badgeStatus, translationKey } = getBadgeConfig(initialSafetyStatus);

  const handleClick = () => {
    onClick(submission);
  };

  return (
    <StyledSubmissionButton onClick={handleClick}>
      <Box>
        <Text
          fontSize="large"
          fontLevel="body"
          color={(theme) => theme.palette.neutral.variation10}
        >
          {`${t('cloud_dvir__equipment_pound')}: ${vehicleNum}`}
        </Text>
        <Text
          fontSize="large"
          fontLevel="body"
          color={(theme) => theme.palette.neutral.variation10}
        >
          {`${submissionTimestamp} | ${inspectionTripTypeLabel}`}
        </Text>
        <Text
          fontSize="medium"
          fontLevel="body"
          color={(theme) => theme.palette.neutral.variation10}
        >
          {`${t('cloud_dvir__mechanic')}: `}
        </Text>
        <Text
          fontSize="medium"
          fontLevel="body"
          color={(theme) => theme.palette.neutral.variation10}
        >
          {`${t('cloud_dvir__driver')}: ${driver.name}`}
        </Text>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <StatusBadge status={badgeStatus} text={t(translationKey)} />
        <NavigateNextIcon />
      </Box>
    </StyledSubmissionButton>
  );
};

export default SubmissionItem;
