import React, {
  FC, memo, useState, createContext, useRef, useMemo, useContext,
} from 'react';
import { getSubmissionItemIdsForMechanicReview } from '../utils';
import { InspectionValue, Submission, SubmissionCategoryItem } from '../../types/submission';
import { AuthenticationContext } from './AuthenticationProvider';
import { MechanicSubmissionUpdate } from '../types';
import { MechanicData, MechanicSign } from '../review/types';

type SubmissionUpdates = Record<SubmissionCategoryItem['id'], MechanicSubmissionUpdate>;

interface MechanicSignatureData {
  inspectorId: string;
  updatedTime: string;
  signature: string,
  src: string;
}

interface MechanicReviewProviderProps {
  submission: Submission;
  children: React.ReactNode | React.ReactNode[];
}

interface ContextValue {
  setItemSafetyStatus: ({ id, safetyStatus }: { id: string, safetyStatus: InspectionValue }) => void;
  resetItemSafetyStatus: (id: string) => void;
  addItemNote: ({ id, note }: { id: string, note: string }) => void;
  deleteItemNote: (id: string) => void;
  submissionUpdates: SubmissionUpdates;
  isAllItemsReviewed: boolean;
  assignedMechanic: MechanicData | null ;
  setAssignedMechanic: (mechanicData: MechanicData) => void;
  signReview: (sign: MechanicSign) => void;
  resetSignReview: () => void;
  inspectorId: string;
  submission: Submission;
  isReadyForSubmit: boolean;
  mechanicSignature: MechanicSignatureData;
}

export const MechanicReviewContext = createContext<ContextValue>({
  setItemSafetyStatus: () => {},
  resetItemSafetyStatus: () => {},
  addItemNote: () => {},
  deleteItemNote: () => {},
  submissionUpdates: {},
  isAllItemsReviewed: false,
  assignedMechanic: null,
  setAssignedMechanic: () => {},
  signReview: () => {},
  resetSignReview: () => {},
  inspectorId: '',
  submission: null,
  isReadyForSubmit: false,
  mechanicSignature: null,
});

export const MechanicReviewProvider:FC<MechanicReviewProviderProps> = memo(({ submission, children }) => {
  const [submissionUpdates, setSubmissionUpdates] = useState<SubmissionUpdates>({});
  const [mechanicSignature, setMechanicSignature] = useState<MechanicSignatureData | null>(null);
  const [assignedMechanic, setAssignedMechanic] = useState<MechanicData | null>(null);

  const { mechanicLoginData: { employeeId } } = useContext(AuthenticationContext);

  const itemIdsForReview = useRef(getSubmissionItemIdsForMechanicReview(submission));

  const isAllItemsReviewed = useMemo(() => {
    /** there is no items for review */
    if (!itemIdsForReview.current.length) {
      return true;
    }

    return !itemIdsForReview.current.some((itemId) => !submissionUpdates[itemId]?.safetyStatus);
  }, [submissionUpdates]);

  const setItemSafetyStatus = ({ id, safetyStatus }) => {
    const event = {
      safetyStatus,
      updatedTime: new Date().toISOString(),
      inspectorId: employeeId,
    };

    setSubmissionUpdates({
      ...submissionUpdates,
      [id]: {
        ...submissionUpdates[id],
        ...event,
      },
    });
  };

  const resetItemSafetyStatus = (id) => {
    setSubmissionUpdates((updates) => ({
      ...updates,
      [id]: {
        ...updates[id],
        safetyStatus: null,
      },
    }));
  };

  const addItemNote = ({ id, note }) => {
    const event = {
      note,
      updatedTime: new Date().toISOString(),
      inspectorId: employeeId,
    };

    setSubmissionUpdates({
      ...submissionUpdates,
      [id]: {
        ...submissionUpdates[id],
        ...event,
      },
    });
  };

  const deleteItemNote = (id) => {
    const { [id]: itemToDeleteNote, ...restUpdates } = submissionUpdates;

    if (itemToDeleteNote.safetyStatus) {
      /** omit note from item object */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { note, ...item } = itemToDeleteNote;
      setSubmissionUpdates({ ...restUpdates, [id]: item });

      return;
    }

    setSubmissionUpdates(restUpdates);
  };

  const signReview = ({ src, id }: MechanicSign) => {
    setMechanicSignature({
      inspectorId: employeeId,
      signature: id,
      src,
      updatedTime: new Date().toISOString(),
    });
  };

  const resetSignReview = () => {
    setMechanicSignature(null);
  };

  const value = useMemo(() => ({
    submissionUpdates,
    inspectorId: employeeId,
    setItemSafetyStatus,
    addItemNote,
    deleteItemNote,
    resetItemSafetyStatus,
    isAllItemsReviewed,
    signReview,
    resetSignReview,
    mechanicSignature,
    isReadyForSubmit: Boolean(mechanicSignature),
    setAssignedMechanic,
    assignedMechanic,
    submission,
  }), [
    submissionUpdates,
    employeeId,
    setItemSafetyStatus,
    addItemNote,
    deleteItemNote,
    resetItemSafetyStatus,
    isAllItemsReviewed,
    signReview,
    resetSignReview,
    mechanicSignature,
    setAssignedMechanic,
    assignedMechanic,
    submission,
  ]);

  return <MechanicReviewContext.Provider value={value}>{children}</MechanicReviewContext.Provider>;
});
