import { Chip as MUIChip, ChipProps as MUIChipProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export type ChipStatus = 'error' | 'success' | 'neutral'

export interface ChipProps extends MUIChipProps {
  status?: ChipStatus
}

const getStylesByStatus = (status: ChipStatus, theme: Theme) => {
  switch (status) {
    case 'success':
      return {
        backgroundColor: theme.palette.green.variation50,
        color: theme.palette.neutral.variation100,
      };
    case 'error':
      return {
        backgroundColor: theme.palette.red.variation50,
        color: theme.palette.neutral.variation100,
      };
    case 'neutral': {
      return {
        backgroundColor: theme.palette.orange.variation70,
        color: theme.palette.neutral.variation10,
      };
    }
    default:
      return {
        backgroundColor: theme.palette.neutral.variation95,
        color: theme.palette.neutral.variation10,
      };
  }
};

const Chip = styled(MUIChip)<ChipProps>(({ size, status = null, theme }) => {
  const statusStyles = getStylesByStatus(status, theme);
  const hoverStyles = status ? statusStyles : {};

  return {
    height: size === 'medium' ? '28px' : '16px',
    ...statusStyles,
    ...theme.fontLevelBox.body.small,
    '&:hover': hoverStyles,
  };
});

export default Chip;
