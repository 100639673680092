import { EVENT_INDEX } from './constants';
import {
  InspectionValue, InspectorType, Submission, SubmissionCategory, SubmissionEvent,
} from '../types/submission';

export const getSubmissionItemIdsForMechanicReview = (submission: Submission) => submission.categories.reduce((ids: string[], category) => {
  const categoryItemIds = category.items.reduce(
    (itemIds: string[], item) => (item.events[EVENT_INDEX.DRIVER].safetyStatus === InspectionValue.NeedsRepair
      ? [...itemIds, item.id]
      : itemIds),
    [],
  );

  return [...ids, ...categoryItemIds];
}, []);

export const sortCategoryItemsByNeedsRepair = (category: SubmissionCategory): SubmissionCategory => ({
  ...category,
  items: category.items.slice().sort((a) => (a.events[0].safetyStatus === InspectionValue.NeedsRepair ? -1 : 1)),
});

export const sortCategoriesByNeedsRepair = (submission: Submission): Submission => {
  const sortedCategories = [...submission.categories].sort((a, b) => {
    const unsatisfactoryItemsA = a.items.filter(
      (item) => item.events[0].safetyStatus === InspectionValue.NeedsRepair,
    );
    const unsatisfactoryItemsB = b.items.filter(
      (item) => item.events[0].safetyStatus === InspectionValue.NeedsRepair,
    );
    return unsatisfactoryItemsB.length - unsatisfactoryItemsA.length;
  });

  const sortedCategoriesAndItems = sortedCategories.map((category) => sortCategoryItemsByNeedsRepair(category));

  return {
    ...submission,
    categories: sortedCategoriesAndItems,
  };
};

export const getNoteData = ({ event: { note, updatedTime, inspectorType } }: { event: SubmissionEvent }) => ({ note, updatedTime, isDriverNote: inspectorType === InspectorType.Driver });
