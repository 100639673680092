import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { StyledHeader } from './styles';

interface BarItemProps {
  title: string;
  itemText: string;
  icon: React.ReactNode;
  onClick?: () => void;
  editable?: boolean;
}

const StyledBoxItem = styled(Box)(() => ({
  minWidth: '188px',
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  overflow: 'hidden',
  borderRadius: 12,
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
}));

const StyledIconContent = styled('div')(({ theme }) => ({
  paddingRight: `${theme.indent * 2}px`,
}));

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: `${theme.indent * 2}px`,
  gap: 2,
}));

const StyledAction = styled('div')(({ theme }) => ({
  padding: `${theme.indent * 2}px`,
  borderLeft: `1px solid ${theme.palette.neutral.variation80}`,
}));

const BarItem: FC<BarItemProps> = ({
  title, itemText, icon, onClick, editable = false,
}) => (
  <Box sx={{
    display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'left',
  }}
  >
    <StyledHeader>
      {title}
    </StyledHeader>
    <StyledBoxItem>
      <StyledContent>
        <StyledIconContent>{icon}</StyledIconContent>
        {itemText}
      </StyledContent>
      {editable && (
        <StyledAction>
          <IconButton color="primary" sx={{ padding: '0' }} onClick={onClick}>
            <EditIcon sx={{ width: 24, height: 24, color: (theme) => theme.palette.neutral.variation40 }} />
          </IconButton>
        </StyledAction>
      )}
    </StyledBoxItem>
  </Box>
);

export default BarItem;
