import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Modal, Text, Button } from '../../components';
import { MismatchType } from './constants';

const StyledMismatchApproveButton = styled(Button)`
  width: 240px;
  margin-top: 12px;
`;

const getMismatchTranslations = (t) => {
  const mismatchTranslations = {
    [MismatchType.NeedsRepair]: t('cloud_dvir__mismatch_title_needs_repair'),
    [MismatchType.AllItemsOk]: t('cloud_dvir__mismatch_title_all_items_ok'),
  };

  return mismatchTranslations;
};

const MismatchModal = ({ onApprove, mismatchNotificationType }) => {
  const { t } = useTranslation();

  const mismatchTranslations = getMismatchTranslations(t);

  return (
    <Modal open onClose={() => {}}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center',
      }}
      >
        <Text
          fontLevel="title"
          fontSize="large"
          sx={{
            color: (theme) => theme.palette.neutral.variation40,
          }}
        >
          {t('cloud_dvir__mismatch_title')}
        </Text>
        <Text sx={{ textAlign: 'center' }}>
          {mismatchTranslations[mismatchNotificationType]}
        </Text>
        <StyledMismatchApproveButton size="large" variant="contained" isRounded onClick={onApprove}>
          {t('cloud_dvir__ok')}
        </StyledMismatchApproveButton>
      </Box>
    </Modal>
  );
};

export default MismatchModal;
