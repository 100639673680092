const _fc_props = {};
_fc_props.ante_meridiem = 'A.M';
_fc_props.post_meridiem = 'P.M';
_fc_props.retry = 'Reîncercați';
_fc_props.default = 'Mod implicit';
_fc_props.origin = 'Origine';
_fc_props.loading = 'Se încarcă';
_fc_props.list = 'Listă';
_fc_props.send = 'Trimite';
_fc_props.error = 'Eroare';
_fc_props.save = 'salva';
_fc_props.delete = 'Șterge';
_fc_props.message = 'Mesaj';
_fc_props.logout = 'Deconectare';
_fc_props.add = 'Adăuga';
_fc_props.remove = 'Elimina';
_fc_props.done = 'Terminat';
_fc_props.other = 'Alte';
_fc_props.interval = 'Interval';
_fc_props.time = 'Timp';
_fc_props.speed = 'Viteză';
_fc_props.longitude = 'Longitudine';
_fc_props.latitude = 'Latitudine';
_fc_props.x_minutes = '{0} min';
_fc_props.general = 'General';
_fc_props.messages = 'Mesaje';
_fc_props.gps_fixes = 'Remedieri GPS';
_fc_props.device_id = 'Identificatorul dispozitivului';
_fc_props.vehicle = 'Vehicul';
_fc_props.employee = 'Angajat';
_fc_props.version = 'Versiune';
_fc_props.statuses = 'Statuturi';
_fc_props.edit = 'Editați | ×';
_fc_props.cancel = 'Anulare';
_fc_props.yes = 'da';
_fc_props.none = 'Nici unul';
_fc_props.never = 'Nu';
_fc_props.name = 'Nume';
_fc_props.true = 'Adevărat';
_fc_props.status = 'stare';
_fc_props.distance = 'Distanţă';
_fc_props.ok = 'Bine';
_fc_props.login = 'Autentificare';
_fc_props.next = 'Următorul';
_fc_props.menu = 'Meniul';
_fc_props.not_available = 'N / A';
_fc_props.back = 'Înapoi';
_fc_props.select = 'Selectați';
_fc_props.dispatch = 'Expediere';
_fc_props.messaging = 'Mesaje';
_fc_props.plants = 'Plante';
_fc_props.last_plants = 'Ultimele Plante';
_fc_props.vehicle_types = 'Tipuri de vehicule';
_fc_props.employee_types = 'Tipuri de angajați';
_fc_props.employee_groups = 'Grupuri de angajați';
_fc_props.equipment_groups = 'Grupuri de echipamente';
_fc_props.job = 'Loc de munca';
_fc_props.order = 'Ordin';
_fc_props.ticket = 'Bilet #';
_fc_props.small = 'Mic';
_fc_props.medium = 'Mediu';
_fc_props.large = 'Mare';
_fc_props.reports = 'Rapoarte';
_fc_props.payroll = 'Salarizare';
_fc_props.support = 'A sustine';
_fc_props.username = 'Nume de utilizator';
_fc_props.all = 'Toate';
_fc_props.question = 'Întrebare';
_fc_props.select_employee = 'Selectați Angajat';
_fc_props.phone = 'Telefon';
_fc_props.january = 'ianuarie';
_fc_props.february = 'februarie';
_fc_props.march = 'Martie';
_fc_props.april = 'Aprilie';
_fc_props.may = 'Mai';
_fc_props.june = 'iunie';
_fc_props.july = 'iulie';
_fc_props.august = 'August';
_fc_props.september = 'Septembrie';
_fc_props.october = 'octombrie';
_fc_props.november = 'noiembrie';
_fc_props.december = 'decembrie';
_fc_props.are_you_sure_you_want_to_delete = 'ești sigur că vrei să ștergi';
_fc_props.unknown = 'Necunoscut';
_fc_props.select_all = 'Selectează tot';
_fc_props.equipment = 'Echipamente';
_fc_props.region = 'Regiune';
_fc_props.no_data = 'Nu există date';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Abordare';
_fc_props.retrieving_address = 'Se preia adresa...';
_fc_props.address_not_found = 'Adresa nu a fost găsită';
_fc_props.active_time = 'Timp activ';
_fc_props.destination_time = 'Ora de destinație';
_fc_props.complete_time = 'Timp complet';
_fc_props.status_report = 'Raport de stare';
_fc_props.export_report = 'Raport de export';
_fc_props.download_report = 'Descărcați raportul';
_fc_props.view_report = 'Vizualizează raportul';
_fc_props.duration = 'Durată';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Data/Ora de începere';
_fc_props.select_start_date = 'Selectați Data de începere';
_fc_props.end_date_time = 'Data/Ora de încheiere';
_fc_props.select_end_date = 'Selectați Data de încheiere';
_fc_props.trip_time = 'Timp de călătorie';
_fc_props.travel_time = 'Timp de calatorie';
_fc_props.description = 'Descriere';
_fc_props.map_controls = 'Controale hărților';
_fc_props.road = 'drum';
_fc_props.aerial = 'aerian';
_fc_props.hybrid = 'Hibrid';
_fc_props.trip = 'excursie';
_fc_props.map = 'Hartă';
_fc_props.plant = 'Plantă';
_fc_props.select_date = 'Selectați Data';
_fc_props.save_settings = 'Salvează setările';
_fc_props.false = 'Fals';
_fc_props.delete_confirmation = 'Ștergeți confirmarea';
_fc_props.last_name = 'Nume';
_fc_props.none_selected = 'Nimic selectat';
_fc_props.start_date = 'Data de început';
_fc_props.group_by = 'A se grupa cu';
_fc_props.equipment_employee = 'Echipament / Angajat';
_fc_props.inactive = 'Inactiv';
_fc_props.active = 'Activ';
_fc_props.note_checking_may_affect_performance = 'Notă: verificarea poate afecta performanța';
_fc_props.show_distancefuel_used = 'Afișați distanța/combustibilul utilizat';
_fc_props.mins = 'min';
_fc_props.threshold_time = 'Timp prag';
_fc_props.include_voided_tickets = 'Includeți bilete anulate';
_fc_props.note_all_times_listed_are_in_minutes = 'Notă: Toate orele enumerate sunt în minute';
_fc_props.please_select_a_job_order_or_equipment = 'Vă rugăm să selectați un loc de muncă, o comandă, o instalație, un echipament sau un angajat';
_fc_props.device = 'Dispozitiv';
_fc_props.select_equipment = 'Selectați Echipament';
_fc_props.all_equipment_types = 'Toate tipurile de echipamente';
_fc_props.units = {};
_fc_props.units.liters = 'Litri';
_fc_props.type = 'Tip';
_fc_props.no_data_available = 'Nici o informatie disponibila';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Distanță și combustibil pe regiune';
_fc_props.menu_settings_title_settings = 'Setări';
_fc_props.menu_settings_title_alerts = 'Alerte';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Echipamente';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspot-uri și regiuni';
_fc_props.days = '{0} zi(e)';
_fc_props.home = 'Acasă';
_fc_props.find = 'Găsi';
_fc_props.close_this_panel = 'închideți acest panou';
_fc_props.start = 'start';
_fc_props.end = 'Sfârşit';
_fc_props.location_details = 'Detalii locație';
_fc_props.first_name = 'Nume';
_fc_props.material_per_hour = 'Material pe oră';
_fc_props.equipment_pound = 'echipament #';
_fc_props.equipment_type = 'tip de echipament';
_fc_props.options = 'Opțiuni';
_fc_props.zoom_on_cursor_position = 'Măriți pe poziția cursorului';
_fc_props.display_equipment_identifier = 'Afișează identificatorul echipamentului';
_fc_props.view_equipment = 'Vezi echipamente';
_fc_props.view_hotspots = 'Vedeți hotspot-uri';
_fc_props.view_regions = 'Vedeți regiunile';
_fc_props.use_standard_filters = 'Utilizați filtre standard';
_fc_props.home_plant = 'Planta de acasă';
_fc_props.last_plant = 'Ultima plantă';
_fc_props.equip_types = 'Echipat. Tipuri';
_fc_props.equip_groups = 'Echipat. Grupuri';
_fc_props.orders = 'Comenzi';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Utilizați filtrul pentru echipamente';
_fc_props.all_selected = 'Toate Selectate';
_fc_props.of = 'De';
_fc_props.your_map_session_has_expired = 'Sesiunea dvs. de hartă a expirat. Vă rugăm să reîmprospătați pagina.';
_fc_props.equipment_short = 'Echipat.';
_fc_props.job_info_for = 'Informații despre job pentru';
_fc_props.order_info_for = 'Informații de comandă pentru';
_fc_props.ticket_info_for_vehicle = 'Informații despre bilet pentru vehicul';
_fc_props.inbox = 'Inbox';
_fc_props.outbox = 'Căsuță de ieșire';
_fc_props.alerts = 'Alerte';
_fc_props.critical = 'Critic';
_fc_props.important = 'Important';
_fc_props.informational = 'Informațional';
_fc_props.view_routes = 'Vedeți traseele';
_fc_props.hours_size = 'Dimensiunea orelor';
_fc_props.used_count = 'Număr folosit';
_fc_props.last_login = 'Ultima logare';
_fc_props.last_vehicle = 'Ultimul Vehicul';
_fc_props.regions = 'Regiuni';
_fc_props.driver = 'Conducător auto';
_fc_props.js_delete = 'Șterge';
_fc_props.move = 'Mișcare';
_fc_props.edit_hotspot = 'Editați hotspot';
_fc_props.adjust_shape = 'Ajustați forma';
_fc_props.edit_region = 'Editați regiunea';
_fc_props.enter_a_start_address = 'Vă rugăm să introduceți o adresă de început';
_fc_props.select_a_start_hotspot = 'Vă rugăm să selectați un hotspot de pornire';
_fc_props.failed_to_find_start_hotspot = 'Nu s-a găsit pornirea Hotspot-ului.';
_fc_props.invalid_latitudelongitude = 'Latitudine/Longitudine nevalidă';
_fc_props.enter_an_end_address = 'Vă rugăm să introduceți o adresă finală';
_fc_props.select_an_end_hotspot = 'Vă rugăm să selectați un hotspot final';
_fc_props.failed_to_find_end_hotspot = 'Hotspot-ul final nu a putut fi găsit.';
_fc_props.print = 'Imprimare';
_fc_props.these_directions_are_for_informational_purposes = 'Aceste instrucțiuni au doar scop informativ. Nu se oferă nicio garanție cu privire la completitudinea sau acuratețea acestora. Proiectele de construcții, traficul sau alte evenimente pot determina condițiile reale să difere de aceste rezultate.';
_fc_props.enter_an_address = 'Vă rugăm să introduceți o adresă';
_fc_props.select_a_hotspot = 'Vă rugăm să selectați un hotspot';
_fc_props.failed_to_find_hotspot = 'Hotspot-ul nu a fost găsit.';
_fc_props.select_a_vehicle = 'Vă rugăm să selectați un vehicul';
_fc_props.failed_to_find_vehicle = 'Nu s-a găsit vehiculul.';
_fc_props.failed_to_find_address = 'Nu s-a găsit adresa';
_fc_props.failed_to_find_vehicles = 'Nu s-au găsit vehicule în criteriile de căutare.';
_fc_props.get_address = 'Obține adresa';
_fc_props.results_for = 'rezultate pentru';
_fc_props.error_retrieving_results = 'Eroare la preluarea rezultatelor';
_fc_props.no_results_found = 'Nici un rezultat gasit';
_fc_props.address_is_required = 'Adresa este obligatorie';
_fc_props.city_is_required = 'Orașul este obligatoriu';
_fc_props.set_location = 'Setați locația';
_fc_props.set_area_of_interest = 'Setați zona de interes';
_fc_props.center_map_here = 'Harta centru aici';
_fc_props.zoom_to_street_level = 'Măriți la nivelul străzii';
_fc_props.set_start = 'Setează Start';
_fc_props.set_end = 'Set End';
_fc_props.delete_node = 'Ștergeți nodul';
_fc_props.create_a_hotspot = 'Creați un hotspot';
_fc_props.create_a_region = 'Creați o regiune';
_fc_props.toggle_shapes = 'Comutați Forme';
_fc_props.refresh_map = 'Actualizează harta';
_fc_props.more_options = 'Mai multe opțiuni';
_fc_props.recenter_map = 'Recentrează harta';
_fc_props.zoom_to_vehicle = 'Măriți la vehicul';
_fc_props.apply_filters = 'Aplicați filtre';
_fc_props.grid_view = 'Vizualizare grilă';
_fc_props.list_view = 'Vizualizare listă';
_fc_props.click_to_sort = 'Faceți clic pentru a sorta';
_fc_props.message_history = 'Istoricul mesajelor';
_fc_props.equipment_types = 'Tipuri de echipamente';
_fc_props.send_message = 'Trimite mesaj';
_fc_props.last_load = 'Ultima încărcare';
_fc_props.last_load_on = 'Ultima încărcare activată';
_fc_props.manual_logout = 'Deconectare manuală';
_fc_props.manual_login = 'Conectare manuală';
_fc_props.no_employees_logged_in = 'Niciun angajat nu a fost autentificat';
_fc_props.select_phone = 'Selectați Telefon';
_fc_props.you_must_select_a_phone = 'Trebuie să selectați un telefon, un echipament și un angajat';
_fc_props.are_you_sure_you_want_to_logout = 'Sigur doriți să vă deconectați';
_fc_props.fix_time = 'Fix Time';
_fc_props.to_next_fix = 'la Următoarea remediere';
_fc_props.minutes_to_next_fix = 'Minute până la următoarea remediere';
_fc_props.liters = 'Litri';
_fc_props.destination = 'Destinaţie';
_fc_props.return = 'Întoarcere';
_fc_props.product = 'Produs';
_fc_props.no_records_found = 'Nu au fost găsite.';
_fc_props.vehicle_type = 'Tip de vehicul';
_fc_props.view_in_map = 'Vezi toate pe hartă';
_fc_props.permanent = 'Permanent';
_fc_props.expire_time = 'Timp de expirare';
_fc_props.please_select_x_for_tickets_marked_active = 'Vă rugăm să selectați {0} pentru Biletele marcate ACTIV';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Mai multe bilete marcate ACTIV pentru același {0}';
_fc_props.failure_connecting_to_servlet = 'Eroare la conectare la servlet.';
_fc_props.status_time_summary = 'Rezumatul timpului de stare';
_fc_props.view_in_map_one = 'Vedeți în Hartă';
_fc_props.question_report = 'Raport de întrebare';
_fc_props.end_date = 'Data de încheiere';
_fc_props.trailer_number = 'Numărul remorcii';
_fc_props.region_panel = 'Panoul de regiune';
_fc_props.hotspot_panel = 'Panoul Hotspot';
_fc_props.shape = 'Formă';
_fc_props.circle = 'Cerc';
_fc_props.polygon = 'Poligon';
_fc_props.click_the_map_to_set_the_location = 'Faceți clic pe hartă pentru a seta locația dorită.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Faceți clic pe hartă pentru a seta centrul hotspot-ului.';
_fc_props.click_the_map_to_set_the_region_center = 'Faceți clic pe hartă pentru a seta centrul regiunii.';
_fc_props.click_and_drag_points = 'Faceți clic și trageți puncte pentru a edita forma. Faceți clic pe SALVARE pentru a salva modificările.';
_fc_props.size = 'mărimea';
_fc_props.are_you_sure_you_want_to_move_this = 'Ești sigur că vrei să muți asta';
_fc_props.are_you_sure_you_want_to_delete_this = 'Sigur doriți să ștergeți acest lucru';
_fc_props.please_enter_a_valid_size = 'Vă rugăm să introduceți o dimensiune validă';
_fc_props.failed_to_save = 'Salvare eșuată.';
_fc_props.failed_to_move = 'Mișcarea eșuată.';
_fc_props.failed_to_delete = 'Ștergerea eșuată.';
_fc_props.x_hours = '{0} ore';
_fc_props.hide_hotspots = 'Ascundeți hotspot-urile';
_fc_props.show_regions = 'Arată regiuni';
_fc_props.show_hotspots = 'Afișați hotspot-uri';
_fc_props.hide_regions = 'Ascundeți regiunile';
_fc_props.numeric = 'Numeric';
_fc_props.message_type = 'Tip mesaj';
_fc_props.text = 'Text';
_fc_props.yes_no = 'Da nu';
_fc_props.responses = 'Răspunsuri';
_fc_props.disabled = 'Dezactivat';
_fc_props.group_name = 'Numele Grupului';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Sigur doriți să ștergeți formatul „{0}”?';
_fc_props.phone_number = 'Numar de telefon';
_fc_props.interface_pound = 'interfață #';
_fc_props.field = 'Camp';
_fc_props.default_label = 'Etichetă implicită';
_fc_props.custom_label = 'Etichetă personalizată';
_fc_props.you_must_enter_a_label = 'Trebuie să introduceți o etichetă.';
_fc_props.enabled = 'Activat';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Integrarea biletelor';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence off hotspot-uri deschise';
_fc_props.settings.associate_open_hotspots_wticket = 'Asociați hotspot-uri deschise cu bilet';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Permiteți geofencing în afara hotspot-urilor deschise sau numai a hotspot-urilor asociate cu Biletul șoferului';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Dacă se asociază sau nu Hotspot-urile deschise cu Biletele atunci când un vehicul intră în ele';
_fc_props.settings.void_ticket_without_destination = 'Bilet nul fără destinație';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'dacă un bilet este primit în timp ce altul este activ, dacă biletul activ nu are o destinație, biletul va fi anulat.';
_fc_props.settings.autocreate_hotspots = 'Creați automat hotspot-uri';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Dacă se creează sau nu hotspot-uri automat de la adresa de destinație atunci când se primește un bilet';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Activați automat biletul la primire';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Biletul este activ când este primit de TrackIt („adevărat” implicit)';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Închidere automată a biletului activ la primirea minutelor';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Cât timp trebuie să fi fost activ biletul activ curent înainte de a-l închide automat („Implicit”)';
_fc_props.settings.ticket_max_activate_age_minutes = 'Biletul Max Activare Minute de vârstă';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Cât de vechi în minute poate avea un bilet atunci când îl activezi în TrackIt (implicit \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Numărul maxim de zile nefolosite';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Stabilește numărul maxim de zile în care un hotspot poate fi neutilizat și totuși reînviat';
_fc_props.settings.radius_multiplier = 'Multiplicator de rază';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Cu ce să înmulțiți raza atunci când se primește un bilet de la sistemul de expediere';
_fc_props.settings.ticket_message_date_format = 'Formatul datei mesajului biletului';
_fc_props.am = 'a.m';
_fc_props.pm = 'p.m';
_fc_props.settings.ticket_message = 'Mesaj pentru bilet';
_fc_props.settings.add_ticket_element = 'Adăugați element de bilet';
_fc_props.driver_performance = 'Performanța șoferului';
_fc_props.update_failed_can_not_connect = 'Actualizare eșuată: nu se poate conecta la servlet.';
_fc_props.unknown_error = 'Eroare necunoscută';
_fc_props.selected = 'Selectat';
_fc_props.web_service = 'Serviciu web';
_fc_props.error_processing_request = 'Eroare la procesarea cererii.';
_fc_props.email_address = 'Adresa de e-mail';
_fc_props.id = 'ID';
_fc_props.last_used = 'Folosit ultima data';
_fc_props.error_invalid_vehiclenum = 'Eroare Număr vehicul nevalid';
_fc_props.error_vehicle_is_not_logged_in = 'Eroare: vehiculul nu este autentificat.';
_fc_props.users = 'Utilizatori';
_fc_props.you_dont_have_access_to_this_page = 'Nu aveți acces pentru a vizualiza această pagină. Vă rugăm să contactați administratorul de sistem pentru acces.';
_fc_props.previous_week = 'Saptamana trecuta';
_fc_props.status_breakdown = 'Defalcare de stare';
_fc_props.select_plant_or_employee_group = 'Trebuie să selectați fie o fabrică, fie un grup de angajați.';
_fc_props.cannot_select_plant_and_employee_group = 'Nu puteți selecta atât o fabrică, cât și un grup de angajați.';
_fc_props.odometer_must_be_a_number = 'Odometrul de început trebuie să fie un număr';
_fc_props.ending_odometer_must_be_a_number = 'Odometrul final trebuie să fie un număr';
_fc_props.beginning_hour_meter_must_be_a_number = 'Contorul orelor de început trebuie să fie un număr';
_fc_props.ending_hour_meter_must_be_a_number = 'Contorul orelor de sfârșit trebuie să fie un număr';
_fc_props.error_loading_status_change_page = 'Eroare la încărcarea paginii de modificare a stării.';
_fc_props.x_is_an_invalid_time_format = '{0} este un format de oră nevalid, trebuie să fie AAAA-LL-ZZ HH:MM:SS';
_fc_props.x_for_schedule_login = '{0} pentru programare de conectare';
_fc_props.x_for_schedule_logout = '{0} pentru deconectare programată';
_fc_props.x_for_actual_login = '{0} pentru autentificare reală';
_fc_props.x_for_actual_logout = '{0} pentru deconectarea reală';
_fc_props.by_refreshing_the_time_card_detail = 'Reîmprospătând detaliile cardului de pontaj, veți pierde toate modificările, continuați?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Sigur doriți să ștergeți {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Adresa URL a biletului de comandă mobil';
_fc_props.year = 'An';
_fc_props.make = 'Face';
_fc_props.model = 'Model';
_fc_props.settings.command_mobile_ticket_password = 'Parola pentru biletul de comandă mobil';
_fc_props.settings.command_mobile_ticket_auth_token = 'Jeton de autentificare pentru biletul mobil de comandă';
_fc_props.target = 'Ţintă';
_fc_props.number_of_digits = 'Număr de cifre';
_fc_props.number_of_integer_digits = 'Numărul de cifre întregi';
_fc_props.number_of_fractional_digits = 'Numărul de cifre fracționale';
_fc_props.search = 'Căutare';
_fc_props.settings.command_mobile_ticket_date_order = 'Command Mobile Ticket Data Comanda';
_fc_props.settings.command_mobile_ticket_date_length = 'Data Tichet Command Mobile Lungime';
_fc_props.settings.command_mobile_ticket_date_separator = 'Separator de date pentru biletul de comandă mobil';
_fc_props.settings.command_mobile_ticket_slash = '/ (bară oblică)';
_fc_props.settings.command_mobile_ticket_dash = '- (liniuță)';
_fc_props.settings.command_mobile_ticket_period = '. (perioadă)';
_fc_props.settings.command_mobile_ticket_space = '(spaţiu)';
_fc_props.settings.command_mobile_ticket_time_length = 'Durata timpului biletului de comandă mobil';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Time Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (colon)';
_fc_props.settings.command_mobile_ticket_time_format = 'Format de timp pentru biletul de comandă mobil';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Utilizați numărul de interfață';
_fc_props.mechanic = 'Mecanic';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Interval de sondaj (secunde)';
_fc_props.driving_time = 'Timp de condus';
_fc_props.find_near_plants = 'Găsiți în apropierea plantelor';
_fc_props.please_create_hot_spot_first = 'Creați mai întâi hotspot';
_fc_props.please_enter_hot_spot_description = 'Vă rugăm să introduceți descrierea punctului fierbinte';
_fc_props.please_select_a_hot_spot_type = 'Vă rugăm să selectați un tip de punct fierbinte';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Trimiteți e-mailul șoferului';
_fc_props.directions_not_found = 'Adresă {0} nevalidă. Indicații nu au fost găsite.';
_fc_props.ws_user = 'Utilizator';
_fc_props.ws_service = 'Serviciu';
_fc_props.ws_method = 'Metodă';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Originea și Destinația';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Ajutor';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Utilizați Android Numărul alt angajat';
_fc_props.settings.command_mobile_ticket_android_url = 'Adresa URL a biletului mobil Android';
_fc_props.settings.command_mobile_ticket_android_secret = 'Secretul biletului mobil Android';
_fc_props.employee_x_has_no_logout_on_y = 'Angajatul {0} nu are deconectare pentru o tură care începe pe {1}.';
_fc_props.phone_number_has_duplicates = 'Numerele de telefon au intrări duplicat';
_fc_props.ounce = 'Uncie';
_fc_props.water_extra = 'Apa suplimentara';
_fc_props.always = 'Mereu';
_fc_props.work_types_must_be_unique = 'Tipurile de lucru trebuie să fie unice';
_fc_props.manufacturer = 'Producător';
_fc_props.need_to_enter_seconds = 'Trebuie să introduceți secunde.';
_fc_props.copy = 'Copie';
_fc_props.create_in_existing_required = 'Creare în existent trebuie selectat pentru a crea hotspot de roaming.';
_fc_props.break = 'Pauză';
_fc_props.add_export_data_type = 'Adăugați tipul de date de export';
_fc_props.edit_export_data_type = 'Editați tipul de date de export';
_fc_props.delete_export_data_type = 'Ștergeți tipul de date de export';
_fc_props.export_data_type = 'Export tip de date';
_fc_props.error_updating_export_data_type = 'Eroare la actualizarea tipului de date de export';
_fc_props.settings.address_key = 'Cheie de adresă';
_fc_props.settings.address_key_hover = 'Opțiuni cheie pentru adresă';
_fc_props.settings.address_key_options = 'Opțiuni cheie pentru adresă';
_fc_props.more_filter_options = 'Mai multe opțiuni de filtrare';
_fc_props.unauthorized_mapit_app = 'Această versiune de MapIt nu este autorizată, vă rugăm să actualizați MapIt cât mai curând posibil.';
_fc_props.zello_timeout = 'Timeout a avut loc contactând Zello.';
_fc_props.user_agreement_text = 'Conectându-vă, sunteți de acord cu';
_fc_props.user_agreement = 'Acordul Utilizatorului';
_fc_props.x_mins_left = 'Au rămas {0} min(e).';
_fc_props.continuous_location_tracking_colon = 'Urmărirea continuă a locației:';
_fc_props.hired_hauler_cannot_register_device = 'Transportatorul angajat nu poate înregistra un dispozitiv nou.';
_fc_props.timecard_does_not_contain_shift = 'Cardul de pontaj al angajaților nu conține schimbul (Informații despre cardul de pontaj) furnizat';
_fc_props.timecard_not_found = 'Cardul de pontaj al angajatului nu a fost găsit';
_fc_props.vehicle_must_be_assigned_for_clt = 'Vehiculul trebuie să fie alocat locațiilor de cale.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Vehiculul nu a fost atribuit. Urmărirea continuă va fi dezactivată.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'S-a pierdut conexiunea cu EDC. Vă rugăm să verificați dacă WiFi și/sau Bluetooth sunt activate.';
_fc_props.acknowledge_all = 'Recunoașteți totul';
_fc_props.expand = 'Extinde';
_fc_props.collapse = 'Colaps';
_fc_props.expand_all = 'Extinde toate';
_fc_props.collapse_all = 'Restrângeți totul';
_fc_props.last_update = 'Ultima actualizare';
_fc_props.acknowledge_alert = 'Confirmați alertă';
_fc_props.acknowledge_message = 'Confirmați mesajul';
_fc_props.acknowledge_all_alerts = 'Confirmați toate alertele';
_fc_props.acknowledge_all_messages = 'Confirmați toate mesajele';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Toleranța trebuie să fie un număr întreg valid între -99 și 99 (ambele incluse)';
_fc_props.gps_permission_required = 'Este necesară permisiunea GPS';
_fc_props.permissions_required = 'Permisiuni necesare';
_fc_props.change_user = 'Schimbă utilizatorul';
_fc_props.account_not_registered_with_carrier = 'Acest cont nu este înregistrat la un operator';
_fc_props.slump_loss = 'Slump Pierdere';
_fc_props.slump_loss_help = 'Slump Loss Ajutor';
_fc_props.did_not_receive_any_edc_devices = 'Nu a primit niciun dispozitiv EDC.';
_fc_props.edc_record_not_found = 'Înregistrarea EDC nu a fost găsită';
_fc_props.disable_hotspot_prompt = 'Vă rugăm să atingeți „OK” când vi se solicită să dezactivați hotspot-ul dvs.';
_fc_props.edc_device_is_in_use = 'EDC {0} este în uz.';
_fc_props.an_unknown_exception_has_occurred = 'A apărut o excepție necunoscută';
_fc_props.login_has_timed_out = 'Autentificarea a expirat';
_fc_props.use_paper_logs = 'Utilizați jurnalele de hârtie';
_fc_props.eld_login_failed = 'Conectarea orelor de serviciu a eșuat';
_fc_props.setup_time_off_type = 'Vă rugăm să configurați un tip de pauză.';
_fc_props.view_load_properties = 'Vizualizați proprietățile de încărcare';
_fc_props.couldnt_download_edc_firmware = 'Nu s-a putut descărca firmware-ul EDC.';
_fc_props.password_incorrect = 'Parola incorecta';
_fc_props.edc_configuration_password_prompt = 'Datorită stării acestui dispozitiv, trebuie să furnizați parola de instalare pentru configurare.';
_fc_props.edc_configuration_check_failed = 'Validarea înregistrărilor serverului EDC a eșuat. Vă rugăm să verificați mesajele de eroare pentru detalii.';
_fc_props.configuration_issues_prompt = 'Următoarele probleme au fost detectate în timpul configurării EDC selectat pentru camionul specificat';
_fc_props.checking_password = 'Se verifică parola';
_fc_props.reset_password_instructions_sent = 'Instrucțiuni pentru a vă reseta parola au fost trimise la adresa de e-mail {0}.';
_fc_props.reset_password_failed = 'Resetarea parolei nu a reușit, încercați din nou.';
_fc_props.forgot_password = 'Aţi uitat parola?';
_fc_props.enter_valid_email = 'Adresă de e-mail nevalidă, introduceți o adresă de e-mail validă și încercați din nou.';
_fc_props.issues_while_updating_edc_record = 'EDC a fost configurat cu succes. Cu toate acestea, există probleme cu înregistrările pentru EDC de pe serverul TrackIt, care pot face ca acesta să nu funcționeze. Vă rugăm să sunați la asistență pentru a corecta informațiile.';
_fc_props.sending = 'Se trimite...';
_fc_props.minimum_ratio = 'Raport minim';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'RPM tambur nu este în interval';
_fc_props.probe_failure = 'Eșecul sondei';
_fc_props.unknown_probe_data_failure = 'Eroare necunoscută a datelor sondei';
_fc_props.no_loaded_status_meaning = 'Stare fără încărcare';
_fc_props.no_depart_status_meaning = 'Fără statut de plecare';
_fc_props.no_arrive_status_meaning = 'Fără stare de sosire';
_fc_props.no_begin_work_status_meaning = 'Stare de lucru fără început';
_fc_props.no_fully_mixed_status_meaning = 'Fără stare complet mixtă';
_fc_props.no_begin_work_or_at_job_status = 'Începeți lucrul sau La job nu există';
_fc_props.driver_didnt_follow_procedure = 'Șoferul nu a urmat procedura (nu a încetinit tamburul)';
_fc_props.probe_malfunction = 'Defecțiune a sondei (acumulare, defecțiune internă etc.)';
_fc_props.no_data_from_probe = 'Fără date de la sondă (pierdere de date)';
_fc_props.missing_probe_calibration = 'Nu există date de calibrare a sondei';
_fc_props.probe_pressure_not_in_range = 'Presiunea sondei nu este în interval';
_fc_props.arrival = 'Sosire';
_fc_props.small_load = 'ÎNCĂRCARE MICĂ';
_fc_props.dry_slump = 'SLUMP USCAT';
_fc_props.probe_remixing_turns = 'Remixare';
_fc_props.turns_needed_to_remix = 'Apă adăugată - Este necesară amestecarea suplimentară';
_fc_props.probe_remixing_finished = 'Remixarea s-a terminat';
_fc_props.probe_battery_low_alert = 'Alertă baterie scăzută a sondei';
_fc_props.probe_battery_low_message = 'Bateria sondei descărcată';
_fc_props.probe_battery_low = 'Bateria sondei descărcată';
_fc_props.alert_when_probe_battery_low = 'Alertă când bateria sondei este descărcată';
_fc_props.probe_sensor_fatal_failure = 'Defecțiune fatală a senzorului';
_fc_props.probe_thermistor_failure = 'Defecțiunea termistorului';
_fc_props.probe_sensor_stuck_in_concrete = 'Senzor blocat în beton';
_fc_props.probe_sensor_over_weight = 'Senzor peste greutate';
_fc_props.probe_batteries_low = 'Baterie descărcată';
_fc_props.no_probe_data = 'Fără date de sondă:';
_fc_props.check_probe = 'Verificați Sonda';
_fc_props.service_probe = 'Sondă de service';
_fc_props.below_x = 'Sub {0}';
_fc_props.above_x = 'Peste {0}';
_fc_props.probe_serial_number_invalid_format = 'Numărul de serie al sondei nu este în format valid, așa cum este afișat în substituent pentru câmp';
_fc_props.talk_dnd_permission_prompt = 'Vorbirea necesită permisiunea NU DERANGERE.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Starea s-a schimbat prea repede';
_fc_props.is_empty = 'Este gol';
_fc_props.slump_increased_plasticizer_question = 'S-a adăugat plastifiant?';
_fc_props.slump_increased_water_added_question = 'Câtă apă necontorizată a fost adăugată?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Monitorizarea sondei și a ciclului de livrare nu sunt acceptate împreună pe același dispozitiv.';
_fc_props.no_data_from_sensor = 'Fără date de la senzor (pierdere de date)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Tip de sistem';
_fc_props.magnet_count = 'Număr de magneti';
_fc_props.sensors_setup = 'Configurarea senzorilor';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM necesită setarea adresei MAC a casetei de I/O, tip tambur, tip DCM și număr de magnet.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Volumul biletului';
_fc_props.drs_current_volume = 'Volumul curent';
_fc_props.drs_current_water_cement_ratio = 'Raportul curent apă/ciment';
_fc_props.drs_target_water_cement_ratio = 'Raportul țintă apă/ciment';
_fc_props.drs_total_revolutions = 'Total';
_fc_props.drs_current_volume_lp_small = 'Actual';
_fc_props.drum_revolutions = 'Revoluții de tobe';
_fc_props.drs_total_revolutions_lp_small = 'Total';
_fc_props.drs_charging_revolutions_lp_small = 'Încărcare';
_fc_props.drs_discharging_revolutions_lp_small = 'Descarcare';
_fc_props.drs_target_wc_lp_small = 'Țintă W/C';
_fc_props.drs_current_wc_lp_small = 'W/C curent';
_fc_props.drs_ticketed_volume_lp_small = 'Tichetat';
_fc_props.android_location_disclosure = 'TrackIt folosește locația fină și grosieră pentru a colecta date de locație în fundal chiar și atunci când nu este utilizat, pentru a activa starea automată și vizibilitatea hărții în timp real pentru expediere.';
_fc_props.android_location_usage = 'Dezvăluirea locației';
_fc_props.end_pour_trigger_threshold = 'Pragul de declanșare a turnării sfârșitului';
_fc_props.drs_wiring_error = 'Eroare de cablare';
_fc_props.drs_wiring_pulse_error = 'Eroare impuls de cablare';
_fc_props.drs_wiring_magnet_error = 'Eroare magnet de cablare';
_fc_props.drs_magnet_missing_error = 'Eroare lipsă magnet';
_fc_props.android_location_disclosure_3p = 'TrackIt colectează date de locație în fundal, pentru a permite auto-starea și vizibilitatea hărții în timp real pentru expediere. Atunci când este oprită de expeditor, locația nu este partajată.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Vă rugăm să selectați fie Full MDM, fie Trackit Remote Support';
_fc_props.move_up = 'Deplasați-vă în sus';
_fc_props.move_down = 'Mutați în jos';
_fc_props.driver_not_found = 'Driverul nu a fost găsit.';
_fc_props.multiple = 'Multiplu';
_fc_props.water_meter_installed = 'Apometru instalat';
_fc_props.delete_waypoint = 'Ștergeți punctul de trecere';
_fc_props.wash_sensor_installed = 'Senzor de spălare instalat';
_fc_props.washing_start_time = 'Ora de începere a spălării';
_fc_props.end_washing = 'Încheiați spălarea';
_fc_props.variable_rpms = 'Var RPM-uri';
_fc_props.no_slump = 'Fără Slump';
_fc_props.end_washing_duration = 'Sfârșiți durata spălării';
_fc_props.privacy_policy = 'Politica de confidențialitate';
_fc_props.privacy_policy_text = 'Am citit și accept';
_fc_props.login_fail_accept_privacy_policy = 'Politica de confidențialitate trebuie să fie acceptată pentru a continua.';
_fc_props.rotation_sensor = 'Senzor de rotație';
_fc_props.pto_sensor = 'Senzor PTO';
_fc_props.pto_sensor_installed = 'Senzor PTO instalat';
_fc_props.polarity_reversed = 'Polaritate inversă';
_fc_props.pto_sensor_type = 'Tip senzor PTO';
_fc_props.sensor_type = 'Tip senzor';
_fc_props.prompt_select_pto_sensor_type = 'Trebuie să selectați un „Tip de senzor PTO” dacă ați selectat un „Senzor PTO” ca tip de senzor principal.';
_fc_props.priming_turns_at_full_load = 'Amorsarea se întoarce la încărcare completă';
_fc_props.priming_turns_at_ten_percent_load = 'Amorsarea se întoarce la sarcină de 10%.';
_fc_props.weight_units = 'Unități de greutate';
_fc_props.settings.send_status = 'Trimiteți starea/locația către Event Bridge';
_fc_props.volume_difference = 'Diferența de volum';
_fc_props.mobile_ticket_iframe_url = 'Adresa URL IFrame a biletului mobil';
_fc_props.mobile_ticket_user = 'Utilizator de bilet mobil';
_fc_props.mobile_ticket_password = 'Parola bilet mobil';
_fc_props.current_truck_status = 'Starea actuală a camionului';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Declanșat de revoluții maxime';
_fc_props.alerts_by_type = 'Alerte după tip';
_fc_props.water_flow_meter = 'Debitmetru de apă';
_fc_props.flow_meter_no_pulses_warning = 'Fără impulsuri - Avertisment';
_fc_props.flow_meter_no_pulses_critical = 'Fără impulsuri - critică';
_fc_props.flow_meter_excessive_pulses = 'Pulsuri excesive';
_fc_props.flow_meter_constantly_flowing = 'Curgând constant';
_fc_props.flow_meter_good = 'Bun';
_fc_props.whats_new = 'Ce mai e nou';
_fc_props.whats_new_widget_text = 'Știați că actualizăm TrackIt cel puțin o dată pe lună? Obțineți cele mai recente actualizări și sfaturi.';
_fc_props.get_the_scoop = 'Ia Scoop-ul';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Analize de afaceri care vă îmbunătățesc profitul, acum incluse în TrackIt';
_fc_props.find_out_more = 'Află mai multe';
_fc_props.daily_breakdown = 'Defalcare zilnică';
_fc_props.question_summary = 'Rezumatul întrebării';
_fc_props.trip_summary = 'Rezumatul călătoriei';
_fc_props.manage_employees = 'Gestionați angajații';
_fc_props.manage_hotspots = 'Gestionați hotspot-urile';
_fc_props.manage_devices = 'Gestionați dispozitivele';
_fc_props.manage_equipment = 'Gestionați echipamentele';
_fc_props.manage_users = 'Gestionare Utilizatori';
_fc_props.manage_statuses = 'Gestionați stările';
_fc_props.manage_alerts = 'Gestionați alertele';
_fc_props.popular_report_links = 'Link-uri de rapoarte populare';
_fc_props.popular_settings_links = 'Link-uri de setări populare';
_fc_props.sidemenu_settings = 'Setări';
_fc_props.loads_per_driver = 'Încărcări per șofer';
_fc_props.please_select_application = 'Vă rugăm să selectați cel puțin o aplicație';
_fc_props.external_app_processing = 'Procesare pe o aplicație externă';
_fc_props.external_app_sent = 'Trimis la aplicația externă';
_fc_props.external_app_received = 'Primit de aplicația externă';
_fc_props.wait_time_at_job = 'Timp de așteptare la job';
_fc_props.wait_time_at_plant = 'Timp de așteptare la fabrică';
_fc_props.loading_time = 'Timp de încărcare';
_fc_props.unloading_time = 'Timp de descărcare';
_fc_props.pre_post_trip = 'Pre/După călătorie';
_fc_props.update_thresholds = 'Actualizați praguri';
_fc_props.green = 'Verde';
_fc_props.yellow = 'Galben';
_fc_props.red = 'roșu';
_fc_props.between = 'Între';
_fc_props.num_of_loads = '# de încărcări';
_fc_props.num_of_drivers = '# de șoferi';
_fc_props.status_meaning_not_setup = 'Sensul de stare nu este configurat';
_fc_props.or_less = 'Sau mai putin';
_fc_props.or_greater = 'Sau Mai Mare';
_fc_props.probe_self_diagnostic_state = 'Starea de autodiagnosticare a sondei';
_fc_props.update_current_truck_status_settings = 'Actualizați setările curente pentru starea camionului';
_fc_props.default_behavior = 'Implicit (Top 10 stări)';
_fc_props.combine_similar_statuses = 'Combinați aceleași stări de la toate tipurile (bucle)';
_fc_props.use_other_category = 'Utilizați categoria „Altele”.';
_fc_props.selected_statuses = 'Stări selectate';
_fc_props.ideas = 'Idei';
_fc_props.air_content = 'Conținut aerian';
_fc_props.air_measured_time = 'Timp măsurat în aer';
_fc_props.ten_max = '10 Max';
_fc_props.loads_per_plant = 'Încărcături pe plantă';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Impuls debitmetru nevalid pe volum.';
_fc_props.coming_soon_title = 'Mai multe widget-uri în curând';
_fc_props.submit_your_ideas = 'Trimiteți-vă ideile pe portalul nostru de idei folosind becul din meniul de navigare din stânga.';
_fc_props.selected_plants = 'Plante alese';
_fc_props.update_loads_per_plant_settings = 'Actualizați setările de încărcări per plantă';
_fc_props.default_top_10_plants = 'Implicit (Top 10 plante)';
_fc_props.phone_number_will_be_uploaded = 'ID-ul dispozitivului va fi încărcat în scopuri de înregistrare.';
_fc_props.include_deleted = 'Includeți șterse';
_fc_props.primary_status_type = 'Tip de stare primară';
_fc_props.additional_status_trigger = 'Declanșatoare de stare suplimentare';
_fc_props.update_tickets_destination = 'Actualizați destinația pentru toate biletele asociate';
_fc_props.settings.send_destination_changes_to_connex = 'Trimiteți modificările destinației către Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Biletul este activ atunci când este primit de TrackIt (intotdeauna „intotdeauna”)';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Activați automat următorul bilet la finalizarea precedentă a biletului';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Ar trebui să activeze automat următorul bilet la finalizarea biletului (implicit „adevărat”)';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Stare la starea inactiv atunci când nu există niciun bilet activ';
_fc_props.break_down = 'Pană';
_fc_props.idle = 'Inactiv';
_fc_props.pto_work_maximum_speed_limit = 'Limita maximă de viteză de lucru';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Asociați schimbarea statutului care completează un bilet cu acel bilet';
_fc_props.approaching = 'Apropiindu-se';
_fc_props.performing_task = 'Efectuarea sarcinii';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Sigur doriți să ștergeți Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Actualizați biletul {0}. Destinația schimbată.';
_fc_props.trackit_return_destination_changed_to_0 = 'Actualizați biletul {0}. Returul s-a schimbat.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Adăugați Geogate';
_fc_props.driver_enabled = 'Driver activat';
_fc_props.dispatcher_enabled = 'Dispecerul este activat';
_fc_props.runsheet_number = 'Numărul foii de execuție';
_fc_props.manual_load = 'Încărcare manuală';
_fc_props.edit_geogate = 'Editați Geogate';
_fc_props.handling = 'Manipulare';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Panoul Geogate';
_fc_props.vehicle_is_not_logged_in = 'Mesajul nu a fost trimis (vehiculul nu este autentificat)';
_fc_props.air = 'Aer';
_fc_props.create_a_geogate = 'Creați o Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Faceți clic pe hartă pentru a seta punctele geogate.';
_fc_props.clock_out = 'Ceas Out';
_fc_props.handling_operation = 'Operație de manipulare';
_fc_props.view_geogates = 'Vedeți Geogates';
_fc_props.select_a_geogate = 'Vă rugăm să selectați o Geogate';
_fc_props.failed_to_find_geogate = 'Geogate nu a fost găsit';
_fc_props.adjust_geogate = 'Ajustați Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Data expirării Ora Implicit Zile suplimentare';
_fc_props.menu_settings_title_retention = 'Păstrarea datelor';
_fc_props.category = 'Categorie';
_fc_props.telemetry = 'Telemetrie';
_fc_props.ticket_data = 'Datele biletelor';
_fc_props._30_days = '30 de zile';
_fc_props._6_months = '6 luni';
_fc_props._1_year = '1 an';
_fc_props._3_years = '3 ani';
_fc_props.new_group = 'Grup nou';
_fc_props.selected_vehicles = 'Vehicule selectate';
_fc_props.group_name_is_required_field = 'Numele grupului este câmp obligatoriu';
_fc_props.at_least_vehicle_should_be_selected = 'Trebuie selectat cel puțin 1 vehicul';
_fc_props.groups = 'Grupuri';
_fc_props.trucks = 'Camioane';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate a fost eliminat. Vă rugăm să reîmprospătați pagina.';
_fc_props.air_timestamp = 'Timpul aerului';
_fc_props.fail = 'FAIL';
_fc_props.stuck = 'ÎNCHIS';
_fc_props.dirty = 'MURDAR';
_fc_props.seasonal = 'Sezonier';
_fc_props.category_details = 'Detalii de categorie';
_fc_props.alert_events = 'Evenimente de alertă';
_fc_props.driver_performance_infractions = 'Infracțiuni de performanță a șoferului';
_fc_props.telemetry_details = 'Date despre locație, Hotspot-uri, Ora în Hotspot-uri, Ora în regiuni, Date DCM, Date Probe, Date Motor';
_fc_props.ticket_data_details = 'Datele biletelor';
_fc_props.general_details = 'Modificări de stare, Fișe de pontaj, Programe';
_fc_props.wash = 'Spalare';
_fc_props.filter_options = 'Opțiuni de filtrare';
_fc_props.home_plants = 'Plante de acasă';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO necesită setarea tipului de senzor PTO.';
_fc_props.invalid_io_box_mac_address = 'Adresă Mac a casetei I/O nevalidă';
_fc_props.access_denied = 'Acces interzis';
_fc_props.wash_events = 'Evenimente de spălare';
_fc_props.min_speed = 'Viteza minima';
_fc_props.temperature_change = 'Schimbarea temperaturii';
_fc_props.degrees_cap = 'Grade';
_fc_props.washout_tracking = 'Urmărirea spălării';
_fc_props.approaching_wash = 'Se apropie de Wash';
_fc_props.arrival_wash = 'Sosire Spălare';
_fc_props.performing_wash = 'Efectuarea Spălării';
_fc_props.wash_complete = 'Spălare completă';
_fc_props.must_be_positive_or_zero = 'Trebuie să fie pozitiv sau zero!';
_fc_props.min_speed_greater_than_max = 'Viteza minimă trebuie să fie mai mică decât Viteza maximă!';
_fc_props.clock_in = 'Ceas intrat';
_fc_props.mix_water_at_x_to_y_for_t = 'Amestecați apa la {0} până la {1} rpm pentru {2} rotații.';
_fc_props.discharge_water_for_x_revolutions = 'Deversați apă pentru {0} rotații.';
_fc_props.eod_drum_wash_complete = 'Spălarea tamburului completă! <br> Opriți tamburul cu sonda în partea de sus.';
_fc_props.washing_complete = 'Spălare ghidată completă';
_fc_props.eod_washout_no_data_available = 'Datele senzorului nu sunt disponibile, vă rugăm să finalizați procesul de spălare.';
_fc_props.eod_washout_no_data_available_title = 'Spălare ghidată indisponibilă';
_fc_props.menu_reports_title_ticket_time_summary = 'Rezumatul timpului biletului';
_fc_props.menu_reports_description_ticket_time_summary = 'Vizualizați informații despre ora biletului pentru o comandă sau vehicul';
_fc_props.menu_reports_title_summary = 'rezumat';
_fc_props.menu_reports_title_ticket_summary = 'Rezumatul biletului';
_fc_props.menu_reports_description_summary1 = 'Vedeți un rezumat al timpului în hotspot-uri';
_fc_props.menu_dispatch_title_main = 'Principal';
_fc_props.menu_dispatch_title_login_registry = 'Registrul de autentificare';
_fc_props.menu_reports_title_main = 'Principal';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Sigur doriți să ștergeți acest tip de date de export?';
_fc_props.dvir_status_paper_logs = 'Bușteni de hârtie';
_fc_props.menu_reports_title_billing_report = 'Raport de facturare';
_fc_props.menu_reports_description_billing_report = 'Raport de facturare';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Stare la starea biletului la activare lucru';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Stare la starea biletului la activare lucru (implicit „False”)';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Lire sterline';
_fc_props.units_weight_kilogram_label = 'Kilograme';
_fc_props.settings_weight = 'Greutate';
_fc_props.units_pounds = 'Lire sterline';
_fc_props.units_kilograms = 'Kilograme';
_fc_props.geo_gate_edit_modal_description = 'Descriere';
_fc_props.geo_gate_edit_modal_start_point = 'Punctul de inceput';
_fc_props.geo_gate_edit_modal_end_point = 'Punctul final';
_fc_props.geo_gate_edit_modal_vehicle_types = 'tip de echipament';
_fc_props.geo_gate_edit_modal_heading_south = 'Sud la Nord';
_fc_props.geo_gate_edit_modal_heading_north = 'De la nord la sud';
_fc_props.geo_gate_edit_modal_driver_message = 'Mesaj șoferului';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Mesaj de expediere';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Data expirării Ora';
_fc_props.geo_gate_edit_modal_permanent = 'Permanent';
_fc_props.geo_gate_edit_modal_critical = 'Critic';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Eroare la salvarea Geogate: câmpul de descriere trebuie completat.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Eroare la salvarea Geogate: câmpul Punct de pornire trebuie completat.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Eroare la salvarea câmpului Geogate: Punct de pornire conține un format de date nevalid.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Eroare la salvarea câmpului Geogate: Punct final conține un format de date nevalid.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Eroare la salvarea Geogate: câmpul Data expirării Ora trebuie completat.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Eroare la salvarea câmpului Geogate: Data expirării Ora conține un format de date nevalid.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Eroare la salvarea Geogate: de la nord la sud - câmpul Mesaj de expediere trebuie completat';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Eroare la salvarea Geogate: nu au fost detectate modificări';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Eroare la salvarea Geogate: câmpul Data Expiration Time conține data și ora expirate';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Eroare la salvarea Geogate: câmpul de descriere trebuie să conțină numai litere și cifre';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Eroare la salvarea Geogate: câmpul Tip echipament trebuie completat';
_fc_props.geo_gate_table_message_label_north_to_south = 'De la nord la sud';
_fc_props.geo_gate_table_message_label_south_to_north = 'Sud la Nord';
_fc_props.geo_gate_table_message_label_both = 'Ambii';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Latitudine longitudine';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Bifarea acestui lucru va anula configurarea pentru restricția mesajelor de mișcare a dispozitivului';
_fc_props.geo_gate_edit_modal_seasonal = 'Sezonier';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Eroare la salvarea Geogate: Datele sezoniere trebuie completate';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Eroare la salvarea Geogate: câmpurile Data sezonieră conțin format de date nevalid';
_fc_props.test_js_test_javascript = 'Testați Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometri';
_fc_props.units_miles = 'Miles';
_fc_props.units_gallons_abbr = 'FATĂ';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Eroare la salvarea Geogate: de la sud la nord - câmpul Mesaj șofer trebuie completat';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Eroare la salvarea Geogate: South to North - Câmpul Mesaj de expediere trebuie completat';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Eroare la salvarea Geogate: de la nord la sud - câmpul Mesaj șofer trebuie completat';
_fc_props.geo_gate_heading = 'Titlu';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Eroare la salvarea Geogate: câmpul Punct final trebuie completat';
_fc_props.units_mile = 'Mile';
_fc_props.units_kilometer = 'Kilometru';
_fc_props.cloud_dvir__exit = 'Ieșire';
_fc_props.cloud_dvir__signature = 'Semnătură';
_fc_props.cloud_dvir__inspection = 'Inspecţie';
_fc_props.cloud_dvir__add_photos_notes = 'Adăugați fotografii/note';
_fc_props.cloud_dvir__odometer_reading = 'Citirea contorului de parcurs';
_fc_props.cloud_dvir__engine_hours = 'Ore motor';
_fc_props.cloud_dvir__truck_details = 'Detalii camion';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Determinați starea generală a vehiculului';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Numele complet și semnătura șoferului';
_fc_props.cloud_dvir__sign = 'Semn';
_fc_props.cloud_dvir__driver_note_from = 'Notă șofer de la';
_fc_props.cloud_dvir__add_notes = 'Adăugați note';
_fc_props.cloud_dvir__driver_notes = 'Note pentru șofer';
_fc_props.cloud_dvir__done = 'Terminat';
_fc_props.cloud_dvir__needs_repair = 'Necesita Reparatie';
_fc_props.cloud_dvir__not_applicable = 'Nu se aplică';
_fc_props.cloud_dvir__review_and_sign = 'Examinați și semnați';
_fc_props.cloud_dvir__add_photos = 'Adauga poza';
_fc_props.cloud_dvir__upload_photo_limit = 'Puteți încărca până la 3 imagini';
_fc_props.cloud_dvir__mismatch_title = 'Alertă de nepotrivire';
_fc_props.cloud_dvir__ok = 'Bine';
_fc_props.cloud_dvir_template = 'Șablon DVIR în cloud';
_fc_props.cloud_dvir__needs_review = 'DVIR are nevoie de revizuire';
_fc_props.cloud_dvir__submitted = 'Trimis';
_fc_props.cloud_dvir__completed_by = 'Completat de';
_fc_props.cloud_dvir__review_btn = 'Revizuire';
_fc_props.cloud_dvir__truck_number = 'Camion {0}';
_fc_props.cloud_dvir__pending_review = 'Revizuire în așteptare';
_fc_props.cloud_dvir__start_dvir = 'Porniți DVIR';
_fc_props.cloud_dvir__dvir_history = 'Istoricul DVIR';
_fc_props.cloud_dvir__view_all = 'A vedea tot';
_fc_props.load_zone = 'Zona de încărcare';
_fc_props.view_load_zones = 'Vedeți zonele de încărcare';
_fc_props.edit_load_zone = 'Editați zona de încărcare';
_fc_props.create_a_load_zone = 'Creați o zonă de încărcare';
_fc_props.load_zone_panel = 'Panoul zonei de încărcare';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Faceți clic pe hartă pentru a seta centrul zonei de încărcare.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Această inspecție are defecte indicate și ați ales că acest vehicul este sigur de operat. Vă rugăm să validați înainte de a continua';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Această inspecție nu are defecte indicate și ați ales că acest vehicul nu este sigur de exploatat. Vă rugăm să validați înainte de a continua.';
_fc_props._90_days = '90 de zile';
_fc_props.cloud_dvir = 'Cloud DVIR';
_fc_props.cloud_dvir_details = 'Trimiteri DVIR în cloud';
_fc_props.show_load_zones = 'arată zonele de încărcare';
_fc_props.hide_load_zones = 'ascunde zonele de încărcare';
_fc_props.no = 'Nu';
_fc_props.cloud_dvir__reset = 'Resetați';
_fc_props.cloud_dvir__clear = 'clar';
_fc_props.mark_read = 'Mark Citit';
_fc_props.mark_unread = 'Marcați ca necitit';
_fc_props.read = 'Citit';
_fc_props.unread = 'Necitit';
_fc_props.air_sensor_serial_number = 'Număr de serie al senzorului de aer';
_fc_props.air_sensor_mac_address = 'Adresa MAC a senzorului de aer';
_fc_props.air_sensor_serial_number_invalid_format = 'Numărul de serie al senzorului de aer nu este în format valid: (alfanumeric și cel mult 32 de caractere cu liniuțe)';
_fc_props.air_sensor_mac_address_invalid_format = 'Adresa MAC a senzorului de aer nu este în format hex valid, așa cum este afișat în substituent pentru câmp';
_fc_props.air_sensor_license = 'Senzor de aer';
_fc_props.adjust_route_point = 'Ajustați punctul de rută';
_fc_props.route_point_panel = 'Panoul de punct de traseu';
_fc_props.route_point = 'Punct de traseu';
_fc_props.cloud_dvir__pre_trip = 'Înainte de călătorie';
_fc_props.cloud_dvir__post_trip = 'Post călătoria';
_fc_props.cloud_dvir__other_insction_type = 'Alte';
_fc_props.cloud_dvir__dvir_completed = 'Efectuat';
_fc_props.cloud_dvir__awaiting_mechanic = 'În așteptarea mecanicului';
_fc_props.cloud_dvir__awaiting_driver = 'Se așteaptă semnarea șoferului';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Nu se poate verifica numărul de serie al senzorului de aer, contactați asistența pentru asistență suplimentară.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Acest număr de serie al senzorului de aer există deja, vă rugăm să utilizați un alt număr de serie';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Nu se poate verifica adresa MAC a senzorului de aer, vă rugăm să contactați asistența pentru asistență';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Eroare la trimiterea DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Vă rugăm să încercați din nou';
_fc_props.ticket_integration_descr = 'Integrarea biletelor permite setarea setărilor de integrare a biletelor, precum și Ecranul de pornire / Ecranul de încărcare / Afișajul montat dur';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR trimis cu succes';
_fc_props.cloud_dvir__inspection_trip_type = 'Tip de călătorie de inspecție';
_fc_props.menu_dvir_title_mechanic = 'Mecanic';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Firmware pentru senzorul de aer';
_fc_props.when_no_active_ticket = 'Când nu există bilet activ';
_fc_props.used_by = 'Folosit de';
_fc_props.fields = 'Câmpuri';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Sigur doriți să ștergeți aspectul biletului „{0}”?';
_fc_props.menu_settings_title_ticket_layout = 'Aspectul biletului';
_fc_props.add_ticket_layout = 'Adăugați aspectul biletului';
_fc_props.edit_ticket_layout = 'Editați aspectul biletului';
_fc_props.ticket_layout_edit_modal_name = 'Nume';
_fc_props.ticket_layout_edit_modal_default = 'Mod implicit';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Eroare la salvarea aspectului biletului: câmpul Nume trebuie completat.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Eroare la salvarea aspectului biletului: câmpul Nume trebuie să conțină numai litere și cifre';
_fc_props.ticket_layout_edit_modal_fields = 'Câmpuri';
_fc_props.ticket_layout_edit_modal_usage = 'Utilizare';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Aspectul biletului a fost eliminat. Vă rugăm să reîmprospătați pagina.';
_fc_props.more_lower_case = 'Mai mult';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Eroare la salvarea aspectului biletului: trebuie selectate unul sau mai multe câmpuri.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Eroare la salvarea aspectului biletului: trebuie selectate una sau mai multe stări.';
_fc_props.cloud_dvir__ad_hoc = 'Ad-hoc';
_fc_props.radius_multiplier_should_be = 'Multiplicatorul de rază ar trebui să fie un număr flotant';
_fc_props.ticket_max_activate_age_err = 'Minutele de vârstă de activare maximă a biletului ar trebui să fie un număr întreg.';
_fc_props.max_hotspot_unused_days_errs = 'Numărul maxim de zile neutilizate ar trebui să fie un număr întreg.';
_fc_props.begin_pour_air_content = 'Începeți să turnați conținutul de aer';
_fc_props.arrival_air_content = 'Conținut aerian de sosire';
_fc_props.fully_mixed_air_content = 'Conținut de aer complet amestecat';
_fc_props.departure_air_content = 'Conținutul aerului de plecare';
_fc_props.popup_enabled = 'Popup activat';
_fc_props.popup_disabled = 'Popup dezactivat';
_fc_props.cloud_dvir__leave_confirmation_title = 'Avertizare';
_fc_props.cloud_dvir__leave_confirmation_description = 'Ieșirea acum din pagină va duce la pierderea tuturor modificărilor.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'A confirma';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Anulare';
_fc_props.air_bt_ratio = 'Raport aer BT';
_fc_props.cloud_dvir__view_photos_notes = 'Vizualizați fotografii/note';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Adăugarea stării va crea o utilizare goală într-un alt aspect al biletului. Doriți să continuați cu adăugarea?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Vehiculul nu are permis pentru senzori de aer.';
_fc_props.air_sensor_installed = 'Senzor de aer instalat';
_fc_props.load_card = 'Card de încărcare';
_fc_props.cloud_dvir__inspection_profile = 'Profil de inspecție';
_fc_props.cloud_dvir__view_details = 'Vezi detalii';
_fc_props.cloud_dvir__certify_repairs = 'Reparații certificate';
_fc_props.cloud_dvir__repairs_made = 'Reparatii facute';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparații nu sunt necesare';
_fc_props.cloud_dvir__mechanic_note_from = 'Notă mecanic de la';
_fc_props.cloud_dvir__mechanic_assignment = 'Atribuire mecanic';
_fc_props.cloud_dvir__mechanic_signature_description = 'Marcați mai jos toate elementele aplicabile și semnați indicând că vehiculul a fost inspectat corespunzător.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Starea acestui vehicul este satisfăcătoare';
_fc_props.cloud_dvir__defects_corrected_label = 'Defectele au fost corectate';
_fc_props.cloud_dvir__repair_not_required_label = 'Reparația nu este necesară pentru funcționarea în siguranță a vehiculului';
_fc_props.safety = 'Siguranță';
_fc_props.cloud_dvir__awaiting_sign = 'Semn de așteptare';
_fc_props.cloud_dvir__dvir_review = 'Revizuire DVIR';
_fc_props.settings.mobile_auto_start_navigation = 'Porniți automat navigarea pe mobil';
_fc_props.cloud_dvir__edit = 'Editați | ×';
_fc_props.delete_custom_label = 'Ștergeți eticheta personalizată';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Versiunea dvs. de TrackIt este învechită și este posibil ca unele funcții DVIR să nu funcționeze corect. Actualizați TrackIt înainte de a continua.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'A apărut o eroare în timpul încărcării semnăturii. Vă rugăm să încercați din nou';
_fc_props.max_hotspot_unused_days_error = 'Max Hotspot Unused Days are maximum 1000 de zile.';
_fc_props.menu_dvir_title_admin_templates = 'Șabloane';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Admin';
_fc_props.cloud_dvir__admin_templates = 'Șabloane';
_fc_props.form_name = 'Nume formular';
_fc_props.form_status = 'Starea formularului';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Descarca';
_fc_props.cloud_dvir__option_view = 'Vedere';
_fc_props.cloud_dvir__inspectortype_driver = 'Conducător auto';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Vizualizați/Editați/Creați formulare și trimiteri DVIR personalizate';
_fc_props.menu.dvir.description.mechanic = 'Vizualizați/editați trimiterile DVIR care pot fi acționate';
_fc_props.not_in_use = 'Nu este în uz';
_fc_props.logged_in = 'conectat';
_fc_props.reviewing_driver = 'Revizuirea driverului';
_fc_props.cloud_dvir__report_title = 'Raportul de inspecție al șoferului';
_fc_props.cloud_dvir__report_signed = 'semnat';
_fc_props.cloud_dvir__report_page_counter = 'Pagina {0} din {1}';
_fc_props.cloud_dvir__report_defects = 'Defecte';
_fc_props.cloud_dvir__report_comments = 'Comentarii';
_fc_props.cloud_dvir__report_inspection_table_title = 'Inspecția vehiculului';
_fc_props.cloud_dvir__report_driver_table_title = 'Informații despre șofer';
_fc_props.cloud_dvir__report_carrier = 'Purtător';
_fc_props.cloud_dvir__report_truck = 'Camion';
_fc_props.cloud_dvir__report_location = 'Locație';
_fc_props.cloud_dvir__report_odometer = 'Odometru';
_fc_props.cloud_dvir__report_hours = 'Ore';
_fc_props.cloud_dvir__report_no_comments = 'Fara comentarii';
_fc_props.include_unlicensed_vehicles = 'Includeți vehicule fără licență';
_fc_props.cloud_dvir__search_by_form_name = 'Căutați după numele formularului';
_fc_props.cloud_dvir__create_dvir = 'Creați un DVIR';
_fc_props.cloud_dvir__update_dvir = 'Actualizați DVIR';
_fc_props.cloud_dvir__form_name = 'Nume formular';
_fc_props.cloud_dvir__form_description = 'Descrierea formularului';
_fc_props.cloud_dvir__form_status = 'Starea formularului';
_fc_props.information = 'informație';
_fc_props.cloud_dvir__search_categories = 'Caută categorii';
_fc_props.cloud_dvir__create_new_category = 'Creați o nouă categorie';
_fc_props.category_name = 'Numele categoriei';
_fc_props.sub_category = 'Subcategorie';
_fc_props.sub_category_name = 'Nume subcategorie';
_fc_props.delete_category = 'Ștergeți categoria';
_fc_props.cloud_dvir__dvir_information = 'Informații DVIR';
_fc_props.cloud_dvir__dvir_choose_categories = 'Alege Categorii';
_fc_props.cloud_dvir__create_category = 'Creați o categorie';
_fc_props.cloud_dvir__update_category = 'Actualizare categorie';
_fc_props.delete_route_point = 'Ștergeți punctul de rută';
_fc_props.view_route_points = 'Vedeți punctele de traseu';
_fc_props.ignore_cancel_preload = 'Ignorați anularea, sunteți preîncărcat. Home run-ul tău va fi afișat în curând';
_fc_props.cloud_dvir__assigned_mechanic = 'Mecanic desemnat';
_fc_props.cloud_dvir__add_mechanic = 'Adăugați un mecanic';
_fc_props.contact_type = 'Tip de contact';
_fc_props.cloud_dvir__send_email_notifications = 'Trimite notificări prin e-mail';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Eroare la preluarea înregistrărilor din Istoricul DVIR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Eroare la preluarea înregistrării DVIR.';
_fc_props.cloud_dvir__error_saving_submission = 'Eroare la salvarea trimiterii. Încearcă din nou.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Eroare la preluarea listei de mecanici.';
_fc_props.cloud_dvir__template_saved = 'Șablonul a fost salvat';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Eroare la preluarea listei de șabloane.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Eroare la salvarea șablonului. Încearcă din nou.';
_fc_props.dispatch_group = 'Grupul de expediere';
_fc_props.save_adjust = 'Salvați și ajustați punctul de rută';
_fc_props.reset_route_point = 'Resetați punctul de rută';
_fc_props.no_records_stub_adjust_filters = 'Încercați să ajustați setările de căutare sau de filtrare pentru a crește numărul de rezultate.';
_fc_props.no_dvir_records = 'Toți prinși de DVIR-uri!';
_fc_props.no_dvir_records_sub_title = 'Ai realizat multe';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'Nu s-a obținut nicio lectură slabă pentru: {0}';
_fc_props.please_select_a_sensor_status_type = 'Vă rugăm să selectați Tipurile de stare a senzorilor pentru a declanșa această alertă';
_fc_props.settings.master_data_connex_token = 'Jetonul Master Data Connex';
_fc_props.error_validating_master_data_connex_credentials = 'Eroare la validarea acreditărilor Master Data Connex';
_fc_props.settings.master_data_connex_base_url = 'Adresa URL a bazei de date master Connex';
_fc_props.settings.master_data_connex_exp_time = 'Timpul de expirare a Conexiunii Master Data';
_fc_props.menu_settings_title_dispatch_group = 'Grupuri de expediere';
_fc_props.settings.dispatch_groups = 'Grupuri de expediere';
_fc_props.dispatch_group_name = 'Numele grupului de expediere';
_fc_props.dispatch_group_plants = 'Instalații de grup de expediere';
_fc_props.dispatch_group_plants_mapping_message = 'Indicați care fabrici vor face parte din acest grup de expediere.';
_fc_props.dispatch_group_users_mapping_message = 'Indicați ce utilizatori vor face parte din acest grup de expediere.';
_fc_props.dispatch_group_plant_disassociation_message = 'Planta ar trebui să fie sub un grup de expediere la un moment dat';
_fc_props.dispatch_group_search_by_plant = 'Căutați după numele plantei';
_fc_props.dispatch_group_search_by_username = 'Căutați după nume de utilizator';
_fc_props.search_by_dispatch_group_name = 'Căutați după numele grupului de expediere';
_fc_props.create_dispatch_group = 'Creați un grup de expediere';
_fc_props.update_dispatch_group = 'Actualizați grupul de expediere';
_fc_props.dispatch_group_saved = 'Grupul de expediere a fost salvat';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Ștergerea acestui grup de expediere va crea grupuri de expediere implicite pentru instalațiile asociate.';
_fc_props.error_saving_dispatch_group = 'Eroare la salvarea grupului de expediere. Încearcă din nou.';
_fc_props.please_enter_a_valid_plant_num = 'Vă rugăm să introduceți un număr de fabrică valid.';
_fc_props.cloud_dvir__report_no_defects = 'Fara defecte';
_fc_props.shoud_select_dispatch_group = 'Trebuie să selectați cel puțin un grup de expediere';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Nu se poate șterge deoarece acest grup de expediere conține utilizatori care sunt asociați numai cu acest grup de expediere.';
_fc_props.cloud_dvir__username = 'Nume de utilizator';
_fc_props.cloud_dvir__password = 'Parola';
_fc_props.cloud_dvir__login = 'Log in';
_fc_props.cloud_dvir__mechanic_sign_in = 'Conectare mecanic';
_fc_props.delete_dispatch_group = 'Ștergeți grupul de expediere';
_fc_props.cloud_dvir__driver = 'Conducător auto';
_fc_props.cloud_dvir__equipment_pound = 'echipament #';
_fc_props.cloud_dvir__mechanic = 'Mecanic';
_fc_props.dispatch_group_user_only_unassociate_message = 'Nu se poate șterge utilizatorul deoarece utilizatorii trebuie să fie asociați cu cel puțin un grup de expediere.';
_fc_props.cloud_dvir__time_period_filter_today = 'Astăzi';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Ieri';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Ultimele 7 zile';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Ultimele 30 de zile';
_fc_props.cloud_dvir__date_range_filter = 'Filtrați după dată';
_fc_props.cloud_dvir__download = 'Descarca';
_fc_props.dispatch_group_is_empty = 'Grupul de expediere nu poate fi gol.';
_fc_props.dispatch_group_with_empty_plants = 'Cel puțin o fabrică trebuie să fie asociată cu grupul de expediere.';
_fc_props.dispatch_group_with_empty_users = 'Cel puțin un utilizator trebuie să fie asociat cu grupul de expediere.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Nu se poate șterge grupul de expediere, deoarece conține utilizatori care sunt asociați numai cu acest grup de expediere.';
_fc_props.dispatch_group_not_exists = 'Grupul de expediere nu există.';
_fc_props.dispatch_group_contains_one_plant = 'Nu se poate șterge deoarece acest grup de expediere conține doar o singură fabrică.';
_fc_props.dispatch_group_users = 'Utilizatori grup de expediere';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Eroare la preluarea înregistrării DVIR';
_fc_props.cloud_dvir__assign_mechanic = 'Atribuiți mecanic';
_fc_props.cloud_dvir__submit_review = 'Trimite recenzie';
_fc_props.error_deleting_dispatch_group = 'Eroare la ștergerea grupului de expediere.';
_fc_props.error_retrieving_dispatch_groups = 'Eroare la preluarea listei de grupuri de expediere.';
_fc_props.cloud_dvir__undo_selection = 'Anulați selecția';
_fc_props.enable_master_data = 'Activați datele de bază';
_fc_props.manually_sync = 'Sincronizare manuală';
_fc_props.master_data_connex_token_expiration = 'Datele principale Connex Token Expirare';
_fc_props.cloud_dvir__time_period_filter_custom = 'Personalizat';
_fc_props.settings.zello_session_timeout_minutes = 'Timp de expirare a sesiunii Zello (minute)';
_fc_props.menu_settings_title_master_data = 'Date de bază';
_fc_props.settings.master_data = 'Date de bază';
_fc_props.error_retrieving_master_data = 'Eroare la preluarea datelor de bază';
_fc_props.master_data_sync_completed = 'Sincronizarea datelor principale a fost finalizată';
_fc_props.master_data_successfully_updated = 'Datele au fost actualizate cu succes';
_fc_props.master_data_failed_to_update = 'Salvare eșuată.';
_fc_props.master_data_sync_initiated = 'Sincronizarea datelor principale a fost inițiată';
_fc_props.master_data_sync_failed = 'Sincronizarea datelor de bază a eșuat';
_fc_props.enabled_excluding_origin_on_deliveries = 'Adevărat (excluzând originea pe biletele de livrare)';
_fc_props.enabled_including_origin_on_deliveries = 'Adevărat (inclusiv originea pe biletele de livrare)';
_fc_props.cloud_dvir__i_will_decide_later = 'Voi decide mai târziu';
_fc_props.returning = 'Întorcându-se';
_fc_props.leftover_concrete = 'Restul de Beton';
_fc_props.leftover_concrete_on_vehicle_x = 'Resturi de beton pe vehicul {0}';
_fc_props.leftover_concrete_alert = 'Alertă de resturi de beton';
_fc_props.wc_ratio_exceeded = 'Raport W/C depășit';
_fc_props.search_by_site_name = 'Căutați după numele site-ului';
_fc_props.sites = 'Site-uri';
_fc_props.token_expiration = 'Expirarea simbolului';
_fc_props.menu_settings_title_connex_api_key_management = 'Gestionarea cheilor API Connex';
_fc_props.settings.connex_api_key_management = 'Gestionarea cheilor API Connex';
_fc_props.error_retrieving_connex_api_key_data = 'Eroare la preluarea datelor cheie API connex';
_fc_props.qr_code = 'Cod QR';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informarea șoferului și a interfeței atunci când intri în fabrică greșită';
_fc_props.enable_driver_message = 'Activați mesajul șoferului';
_fc_props.enable_dispatch_message = 'Activați mesajul de expediere';
_fc_props.enable_sending_message_to_interface = 'Activați trimiterea mesajului către Interfață';
_fc_props.ticket_qr_code = 'Cod QR pentru bilet';
_fc_props.leftover_concrete_alert_description = 'Alertă când s-au găsit resturi de beton după turnare.';
_fc_props.minimum_leftover_concrete_amount = 'Cantitatea minimă de beton rămasă';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Eroare la validarea acreditărilor Master Data Connex neautorizate';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Eroare la validarea permisiunilor de acreditări Master Data Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Eroare la validarea Master Data Connex URL Not Found';
_fc_props.error_validating_master_data_connex_unknown_error = 'Eroare la validarea Master Data Connex Eroare necunoscută';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Sincronizarea datelor de bază a eșuat Sincronizarea tipului de vehicul.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Sincronizarea datelor principale a eșuat Sincronizarea vehiculului.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Sincronizarea datelor principale a eșuat Sincronizarea instalației.';
_fc_props.master_data_sync_failed_data_base_connection = 'Sincronizarea datelor de bază a eșuat conexiunea la baza de date.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Eroare la validarea Master Data Connex Credentials Bearer Token Permisiunea este insuficientă';
_fc_props.settings.back_date_arrive_job = 'Loc de sosire cu data din spate';
_fc_props.device_notifications = 'Notificări';
_fc_props.loading_number = 'Numărul de încărcare';
_fc_props.leftover_concrete_volume_temp_age = 'Volum rămas={0}, Temp={1}, Vârstă={2}';
_fc_props.total_ordered = 'Cantitatea totală comandată';
_fc_props.total_ticketed = 'Livrat Comandat';
_fc_props.load_number = 'Număr de încărcare';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Betonul rămas minim trebuie să fie completat și mai mare de {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Eroare la validarea datelor de bază. Acreditările nu pot fi aceleași.';
_fc_props.use_aws_for_talk = 'Utilizați AWS for Talk';
_fc_props.air_readings_not_supported = 'Citirile aerului nu sunt acceptate';
_fc_props.air_not_supported = 'Aerul nu este acceptat';
_fc_props.mix_not_supported = 'Mix nu este acceptat';
_fc_props.air_default_and_none_not_supported = 'Nu se poate selecta implicit și nu este acceptat în același timp!';
_fc_props.notifications_enabled = 'Notificări activate';
_fc_props.prevent_status_change = 'Preveniți schimbarea stării';
_fc_props.message_to_interface = 'Mesaj către Interfață';
_fc_props.prevent_wrong_plant = 'Preveniți planta greșită';
_fc_props.previous_tickets = 'Bilete anterioare';
_fc_props.audit_ticket_number = 'Număr de bilet';
_fc_props.audit_date_created = 'Data creării';
_fc_props.audit_vehicle_number = 'Numărul vehiculului';
_fc_props.cloud_dvir__table_ok = 'Sigur de operat';
_fc_props.cloud_dvir__table_needs_repair = 'Operat nesigur';
_fc_props.missing_air_calibration = 'Nu există date de calibrare a aerului';
_fc_props.reported_defects = 'Defecte raportate';
_fc_props.address_latitude = 'Adresă Latitude';
_fc_props.address_longitude = 'Longitudine adresă';
_fc_props.routing_latitude = 'Rutarea Latitude';
_fc_props.routing_longitude = 'Longitudine de rutare';
_fc_props.category_name_must_be_unique = 'Numele categoriei trebuie să fie unic';
_fc_props.subcategory_name_must_be_unique = 'Numele subcategoriei trebuie să fie unic';
_fc_props.menu_settings_tracking_device = 'Dispozitiv de urmărire';
_fc_props.menu_settings_title_tracking_device = 'Dispozitiv de urmărire';
_fc_props.menu_settings_title_personal_device_registration = 'Înregistrarea dispozitivului personal';
_fc_props.registration_code = 'Cod de înregistrare';
_fc_props.creation_date = 'Creare';
_fc_props.expiration_date = 'Expirare';
_fc_props.trackitadmin_personal_device_registration = 'Înregistrarea dispozitivului personal';
_fc_props.create_registration_code = 'Adăugați codul de înregistrare';
_fc_props.remove_registration_code = 'Eliminați codul de înregistrare';
_fc_props.add_a_registration_code = 'Adăugați un cod de înregistrare';
_fc_props.select_number_of_registration_codes_to_generate = 'Selectați numărul de coduri de înregistrare pe care doriți să le generați.';
_fc_props.registration_code_count = 'Număr de coduri de înregistrare';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Sigur doriți să ștergeți codul înregistrat selectat?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Sigur doriți să ștergeți codurile înregistrate selectate?';
_fc_props.add_personal_device = 'Adăugați un dispozitiv personal';
_fc_props.menu_settings_title_personal_device = 'Dispozitiv personal';
_fc_props.cloud_dvir__approval_critical_alert = 'Vehiculul are un defect critic. Nu poate fi marcat ca Sigur de utilizat.';
_fc_props.export_registration_codes = 'Coduri de înregistrare pentru export';
_fc_props.download_format = 'Format de descărcare';
_fc_props.specify_download_format_x = 'Specificați formatul de descărcare pentru a exporta {0} coduri de înregistrare';
_fc_props.personal_device_download = 'Descarca';
_fc_props.add_personal_device_input_message = 'Introduceți un ID de dispozitiv personal mai jos. Expirarea va fi aplicată automat după salvare';
_fc_props.error_creating_personal_device = 'Eroare la crearea unui dispozitiv personal';
_fc_props.device_already_exists = 'Dispozitivul personal există deja';
_fc_props.export = 'Export';
_fc_props.error_can_not_change_connex_eid = 'Nu se poate modifica connex eid deoarece este utilizat în prezent pentru datele de bază';
_fc_props.remove_personal_device = 'Eliminați dispozitivul personal';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Sigur doriți să ștergeți dispozitivul personal selectat?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Sigur doriți să ștergeți dispozitivele personale selectate?';
_fc_props.error_deleting_personal_device = 'Eroare la ștergerea dispozitivului personal';
_fc_props.customerid = 'Număr de înregistrare client';
_fc_props.customername = 'Numele clientului';
_fc_props.ordernumber = 'Număr de ordine';
_fc_props.orderdescription = 'Descrierea comenzii';
_fc_props.locationid = 'ID locație';
_fc_props.locationdescription = 'Descrierea locației';
_fc_props.productid = 'ID produs';
_fc_props.productdescription = 'Descriere produs';
_fc_props.vehicleid = 'ID vehicul';
_fc_props.vehicledescription = 'Descrierea vehiculului';
_fc_props.ticketnumber = 'Bilet #';
_fc_props.ticketdate = 'Data/ora biletului';
_fc_props.drivername = 'Nume șofer';
_fc_props.arriveregion = 'Sosire în regiune (TIME)';
_fc_props.leaveregion = 'Părăsiți regiunea (TIME)';
_fc_props.regionduration = 'Regiune Durata';
_fc_props.menu_reports_title_prevailing_wage = 'Salariul predominant';
_fc_props.slump_calibration_x_not_found_for_y = 'Slump Calibration „{0}” nu a fost găsită pentru biletul {1}';
_fc_props.slump_calibration_not_found = 'Calibrarea Slump nu a fost găsită';
_fc_props.slump_calibration_not_found_description = 'Alertă când calibrarea slumpului nu a fost găsită';
_fc_props.error_getting_personal_device_list = 'Eroare la obținerea listei de dispozitive personale';
_fc_props.batch_summary_date_range_note = '*NOTĂ: Acest raport este limitat la 3 zile dacă nu sunt aplicate filtre. Intervalul de date poate merge până la 31 de zile când este filtrat după Echipament (maxim 10), Uzină (maxim 1), Produs (maxim 1) sau Loc de muncă (maxim 1)';
_fc_props.select_registration_code_expiration_time_days = 'Selectați numărul de zile până când codurile de înregistrare vor expira și apoi faceți clic pe Salvare.';
_fc_props.add_personal_device_expiration = 'Expirare (în zile)';
_fc_props.cloud_dvir__vehicle_no = 'Vehiculul nr';
_fc_props.cloud_dvir__trailer_no = 'Trailerul nr';
_fc_props.cloud_dvir__vehicle = 'Vehicul';
_fc_props.cloud_dvir__trailer = 'Trailer';
_fc_props.cloud_dvir__truck_number_label = 'Numărul camionului';
_fc_props.cloud_dvir__trailer_number = 'Numărul remorcii';
_fc_props.cloud_dvir__has_trailer = 'Nu conduceți cu o remorcă';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Introduceți numărul remorcii dvs. aici';
_fc_props.work_order = 'Comanda de lucru';
_fc_props.unable_to_determine_slump = 'Nu se poate determina Slump';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Determinați starea generală a remorcii';
_fc_props.defaultTrailer = 'Trailer implicit';
_fc_props.trailer_type_column_billable = 'Facturabil';
_fc_props.trailer_type_column_notes = 'Note';
_fc_props.trailer_type_column_date = 'Data';
_fc_props.trailer_type_column_order = 'Comanda';
_fc_props.trailer_type_column_ticket = 'Bilet';
_fc_props.trailer_type_column_project = 'Proiect';
_fc_props.trailer_type_column_customer = 'Client';
_fc_props.trailer_type_column_location = 'Locaţie';
_fc_props.trailer_type_column_vehicle_type = 'Tipul vehiculului';
_fc_props.trailer_type_column_product = 'Produs';
_fc_props.trailer_type_action_rebill = 'Refacturați';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Sincronizarea datelor principale nu a reușit, nu a fost setat niciun tip de echipament implicit';
_fc_props.trailer_type = 'Tip remorcă';
_fc_props.trailer_type_is_required = 'Este necesar tipul de remorcă';
_fc_props.trailer_number_already_exists = 'Numărul trailerului există deja';
_fc_props.plate_number_already_exists = 'Numărul plăcuței există deja';
_fc_props.trailer_vin_already_exists = 'Trailerul Vin există deja';
_fc_props.trailer_does_not_exist = 'Trailerul nu există';
_fc_props.trailer_number_is_required = 'Numărul remorcii este necesar';
_fc_props.plate_number_is_required = 'Numărul plăcuței este necesar';
_fc_props.plate_number = 'Numărul plăcuței';
_fc_props.menu_settings_title_trailer = 'Trailer';
_fc_props.add_trailer = 'Adăugați Trailer';
_fc_props.edit_trailer = 'Editați trailerul';
_fc_props.menu_settings_title_equipment = 'Echipamente';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorați biletele noi de la Command Cloud mai vechi de X minute';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignorați biletele noi din Command Cloud cu data de expediere, timp mai vechi de X minute (implicit este gol și dezactivat)';
_fc_props.edit_load_card = 'Editați cardul de încărcare';
_fc_props.default_layout = 'Aspect implicit';
_fc_props.load_card_empty_fields = 'Eroare la salvarea cardului de încărcare: trebuie selectate unul sau mai multe câmpuri.';
_fc_props.trailers = 'Remorci';
_fc_props.error_failed_to_save_data = 'Eroare: Salvarea datelor eșuate';
_fc_props.slump_increased_plasticizer_added_question = 'Cât plastifiant a fost adăugat?';
_fc_props.add_trailer_type = 'Adăugați tipul de remorcă';
_fc_props.menu_settings_title_trailer_type = 'Tip remorcă';
_fc_props.edit_trailer_type = 'Editați tipul trailerului';
_fc_props.single_use_codes = 'Coduri de unică folosință';
_fc_props.multiple_use_codes = 'Coduri cu utilizare multiplă';
_fc_props.trailer_type_description_is_required = 'Este necesară descrierea tipului de remorcă';
_fc_props.trailer_saved = 'Trailer salvat';
_fc_props.cloud_dvir__trailer_only_dvir = 'Doar trailer DVIR';

export default _fc_props;
