import React, { FC, useContext, useState } from 'react';
import { Collapse as MUICollapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Button, RadioButtonsGroup, Text } from '../../../components';
import NoteEditor from './NoteEditor';
import {
  InspectionValue, Photo, SubmissionCategoryItem, SubmissionEvent,
} from '../../../types/submission';
import { MechanicReviewContext } from '../../context/MechanicReviewProvider';
import { EVENT_INDEX } from '../../constants';
import { getNoteData } from '../../utils';
import Note from '../../../submission/attachments/Note';
import { MechanicSubmissionUpdate } from '../../types';
import ImageThumbnail from '../../../components/image-thumbnail/ImageThubnail';

const StyledCollapse = styled(MUICollapse)`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const StyledOptionsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const StyledItemDetailsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

const StyledPhotosWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
`;

const StyledNotesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const getMechanicReviewOptions = (t: TFunction) => [
  {
    value: InspectionValue.RepairsMade,
    label: t('cloud_dvir__repairs_made'),
    name: 'repairs_made',
  },
  {
    value: InspectionValue.RepairsNotNecessary,
    label: t('cloud_dvir__repairs_not_necessary'),
    name: 'repairs_not_necessary',
  },
];

interface CategoryItemDetailsProps {
  item: SubmissionCategoryItem;
  isShown?: boolean;
}

const CategoryItemDetails:FC<CategoryItemDetailsProps> = ({ item: { id, events }, isShown }) => {
  const {
    submissionUpdates, setItemSafetyStatus, resetItemSafetyStatus, addItemNote, deleteItemNote,
  } = useContext(MechanicReviewContext);

  const [isNoteEditMode, setIsNoteEditMode] = useState(false);

  const { t } = useTranslation();

  const itemUpdates = submissionUpdates[id] || ({} as MechanicSubmissionUpdate);
  const shouldShowNoteEditor = isNoteEditMode || !itemUpdates.note;

  const { photos = [], notes = [] } = {
    photos: events[EVENT_INDEX.DRIVER].photos,
    notes: [
      ...events.map((event) => getNoteData({ event })),
      getNoteData({ event: itemUpdates as SubmissionEvent }),
    ],
  };

  const handleSafetyStatusChange = ({ target: { value } }) => {
    setItemSafetyStatus({ id, safetyStatus: value });
  };

  const handleResetSafetyStatus = () => {
    resetItemSafetyStatus(id);
  };

  const handleAddItemNote = (note: string) => {
    addItemNote({ id, note });
    setIsNoteEditMode(false);
  };

  const handleDeleteItemNote = () => {
    deleteItemNote(id);
  };

  const handleEditNote = () => {
    setIsNoteEditMode(true);
  };

  return (
    <StyledCollapse in={isShown} timeout="auto" unmountOnExit>
      <StyledItemDetailsWrapper>
        {photos && photos.length > 0 && (
          <StyledPhotosWrapper>
            {(photos as unknown as Photo[]).map((imageSrc) => (
              <ImageThumbnail src={imageSrc.src} alt={imageSrc.id} name={imageSrc.id} key={imageSrc.id} readOnly />
            ))}
          </StyledPhotosWrapper>
        )}
        <div>
          <Text
            fontLevel="title"
            fontSize="large"
            fontWeight="bold"
            sx={{ color: (theme) => theme.palette.neutral.variation40 }}
          >
            {t('cloud_dvir__certify_repairs')}
          </Text>

          <StyledOptionsWrapper>
            <RadioButtonsGroup
              options={getMechanicReviewOptions(t)}
              groupName="certification_status"
              onChange={handleSafetyStatusChange}
              selectedValue={itemUpdates.safetyStatus}
            />

            <Button onClick={handleResetSafetyStatus} disabled={!itemUpdates.safetyStatus}>
              {t('cloud_dvir__undo_selection')}
            </Button>
          </StyledOptionsWrapper>
        </div>

        <StyledNotesWrapper>
          {notes.map(
            ({ note, updatedTime, isDriverNote }, index) => note
                && (
                  <Note
                    noteText={note}
                    isDriverNote={isDriverNote}
                    readOnly={isDriverNote}
                    createdAt={updatedTime}
                    key={updatedTime}
                    isOdd={Boolean(index % 2)}
                    onDelete={handleDeleteItemNote}
                    onEdit={handleEditNote}
                  />
                ),
          )}
        </StyledNotesWrapper>
        {shouldShowNoteEditor && (
          <NoteEditor defaultValue={itemUpdates.note || ''} isEditMode={isNoteEditMode} onAddItem={handleAddItemNote} />
        )}
      </StyledItemDetailsWrapper>
    </StyledCollapse>
  );
};

export default CategoryItemDetails;
