import React, { FC, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Modal from '../modal/Modal';

const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const StyledDeleteButton = styled('button')`
  position: absolute;
  top: 4px;
  right: 4px;
  border: none;
  background-color: rgba(0,0,0, 0.4);
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
`;

const StyledDeleteIcon = styled(DeleteOutlineIcon)`
  color: ${({ theme }) => theme.palette.neutral.variation100};
`;

interface ImageThumbnailProps {
  src: string;
  alt: string;
  name: string;
  readOnly?: boolean;
  onDelete?: (name: string) => void;
}

const ImageThumbnail:FC<ImageThumbnailProps> = ({
  src,
  alt,
  name,
  readOnly = false,
  onDelete = () => {},
}) => {
  const [showImagePreview, setShowImagePreview] = useState<boolean>(false);

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!readOnly) {
      URL.revokeObjectURL(src);
      onDelete(name);
    }
  };

  const toggleImagePreview = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setShowImagePreview(!showImagePreview);
  };

  return (
    <Box
      onClick={toggleImagePreview}
      sx={{
        position: 'relative', width: '104px', height: '104px', marginRight: 2, marginBottom: 2, borderRadius: '8px', overflow: 'hidden',
      }}
    >
      <StyledImage src={src} alt={alt} />
      {!readOnly && (
        <StyledDeleteButton onClick={handleDelete}>
          <StyledDeleteIcon />
        </StyledDeleteButton>
      )}
      <Modal open={showImagePreview} onClose={toggleImagePreview}>
        <StyledImage src={src} alt={alt} />
      </Modal>
    </Box>
  );
};

export default ImageThumbnail;
