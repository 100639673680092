import React, {
  FC, useContext, useMemo, useState,
} from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Text } from '../../../components';
import { ChipStatus } from '../../../components/chip/Chip';
import { InspectionValue, SubmissionCategoryItem } from '../../../types/submission';
import CategoryItemDetails from './CategoryItemDetails';
import { MechanicReviewContext } from '../../context/MechanicReviewProvider';

const StyledText = styled(Text)`
  width: 20%;
`;

const StyledChip = styled(Chip)`
  width: 40%;
`;

const StyledAttachmentsButton = styled(Button)`
  width: fit-content;
`;

interface CategoryItemProps {
    item: SubmissionCategoryItem
}

const getChipStatusAndLabelBySafetyStatus = (safetyStatus: InspectionValue): { status: ChipStatus, label: string, icon: React.ReactElement} => {
  if (safetyStatus === InspectionValue.RepairsMade) {
    return {
      status: 'success',
      label: 'cloud_dvir__repairs_made',
      icon: <CheckIcon fontSize="small" color="inherit" />,
    };
  }

  if (safetyStatus === InspectionValue.RepairsNotNecessary) {
    return {
      status: 'success',
      label: 'cloud_dvir__repairs_not_necessary',
      icon: <CheckIcon fontSize="small" color="inherit" />,
    };
  }

  if (safetyStatus === InspectionValue.Ok) {
    return {
      status: 'success',
      label: 'cloud_dvir__ok',
      icon: <CheckIcon fontSize="small" color="inherit" />,
    };
  }

  if (safetyStatus === InspectionValue.NeedsRepair) {
    return {
      status: 'error',
      label: 'cloud_dvir__needs_repair',
      icon: <TaxiAlertIcon fontSize="small" color="inherit" />,
    };
  }

  return {
    status: 'neutral',
    label: 'cloud_dvir__not_applicable',
    icon: <RemoveCircleOutlineIcon fontSize="small" color="inherit" />,
  };
};

const CategoryItem:FC<CategoryItemProps> = ({ item }) => {
  const [isShownAttachments, setIsShownAttachments] = useState(false);

  const { submissionUpdates } = useContext(MechanicReviewContext);

  const { t } = useTranslation();

  const safetyStatusByDriver = item.events[0].safetyStatus;
  const safetyStatusByMechanic = submissionUpdates[item.id]?.safetyStatus;

  const { status, label, icon } = useMemo(() => getChipStatusAndLabelBySafetyStatus(safetyStatusByMechanic ?? safetyStatusByDriver), [safetyStatusByMechanic, safetyStatusByDriver]);

  const toggleShowAttachments = () => {
    setIsShownAttachments(!isShownAttachments);
  };

  return (
    <Box
      sx={{
        padding: '20px',
        border: 1,
        borderRadius: '8px',
        backgroundColor: (theme) => theme.palette.neutral.variation100,
        borderColor: (theme) => theme.palette.neutral.variation90,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <StyledText>{item.name}</StyledText>
          <StyledChip label={t(label)} icon={icon} size="medium" status={status} onClick={() => {}} />
          <StyledAttachmentsButton
            size="small"
            endIcon={isShownAttachments ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
            onClick={toggleShowAttachments}
          >
            {t('cloud_dvir__view_details')}
          </StyledAttachmentsButton>
        </Box>
        <CategoryItemDetails isShown={isShownAttachments} item={item} />
      </Box>
    </Box>
  );
};

export default CategoryItem;
