import { InspectionFieldState, InspectionForm } from '../forms/useInspectionForm';
import { InspectionValue } from '../../types/submission';
import { MismatchType } from './constants';
import { CategoryType } from '../../types/template';
import { splitCategoryItemsByCategoryType } from '../utils/utils';
import { OverallForm, OverallFormFields } from '../forms/useOverallForm';

type ValidateMismatchCallback = (value: InspectionFieldState['value']) => boolean;

const validateInspectionForm = (category: InspectionForm, validateMismatch: ValidateMismatchCallback): boolean => Object.values(category)
  .some((categories) => Object.values(categories.items)
    .some(({ value }) => validateMismatch(value)));

const getMismatch = (categoryItems: InspectionForm, overallValue: InspectionValue) => {
  if (overallValue === InspectionValue.NeedsRepair) {
    const hasItemToRepair = validateInspectionForm(categoryItems, (value) => value === InspectionValue.NeedsRepair);

    return hasItemToRepair ? MismatchType.NoMismatch : MismatchType.NeedsRepair;
  }

  const hasItemToRepair = validateInspectionForm(categoryItems, (value) => value !== InspectionValue.Ok && value !== InspectionValue.NotApplicable);

  return hasItemToRepair ? MismatchType.AllItemsOk : MismatchType.NoMismatch;
};

export const getOverallFormFieldByType = (categoryType: CategoryType | string) => {
  switch (categoryType) {
    case CategoryType.Trailer: {
      return OverallFormFields.OverallTrailer;
    }
    case CategoryType.Vehicle: {
      return OverallFormFields.OverallVehicle;
    }
    default: {
      return null;
    }
  }
};

export const getMismatches = (inspectionForm: InspectionForm, overallForm: OverallForm, isVehicleDvirSubmit: boolean, isTrailerDvirSubmit: boolean): Record<CategoryType, MismatchType> => {
  const categoriesByType = splitCategoryItemsByCategoryType(inspectionForm);

  return Object.entries(categoriesByType).reduce((result, [categoryType, category]) => {
    if ((categoryType === CategoryType.Vehicle && !isVehicleDvirSubmit)
        || (categoryType === CategoryType.Trailer && !isTrailerDvirSubmit)) {
      return result;
    }

    const overallFormField = getOverallFormFieldByType(categoryType);
    const mismatchType = getMismatch(category, overallForm[overallFormField]?.value);

    return {
      ...result,
      [categoryType]: mismatchType,
    };
  }, {} as Record<CategoryType, MismatchType>);
};
