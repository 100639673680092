import React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LabeledIconButton } from '../../components';

const NavigationButton = ({
  label, onClick, isNext = false, ...rest
}) => (
  <LabeledIconButton
    {...rest}
    label={label}
    onClick={onClick}
    icon={isNext ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
    viewType={isNext ? 'primary' : 'secondary'}
    size="medium"
  />
);

export default NavigationButton;
