import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import da from './locales/properties_da';
import de from './locales/properties_de';
import en from './locales/properties_en';
import es from './locales/properties_es';
import fi from './locales/properties_fi';
import fr from './locales/properties_fr';
import hr from './locales/properties_hr';
import it from './locales/properties_it';
import ja from './locales/properties_ja';
import nl from './locales/properties_nl';
import no from './locales/properties_no';
import pa from './locales/properties_pa';
import pl from './locales/properties_pl';
import pt from './locales/properties_pt';
import ro from './locales/properties_ro';
import ru from './locales/properties_ru';
import sv from './locales/properties_sv';
import uk from './locales/properties_uk';

const FILTRATION_KEY_CLOUD_DVIR = 'cloud_dvir__';

const resources = {
  da: { translation: da },
  de: { translation: de },
  en: { translation: en },
  es: { translation: es },
  fi: { translation: fi },
  fr: { translation: fr },
  hr: { translation: hr },
  it: { translation: it },
  ja: { translation: ja },
  nl: { translation: nl },
  no: { translation: no },
  pa: { translation: pa },
  pl: { translation: pl },
  pt: { translation: pt },
  ro: { translation: ro },
  ru: { translation: ru },
  sv: { translation: sv },
  uk: { translation: uk },
};

const initializeI18n = (filteredResources) => {
  i18n
    .use(initReactI18next)
    .init({
      resources: filteredResources,
      fallbackLng: 'en',
      lng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
};

const filterTranslations = () => {
  const filteredResources = Object.keys(resources)
    .reduce((filteredResource, language) => {
      // eslint-disable-next-line no-param-reassign
      filteredResource[language] = {
        translation: Object.keys(resources[language].translation)
          .filter((key) => key.startsWith(FILTRATION_KEY_CLOUD_DVIR))
          .reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = resources[language].translation[key];
            return obj;
          }, {}),
      };
      return filteredResource;
    }, {});

  initializeI18n({ ...filteredResources });
};

filterTranslations();

export default i18n;
