import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  height: '48px',

  '& .MuiInputBase-root': {
    height: '48px',
  },
  '& .MuiInputBase-input': {
    height: '48px',
    backgroundColor: theme.palette.neutral.variation100,
  },
  '& .MuiFormLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    transform: 'translate(14px, 50%) scale(1)',
  },
}));

export interface SelectFieldOption<T = any> {
    key: string;
    value: string;
    label: string;
    optionData?: T;
}

interface SelectFieldProps<T = any> extends Omit<SelectProps, 'onSelect'> {
    options: SelectFieldOption<T>[];
    onSelect: (selectedOption: SelectFieldOption<T>) => void;
}

const SelectField:FC<SelectFieldProps> = ({
  name, fullWidth = false, value, options, onSelect, label, ...rest
}) => {
  const handleChange = ({ target: { value: selectedValue } }) => {
    const selectedOption = options.find((opt) => opt.value === selectedValue);

    onSelect(selectedOption);
  };

  return (
    <StyledFormControl fullWidth={fullWidth}>
      <InputLabel id={name} sx={{ fontSize: '16px' }}>
        {label}
      </InputLabel>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        labelId={name}
        label={label}
        sx={{ fontSize: '16px' }}
        {...rest}
      >
        {options.map(({ key, value: itemValue, label: itemLabel }) => (
          <MenuItem key={key} value={itemValue}>
            {itemLabel}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default SelectField;
