import api from '../api';
import { API_VERSION } from '../api/paths';
import S3SignedLinkFileUploader from '../submission/utils/S3SignedLinkFileUploader';
import { Log } from '../utils/Log';
import { blobToBase64 } from '../utils/blobToB64';
import { Photo } from '../types/submission';
import { S3Type } from '../submission/forms/useAttachments';

export class DvirFailedSubmissionRetrier {
  private readonly submissionStorageKey: string;

  private readonly attachmentsStorageKey: string;

  private readonly context: string;

  private readonly submissionUrl: string;

  constructor({
    driverId, vin, submissionUrl, context,
  }) {
    this.submissionStorageKey = `to_upload_${driverId}_${vin}`;
    this.attachmentsStorageKey = `to_upload_attachments_${driverId}_${vin}`;
    this.submissionUrl = submissionUrl;
    this.context = context;
  }

  prepareAttachmentsToSave = (attachments: Photo[]) => {
    if (!attachments.length) {
      return Promise.resolve([]);
    }

    return Promise.all(
      attachments.map(async (attachment) => {
        const newSrc = await blobToBase64(attachment.src);

        return { ...attachment, src: newSrc };
      }),
    );
  };

  prepareAttachmentToSend = (attachment: Photo) => {
    const parts = attachment.src.split(',');
    const contentType = parts[0].split(':')[1].split(';')[0];
    const byteCharacters = atob(parts[1]);

    const byteNumbers = new Array(byteCharacters.length);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: contentType });
  };

  saveSubmissionForm = async (submissionForm) => {
    const attachments = await this.prepareAttachmentsToSave(submissionForm.attachments);

    localStorage.setItem(this.submissionStorageKey, JSON.stringify({ ...submissionForm, attachments }));
  };

  saveSubmissionAttachment = async (attachment: Photo) => {
    const base64Src = await blobToBase64(attachment.src);
    const attachmentToSave = {
      ...attachment,
      src: base64Src,
    };
    const existingAttachments = localStorage.getItem(this.attachmentsStorageKey);

    if (existingAttachments) {
      const updatedAttachments = [...JSON.parse(existingAttachments), attachmentToSave];

      localStorage.setItem(this.attachmentsStorageKey, JSON.stringify(updatedAttachments));

      return;
    }

    localStorage.setItem(this.attachmentsStorageKey, JSON.stringify([attachmentToSave]));
  };

  retrieveFailedSubmissionForm = () => {
    const failedSubmissionForm = localStorage.getItem(this.submissionStorageKey);

    return failedSubmissionForm ? JSON.parse(failedSubmissionForm) : null;
  };

  retrieveFailedSubmissionAttachments = () => {
    const failedSubmissionAttachments = localStorage.getItem(this.attachmentsStorageKey);

    return failedSubmissionAttachments ? JSON.parse(failedSubmissionAttachments) : null;
  };

  resendFailedSubmission = () => {
    const failedSubmissionForm = this.retrieveFailedSubmissionForm();

    if (!failedSubmissionForm) {
      return;
    }

    this.sendSubmission(failedSubmissionForm);
  };

  resendFailedSubmissionAttachments = () => {
    const failedSubmissionAttachments = this.retrieveFailedSubmissionAttachments();

    if (!failedSubmissionAttachments) {
      return;
    }

    this.sendAttachments(failedSubmissionAttachments);
  };

  sendSubmission = async (failedSubmissionForm) => {
    const signedUrls = await api.put(`${this.submissionUrl}/${API_VERSION}`, {}, failedSubmissionForm.formData);

    this.cleanUp();
    this.uploadAttachments(failedSubmissionForm.attachments, signedUrls);
  };

  sendAttachments = async (attachments) => {
    const attachmentIDs = attachments.map((item) => item.id);

    const guids = JSON.stringify(attachmentIDs);
    const signedUrlsArray: Array<any> = await api.get(`${this.submissionUrl}/${API_VERSION}/companies/${this.context}/images`, { action: 'put', guids });
    const signedUrls = Object.assign({}, ...signedUrlsArray);

    this.uploadAttachments(attachments, signedUrls);
  };

  uploadAttachments = (attachments, signedUrls) => {
    attachments?.forEach((attachment) => {
      const signedUrl = signedUrls[attachment.id];
      const blob = this.prepareAttachmentToSend(attachment);

      if (signedUrl) {
        const successCallback = (data) => {
          this.cleanUpAttachment(attachment.id);
          Log.i('Successfully uploaded to S3:', data);
        };
        const failsCallback = () => {
          Log.e('Failed upload to S3:');
        };
        S3SignedLinkFileUploader.fetch(signedUrl, blob, S3Type.JPEG, successCallback, failsCallback)
          .catch((error) => {
            Log.e('Failed upload to S3:', error);
          });
      }
    });
  };

  cleanUpAttachment = (attachmentId) => {
    const attachments = localStorage.getItem(this.attachmentsStorageKey);

    if (!attachments) {
      return;
    }

    const removedAttachments = JSON.parse(attachments)?.filter((item) => item.id !== attachmentId);

    localStorage.setItem(this.attachmentsStorageKey, JSON.stringify(removedAttachments));
  };

  cleanUp = () => {
    localStorage.removeItem(this.submissionStorageKey);
  };
}
