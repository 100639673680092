import React, { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TFunction } from 'i18next';
import { Text, StatusBadge } from '../components';
import { DvirHistoryData } from './types';
import formatDate from '../utils/format-date';
import { DvirStatus, InspectionTripType } from '../types/types';
import { BadgeStatus } from '../components/status-badge/StatusBadge';
import { SubmissionType } from '../types/submission';

const getBadgeConfig = (status: DvirStatus): { badgeStatus: BadgeStatus, translationKey: string } => {
  switch (status) {
    case DvirStatus.Completed: {
      return { badgeStatus: 'success', translationKey: 'cloud_dvir__dvir_completed' };
    }

    case DvirStatus.AwaitingMechanic: {
      return { badgeStatus: 'info', translationKey: 'cloud_dvir__awaiting_mechanic' };
    }

    case DvirStatus.AwaitingSign: {
      return { badgeStatus: 'warning', translationKey: 'cloud_dvir__awaiting_driver' };
    }

    default:
      return { badgeStatus: 'success', translationKey: 'cloud_dvir__dvir_completed' };
  }
};

const StyledHistoryButton = styled('button')<{ isHomePage: boolean }>(({ theme, isHomePage }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 'none',
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
  ...(isHomePage
    ? {
      padding: `${theme.indent * 1.5}px ${theme.indent * 4}px ${theme.indent * 1.5}px ${theme.indent * 2}px`,
      borderBottom: `1px solid ${theme.palette.neutral.variation80}`,
    }
    : {
      width: '100%',
      padding: `${theme.indent}px ${theme.indent * 3}px ${theme.indent}px ${theme.indent * 2}px`,
      backgroundColor: theme.palette.neutral.variation100,
      border: `1px solid ${theme.palette.neutral.variation80}`,
      borderRadius: 8,
    }),
}));

const getInspectionTripTypeLabel = (t: TFunction, type: InspectionTripType) => {
  if (type === 'PRE_TRIP') {
    return t('cloud_dvir__pre_trip');
  }

  if (type === 'POST_TRIP') {
    return t('cloud_dvir__post_trip');
  }

  return t('cloud_dvir__ad_hoc');
};

interface HistoryItemProps {
  dvirHistoryData: DvirHistoryData;
  onClick?: () => void;
  isHomePage?: boolean;
}

const HistoryItem: FC<HistoryItemProps> = ({
  onClick = () => { },
  isHomePage = false,
  dvirHistoryData: {
    submissionDate, driverName, inspectionTripType, status, type, vin, vehicleNum,
  },
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick();
  };

  const submissionTimestamp = useMemo(() => formatDate(new Date(submissionDate)), [submissionDate]);
  const inspectionTripTypeLabel = getInspectionTripTypeLabel(t, inspectionTripType);

  const { translationKey, badgeStatus } = getBadgeConfig(status);

  return (
    <StyledHistoryButton
      isHomePage={isHomePage}
      onClick={handleClick}
    >
      <Box>
        <Text
          fontSize="large"
          fontLevel="body"
          color={(theme) => (isHomePage ? theme.palette.neutral.variation40 : theme.palette.neutral.variation10)}
        >
          {`${submissionTimestamp} | ${inspectionTripTypeLabel}`}
        </Text>
        <Text
          fontSize="medium"
          fontLevel="body"
          color={(theme) => (isHomePage ? theme.palette.neutral.variation40 : theme.palette.neutral.variation10)}
        >
          {driverName}
        </Text>
        {type === SubmissionType.Vehicle && (
          <Text
            fontSize="large"
            fontLevel="body"
            color={(theme) => (isHomePage ? theme.palette.neutral.variation40 : theme.palette.neutral.variation10)}
          >
            {`${t('cloud_dvir__vehicle_no')}: ${vehicleNum}`}
          </Text>
        )}
        {type === SubmissionType.Trailer && (
          <Text
            fontSize="large"
            fontLevel="body"
            color={(theme) => (isHomePage ? theme.palette.neutral.variation40 : theme.palette.neutral.variation10)}
          >
            {`${t('cloud_dvir__trailer_no')}: ${vin}`}
          </Text>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        {!isHomePage && <StatusBadge status={badgeStatus} text={t(translationKey)} />}
        <NavigateNextIcon />
      </Box>
    </StyledHistoryButton>
  );
};

export default HistoryItem;
