import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, Spinner, Text,
} from '../components';
import { isEmptyObject, isEmptyString } from '../utils/validation';
import { AuthenticationContext } from './context/AuthenticationProvider';
import { LoginData } from './types';

type LoginDataErrors = {
    [K in keyof LoginData]: boolean;
};

const StyledWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const StyledTitle = styled(Text)`
    text-align: center;
    margin-bottom: 24px;
`;

const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 320px;
`;

const LoginPage = () => {
  const [errors, setErrors] = useState<Partial<LoginDataErrors>>({ username: false, password: false });
  const [loginData, setLoginData] = useState<LoginData>({ username: '', password: '' });

  const { t } = useTranslation();

  const { login, isLoading } = useContext(AuthenticationContext);

  const handleLoginDataChange = ({ target: { name, value } }) => {
    setLoginData({ ...loginData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validate = () => {
    const loginErrors:Partial<LoginDataErrors> = {};

    if (isEmptyString(loginData.username)) {
      loginErrors.username = true;
    }

    if (isEmptyString(loginData.password)) {
      loginErrors.password = true;
    }

    if (!isEmptyObject(loginErrors)) {
      setErrors(loginErrors);

      return false;
    }

    return true;
  };

  const handleLogin = async () => {
    const isValid = validate();

    if (!isValid) {
      return;
    }

    login(loginData);
  };

  return (
    <StyledWrapper>
      {isLoading && <Spinner />}
      <StyledTitle fontLevel="title" fontSize="large" fontWeight="bold">
        {t('cloud_dvir__mechanic_sign_in')}
      </StyledTitle>
      <StyledForm noValidate autoComplete="off">
        <Input
          fullWidth
          name="username"
          label={t('cloud_dvir__username')}
          value={loginData.username}
          error={!!errors.username}
          onChange={handleLoginDataChange}
          autoComplete="off"
          InputProps={{ autoComplete: 'off' }}
        />
        <Input
          fullWidth
          name="password"
          type="password"
          label={t('cloud_dvir__password')}
          value={loginData.password}
          error={!!errors.password}
          onChange={handleLoginDataChange}
          autoComplete="new-password"
          InputProps={{ autoComplete: 'new-password' }}
        />
        <Button
          onClick={handleLogin}
          textTransform="uppercase"
          isFilled
        >
          {t('cloud_dvir__login')}
        </Button>
      </StyledForm>
    </StyledWrapper>
  );
};

export default LoginPage;
