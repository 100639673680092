import React, { FC } from 'react';
import { Box } from '@mui/material';
import Stepper, { Step } from '../stepper/Stepper';

interface NavigationBarProps {
    steps: Step[];
    currentStep: string;
    prevStep: React.ReactNode;
    nextStep: React.ReactNode;
}

const NavigationBar:FC<NavigationBarProps> = ({
  steps, currentStep, prevStep, nextStep,
}) => (
  <Box
    sx={{
      padding: 1,
      backgroundColor: (theme) => theme.palette.neutral.variation100,
      position: 'sticky',
      top: 0,
      zIndex: 10,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        maxWidth: '800px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      {prevStep}
      <Stepper steps={steps} currentStepKey={currentStep} />
      {nextStep}
    </Box>
  </Box>
);

export default NavigationBar;
