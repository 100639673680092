import { v4 as randomUUID } from 'uuid';
import { EVENT_TYPE } from '../context/constants';
import VersionManager from '../utils/VersionManager';
import GetTokenRequest from './model/auxRequest/GetTokenRequest';

enum RequestMethod {
  GET = 'GET',
  PUT = 'PUT'
}

class Api {
  private getRequestOptions(jwt, options) {
    const authorizationHeader = jwt ? { Authorization: `Bearer ${jwt}` } : {};
    return {
      ...options,
      headers: {
        ...options.headers || {},
        ...authorizationHeader,
        'Content-Type': 'application/json',
      },
    };
  }

  private initTokenGeneration(eventUUID: string, method: string, requestUrl: string, additionalParams: Record<string, any>) {
    const getTokenRequest: GetTokenRequest = new GetTokenRequest(eventUUID, method, requestUrl, additionalParams);
    VersionManager.auxFunctionsMap.getToken(getTokenRequest);
  }

  private sendRequest = async <T>(method: RequestMethod, url: string, tokenAdditionalParams: Record<string, any>, requestOptions): Promise<T> => new Promise<T>((resolve, reject) => {
    const eventUUID = randomUUID();

    const jwtReadyEventListener = (event: CustomEvent) => {
      if (event.detail && event.detail.eventUUID
          && event.detail.type === `${EVENT_TYPE.JWT_READY}_${event.detail.eventUUID}`
          && event.detail.token) {
        const data = this.fetch(method, url, event.detail.token, requestOptions);
        resolve(data as T);
      }
    };

    const jwtReadyEventTimeoutHandler = () => {
      window.removeEventListener(`${EVENT_TYPE.JWT_READY}_${eventUUID}`, jwtReadyEventListener);
      reject(new Error('Timeout exceeded'));
    };

    window.addEventListener(`${EVENT_TYPE.JWT_READY}_${eventUUID}`, jwtReadyEventListener, { once: true });

    this.initTokenGeneration(eventUUID, method, url, tokenAdditionalParams);

    setTimeout(jwtReadyEventTimeoutHandler, 5000);
  });

  public get = async <T>(url: string, tokenAdditionalParams: Record<string, any>): Promise<T> => {
    const data = await this.sendRequest(RequestMethod.GET, url, tokenAdditionalParams, {});
    return data as unknown as Promise<T>;
  };

  public put = async <T>(url: string, tokenAdditionalParams: Record<string, any>, body, options?: Omit<RequestInit, 'body' | 'method'>): Promise<T> => {
    const requestOptions = {
      ...options,
      body: JSON.stringify(body),
    };

    const data = await this.sendRequest(RequestMethod.PUT, url, tokenAdditionalParams, requestOptions);
    return data as unknown as Promise<T>;
  };

  private fetch = async <T>(method: RequestMethod, url: string, jwt: string, options: Omit<RequestInit, 'method'> = {}): Promise<T> => {
    try {
      const response = await fetch(url, {
        method,
        ...this.getRequestOptions(jwt, options),
      });
      if (!response.ok) {
        throw new Error(`HTTP status code: ${response.status}`);
      }
      const data = await response.json();

      return data;
    } catch (e) {
      throw new Error(e);
    }
  };
}

const api = new Api();

export default api;
