import { CLOUD_DVIR_TAG } from '../Constants';

class LogRequest {
  readonly dataModelNumber: number = 1;

  tag: string;

  level: string;

  objects: any[];

  constructor(level: string, objects: any[]) {
    this.tag = CLOUD_DVIR_TAG;
    this.level = level;
    this.objects = objects;
  }
}

export default LogRequest;
