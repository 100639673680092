import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Category from './Category';
import ViewTitle from '../shared/ViewTitle';
import { Submission } from '../../../types/submission';

interface MechanicInspectionProps {
    submission: Submission;
}

const MechanicInspection:FC<MechanicInspectionProps> = ({ submission }) => {
  const { t } = useTranslation();

  return (
    <>
      <ViewTitle title={t('cloud_dvir__inspection_profile')} />
      <div>
        {submission.categories.map((category) => <Category key={category.name} category={category} />)}
      </div>
    </>

  );
};

export default MechanicInspection;
