import { Button as MUIButton, ButtonProps as MUIButtonProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type ViewType = 'primary' | 'secondary' | 'success';
type TextTransform = 'uppercase' | 'capitalize' | 'capitalize' | 'none'

const getBackgroundColor = (theme: Theme, viewType: ViewType, isHovered = false) => {
  switch (viewType) {
    case 'primary':
      return isHovered ? theme.palette.darkBlue.variation30 : theme.palette.darkBlue.variation50;

    case 'secondary':
      return isHovered ? theme.palette.neutral.variation80 : theme.palette.neutral.variation90;

    case 'success':
      return isHovered ? theme.palette.green.variation30 : theme.palette.green.variation40;

    default:
      return isHovered ? theme.palette.darkBlue.variation30 : theme.palette.darkBlue.variation50;
  }
};

const getColor = (theme: Theme, viewType: ViewType) => {
  switch (viewType) {
    case 'primary':
      return theme.palette.neutral.variation100;

    case 'secondary':
      return theme.palette.neutral.variation20;

    default:
      return theme.palette.darkBlue.variation100;
  }
};

interface ButtonProps extends MUIButtonProps {
  isRounded?: boolean;
  isFilled?: boolean;
  viewType?: ViewType;
  textTransform?: TextTransform,
}

const Button = styled(MUIButton, {
  shouldForwardProp: (prop) => prop !== 'isRounded' && prop !== 'isFilled' && prop !== 'textTransform' && prop !== 'viewType',
})<ButtonProps>(({
  theme, isRounded, isFilled, viewType = 'primary', textTransform = 'none',
}) => ({
  textTransform,
  borderRadius: isRounded ? '100px' : 'unset',
  ...(isFilled ? {
    backgroundColor: getBackgroundColor(theme, viewType),
    color: getColor(theme, viewType),
    borderRadius: 4,
    '&:hover': {
      backgroundColor: getBackgroundColor(theme, viewType, true),
    },
  } : {}),
}));

export default Button;
