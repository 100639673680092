import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { SelectField } from '../../../components';
import ViewTitle from '../shared/ViewTitle';
import { MechanicOption, MechanicOptions } from '../types';
import { MechanicReviewContext } from '../../context/MechanicReviewProvider';

const StyledSelectField = styled(SelectField)`
    width: 300px;
`;

interface AssignMechanicProps {
  mechanicOptions: MechanicOptions;
}

const AssignMechanic:FC<AssignMechanicProps> = ({ mechanicOptions }) => {
  const { assignedMechanic, setAssignedMechanic } = useContext(MechanicReviewContext);
  const { t } = useTranslation();

  const handleSelect = (option: MechanicOption) => {
    setAssignedMechanic(option.optionData);
  };

  return (
    <>
      <ViewTitle title={t('cloud_dvir__assign_mechanic')} />
      <StyledSelectField
        options={mechanicOptions}
        onSelect={handleSelect}
        value={assignedMechanic?.id || ''}
        label={t('cloud_dvir__assigned_mechanic')}
      />
    </>
  );
};

export default AssignMechanic;
