import React, {
  FC, useContext, useMemo, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, NavigationBar, Spinner } from '../../components';
import { ReviewFormSteps } from '../types';
import NavigationButton from './NavigationButton';
import { MechanicReviewContext } from '../context/MechanicReviewProvider';
import { useAlert } from '../../hooks';
import LeaveConfirmationModal from '../../submission/leave-confirmation-modal/LeaveConfirmationModal';

const steps = [
  {
    key: ReviewFormSteps.Inspection,
    label: 'cloud_dvir__inspection',
  },
  {
    key: ReviewFormSteps.AssignMechanic,
    label: 'cloud_dvir__assign_mechanic',
  },
  {
    key: ReviewFormSteps.Sign,
    label: 'cloud_dvir__signature',
  },
];

interface ReviewNavigationProps {
    step: ReviewFormSteps;
    onStepChange: (step: ReviewFormSteps) => void;
    onExit: () => void;
}

const ReviewNavigation:FC<ReviewNavigationProps> = ({
  step, onExit, onStepChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    isAllItemsReviewed, assignedMechanic, isReadyForSubmit, submission, submissionUpdates, mechanicSignature,
  } = useContext(MechanicReviewContext);
  const { t } = useTranslation();
  const alert = useAlert();
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState<boolean>(false);
  const navigationCallbacksRef = useRef<{ onApprove: VoidFunction; onAbort: VoidFunction }>({
    onApprove: () => {},
    onAbort: () => {},
  });

  const storeSignature = () => {
    const signId = mechanicSignature.inspectorId.replace(' ', '_');
    const signatureData = mechanicSignature.src;
    if (!signatureData || !signatureData.startsWith('blob:')) {
      return;
    }
    fetch(signatureData)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
        const imageData = `data:image/jpeg;base64,${base64String}`;
        localStorage.setItem(`signature_${signId}`, imageData);
      });
  };

  const handleAbortExit = () => {
    setShowLeaveConfirmationModal(false);
  };

  const handleExit = () => {
    navigationCallbacksRef.current.onApprove = onExit;
    navigationCallbacksRef.current.onAbort = handleAbortExit;

    setShowLeaveConfirmationModal(true);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    /** TODO: replace to POST review */
    new Promise((res) => { setTimeout(res, 500); })
      .then(() => {
        const reviewData = {
          vin: submission.vin,
          submissionDate: submission.submissionDate,
          updates: submissionUpdates,
          signature: mechanicSignature,
          assignedMechanic,
        };

        console.log('reviewData: ', reviewData);

        storeSignature();

        onExit();
      })
      .catch(() => {
        alert.error({ title: t('cloud_dvir__error_retrieving_dvir'), children: t('cloud_dvir__error_submit_dvir_descr') });
      })
      .finally(() => setIsLoading(false));
  };

  const [prevStep, nextStep] = useMemo(() => {
    switch (step) {
      case ReviewFormSteps.Inspection: {
        return [
          <NavigationButton label={t('cloud_dvir__exit')} onClick={handleExit} />,
          <NavigationButton label={t('cloud_dvir__assign_mechanic')} onClick={() => onStepChange(ReviewFormSteps.AssignMechanic)} isNext disabled={!isAllItemsReviewed} />,
        ];
      }
      case ReviewFormSteps.AssignMechanic: {
        return [
          <NavigationButton label={t('cloud_dvir__inspection')} onClick={() => onStepChange(ReviewFormSteps.Inspection)} />,
          <NavigationButton label={t('cloud_dvir__signature')} onClick={() => onStepChange(ReviewFormSteps.Sign)} isNext disabled={!assignedMechanic} />,
        ];
      }
      default: {
        return [
          <NavigationButton label={t('cloud_dvir__assign_mechanic')} onClick={() => onStepChange(ReviewFormSteps.AssignMechanic)} />,
          <Button disabled={!isReadyForSubmit} size="large" variant="contained" isRounded isFilled onClick={handleSubmit}>{t('cloud_dvir__submit_review')}</Button>,
        ];
      }
    }
  }, [step, isAllItemsReviewed, assignedMechanic, isReadyForSubmit]);

  return (
    <>
      {isLoading && <Spinner />}
      <NavigationBar steps={steps} currentStep={step} prevStep={prevStep} nextStep={nextStep} />

      {showLeaveConfirmationModal && (
      <LeaveConfirmationModal
        onApprove={navigationCallbacksRef.current.onApprove}
        onAbort={navigationCallbacksRef.current.onAbort}
      />
      )}

    </>
  );
};

export default ReviewNavigation;
