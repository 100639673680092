import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Home from '../home/Home';
import SubmissionForm from '../submission/SubmissionForm';
import { EventsManagerContext } from '../context/EventsManagerContext';
import { PageMode, Pages } from '../pages';
import { DvirFailedSubmissionRetrierContext } from '../context/DvirFailedSubmissionRetrier';
import { DvirHistoryData } from '../home/types';
import { DvirSubmitMode } from '../constants';
import { SubmissionType } from '../types/submission';

const DriverDVIR = () => {
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.Home);
  const [currentSubmission, setSubmission] = useState<DvirHistoryData>(null);
  const [currentDvirSubmitMode, setDvirSubmitMode] = useState<DvirSubmitMode>(null);
  const [pageMode, setPageMode] = useState<PageMode>(PageMode.New);
  const [hasNotifiedFormForClosingPage, setHasNotifiedFormForClosingPage] = useState<boolean>(false);
  const { dvirScheduler } = useContext(DvirFailedSubmissionRetrierContext);

  const {
    language, isAttemptToCloseDvir, setIsAttemptToCloseDvir, auxFunctions,
  } = useContext(EventsManagerContext);
  const { driverData } = useContext(EventsManagerContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    if (dvirScheduler) {
      dvirScheduler.resendFailedSubmission();
      dvirScheduler.resendFailedSubmissionAttachments();
    }
  }, [dvirScheduler]);

  const goToHomePage = () => {
    setCurrentPage(Pages.Home);
  };

  const onStartDvir = () => {
    let dvirSubmitMode = DvirSubmitMode.Vehicle;
    if (driverData?.driverId) {
      const storedVin = localStorage.getItem(`trailer_vin_${driverData?.driverId}`);
      if (storedVin) {
        dvirSubmitMode = DvirSubmitMode.VehicleTrailer;
      }
    }

    setDvirSubmitMode(dvirSubmitMode);
    setSubmission(null);
    setCurrentPage(Pages.SubmissionForm);
    setPageMode(PageMode.New);
  };

  const onStartTrailerDvir = () => {
    setDvirSubmitMode(DvirSubmitMode.Trailer);
    setSubmission(null);
    setCurrentPage(Pages.SubmissionForm);
    setPageMode(PageMode.New);
  };

  const onReview = (dvir: DvirHistoryData, reviewType: PageMode) => {
    const dvirSubmitMode = dvir.type === SubmissionType.Vehicle
      ? DvirSubmitMode.Vehicle
      : DvirSubmitMode.Trailer;
    setDvirSubmitMode(dvirSubmitMode);
    setSubmission(dvir);
    setCurrentPage(Pages.SubmissionForm);
    setPageMode(reviewType);
  };

  const handleApproveCloseDvir = () => {
    goToHomePage();
    setHasNotifiedFormForClosingPage(false);
    setIsAttemptToCloseDvir(false);
    auxFunctions.approveCloseDvir({});
  };

  const handleAbortCloseDvir = () => {
    setHasNotifiedFormForClosingPage(false);
    setIsAttemptToCloseDvir(false);
  };

  useEffect(() => {
    if (isAttemptToCloseDvir) {
      if (currentPage !== Pages.SubmissionForm) {
        handleApproveCloseDvir();

        return;
      }

      setHasNotifiedFormForClosingPage(true);
    }
  }, [isAttemptToCloseDvir]);

  switch (currentPage) {
    case Pages.SubmissionForm:
      return (
        <SubmissionForm
          currentSubmission={currentSubmission}
          goToHomePage={goToHomePage}
          mode={pageMode}
          currentDvirSubmitMode={currentDvirSubmitMode}
          hasNotifiedFormForClosingPage={hasNotifiedFormForClosingPage}
          onApproveCloseDvir={handleApproveCloseDvir}
          onAbortCloseDvir={handleAbortCloseDvir}
        />
      );

    default:
      return <Home onStartDvir={onStartDvir} onStartTrailerDvir={onStartTrailerDvir} onReview={onReview} />;
  }
};

export default DriverDVIR;
