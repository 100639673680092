import { InspectionTripType } from '../types/types';
import { InspectionValue } from '../types/submission';

export interface ISubmissionItem {
    companyId: string;
    vin: string;
    submissionDate: string;
    inspectionTripType: InspectionTripType;
    driver: { id: string; name: string; },
    vehicleNum: string;
    initialSafetyStatus: InspectionValue;
    status: string;
}

export enum ReviewFormSteps {
    Inspection = 'inspection',
    AssignMechanic = 'assign_mechanic',
    Sign = 'sign'
}

/** TODO: define type after login integration */
export interface MechanicLoginData {
    employeeId: string;
}

export interface LoginData {
    password: string;
    username: string;
}

export interface MechanicSubmissionUpdate {
    safetyStatus: InspectionValue,
    updatedTime: string,
    inspectorId: string,
    note?: string;
}
