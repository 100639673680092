import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, TextFieldProps } from '@mui/material';

const StyledTextArea = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '96px',
    fontSize: '14px',
  },
  '& .MuiInputBase-input': {
    height: '96px',
    backgroundColor: theme.palette.neutral.variation100,
    color: theme.palette.neutral.variation0,
  },
}));

const TextArea = (props: TextFieldProps) => <StyledTextArea {...props} multiline />;

export default TextArea;
