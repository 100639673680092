import React, { FC, useContext } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AddPhoto, ImageThubnail, Text,
} from '../../components';
import b64toBlob from '../../utils/b64toBlob';
import { createGUID } from '../utils/utils';
import { EventsManagerContext } from '../../context/EventsManagerContext';
import { Photo } from '../../types/submission';

const MAX_IMAGES_TO_UPLOAD = 3;

interface ImagesEditorProps {
  images: Photo[];
  onAddPhoto: (photo: Photo) => void;
  readOnly?: boolean;
  onDeletePhoto: (photoId: string) => void;
}

const ImagesEditor:FC<ImagesEditorProps> = ({
  images,
  onAddPhoto,
  onDeletePhoto,
  readOnly = false,
}) => {
  const { vin } = useContext(EventsManagerContext);
  const { t } = useTranslation();

  const handleAppPhoto = (base64Photo: string) => {
    const blob = b64toBlob(base64Photo, 'image/jpeg');

    onAddPhoto({ src: URL.createObjectURL(blob), id: `${createGUID(vin)}.jpg` });
  };

  const handleDeleteImage = (imageId: string) => {
    onDeletePhoto(imageId);
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 0.5 }}>
        {!readOnly && (
          <AddPhoto
            onAddPhoto={handleAppPhoto}
            isDisabled={images.length >= MAX_IMAGES_TO_UPLOAD}
            sx={{ marginRight: 2 }}
          />
        )}
        {images.map(({ src, id }) => <ImageThubnail src={src} alt={id} name={id} key={id} readOnly={readOnly} onDelete={handleDeleteImage} />)}
      </Box>
      {!readOnly && (
        <Text fontLevel="body" fontSize="medium">{`** ${t('cloud_dvir__upload_photo_limit')}`}</Text>
      )}
    </Box>
  );
};

export default ImagesEditor;
