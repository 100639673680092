import React from 'react';
import { Text } from '../../../components';

const ViewTitle = ({ title }) => (
  <Text
    fontLevel="title"
    fontSize="large"
    fontWeight="bold"
    sx={{
      color: (theme) => theme.palette.neutral.variation40,
      marginBottom: 3,
    }}
  >
    {title}
  </Text>
);

export default ViewTitle;
