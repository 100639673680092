const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Retry';
_fc_props.default = 'Default';
_fc_props.origin = 'Origin';
_fc_props.loading = 'Loading';
_fc_props.list = 'List';
_fc_props.send = 'Send';
_fc_props.error = 'Error';
_fc_props.save = 'Save';
_fc_props.delete = 'Delete';
_fc_props.message = 'Message';
_fc_props.logout = 'Logout';
_fc_props.add = 'Add';
_fc_props.remove = 'Remove';
_fc_props.done = 'Done';
_fc_props.other = 'Other';
_fc_props.interval = 'Interval';
_fc_props.time = 'Time';
_fc_props.speed = 'Speed';
_fc_props.longitude = 'Longitude';
_fc_props.latitude = 'Latitude';
_fc_props.x_minutes = '{0} Min';
_fc_props.general = 'General';
_fc_props.messages = 'Messages';
_fc_props.gps_fixes = 'GPS Fixes';
_fc_props.device_id = 'Device Id';
_fc_props.vehicle = 'Vehicle';
_fc_props.employee = 'Employee';
_fc_props.version = 'Version';
_fc_props.statuses = 'Statuses';
_fc_props.edit = 'Edit';
_fc_props.cancel = 'Cancel';
_fc_props.yes = 'Yes';
_fc_props.none = 'None';
_fc_props.never = 'Never';
_fc_props.name = 'Name';
_fc_props.true = 'True';
_fc_props.status = 'Status';
_fc_props.distance = 'Distance';
_fc_props.ok = 'Ok';
_fc_props.login = 'Login';
_fc_props.next = 'Next';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Back';
_fc_props.select = 'Select';
_fc_props.dispatch = 'Dispatch';
_fc_props.messaging = 'Messaging';
_fc_props.plants = 'Plants';
_fc_props.last_plants = 'Last Plants';
_fc_props.vehicle_types = 'Vehicle Types';
_fc_props.employee_types = 'Employee Types';
_fc_props.employee_groups = 'Employee Groups';
_fc_props.equipment_groups = 'Equipment Groups';
_fc_props.job = 'Job';
_fc_props.order = 'Order';
_fc_props.ticket = 'Ticket #';
_fc_props.small = 'Small';
_fc_props.medium = 'Medium';
_fc_props.large = 'Large';
_fc_props.reports = 'Reports';
_fc_props.payroll = 'Payroll';
_fc_props.support = 'Support';
_fc_props.username = 'Username';
_fc_props.all = 'All';
_fc_props.question = 'Question';
_fc_props.select_employee = 'Select Employee';
_fc_props.phone = 'Phone';
_fc_props.january = 'January';
_fc_props.february = 'February';
_fc_props.march = 'March';
_fc_props.april = 'April';
_fc_props.may = 'May';
_fc_props.june = 'June';
_fc_props.july = 'July';
_fc_props.august = 'August';
_fc_props.september = 'September';
_fc_props.october = 'October';
_fc_props.november = 'November';
_fc_props.december = 'December';
_fc_props.are_you_sure_you_want_to_delete = 'Are you sure you want to delete';
_fc_props.unknown = 'Unknown';
_fc_props.select_all = 'Select All';
_fc_props.equipment = 'Equipment';
_fc_props.region = 'Region';
_fc_props.no_data = 'No Data';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Address';
_fc_props.retrieving_address = 'Retrieving address...';
_fc_props.address_not_found = 'Address not found';
_fc_props.active_time = 'Active Time';
_fc_props.destination_time = 'Destination Time';
_fc_props.complete_time = 'Complete Time';
_fc_props.status_report = 'Status Report';
_fc_props.export_report = 'Export Report';
_fc_props.download_report = 'Download Report';
_fc_props.view_report = 'View Report';
_fc_props.duration = 'Duration';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Start Date/Time';
_fc_props.select_start_date = 'Select Start Date';
_fc_props.end_date_time = 'End Date/Time';
_fc_props.select_end_date = 'Select End Date';
_fc_props.trip_time = 'Trip Time';
_fc_props.travel_time = 'Travel Time';
_fc_props.description = 'Description';
_fc_props.map_controls = 'Map Controls';
_fc_props.road = 'Road';
_fc_props.aerial = 'Aerial';
_fc_props.hybrid = 'Hybrid';
_fc_props.trip = 'Trip';
_fc_props.map = 'Map';
_fc_props.plant = 'Plant';
_fc_props.select_date = 'Select Date';
_fc_props.save_settings = 'Save Settings';
_fc_props.false = 'False';
_fc_props.delete_confirmation = 'Delete Confirmation';
_fc_props.last_name = 'Last Name';
_fc_props.none_selected = 'None Selected';
_fc_props.start_date = 'Start Date';
_fc_props.group_by = 'Group By';
_fc_props.equipment_employee = 'Equipment / Employee';
_fc_props.inactive = 'Inactive';
_fc_props.active = 'Active';
_fc_props.note_checking_may_affect_performance = 'Note: Checking may affect performance';
_fc_props.show_distancefuel_used = 'Show Distance/Fuel Used';
_fc_props.mins = 'Mins';
_fc_props.threshold_time = 'Threshold Time';
_fc_props.include_voided_tickets = 'Include Voided Tickets';
_fc_props.note_all_times_listed_are_in_minutes = 'Note: All times listed are in minutes';
_fc_props.please_select_a_job_order_or_equipment = 'Please select a Job, Order, Plant, Equipment or Employee';
_fc_props.device = 'Device';
_fc_props.select_equipment = 'Select Equipment';
_fc_props.all_equipment_types = 'All Equipment Types';
_fc_props.units = {};
_fc_props.units.liters = 'Liters';
_fc_props.type = 'Type';
_fc_props.no_data_available = 'No data available';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Distance and Fuel by Region';
_fc_props.menu_settings_title_settings = 'Settings';
_fc_props.menu_settings_title_alerts = 'Alerts';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Equipment';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots & Regions';
_fc_props.days = '{0} day(s)';
_fc_props.home = 'Home';
_fc_props.find = 'Find';
_fc_props.close_this_panel = 'close this panel';
_fc_props.start = 'Start';
_fc_props.end = 'End';
_fc_props.location_details = 'Location Details';
_fc_props.first_name = 'First Name';
_fc_props.material_per_hour = 'Material Per Hour';
_fc_props.equipment_pound = 'Equipment #';
_fc_props.equipment_type = 'Equipment Type';
_fc_props.options = 'Options';
_fc_props.zoom_on_cursor_position = 'Zoom On Cursor Position';
_fc_props.display_equipment_identifier = 'Display Equipment Identifier';
_fc_props.view_equipment = 'View Equipment';
_fc_props.view_hotspots = 'View Hotspots';
_fc_props.view_regions = 'View Regions';
_fc_props.use_standard_filters = 'Use Standard Filters';
_fc_props.home_plant = 'Home Plant';
_fc_props.last_plant = 'Last Plant';
_fc_props.equip_types = 'Equip. Types';
_fc_props.equip_groups = 'Equip. Groups';
_fc_props.orders = 'Orders';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Use Equipment Filter';
_fc_props.all_selected = 'All Selected';
_fc_props.of = 'Of';
_fc_props.your_map_session_has_expired = 'Your Map Session has expired, Please Refresh the page.';
_fc_props.equipment_short = 'Equip.';
_fc_props.job_info_for = 'Job Info for';
_fc_props.order_info_for = 'Order Info for';
_fc_props.ticket_info_for_vehicle = 'Ticket Info for Vehicle';
_fc_props.inbox = 'Inbox';
_fc_props.outbox = 'Outbox';
_fc_props.alerts = 'Alerts';
_fc_props.critical = 'Critical';
_fc_props.important = 'Important';
_fc_props.informational = 'Informational';
_fc_props.view_routes = 'View Routes';
_fc_props.hours_size = 'Hours Size';
_fc_props.used_count = 'Used Count';
_fc_props.last_login = 'Last Login';
_fc_props.last_vehicle = 'Last Vehicle';
_fc_props.regions = 'Regions';
_fc_props.driver = 'Driver';
_fc_props.js_delete = 'Delete';
_fc_props.move = 'Move';
_fc_props.edit_hotspot = 'Edit Hotspot';
_fc_props.adjust_shape = 'Adjust Shape';
_fc_props.edit_region = 'Edit Region';
_fc_props.enter_a_start_address = 'Please enter a start Address';
_fc_props.select_a_start_hotspot = 'Please select a start Hotspot';
_fc_props.failed_to_find_start_hotspot = 'Failed to find start Hotspot.';
_fc_props.invalid_latitudelongitude = 'Invalid Latitude/Longitude';
_fc_props.enter_an_end_address = 'Please enter an end Address';
_fc_props.select_an_end_hotspot = 'Please select an end Hotspot';
_fc_props.failed_to_find_end_hotspot = 'Failed to find end Hotspot.';
_fc_props.print = 'Print';
_fc_props.these_directions_are_for_informational_purposes = 'These directions are for informational purposes only. No guarantee is made regarding their completeness or accuracy. Construction projects, traffic, or other events may cause actual conditions to differ from these results.';
_fc_props.enter_an_address = 'Please enter an Address';
_fc_props.select_a_hotspot = 'Please select a Hotspot';
_fc_props.failed_to_find_hotspot = 'Failed to find Hotspot.';
_fc_props.select_a_vehicle = 'Please select a Vehicle';
_fc_props.failed_to_find_vehicle = 'Failed to find Vehicle.';
_fc_props.failed_to_find_address = 'Failed to find address';
_fc_props.failed_to_find_vehicles = 'Failed to find vehicles in search criteria.';
_fc_props.get_address = 'Get Address';
_fc_props.results_for = 'Results for';
_fc_props.error_retrieving_results = 'Error Retrieving Results';
_fc_props.no_results_found = 'No Results Found';
_fc_props.address_is_required = 'Address is Required';
_fc_props.city_is_required = 'City is Required';
_fc_props.set_location = 'Set Location';
_fc_props.set_area_of_interest = 'Set Area of Interest';
_fc_props.center_map_here = 'Center Map Here';
_fc_props.zoom_to_street_level = 'Zoom to Street Level';
_fc_props.set_start = 'Set Start';
_fc_props.set_end = 'Set End';
_fc_props.delete_node = 'Delete Node';
_fc_props.create_a_hotspot = 'Create a Hotspot';
_fc_props.create_a_region = 'Create a Region';
_fc_props.toggle_shapes = 'Toggle Shapes';
_fc_props.refresh_map = 'Refresh Map';
_fc_props.more_options = 'More Options';
_fc_props.recenter_map = 'Recenter Map';
_fc_props.zoom_to_vehicle = 'Zoom to Vehicle';
_fc_props.apply_filters = 'Apply Filters';
_fc_props.grid_view = 'Grid View';
_fc_props.list_view = 'List View';
_fc_props.click_to_sort = 'Click to sort';
_fc_props.message_history = 'Message History';
_fc_props.equipment_types = 'Equipment Types';
_fc_props.send_message = 'Send Message';
_fc_props.last_load = 'Last Load';
_fc_props.last_load_on = 'Last Load On';
_fc_props.manual_logout = 'Manual Logout';
_fc_props.manual_login = 'Manual Login';
_fc_props.no_employees_logged_in = 'No Employees Logged In';
_fc_props.select_phone = 'Select Phone';
_fc_props.you_must_select_a_phone = 'You must select a Phone, Equipment and Employee';
_fc_props.are_you_sure_you_want_to_logout = 'Are you sure you want to logout';
_fc_props.fix_time = 'Fix Time';
_fc_props.to_next_fix = 'to Next Fix';
_fc_props.minutes_to_next_fix = 'Minutes to Next Fix';
_fc_props.liters = 'Liters';
_fc_props.destination = 'Destination';
_fc_props.return = 'Return';
_fc_props.product = 'Product';
_fc_props.no_records_found = 'No records found.';
_fc_props.vehicle_type = 'Vehicle Type';
_fc_props.view_in_map = 'View All In Map';
_fc_props.permanent = 'Permanent';
_fc_props.expire_time = 'Expire Time';
_fc_props.please_select_x_for_tickets_marked_active = 'Please Select {0} for Tickets marked ACTIVE';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Multiple Tickets marked ACTIVE for same {0}';
_fc_props.failure_connecting_to_servlet = 'Failure connecting to servlet.';
_fc_props.status_time_summary = 'Status Time Summary';
_fc_props.view_in_map_one = 'View in Map';
_fc_props.question_report = 'Question Report';
_fc_props.end_date = 'End Date';
_fc_props.trailer_number = 'Trailer Number';
_fc_props.region_panel = 'Region Panel';
_fc_props.hotspot_panel = 'Hotspot Panel';
_fc_props.shape = 'Shape';
_fc_props.circle = 'Circle';
_fc_props.polygon = 'Polygon';
_fc_props.click_the_map_to_set_the_location = 'Click the map to set the desired location.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Click the map to set the hotspot center.';
_fc_props.click_the_map_to_set_the_region_center = 'Click the map to set the region center.';
_fc_props.click_and_drag_points = 'Click and drag points to edit the shape. Click SAVE to save changes.';
_fc_props.size = 'Size';
_fc_props.are_you_sure_you_want_to_move_this = 'Are you sure you want to move this';
_fc_props.are_you_sure_you_want_to_delete_this = 'Are you sure you want to delete this';
_fc_props.please_enter_a_valid_size = 'Please enter a valid size';
_fc_props.failed_to_save = 'Failed to save.';
_fc_props.failed_to_move = 'Failed to move.';
_fc_props.failed_to_delete = 'Failed to delete.';
_fc_props.x_hours = '{0} hours';
_fc_props.hide_hotspots = 'Hide Hotspots';
_fc_props.show_regions = 'Show Regions';
_fc_props.show_hotspots = 'Show Hotspots';
_fc_props.hide_regions = 'Hide Regions';
_fc_props.numeric = 'Numeric';
_fc_props.message_type = 'Message Type';
_fc_props.text = 'Text';
_fc_props.yes_no = 'Yes/No';
_fc_props.responses = 'Responses';
_fc_props.disabled = 'Disabled';
_fc_props.group_name = 'Group Name';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Are you sure you want to delete the \'{0}\' format?';
_fc_props.phone_number = 'Phone Number';
_fc_props.interface_pound = 'Interface #';
_fc_props.field = 'Field';
_fc_props.default_label = 'Default Label';
_fc_props.custom_label = 'Custom Label';
_fc_props.you_must_enter_a_label = 'You must enter a Label.';
_fc_props.enabled = 'Enabled';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Ticket Integration';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence off open Hotspots';
_fc_props.settings.associate_open_hotspots_wticket = 'Associate Open Hotspots w/Ticket';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Allow geofencing off of open hotspots or only hotspots associated with Driver\'s Ticket';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Whether or not to associate open Hotspots with Tickets when a vehicle enters them';
_fc_props.settings.void_ticket_without_destination = 'Void ticket without destination';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'if a ticket is received while another one is active, if the active ticket does not have a destination the ticket will be voided.';
_fc_props.settings.autocreate_hotspots = 'Auto-create Hotspots';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Whether or not to automatically create hotspots from the destination address when a ticket is received';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Auto-activate Ticket Upon Receive';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Is the ticket active when it is received by TrackIt (Default \'true\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Auto Close Active Ticket Upon Receive Minutes';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'How long does the current active ticket need to have been active before auto-closing it (Default \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Ticket Max Activate Age Minutes';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'How old in minutes can a ticket be when activating it in TrackIt (Default \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Max Hotspot Unused Days';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Sets the max days a Hotspot can be unused and still be revived';
_fc_props.settings.radius_multiplier = 'Radius Multiplier';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'What to multiply the radius by when a ticket is received from the dispatch system';
_fc_props.settings.ticket_message_date_format = 'Ticket Message Date Format';
_fc_props.am = 'am';
_fc_props.pm = 'pm';
_fc_props.settings.ticket_message = 'Ticket Message';
_fc_props.settings.add_ticket_element = 'Add Ticket Element';
_fc_props.driver_performance = 'Driver Performance';
_fc_props.update_failed_can_not_connect = 'Update Failed: Cannot connect to servlet.';
_fc_props.unknown_error = 'Unknown Error';
_fc_props.selected = 'Selected';
_fc_props.web_service = 'Web Service';
_fc_props.error_processing_request = 'Error processing request.';
_fc_props.email_address = 'Email Address';
_fc_props.id = 'ID';
_fc_props.last_used = 'Last Used';
_fc_props.error_invalid_vehiclenum = 'Error Invalid Vehicle Number ';
_fc_props.error_vehicle_is_not_logged_in = 'Error: Vehicle is not logged in.';
_fc_props.users = 'Users';
_fc_props.you_dont_have_access_to_this_page = 'You do not have access to view this page.  Please contact your system administrator for access.';
_fc_props.previous_week = 'Previous Week';
_fc_props.status_breakdown = 'Status Breakdown';
_fc_props.select_plant_or_employee_group = 'You must select either a plant or an employee group.';
_fc_props.cannot_select_plant_and_employee_group = 'You cannot select both a plant and an employee group.';
_fc_props.odometer_must_be_a_number = 'Beginning Odometer must be a number';
_fc_props.ending_odometer_must_be_a_number = 'Ending Odometer must be a number';
_fc_props.beginning_hour_meter_must_be_a_number = 'Beginning Hour Meter must be a number';
_fc_props.ending_hour_meter_must_be_a_number = 'Ending Hour Meter must be a number';
_fc_props.error_loading_status_change_page = 'Error loading Status Change page.';
_fc_props.x_is_an_invalid_time_format = '{0} is an invalid time format it must be YYYY-MM-DD HH:MM:SS';
_fc_props.x_for_schedule_login = '{0} for schedule login';
_fc_props.x_for_schedule_logout = '{0} for schedule logout';
_fc_props.x_for_actual_login = '{0} for actual login';
_fc_props.x_for_actual_logout = '{0} for actual logout';
_fc_props.by_refreshing_the_time_card_detail = 'By refreshing the Time Card Detail you will lose all changes, continue?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Are you sure you want to delete {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Command Mobile Ticket URL';
_fc_props.year = 'Year';
_fc_props.make = 'Make';
_fc_props.model = 'Model';
_fc_props.settings.command_mobile_ticket_password = 'Command Mobile Ticket Password';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command Mobile Ticket Auth Token';
_fc_props.target = 'Target';
_fc_props.number_of_digits = 'Number of Digits';
_fc_props.number_of_integer_digits = 'Number of Integer Digits';
_fc_props.number_of_fractional_digits = 'Number of Fractional Digits';
_fc_props.search = 'Search';
_fc_props.settings.command_mobile_ticket_date_order = 'Command Mobile Ticket Date Order';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Mobile Ticket Date Length';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Mobile Ticket Date Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (slash)';
_fc_props.settings.command_mobile_ticket_dash = '- (dash)';
_fc_props.settings.command_mobile_ticket_period = '. (period)';
_fc_props.settings.command_mobile_ticket_space = '(space)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command Mobile Ticket Time Length';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Time Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (colon)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command Mobile Ticket Time Format';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Use Interface Number';
_fc_props.mechanic = 'Mechanic';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Poll Interval (seconds)';
_fc_props.driving_time = 'Driving Time';
_fc_props.find_near_plants = 'Find Near Plants';
_fc_props.please_create_hot_spot_first = 'Please create hotspot first';
_fc_props.please_enter_hot_spot_description = 'Please enter hot spot description';
_fc_props.please_select_a_hot_spot_type = 'Please select a hot spot type';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Send Driver Email';
_fc_props.directions_not_found = 'Invalid {0} Address. Directions Not Found.';
_fc_props.ws_user = 'User';
_fc_props.ws_service = 'Service';
_fc_props.ws_method = 'Method';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Origin and Destination';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Help';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Use Alt Employee Number';
_fc_props.settings.command_mobile_ticket_android_url = 'Android Mobile Ticket URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Ticket Secret';
_fc_props.employee_x_has_no_logout_on_y = 'Employee {0} has no logout for a shift starting on {1}.';
_fc_props.phone_number_has_duplicates = 'Phone numbers have duplicate entries';
_fc_props.ounce = 'Ounce';
_fc_props.water_extra = 'Water Extra';
_fc_props.always = 'Always';
_fc_props.work_types_must_be_unique = 'Work Types must be unique';
_fc_props.manufacturer = 'Manufacturer';
_fc_props.need_to_enter_seconds = 'Need to enter seconds.';
_fc_props.copy = 'Copy';
_fc_props.create_in_existing_required = 'Create in existing must be selected to create roaming hotspot.';
_fc_props.break = 'Break';
_fc_props.add_export_data_type = 'Add Export Data Type';
_fc_props.edit_export_data_type = 'Edit Export Data Type';
_fc_props.delete_export_data_type = 'Delete Export Data Type';
_fc_props.export_data_type = 'Export Data Type';
_fc_props.error_updating_export_data_type = 'Error Updating Export Data Type';
_fc_props.settings.address_key = 'Address Key';
_fc_props.settings.address_key_hover = 'Address Key Options';
_fc_props.settings.address_key_options = 'Address Key Options';
_fc_props.more_filter_options = 'More Filter Options';
_fc_props.unauthorized_mapit_app = 'This version of MapIt is not authorized, please update MapIt as soon as possible.';
_fc_props.zello_timeout = 'Timeout occurred contacting Zello.';
_fc_props.user_agreement_text = 'By logging in, you agree to the';
_fc_props.user_agreement = 'user agreement';
_fc_props.x_mins_left = '{0} min(s) left';
_fc_props.continuous_location_tracking_colon = 'Continuous Location Tracking:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Hauler cannot register new device.';
_fc_props.timecard_does_not_contain_shift = 'Employee Time Card doesn\'t contain the shift (Time Card Info) provided';
_fc_props.timecard_not_found = 'Employee Time Card not found';
_fc_props.vehicle_must_be_assigned_for_clt = 'Vehicle must be assigned to track locations.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Vehicle was unassigned continuous tracking will be disabled.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Lost connection with EDC. Please check your WiFi and/or Bluetooth are enabled.';
_fc_props.acknowledge_all = 'Acknowledge All';
_fc_props.expand = 'Expand';
_fc_props.collapse = 'Collapse';
_fc_props.expand_all = 'Expand All';
_fc_props.collapse_all = 'Collapse All';
_fc_props.last_update = 'Last Update';
_fc_props.acknowledge_alert = 'Acknowledge Alert';
_fc_props.acknowledge_message = 'Acknowledge Message';
_fc_props.acknowledge_all_alerts = 'Acknowledge All Alerts';
_fc_props.acknowledge_all_messages = 'Acknowledge All Messages';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Tolerance must be a valid integer between -99 and 99 (both included)';
_fc_props.gps_permission_required = 'GPS Permission Required';
_fc_props.permissions_required = 'Permissions Required';
_fc_props.change_user = 'Change User';
_fc_props.account_not_registered_with_carrier = 'This account is not registered with a carrier';
_fc_props.slump_loss = 'Slump Loss';
_fc_props.slump_loss_help = 'Slump Loss Help';
_fc_props.did_not_receive_any_edc_devices = 'Did not receive any EDC devices.';
_fc_props.edc_record_not_found = 'EDC record was not found';
_fc_props.disable_hotspot_prompt = 'Please tap "OK" when prompted to disable your hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} is in use.';
_fc_props.an_unknown_exception_has_occurred = 'An unknown exception has occurred';
_fc_props.login_has_timed_out = 'Login has timed out';
_fc_props.use_paper_logs = 'Use Paper Logs';
_fc_props.eld_login_failed = 'Hours of Service login failed';
_fc_props.setup_time_off_type = 'Please setup a time off type.';
_fc_props.view_load_properties = 'View Load Properties';
_fc_props.couldnt_download_edc_firmware = 'Couldn\'t download EDC firmware.';
_fc_props.password_incorrect = 'Password incorrect';
_fc_props.edc_configuration_password_prompt = 'Due to the state of this device you must provide the installer password to configure.';
_fc_props.edc_configuration_check_failed = 'EDC server records validation failed. Please check error messages for details.';
_fc_props.configuration_issues_prompt = 'The following issues were detected while configuring the selected EDC for the specified truck';
_fc_props.checking_password = 'Checking password';
_fc_props.reset_password_instructions_sent = 'Instructions to reset your password sent to email address {0}.';
_fc_props.reset_password_failed = 'Reset password failed, try again.';
_fc_props.forgot_password = 'Forgot Password?';
_fc_props.enter_valid_email = 'Invalid email address, please enter a valid email address and try again.';
_fc_props.issues_while_updating_edc_record = 'The EDC was configured successfully. However there are issues with the records for the EDC on the TrackIt server that may cause it to not function. Please call support to correct the information.';
_fc_props.sending = 'Sending...';
_fc_props.minimum_ratio = 'Minimum Ratio';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Drum RPM not in range';
_fc_props.probe_failure = 'Probe failure';
_fc_props.unknown_probe_data_failure = 'Unknown probe data failure';
_fc_props.no_loaded_status_meaning = 'No Loaded Status';
_fc_props.no_depart_status_meaning = 'No Departure Status';
_fc_props.no_arrive_status_meaning = 'No Arrival Status';
_fc_props.no_begin_work_status_meaning = 'No Begin Work Status';
_fc_props.no_fully_mixed_status_meaning = 'No Fully Mixed Status';
_fc_props.no_begin_work_or_at_job_status = 'Begin Work or At Job statuses not present';
_fc_props.driver_didnt_follow_procedure = 'Driver did not follow procedure (Not slowing down drum)';
_fc_props.probe_malfunction = 'Probe malfunction (Buildup, Internal Failure, etc.)';
_fc_props.no_data_from_probe = 'No data from probe (Data loss)';
_fc_props.missing_probe_calibration = 'No Probe Calibration Data';
_fc_props.probe_pressure_not_in_range = 'Probe pressure not in range';
_fc_props.arrival = 'Arrival';
_fc_props.small_load = 'SMALL LOAD';
_fc_props.dry_slump = 'DRY SLUMP';
_fc_props.probe_remixing_turns = 'Remixing';
_fc_props.turns_needed_to_remix = 'Water Added - Additional mixing needed';
_fc_props.probe_remixing_finished = 'Remixing finished';
_fc_props.probe_battery_low_alert = 'Probe Battery Low Alert';
_fc_props.probe_battery_low_message = 'Probe Battery Low';
_fc_props.probe_battery_low = 'Probe Battery Low';
_fc_props.alert_when_probe_battery_low = 'Alert when probe battery is low';
_fc_props.probe_sensor_fatal_failure = 'Sensor fatal failure';
_fc_props.probe_thermistor_failure = 'Thermistor failure';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor stuck in concrete';
_fc_props.probe_sensor_over_weight = 'Sensor over weight';
_fc_props.probe_batteries_low = 'Battery low';
_fc_props.no_probe_data = 'No Probe Data:';
_fc_props.check_probe = 'Check Probe';
_fc_props.service_probe = 'Service Probe';
_fc_props.below_x = 'Below {0}';
_fc_props.above_x = 'Above {0}';
_fc_props.probe_serial_number_invalid_format = 'Probe Serial Number is not in valid format as displayed in placeholder for the field';
_fc_props.talk_dnd_permission_prompt = 'Talk requires DO NOT DISTURB permission.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Status Changed Too Quickly';
_fc_props.is_empty = 'Is Empty';
_fc_props.slump_increased_plasticizer_question = 'Was plasticizer added?';
_fc_props.slump_increased_water_added_question = 'How much unmetered water was added?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Probe and Delivery Cycle Monitoring are not supported together on the same device.';
_fc_props.no_data_from_sensor = 'No data from sensor (Data loss)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'System Type';
_fc_props.magnet_count = 'Magnet Count';
_fc_props.sensors_setup = 'Sensors Setup';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM requires I/O Box MAC Address, Drum Type, DCM Type, & Magnet Count to be set.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Ticketed Volume';
_fc_props.drs_current_volume = 'Current Volume';
_fc_props.drs_current_water_cement_ratio = 'Current Water/Cement Ratio';
_fc_props.drs_target_water_cement_ratio = 'Target Water/Cement Ratio';
_fc_props.drs_total_revolutions = 'Total';
_fc_props.drs_current_volume_lp_small = 'Current';
_fc_props.drum_revolutions = 'Drum Revolutions';
_fc_props.drs_total_revolutions_lp_small = 'Total';
_fc_props.drs_charging_revolutions_lp_small = 'Charging';
_fc_props.drs_discharging_revolutions_lp_small = 'Discharging';
_fc_props.drs_target_wc_lp_small = 'Target W/C';
_fc_props.drs_current_wc_lp_small = 'Current W/C';
_fc_props.drs_ticketed_volume_lp_small = 'Ticketed';
_fc_props.android_location_disclosure = 'TrackIt uses fine and coarse location to collect location data in the background even while not in use, to enable auto-statusing and real-time map visibility for dispatch.';
_fc_props.android_location_usage = 'Location Disclosure';
_fc_props.end_pour_trigger_threshold = 'End Pour Trigger Threshold';
_fc_props.drs_wiring_error = 'Wiring Error';
_fc_props.drs_wiring_pulse_error = 'Wiring Pulse Error';
_fc_props.drs_wiring_magnet_error = 'Wiring Magnet Error';
_fc_props.drs_magnet_missing_error = 'Magnet Missing Error';
_fc_props.android_location_disclosure_3p = 'TrackIt collects location data in the background, to enable auto-statusing and real-time map visibility for dispatch. When clocked out from shipper, location is not shared.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Please select either Full MDM or Trackit Remote Support';
_fc_props.move_up = 'Move Up';
_fc_props.move_down = 'Move Down';
_fc_props.driver_not_found = 'Driver not found.';
_fc_props.multiple = 'Multiple';
_fc_props.water_meter_installed = 'Water Meter Installed';
_fc_props.delete_waypoint = 'Delete Waypoint';
_fc_props.wash_sensor_installed = 'Wash Sensor Installed';
_fc_props.washing_start_time = 'Washing Start Time';
_fc_props.end_washing = 'End Washing';
_fc_props.variable_rpms = 'Var RPMs';
_fc_props.no_slump = 'No Slump';
_fc_props.end_washing_duration = 'End Washing Duration';
_fc_props.privacy_policy = 'Privacy Policy';
_fc_props.privacy_policy_text = 'I have read and accept the';
_fc_props.login_fail_accept_privacy_policy = 'Privacy Policy must be Accepted to proceed.';
_fc_props.rotation_sensor = 'Rotation Sensor';
_fc_props.pto_sensor = 'PTO Sensor';
_fc_props.pto_sensor_installed = 'PTO Sensor Installed';
_fc_props.polarity_reversed = 'Reverse Polarity';
_fc_props.pto_sensor_type = 'PTO Sensor Type';
_fc_props.sensor_type = 'Sensor Type';
_fc_props.prompt_select_pto_sensor_type = 'You must select a "PTO Sensor Type" if you\'ve selected a "PTO Sensor" as your main sensor type.';
_fc_props.priming_turns_at_full_load = 'Priming turns at Full Load';
_fc_props.priming_turns_at_ten_percent_load = 'Priming turns at 10% load';
_fc_props.weight_units = 'Weight Units';
_fc_props.settings.send_status = 'Send Status/Location To Event Bridge';
_fc_props.volume_difference = 'Volume Difference';
_fc_props.mobile_ticket_iframe_url = 'Mobile Ticket IFrame URL';
_fc_props.mobile_ticket_user = 'Mobile Ticket User';
_fc_props.mobile_ticket_password = 'Mobile Ticket Password';
_fc_props.current_truck_status = 'Current Truck Status';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Triggered by max revolutions';
_fc_props.alerts_by_type = 'Alerts by Type';
_fc_props.water_flow_meter = 'Water Flow Meter';
_fc_props.flow_meter_no_pulses_warning = 'No Pulses - Warning';
_fc_props.flow_meter_no_pulses_critical = 'No Pulses - Critical';
_fc_props.flow_meter_excessive_pulses = 'Excessive Pulses';
_fc_props.flow_meter_constantly_flowing = 'Constantly Flowing';
_fc_props.flow_meter_good = 'Good';
_fc_props.whats_new = 'What\'s New';
_fc_props.whats_new_widget_text = 'Did you know we update TrackIt at least once a month? Get the latest updates and tips.';
_fc_props.get_the_scoop = 'Get the Scoop';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Business analytics that improve your bottom line, now included with TrackIt';
_fc_props.find_out_more = 'Find out more';
_fc_props.daily_breakdown = 'Daily Breakdown';
_fc_props.question_summary = 'Question Summary';
_fc_props.trip_summary = 'Trip Summary';
_fc_props.manage_employees = 'Manage Employees';
_fc_props.manage_hotspots = 'Manage Hotspots';
_fc_props.manage_devices = 'Manage Devices';
_fc_props.manage_equipment = 'Manage Equipment';
_fc_props.manage_users = 'Manage Users';
_fc_props.manage_statuses = 'Manage Statuses';
_fc_props.manage_alerts = 'Manage Alerts';
_fc_props.popular_report_links = 'Popular Report Links';
_fc_props.popular_settings_links = 'Popular Settings Links';
_fc_props.sidemenu_settings = 'Settings';
_fc_props.loads_per_driver = 'Loads Per Driver';
_fc_props.please_select_application = 'Please select at least one application';
_fc_props.external_app_processing = 'Processing on External App';
_fc_props.external_app_sent = 'Sent to External App';
_fc_props.external_app_received = 'Received by External App';
_fc_props.wait_time_at_job = 'Wait Time at Job';
_fc_props.wait_time_at_plant = 'Wait Time at Plant';
_fc_props.loading_time = 'Loading Time';
_fc_props.unloading_time = 'Unloading Time';
_fc_props.pre_post_trip = 'Pre/Post Trip';
_fc_props.update_thresholds = 'Update Thresholds';
_fc_props.green = 'Green';
_fc_props.yellow = 'Yellow';
_fc_props.red = 'Red';
_fc_props.between = 'Between';
_fc_props.num_of_loads = '# of Loads';
_fc_props.num_of_drivers = '# of Drivers';
_fc_props.status_meaning_not_setup = 'Status Meaning not setup';
_fc_props.or_less = 'Or Less';
_fc_props.or_greater = 'Or Greater';
_fc_props.probe_self_diagnostic_state = 'Probe Self Diagnostic State';
_fc_props.update_current_truck_status_settings = 'Update Current Truck Status Settings';
_fc_props.default_behavior = 'Default (Top 10 Statuses)';
_fc_props.combine_similar_statuses = 'Combine Same Statuses from All Types (Loops)';
_fc_props.use_other_category = 'Use \'Other\' Category';
_fc_props.selected_statuses = 'Selected Statuses';
_fc_props.ideas = 'Ideas';
_fc_props.air_content = 'Air Content';
_fc_props.air_measured_time = 'Air Measured Time';
_fc_props.ten_max = '10 Max';
_fc_props.loads_per_plant = 'Loads Per Plant';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Invalid Flow meter pulse per volume.';
_fc_props.coming_soon_title = 'More Widgets Coming Soon';
_fc_props.submit_your_ideas = 'Submit your ideas to our Ideas Portal using the Light Bulb on your left Navigation Menu.';
_fc_props.selected_plants = 'Selected Plants';
_fc_props.update_loads_per_plant_settings = 'Update Loads Per Plant Settings';
_fc_props.default_top_10_plants = 'Default (Top 10 Plants)';
_fc_props.phone_number_will_be_uploaded = 'Device ID will be uploaded for registration purposes.';
_fc_props.include_deleted = 'Include Deleted';
_fc_props.primary_status_type = 'Primary Status Type';
_fc_props.additional_status_trigger = 'Additional Status Triggers';
_fc_props.update_tickets_destination = 'Update destination on all associated tickets';
_fc_props.settings.send_destination_changes_to_connex = 'Send destination changes to Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Is the ticket active when it is received by TrackIt (Default \'always\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Auto-activate Next Ticket on Previous Ticket Completion';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Should auto-activate next ticket on ticket completion (Default \'true\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status to idle status when no active ticket';
_fc_props.break_down = 'Break Down';
_fc_props.idle = 'Idle';
_fc_props.pto_work_maximum_speed_limit = 'Work Maximum Speed Limit';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Associate status change that completes a ticket with that ticket';
_fc_props.approaching = 'Approaching';
_fc_props.performing_task = 'Performing Task';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Are you sure you want to delete Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Update ticket {0}.  Destination changed.';
_fc_props.trackit_return_destination_changed_to_0 = 'Update ticket {0}.  Return changed.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Add Geogate';
_fc_props.driver_enabled = 'Driver enabled';
_fc_props.dispatcher_enabled = 'Dispatcher enabled';
_fc_props.runsheet_number = 'Runsheet Number';
_fc_props.manual_load = 'Manual Load';
_fc_props.edit_geogate = 'Edit Geogate';
_fc_props.handling = 'Handling';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate Panel';
_fc_props.vehicle_is_not_logged_in = 'Message not sent (vehicle is not logged in)';
_fc_props.air = 'Air';
_fc_props.create_a_geogate = 'Create a Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Click the map to set the geogate points.';
_fc_props.clock_out = 'Clock Out';
_fc_props.handling_operation = 'Handling Operation';
_fc_props.view_geogates = 'View Geogates';
_fc_props.select_a_geogate = 'Please select a Geogate';
_fc_props.failed_to_find_geogate = 'Failed to find Geogate';
_fc_props.adjust_geogate = 'Adjust Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Expiration Date Time Default Extra Days';
_fc_props.menu_settings_title_retention = 'Data Retention';
_fc_props.category = 'Category';
_fc_props.telemetry = 'Telemetry';
_fc_props.ticket_data = 'Ticket Data';
_fc_props._30_days = '30 Days';
_fc_props._6_months = '6 Months';
_fc_props._1_year = '1 Year';
_fc_props._3_years = '3 Years';
_fc_props.new_group = 'New Group';
_fc_props.selected_vehicles = 'Selected vehicles';
_fc_props.group_name_is_required_field = 'Group name is required field';
_fc_props.at_least_vehicle_should_be_selected = 'At least 1 vehicle should be selected';
_fc_props.groups = 'Groups';
_fc_props.trucks = 'Trucks';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate has been removed. Please refresh page.';
_fc_props.air_timestamp = 'Air Timestamp';
_fc_props.fail = 'FAIL';
_fc_props.stuck = 'STUCK';
_fc_props.dirty = 'DIRTY';
_fc_props.seasonal = 'Seasonal';
_fc_props.category_details = 'Category Details';
_fc_props.alert_events = 'Alert Events';
_fc_props.driver_performance_infractions = 'Driver Performance Infractions';
_fc_props.telemetry_details = 'Location Data, Hotspots, Time in Hotspots, Time in Regions, DCM Data, Probe Data, Engine Data';
_fc_props.ticket_data_details = 'Ticket Data';
_fc_props.general_details = 'Status Changes, Timecards, Schedules';
_fc_props.wash = 'Wash';
_fc_props.filter_options = 'Filter Options';
_fc_props.home_plants = 'Home Plants';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO requires PTO Sensor Type to be set.';
_fc_props.invalid_io_box_mac_address = 'Invalid I/O Box Mac Address';
_fc_props.access_denied = 'Access denied';
_fc_props.wash_events = 'Wash Events';
_fc_props.min_speed = 'Min Speed';
_fc_props.temperature_change = 'Temperature Change';
_fc_props.degrees_cap = 'Degrees';
_fc_props.washout_tracking = 'Washout Tracking';
_fc_props.approaching_wash = 'Approaching Wash';
_fc_props.arrival_wash = 'Arrival Wash';
_fc_props.performing_wash = 'Performing Wash';
_fc_props.wash_complete = 'Wash Complete';
_fc_props.must_be_positive_or_zero = 'Must be positive or zero!';
_fc_props.min_speed_greater_than_max = 'Min Speed must be less than Max Speed!';
_fc_props.clock_in = 'Clock In';
_fc_props.mix_water_at_x_to_y_for_t = 'Mix water at {0} to {1} RPMs for {2} revolutions.';
_fc_props.discharge_water_for_x_revolutions = 'Discharge water for {0} revolutions.';
_fc_props.eod_drum_wash_complete = 'Drum Wash Complete! <br> Stop Drum with Probe at top.';
_fc_props.washing_complete = 'Guided Washout Complete';
_fc_props.eod_washout_no_data_available = 'Sensor data unavailable, please complete wash process.';
_fc_props.eod_washout_no_data_available_title = 'Guided Washout Unavailable';
_fc_props.menu_reports_title_ticket_time_summary = 'Ticket Time Summary';
_fc_props.menu_reports_description_ticket_time_summary = 'View ticket time information for a order or vehicle';
_fc_props.menu_reports_title_summary = 'Summary';
_fc_props.menu_reports_title_ticket_summary = 'Ticket Summary';
_fc_props.menu_reports_description_summary1 = 'View a summary of time in hotspots';
_fc_props.menu_dispatch_title_main = 'Main';
_fc_props.menu_dispatch_title_login_registry = 'Login Registry';
_fc_props.menu_reports_title_main = 'Main';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Are you sure you want to delete this export data type?';
_fc_props.dvir_status_paper_logs = 'Paper Logs';
_fc_props.menu_reports_title_billing_report = 'Billing Report';
_fc_props.menu_reports_description_billing_report = 'Billing Report';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status To Ticketed Status On Activate Work';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status To Ticketed Status On Activate Work (Default \'False\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Pounds';
_fc_props.units_weight_kilogram_label = 'Kilograms';
_fc_props.settings_weight = 'Weight';
_fc_props.units_pounds = 'Pounds';
_fc_props.units_kilograms = 'Kilograms';
_fc_props.geo_gate_edit_modal_description = 'Description';
_fc_props.geo_gate_edit_modal_start_point = 'Start Point';
_fc_props.geo_gate_edit_modal_end_point = 'End Point';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Equipment Type';
_fc_props.geo_gate_edit_modal_heading_south = 'South to North';
_fc_props.geo_gate_edit_modal_heading_north = 'North to South';
_fc_props.geo_gate_edit_modal_driver_message = 'Driver Message';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Dispatch Message';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Expiration Date Time';
_fc_props.geo_gate_edit_modal_permanent = 'Permanent';
_fc_props.geo_gate_edit_modal_critical = 'Critical';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Error saving Geogate: Description field must be populated.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Error saving Geogate: Start Point field must be populated.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Error saving Geogate: Start Point field contains invalid data format.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Error saving Geogate: End Point field contains invalid data format.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Error saving Geogate: Expiration Date Time field must be populated.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Error saving Geogate: Expiration Date Time field contains invalid data format.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Error saving Geogate: North to South - Dispatch Message field must be populated';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Error saving Geogate: No changes detected';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Error saving Geogate: Expiration Date Time field contains expired date-time';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Error saving Geogate: Description field must contains only letters and numbers';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Error saving Geogate: Equipment Type field must be populated';
_fc_props.geo_gate_table_message_label_north_to_south = 'North to South';
_fc_props.geo_gate_table_message_label_south_to_north = 'South to North';
_fc_props.geo_gate_table_message_label_both = 'Both';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Latitude, Longitude';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Checking this will override the setup for device movement message restriction';
_fc_props.geo_gate_edit_modal_seasonal = 'Seasonal';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Error saving Geogate: Seasonal dates must be populated';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Error saving Geogate: Seasonal Date fields contain invalid data format';
_fc_props.test_js_test_javascript = 'Test Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometers';
_fc_props.units_miles = 'Miles';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Error saving Geogate: South to North - Driver Message field must be populated';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Error saving Geogate: South to North - Dispatch Message field must be populated';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Error saving Geogate: North to South - Driver Message field must be populated';
_fc_props.geo_gate_heading = 'Heading';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Error saving Geogate: End Point field must be populated';
_fc_props.units_mile = 'Mile';
_fc_props.units_kilometer = 'Kilometer';
_fc_props.cloud_dvir__exit = 'Exit';
_fc_props.cloud_dvir__signature = 'Signature';
_fc_props.cloud_dvir__inspection = 'Inspection';
_fc_props.cloud_dvir__add_photos_notes = 'Add Photos/Notes';
_fc_props.cloud_dvir__odometer_reading = 'Odometer Reading';
_fc_props.cloud_dvir__engine_hours = 'Engine Hours';
_fc_props.cloud_dvir__truck_details = 'Truck Details';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Determine the overall condition of the vehicle';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Full name and signature of driver';
_fc_props.cloud_dvir__sign = 'Sign';
_fc_props.cloud_dvir__driver_note_from = 'Driver Note from';
_fc_props.cloud_dvir__add_notes = 'Add notes';
_fc_props.cloud_dvir__driver_notes = 'Driver Notes';
_fc_props.cloud_dvir__done = 'Done';
_fc_props.cloud_dvir__needs_repair = 'Needs Repair';
_fc_props.cloud_dvir__not_applicable = 'Not Applicable';
_fc_props.cloud_dvir__review_and_sign = 'Review & Sign';
_fc_props.cloud_dvir__add_photos = 'Add Photo';
_fc_props.cloud_dvir__upload_photo_limit = 'You can upload up to 3 pictures';
_fc_props.cloud_dvir__mismatch_title = 'Mismatch Alert';
_fc_props.cloud_dvir__ok = 'Ok';
_fc_props.cloud_dvir_template = 'Cloud DVIR Template';
_fc_props.cloud_dvir__needs_review = 'DVIR Needs Review';
_fc_props.cloud_dvir__submitted = 'Submitted';
_fc_props.cloud_dvir__completed_by = 'Completed by';
_fc_props.cloud_dvir__review_btn = 'RevIew';
_fc_props.cloud_dvir__truck_number = 'Truck {0}';
_fc_props.cloud_dvir__pending_review = 'Pending Review';
_fc_props.cloud_dvir__start_dvir = 'Start DVIR';
_fc_props.cloud_dvir__dvir_history = 'DVIR History';
_fc_props.cloud_dvir__view_all = 'View All';
_fc_props.load_zone = 'Load Zone';
_fc_props.view_load_zones = 'View Load Zones';
_fc_props.edit_load_zone = 'Edit Load Zone';
_fc_props.create_a_load_zone = 'Create a Load Zone';
_fc_props.load_zone_panel = 'Load Zone Panel';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Click the map to set the load zone center.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'This inspection has defects indicated and you have selected that this vehicle is safe to operate.  Please validate before proceeding';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'This inspection has no defects indicated and you have selected that this vehicle is unsafe to operate.  Please validate before proceeding.';
_fc_props._90_days = '90 Days';
_fc_props.cloud_dvir = 'Cloud DVIR';
_fc_props.cloud_dvir_details = 'Cloud DVIR submissions';
_fc_props.show_load_zones = 'show load zones';
_fc_props.hide_load_zones = 'hide load zones';
_fc_props.no = 'No';
_fc_props.cloud_dvir__reset = 'Reset';
_fc_props.cloud_dvir__clear = 'Clear';
_fc_props.mark_read = 'Mark Read';
_fc_props.mark_unread = 'Mark Unread';
_fc_props.read = 'Read';
_fc_props.unread = 'Unread';
_fc_props.air_sensor_serial_number = 'Air Sensor Serial #';
_fc_props.air_sensor_mac_address = 'Air Sensor MAC Address';
_fc_props.air_sensor_serial_number_invalid_format = 'Air Sensor Serial Number is not in valid format: (alphanumeric and at most 32 characters long with dashes)';
_fc_props.air_sensor_mac_address_invalid_format = 'Air Sensor MAC Address is not in valid hex format as displayed in placeholder for the field';
_fc_props.air_sensor_license = 'Air Sensor';
_fc_props.adjust_route_point = 'Adjust Route Point';
_fc_props.route_point_panel = 'Route Point Panel';
_fc_props.route_point = 'Route Point';
_fc_props.cloud_dvir__pre_trip = 'Pre Trip';
_fc_props.cloud_dvir__post_trip = 'Post Trip';
_fc_props.cloud_dvir__other_insction_type = 'Other';
_fc_props.cloud_dvir__dvir_completed = 'Completed';
_fc_props.cloud_dvir__awaiting_mechanic = 'Awaiting Mechanic';
_fc_props.cloud_dvir__awaiting_driver = 'Awaiting Driver Sign-off';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Unable to check air sensor serial number, contact support for additional assistance.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'This air sensor serial number already exists, please use another serial number';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Unable to check air sensor MAC address, please contact support for assistance';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Error submitting DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Please try again';
_fc_props.ticket_integration_descr = 'Ticket Integration allows to set ticket integration settings as well as Home Screen / Load Screen / Hard Mount Display';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR Successfully Submitted';
_fc_props.cloud_dvir__inspection_trip_type = 'Inspection Trip Type';
_fc_props.menu_dvir_title_mechanic = 'Mechanic';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Air Sensor Firmware';
_fc_props.when_no_active_ticket = 'When no active ticket';
_fc_props.used_by = 'Used By';
_fc_props.fields = 'Fields';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Are you sure you want to delete Ticket Layout "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Ticket Layout';
_fc_props.add_ticket_layout = 'Add Ticket Layout';
_fc_props.edit_ticket_layout = 'Edit Ticket Layout';
_fc_props.ticket_layout_edit_modal_name = 'Name';
_fc_props.ticket_layout_edit_modal_default = 'Default';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Error saving Ticket Layout: Name field must be populated.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Error saving Ticket Layout: Name field must contain only letters and numbers';
_fc_props.ticket_layout_edit_modal_fields = 'Fields';
_fc_props.ticket_layout_edit_modal_usage = 'Usage';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Ticket Layout has been removed. Please, refresh the page.';
_fc_props.more_lower_case = 'more';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Error saving Ticket Layout: One or more fields must be selected.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Error saving Ticket Layout: One or more statuses must be selected.';
_fc_props.cloud_dvir__ad_hoc = 'Ad Hoc';
_fc_props.radius_multiplier_should_be = 'Radius Multiplier should be a floating number';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minutes should be an integer.';
_fc_props.max_hotspot_unused_days_errs = 'Max Hotspot Unused Days should be an integer.';
_fc_props.begin_pour_air_content = 'Begin Pour Air Content';
_fc_props.arrival_air_content = 'Arrival Air Content';
_fc_props.fully_mixed_air_content = 'Fully Mixed Air Content';
_fc_props.departure_air_content = 'Departure Air Content';
_fc_props.popup_enabled = 'Popup Enabled';
_fc_props.popup_disabled = 'Popup Disabled';
_fc_props.cloud_dvir__leave_confirmation_title = 'Warning';
_fc_props.cloud_dvir__leave_confirmation_description = 'Exiting the page now will cause all changes to be lost.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Confirm';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Cancel';
_fc_props.air_bt_ratio = 'Air BT Ratio';
_fc_props.cloud_dvir__view_photos_notes = 'View Photos/Notes';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Adding the status will create a blank usage in another ticket layout. Would you like to proceed with the addition?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Vehicle does not have air sensor license.';
_fc_props.air_sensor_installed = 'Air Sensor Installed';
_fc_props.load_card = 'Load Card';
_fc_props.cloud_dvir__inspection_profile = 'Inspection Profile';
_fc_props.cloud_dvir__view_details = 'View Details';
_fc_props.cloud_dvir__certify_repairs = 'Certify Repairs';
_fc_props.cloud_dvir__repairs_made = 'Repairs Made';
_fc_props.cloud_dvir__repairs_not_necessary = 'Repairs Not Necessary';
_fc_props.cloud_dvir__mechanic_note_from = 'Mechanic Note From';
_fc_props.cloud_dvir__mechanic_assignment = 'Mechanic Assignment';
_fc_props.cloud_dvir__mechanic_signature_description = 'Mark all applicable items below and sign indicating that the vehicle has been properly inspected.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Condition of this vehicle is satisfactory';
_fc_props.cloud_dvir__defects_corrected_label = 'Defects have been corrected';
_fc_props.cloud_dvir__repair_not_required_label = 'Repair not required for safe operation of the vehicle';
_fc_props.safety = 'Safety';
_fc_props.cloud_dvir__awaiting_sign = 'Awaiting Sign';
_fc_props.cloud_dvir__dvir_review = 'DVIR Review';
_fc_props.settings.mobile_auto_start_navigation = 'Auto-start Navigation on Mobile';
_fc_props.cloud_dvir__edit = 'Edit';
_fc_props.delete_custom_label = 'Delete Custom Label';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Your version of TrackIt is out of date and some DVIR features may not work properly. Please update TrackIt before proceeding.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Something went wrong during signature upload. Please try again';
_fc_props.max_hotspot_unused_days_error = 'Max Hotspot Unused Days has a max of 1000 days.';
_fc_props.menu_dvir_title_admin_templates = 'Templates';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Admin';
_fc_props.cloud_dvir__admin_templates = 'Templates';
_fc_props.form_name = 'Form Name';
_fc_props.form_status = 'Form Status';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Download';
_fc_props.cloud_dvir__option_view = 'View';
_fc_props.cloud_dvir__inspectortype_driver = 'Driver';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'View/Edit/Create custom DVIR forms and submissions';
_fc_props.menu.dvir.description.mechanic = 'View/Edit actionable DVIR submissions';
_fc_props.not_in_use = 'Not in Use';
_fc_props.logged_in = 'logged in';
_fc_props.reviewing_driver = 'Reviewing Driver';
_fc_props.cloud_dvir__report_title = 'Driver’s Vehicle Inspection Report';
_fc_props.cloud_dvir__report_signed = 'signed';
_fc_props.cloud_dvir__report_page_counter = 'Page {0} of {1}';
_fc_props.cloud_dvir__report_defects = 'Defects';
_fc_props.cloud_dvir__report_comments = 'Comments';
_fc_props.cloud_dvir__report_inspection_table_title = 'Vehicle inspection';
_fc_props.cloud_dvir__report_driver_table_title = 'Driver information';
_fc_props.cloud_dvir__report_carrier = 'Carrier';
_fc_props.cloud_dvir__report_truck = 'Truck';
_fc_props.cloud_dvir__report_location = 'Location';
_fc_props.cloud_dvir__report_odometer = 'Odometer';
_fc_props.cloud_dvir__report_hours = 'Hours';
_fc_props.cloud_dvir__report_no_comments = 'No comments';
_fc_props.include_unlicensed_vehicles = 'Include Unlicensed Vehicles';
_fc_props.cloud_dvir__search_by_form_name = 'Search by Form Name';
_fc_props.cloud_dvir__create_dvir = 'Create a DVIR';
_fc_props.cloud_dvir__update_dvir = 'Update DVIR';
_fc_props.cloud_dvir__form_name = 'Form Name';
_fc_props.cloud_dvir__form_description = 'Form Description';
_fc_props.cloud_dvir__form_status = 'Form Status';
_fc_props.information = 'Information';
_fc_props.cloud_dvir__search_categories = 'Search Categories';
_fc_props.cloud_dvir__create_new_category = 'Create new Category';
_fc_props.category_name = 'Category Name';
_fc_props.sub_category = 'Sub Category';
_fc_props.sub_category_name = 'Sub Category Name';
_fc_props.delete_category = 'Delete Category';
_fc_props.cloud_dvir__dvir_information = 'DVIR Information';
_fc_props.cloud_dvir__dvir_choose_categories = 'Choose Categories';
_fc_props.cloud_dvir__create_category = 'Create Category';
_fc_props.cloud_dvir__update_category = 'Update Category';
_fc_props.delete_route_point = 'Delete Route Point';
_fc_props.view_route_points = 'View Route Points';
_fc_props.ignore_cancel_preload = 'Ignore the cancellation, you are preloaded. Your home run will be shown soon';
_fc_props.cloud_dvir__assigned_mechanic = 'Assigned mechanic';
_fc_props.cloud_dvir__add_mechanic = 'Add a Mechanic';
_fc_props.contact_type = 'Contact type';
_fc_props.cloud_dvir__send_email_notifications = 'Send email notifications';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Error retrieving DVIR History records.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Error retrieving DVIR record.';
_fc_props.cloud_dvir__error_saving_submission = 'Error saving submission. Try again.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Error retrieving list of mechanics.';
_fc_props.cloud_dvir__template_saved = 'Template saved';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Error retrieving list of templates.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Error saving template. Try again.';
_fc_props.dispatch_group = 'Dispatch Group';
_fc_props.save_adjust = 'Save and Adjust Route Point';
_fc_props.reset_route_point = 'Reset Route Point';
_fc_props.no_records_stub_adjust_filters = 'Try adjusting your search or filter settings to increase the number of results.';
_fc_props.no_dvir_records = 'All caught up on DVIRs!';
_fc_props.no_dvir_records_sub_title = 'You’ve accomplished a lot';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'No Slump Reading Obtained for: {0}';
_fc_props.please_select_a_sensor_status_type = 'Please select the Sensor Status Types to trigger this alert';
_fc_props.settings.master_data_connex_token = 'Master Data Connex Token';
_fc_props.error_validating_master_data_connex_credentials = 'Error validating Master Data Connex Credentials';
_fc_props.settings.master_data_connex_base_url = 'Master Data Connex Base URL';
_fc_props.settings.master_data_connex_exp_time = 'Master Data Connex Expiration Time';
_fc_props.menu_settings_title_dispatch_group = 'Dispatch Groups';
_fc_props.settings.dispatch_groups = 'Dispatch Groups';
_fc_props.dispatch_group_name = 'Dispatch Group Name';
_fc_props.dispatch_group_plants = 'Dispatch Group Plants';
_fc_props.dispatch_group_plants_mapping_message = 'Indicate which plants will be part of this Dispatch Group.';
_fc_props.dispatch_group_users_mapping_message = 'Indicate which users will be part of this Dispatch Group.';
_fc_props.dispatch_group_plant_disassociation_message = 'Plant should be under one dispatch group at a time';
_fc_props.dispatch_group_search_by_plant = 'Search by Plant Name';
_fc_props.dispatch_group_search_by_username = 'Search by Username';
_fc_props.search_by_dispatch_group_name = 'Search by Dispatch Group Name';
_fc_props.create_dispatch_group = 'Create a Dispatch Group';
_fc_props.update_dispatch_group = 'Update Dispatch Group';
_fc_props.dispatch_group_saved = 'Dispatch group saved';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Deleting this dispatch group will create default dispatch groups for the associated plants.';
_fc_props.error_saving_dispatch_group = 'Error saving dispatch group. Try again.';
_fc_props.please_enter_a_valid_plant_num = 'Please enter a valid plant number.';
_fc_props.cloud_dvir__report_no_defects = 'No defects';
_fc_props.shoud_select_dispatch_group = 'You have to select at least one Dispatch Group';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Unable to delete because this Dispatch Group contains users that are only associated with this Dispatch Group.';
_fc_props.cloud_dvir__username = 'Username';
_fc_props.cloud_dvir__password = 'Password';
_fc_props.cloud_dvir__login = 'Login';
_fc_props.cloud_dvir__mechanic_sign_in = 'Mechanic Sign In';
_fc_props.delete_dispatch_group = 'Delete Dispatch Group';
_fc_props.cloud_dvir__driver = 'Driver';
_fc_props.cloud_dvir__equipment_pound = 'Equipment #';
_fc_props.cloud_dvir__mechanic = 'Mechanic';
_fc_props.dispatch_group_user_only_unassociate_message = 'Unable to delete user because users must be associated with at least one Dispatch Group.';
_fc_props.cloud_dvir__time_period_filter_today = 'Today';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Yesterday';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Last 7 Days';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Last 30 Days';
_fc_props.cloud_dvir__date_range_filter = 'Filter by Date';
_fc_props.cloud_dvir__download = 'Download';
_fc_props.dispatch_group_is_empty = 'Dispatch group cannot be empty.';
_fc_props.dispatch_group_with_empty_plants = 'At least one plant has to be associated with the dispatch group.';
_fc_props.dispatch_group_with_empty_users = 'At least one user has to be associated with the dispatch group.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Unable to delete the Dispatch Group as it contains users that are only associated with this Dispatch Group.';
_fc_props.dispatch_group_not_exists = 'Dispatch group does not exist.';
_fc_props.dispatch_group_contains_one_plant = 'Unable to delete because this Dispatch Group contains only one plant.';
_fc_props.dispatch_group_users = 'Dispatch Group Users';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Error retrieving DVIR record';
_fc_props.cloud_dvir__assign_mechanic = 'Assign Mechanic';
_fc_props.cloud_dvir__submit_review = 'Submit Review';
_fc_props.error_deleting_dispatch_group = 'Error deleting dispatch group.';
_fc_props.error_retrieving_dispatch_groups = 'Error retrieving list of dispatch groups.';
_fc_props.cloud_dvir__undo_selection = 'Undo Selection';
_fc_props.enable_master_data = 'Enable Master Data';
_fc_props.manually_sync = 'Manually Sync';
_fc_props.master_data_connex_token_expiration = 'Master Data Connex Token Expiration';
_fc_props.cloud_dvir__time_period_filter_custom = 'Custom';
_fc_props.settings.zello_session_timeout_minutes = 'Zello Session Timeout (minutes)';
_fc_props.menu_settings_title_master_data = 'Master Data';
_fc_props.settings.master_data = 'Master Data';
_fc_props.error_retrieving_master_data = 'Error retrieving master data';
_fc_props.master_data_sync_completed = 'Master data sync completed';
_fc_props.master_data_successfully_updated = 'Data successfully updated';
_fc_props.master_data_failed_to_update = 'Failed to Save.';
_fc_props.master_data_sync_initiated = 'Master data sync initiated';
_fc_props.master_data_sync_failed = 'Master data sync failed';
_fc_props.enabled_excluding_origin_on_deliveries = 'True (excluding origin on delivery tickets)';
_fc_props.enabled_including_origin_on_deliveries = 'True (including origin on delivery tickets)';
_fc_props.cloud_dvir__i_will_decide_later = 'I will decide later';
_fc_props.returning = 'Returning';
_fc_props.leftover_concrete = 'Leftover Concrete';
_fc_props.leftover_concrete_on_vehicle_x = 'Leftover Concrete on Vehicle {0}';
_fc_props.leftover_concrete_alert = 'Leftover Concrete Alert';
_fc_props.wc_ratio_exceeded = 'W/C Ratio Exceeded';
_fc_props.search_by_site_name = 'Search by site name';
_fc_props.sites = 'Sites';
_fc_props.token_expiration = 'Token expiration';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API Key Management';
_fc_props.settings.connex_api_key_management = 'Connex API Key Management';
_fc_props.error_retrieving_connex_api_key_data = 'Error retrieving connex api key data';
_fc_props.qr_code = 'QR Code';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informing Driver and Interface when entering wrong Plant';
_fc_props.enable_driver_message = 'Enable Driver Message';
_fc_props.enable_dispatch_message = 'Enable Dispatch Message';
_fc_props.enable_sending_message_to_interface = 'Enable sending message to Interface';
_fc_props.ticket_qr_code = 'Ticket QR Code';
_fc_props.leftover_concrete_alert_description = 'Alert when leftover concrete found after pour.';
_fc_props.minimum_leftover_concrete_amount = 'Min leftover concrete amount';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Error validating Master Data Connex Credentials Not Authorized';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Error validating Master Data Connex Credentials Permissions';
_fc_props.error_validating_master_data_connex_url_not_found = 'Error validating Master Data Connex Url Not Found';
_fc_props.error_validating_master_data_connex_unknown_error = 'Error validating Master Data Connex Unknown Error';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Master data sync failed Sync up vehicle type.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Master data sync failed Sync up vehicle.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Master data sync failed Sync up plant.';
_fc_props.master_data_sync_failed_data_base_connection = 'Master data sync failed connection to database.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Error validating Master Data Connex Credentials Bearer Token Permission is Insufficient';
_fc_props.settings.back_date_arrive_job = 'Back date arrive job';
_fc_props.device_notifications = 'Notifications';
_fc_props.loading_number = 'Loading Number';
_fc_props.leftover_concrete_volume_temp_age = 'Leftover Volume={0}, Temp={1}, Age={2}';
_fc_props.total_ordered = 'Total Ordered Quantity';
_fc_props.total_ticketed = 'Delivered Ordered';
_fc_props.load_number = 'Load Number';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Min leftover concrete must be filled in and greater than {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Error Validating Master Data. Credentials Cannot Be The Same.';
_fc_props.use_aws_for_talk = 'Use AWS for Talk';
_fc_props.air_readings_not_supported = 'Air Readings Not Supported';
_fc_props.air_not_supported = 'Air Not Supported';
_fc_props.mix_not_supported = 'Mix Not Supported';
_fc_props.air_default_and_none_not_supported = 'Cannot select default and not supported at the same time!';
_fc_props.notifications_enabled = 'Notifications Enabled';
_fc_props.prevent_status_change = 'Prevent Status Change';
_fc_props.message_to_interface = 'Message to Interface';
_fc_props.prevent_wrong_plant = 'Prevent Wrong Plant';
_fc_props.previous_tickets = 'Previous Tickets';
_fc_props.audit_ticket_number = 'Ticket Number';
_fc_props.audit_date_created = 'Date Created';
_fc_props.audit_vehicle_number = 'Vehicle Number';
_fc_props.cloud_dvir__table_ok = 'Safe to Operate';
_fc_props.cloud_dvir__table_needs_repair = 'Unsafe to Operate';
_fc_props.missing_air_calibration = 'No Air Calibration Data';
_fc_props.reported_defects = 'Reported Defects';
_fc_props.address_latitude = 'Address Latitude';
_fc_props.address_longitude = 'Address Longitude';
_fc_props.routing_latitude = 'Routing Latitude';
_fc_props.routing_longitude = 'Routing Longitude';
_fc_props.category_name_must_be_unique = 'Category name must be unique';
_fc_props.subcategory_name_must_be_unique = 'Subcategory name must be unique';
_fc_props.menu_settings_tracking_device = 'Tracking Device';
_fc_props.menu_settings_title_tracking_device = 'Tracking Device';
_fc_props.menu_settings_title_personal_device_registration = 'Personal Device Registration';
_fc_props.registration_code = 'Registration Code';
_fc_props.creation_date = 'Creation';
_fc_props.expiration_date = 'Expiration';
_fc_props.trackitadmin_personal_device_registration = 'Personal Device Registration';
_fc_props.create_registration_code = 'Add Registration Code';
_fc_props.remove_registration_code = 'Remove Registration Code';
_fc_props.add_a_registration_code = 'Add a Registration Code';
_fc_props.select_number_of_registration_codes_to_generate = 'Select the number of registration codes you would like to generate.';
_fc_props.registration_code_count = 'Registration code count';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Are you sure you want to delete the selected registered code?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Are you sure to want to delete the selected registered codes?';
_fc_props.add_personal_device = 'Add Personal Device';
_fc_props.menu_settings_title_personal_device = 'Personal Device';
_fc_props.cloud_dvir__approval_critical_alert = 'Vehicle has a critical defect. Cannot be marked as Safe to Operate.';
_fc_props.export_registration_codes = 'Export Registration Codes';
_fc_props.download_format = 'Download format';
_fc_props.specify_download_format_x = 'Specify the download format to export {0} registration codes';
_fc_props.personal_device_download = 'Download';
_fc_props.add_personal_device_input_message = 'Input a personal device ID below. Expiration will automatically be applied after saving';
_fc_props.error_creating_personal_device = 'Error creating a personal device';
_fc_props.device_already_exists = 'Personal device already exists';
_fc_props.export = 'Export';
_fc_props.error_can_not_change_connex_eid = 'Can not change connex eid as it is currently being used for master data';
_fc_props.remove_personal_device = 'Remove Personal Device';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Are you sure you want to delete the selected personal device?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Are you sure to want to delete the selected personal devices?';
_fc_props.error_deleting_personal_device = 'Error deleting personal device';
_fc_props.customerid = 'Customer ID';
_fc_props.customername = 'Customer Name';
_fc_props.ordernumber = 'Order Number';
_fc_props.orderdescription = 'Order Description';
_fc_props.locationid = 'Location ID';
_fc_props.locationdescription = 'Location Description';
_fc_props.productid = 'Product ID';
_fc_props.productdescription = 'Product Description';
_fc_props.vehicleid = 'Vehicle ID';
_fc_props.vehicledescription = 'Vehicle Description';
_fc_props.ticketnumber = 'Ticket #';
_fc_props.ticketdate = 'Ticket Date/Time';
_fc_props.drivername = 'Driver Name';
_fc_props.arriveregion = 'Arrive Region (TIME)';
_fc_props.leaveregion = 'Leave Region (TIME)';
_fc_props.regionduration = 'Region Duration';
_fc_props.menu_reports_title_prevailing_wage = 'Prevailing Wage';
_fc_props.slump_calibration_x_not_found_for_y = 'Slump Calibration "{0}" not found for ticket {1}';
_fc_props.slump_calibration_not_found = 'Slump Calibration Not Found';
_fc_props.slump_calibration_not_found_description = 'Alert when Slump Calibration Not Found';
_fc_props.error_getting_personal_device_list = 'Error getting personal device list';
_fc_props.batch_summary_date_range_note = '*NOTE: This report is limited to 3 days if no filters are applied. The Date range can go up to 31 days when filtered by Equipment (10 maximum), Plant (1 maximum), Product (1 maximum), or Job (1 maximum)';
_fc_props.select_registration_code_expiration_time_days = 'Select the number of days until the registration codes will expire and then click Save.';
_fc_props.add_personal_device_expiration = 'Expiration (in days)';
_fc_props.cloud_dvir__vehicle_no = 'Vehicle No';
_fc_props.cloud_dvir__trailer_no = 'Trailer No';
_fc_props.cloud_dvir__vehicle = 'Vehicle';
_fc_props.cloud_dvir__trailer = 'Trailer';
_fc_props.cloud_dvir__truck_number_label = 'Truck Number';
_fc_props.cloud_dvir__trailer_number = 'Trailer Number';
_fc_props.cloud_dvir__has_trailer = 'Not driving with a trailer';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Enter your trailer number here';
_fc_props.work_order = 'Work Order';
_fc_props.unable_to_determine_slump = 'Unable to determine Slump';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Determine the overall condition of the trailer';
_fc_props.defaultTrailer = 'Default Trailer';
_fc_props.trailer_type_column_billable = 'Billable';
_fc_props.trailer_type_column_notes = 'Notes';
_fc_props.trailer_type_column_date = 'Date';
_fc_props.trailer_type_column_order = 'Order';
_fc_props.trailer_type_column_ticket = 'Ticket';
_fc_props.trailer_type_column_project = 'Project';
_fc_props.trailer_type_column_customer = 'Customer';
_fc_props.trailer_type_column_location = 'Location';
_fc_props.trailer_type_column_vehicle_type = 'Vehicle Type';
_fc_props.trailer_type_column_product = 'Product';
_fc_props.trailer_type_action_rebill = 'Rebill';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Master data sync failed no default equipment type has been set';
_fc_props.trailer_type = 'Trailer Type';
_fc_props.trailer_type_is_required = 'Trailer type is required';
_fc_props.trailer_number_already_exists = 'Trailer number already exists';
_fc_props.plate_number_already_exists = 'Plate number already exists';
_fc_props.trailer_vin_already_exists = 'Trailer Vin already exists';
_fc_props.trailer_does_not_exist = 'Trailer does not exist';
_fc_props.trailer_number_is_required = 'Trailer Number is required';
_fc_props.plate_number_is_required = 'Plate number is required';
_fc_props.plate_number = 'Plate Number';
_fc_props.menu_settings_title_trailer = 'Trailer';
_fc_props.add_trailer = 'Add Trailer';
_fc_props.edit_trailer = 'Edit Trailer';
_fc_props.menu_settings_title_equipment = 'Equipment';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignore new tickets from Command Cloud older that X minutes';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignore new tickets from Command Cloud with Dispatch Date Time older than X minutes (default is empty and off)';
_fc_props.edit_load_card = 'Edit Load Card';
_fc_props.default_layout = 'Default Layout';
_fc_props.load_card_empty_fields = 'Error saving Load Card: One or more fields must be selected.';
_fc_props.trailers = 'Trailers';
_fc_props.error_failed_to_save_data = 'Error: Failed to Save Data';
_fc_props.slump_increased_plasticizer_added_question = 'How much plasticizer was added?';
_fc_props.add_trailer_type = 'Add Trailer Type';
_fc_props.menu_settings_title_trailer_type = 'Trailer Type';
_fc_props.edit_trailer_type = 'Edit Trailer Type';
_fc_props.single_use_codes = 'Single Use Codes';
_fc_props.multiple_use_codes = 'Multiple Use Codes';
_fc_props.trailer_type_description_is_required = 'Trailer Type Description is required';
_fc_props.trailer_saved = 'Trailer Saved';
_fc_props.cloud_dvir__trailer_only_dvir = 'Trailer Only DVIR';

export default _fc_props;
