import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import LoginPage from './LoginPage';
import SubmissionPage from './SubmissionsPage';
import { AuthenticationContext } from './context/AuthenticationProvider';

const MechanicWrapper = styled('div')`
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.neutral.variation100};
`;

const ContentSwitcher = () => {
  const { isAuthorized } = useContext(AuthenticationContext);

  return (
    <MechanicWrapper>
      {isAuthorized ? <SubmissionPage /> : <LoginPage />}
    </MechanicWrapper>
  );
};

export default ContentSwitcher;
