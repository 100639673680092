import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createRoot } from 'react-dom/client';
import App from './App';
import theme from './theme';
import { AlertContextProvider } from './context/AlertContext';
import i18n from './i18n/i18n';

const root = createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <I18nextProvider i18n={i18n}>
      <AlertContextProvider>
        <App />
      </AlertContextProvider>
    </I18nextProvider>
  </ThemeProvider>,
);
