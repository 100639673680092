export const DVIR_MODE = {
  MECHANIC: 'mechanic',
  DRIVER: 'driver',
} as const;

export type DVIRMode = typeof DVIR_MODE[keyof typeof DVIR_MODE];

export enum DvirSubmitMode {
  Vehicle = 'vehicle',
  Trailer = 'trailer',
  VehicleTrailer = 'vehicle_trailer',
}
