export const MechanicURLDataPoints = {
  Mechanic: 'mechanic',
  LoginUrl: 'loginUrl',
  Context: 'context',
  AuxVersion: 'auxVersion',
} as const;

export const EVENT_INDEX = {
  DRIVER: 0,
  MECHANIC: 1,
} as const;
