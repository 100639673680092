import React, { useEffect, useState } from 'react';
import { isMechanic } from './utils/resolvers';
import DriverDVIR from './driver/DriverDVIR';
import MechanicDVIR from './mechanic/MechanicDVIR';
import { Spinner } from './components';
import { DVIR_MODE } from './constants';
import VersionManager from './utils/VersionManager';
import { DriverURLDataPoints } from './driver/constants';

const MECHANIC_URL_PARAM_KEY = 'mechanic';

const App = () => {
  const [mode, setMode] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mechanicParam = urlParams.get(MECHANIC_URL_PARAM_KEY);

    if (isMechanic(mechanicParam)) {
      setMode(DVIR_MODE.MECHANIC);

      return;
    }

    setMode(DVIR_MODE.DRIVER);
    if (!loaded) {
      setLoaded(true);
      VersionManager.init(urlParams.get(DriverURLDataPoints.AuxVersion) ?? '0');
      VersionManager.auxFunctionsMap.loaded({});
    }
  }, []);

  const renderContent = () => {
    switch (mode) {
      case DVIR_MODE.DRIVER: {
        return <DriverDVIR />;
      }
      case DVIR_MODE.MECHANIC: {
        return <MechanicDVIR />;
      }
      default: {
        return <Spinner />;
      }
    }
  };

  return renderContent();
};

export default App;
