import { Log } from './Log';
import { EVENT_TYPE } from '../context/constants';

export type FunctionMap = {
  [key: string]: (data: any) => void;
};

class VersionManager {
  static AUX_MIN_COMPATIBLE_VERSION = '14.4.136';

  static VERSION_14_4_146 = '14.4.146';

  static VERSION_14_4_151 = '14.4.151';

  static BASE_VERSION = this.AUX_MIN_COMPATIBLE_VERSION;

  static ALWAYS_SUPPORTED_VERSION = '0';

  static auxFunctionsMap: FunctionMap = {
    getToken: (data) => {
      this.postMessage(data, EVENT_TYPE.JWT_GENERATION_INIT);
    },
    submitDvir: (data) => {
      this.postMessage(data, EVENT_TYPE.SUBMISSION);
    },
    loaded: (data) => {
      this.postMessage(data, EVENT_TYPE.LOADED);
    },
    saveDvir: (data) => {
      this.postMessage(data, EVENT_TYPE.SAVED);
    },
    approveCloseDvir: (data) => {
      this.postMessage(data, EVENT_TYPE.CLOSE_APPROVE);
    },
    log: (data) => {
      this.postMessage(data, EVENT_TYPE.LOG);
    },
    getAddress: (data) => {
      this.postMessage(data, EVENT_TYPE.ADDRESS_REQUEST);
    },
    getTrailerList: () => {
      this.postMessage(null, EVENT_TYPE.TRAILER_LIST);
    },
  };

  static functionsVersion = new Map<string, string>([
    [EVENT_TYPE.JWT_GENERATION_INIT, this.BASE_VERSION],
    [EVENT_TYPE.SUBMISSION, this.BASE_VERSION],
    [EVENT_TYPE.CLOSE_APPROVE, this.BASE_VERSION],
    [EVENT_TYPE.SAVED, this.VERSION_14_4_146],
    [EVENT_TYPE.LOADED, this.VERSION_14_4_146],
    [EVENT_TYPE.LOG, this.ALWAYS_SUPPORTED_VERSION],
    [EVENT_TYPE.ADDRESS_REQUEST, this.BASE_VERSION],
    [EVENT_TYPE.TRAILER_LIST, this.VERSION_14_4_151],
  ]);

  static currentAuxVersion: string;

  constructor() {
    throw new Error('AuxVersionCompatability class cannot be instantiated.');
  }

  static init(currentAuxVersion: string) {
    this.currentAuxVersion = currentAuxVersion;
  }

  private static compare(currentVersion: string, minRequiredVersion: string) {
    const currentVersionParts = currentVersion.split('.').map(Number);
    const minRequiredVersionParts = minRequiredVersion.split('.').map(Number);
    const maxLength = Math.max(currentVersionParts.length, minRequiredVersionParts.length);

    let i = 0;
    while (i < maxLength) {
      const currentVersionPart = i < currentVersionParts.length ? currentVersionParts[i] : 0;
      const minRequiredVersionPart = i < minRequiredVersionParts.length ? minRequiredVersionParts[i] : 0;

      if (currentVersionPart < minRequiredVersionPart) {
        return -1;
      }

      if (currentVersionPart > minRequiredVersionPart) {
        return 1;
      }

      i += 1;
    }

    return 0;
  }

  static isFunctionSupported(functionName: string) {
    const functionVersion = this.functionsVersion.get(functionName);

    if (!this.currentAuxVersion || !functionVersion) {
      return false;
    }

    const compareResult = this.compare(this.currentAuxVersion, functionVersion);
    return compareResult >= 0;
  }

  public static postMessage = (data: any, type: string) => {
    if (!this.isFunctionSupported(type)) {
      Log.i(
        `VersionManager: aux version ${
          this.currentAuxVersion
        } does not support ${type} function version ${this.functionsVersion.get(type)}`,
      );
      return;
    }

    const eventData = {
      type,
      source: 'DVIR-UI',
      payload: data,
    };

    window.parent.postMessage(JSON.stringify(eventData), '*');
  };
}

export default VersionManager;
