import { v4 as randomUUID } from 'uuid';
import { SubmissionEvent, InspectionValue } from '../../types/submission';
import { EVENT_INDEX } from '../../mechanic/constants';
import { CategoryType } from '../../types/template';
import { InspectionForm } from '../forms/useInspectionForm';

export const createGUID = (prefix: string) => `${prefix}-${randomUUID()}`;

const priorityMap = {
  [InspectionValue.RepairsMade]: 4,
  [InspectionValue.RepairsNotNecessary]: 3,
  [InspectionValue.NeedsRepair]: 2,
  [InspectionValue.Ok]: 1,
};

export const getCurrentState = (events: SubmissionEvent[]): InspectionValue => {
  if (events == null || events.length === 0) {
    return null;
  }

  return events[events.length - 1].safetyStatus || InspectionValue.Ok;
};

const sortCategoryItemsByPriority = (category) => ({
  ...category,
  items: category.items.sort((a, b) => {
    const priorityA = priorityMap[a.events[EVENT_INDEX.MECHANIC]?.safetyStatus] || 0;
    const priorityB = priorityMap[b.events[EVENT_INDEX.MECHANIC]?.safetyStatus] || 0;
    return priorityB - priorityA;
  }),
});

export const sortCategoriesByPriority = (submissionData) => {
  const sortedCategories = [...submissionData.categories].sort((a, b) => {
    const getPriority = (item) => priorityMap[item.events[EVENT_INDEX.MECHANIC]?.safetyStatus] || 0;
    const prioritySumA = a.items.reduce((sum, item) => sum + getPriority(item), 0);
    const prioritySumB = b.items.reduce((sum, item) => sum + getPriority(item), 0);

    return prioritySumB - prioritySumA;
  });

  const sortedCategoriesAndItems = sortedCategories.map((category) => sortCategoryItemsByPriority(category));
  return {
    ...submissionData,
    categories: sortedCategoriesAndItems,
  };
};

export const addCategoryNameSuffixByCategoryType = (categoryName: string, categoryType: CategoryType): string => `${categoryType}-${categoryName}`;

export const removeCategoryNameSuffixByCategoryType = (categoryName: string, categoryType: CategoryType): string => categoryName.replace(`${categoryType}-`, '');

export const splitCategoryItemsByCategoryType = (form: InspectionForm): Record<CategoryType, InspectionForm> => {
  const transformed = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in form) {
    const { categoryType } = form[key];

    if (!transformed[categoryType]) {
      transformed[categoryType] = {};
    }

    transformed[categoryType][key] = form[key];
  }

  return transformed as Record<CategoryType, InspectionForm>;
};
