import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Paper, TextField, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledHeader } from './styles';
import { StoreContext } from '../context/StoreContext';
import { EventsManagerContext } from '../context/EventsManagerContext';
import VersionManager from '../utils/VersionManager';

interface TrailerOption {
  label: string;
  value: string;
}

const StyledAutocomplete = styled(Autocomplete)`
  width: 190px;
  height: 54.77px;
  border-radius: 12px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  border: none;
`;

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
  },
});

const CustomPaperComponent = ({ children }: { children: React.ReactNode }) => (
  <Zoom in style={{ transformOrigin: 'top' }} timeout={200}>
    <Paper elevation={4}>{children}</Paper>
  </Zoom>
);

const TrailerNumber = () => {
  const { t } = useTranslation();
  const DEFAULT_TRAILER = { label: t('NONE'), value: '-1' };
  const { trailer, setTrailerVin } = useContext(StoreContext);
  const { driverData, trailerList } = useContext(EventsManagerContext);
  const [trailerOptions, setTrailerOptions] = useState<TrailerOption[]>([DEFAULT_TRAILER]);

  useEffect(() => {
    if (!trailerList.length) {
      VersionManager.auxFunctionsMap.getTrailerList(null);
    }
    setTrailerOptions(
      [DEFAULT_TRAILER].concat(
        trailerList.map(({ trailerNumber }) => ({ label: trailerNumber, value: trailerNumber })),
      ),
    );
  }, []);

  const storeTrailerNumber = (value) => {
    const trailerVinStorageKey = `trailer_vin_${driverData?.driverId}`;
    localStorage.setItem(trailerVinStorageKey, value);
  };

  const storeTrailerTemplateId = (value) => {
    const trailerVinStorageKey = `trailer_template_id_${driverData?.driverId}`;
    localStorage.setItem(trailerVinStorageKey, value);
  };

  const clearTrailerNumber = () => {
    const trailerVinStorageKey = `trailer_vin_${driverData?.driverId}`;
    localStorage.removeItem(trailerVinStorageKey);
  };

  const clearTrailerTemplateId = () => {
    const trailerTemplateIdStorageKey = `trailer_template_id_${driverData?.driverId}`;
    localStorage.removeItem(trailerTemplateIdStorageKey);
  };

  const handleValueChange = (event, trailerSelected: TrailerOption | null) => {
    if (!trailerSelected) {
      return;
    }
    if (trailerSelected.value === DEFAULT_TRAILER.value) {
      setTrailerVin(null);
      clearTrailerNumber();
      clearTrailerTemplateId();
      return;
    }
    storeTrailerNumber(trailerSelected.value);
    setTrailerVin(trailerSelected.value);

    const selectedTrailer = trailerList.find((trailerObj) => trailerObj.trailerNumber === trailerSelected.value);
    if (!selectedTrailer) {
      return;
    }
    const trailerTemplateId = selectedTrailer.cloudDvirTemplateId;
    storeTrailerTemplateId(trailerTemplateId);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <StyledHeader>{t('cloud_dvir__trailer_number')}</StyledHeader>

      <StyledAutocomplete
        options={trailerOptions}
        getOptionLabel={(option: TrailerOption) => option.label}
        isOptionEqualToValue={(option: TrailerOption, value: TrailerOption) => option.value === value.value}
        value={trailerOptions.find((option) => option.value === trailer?.vin) || DEFAULT_TRAILER}
        disabled={trailerOptions.length === 1}
        onChange={handleValueChange}
        renderInput={(params) => <StyledTextField {...params} />}
        PaperComponent={CustomPaperComponent}
      />
    </Box>
  );
};

export default TrailerNumber;
