import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import NoteEditor from './NoteEditor';
import ImagesEditor from './ImagesEditor';
import Note from './Note';
import { Modal, Text } from '../../components';
import {
  AttachmentFieldItem, AttachmentType, AddAttachment,
} from '../forms/useAttachments';
import { Photo } from '../../types/submission';

interface AttachmentsModalProps {
  attachments: AttachmentFieldItem;
  title: string;
  isOpen: boolean;
  readOnly?: boolean;
  onAddAttachment: (attachmentOptions: Omit<AddAttachment, 'categoryName' | 'itemName'>) => void;
  onEditNote: (noteText: string) => void;
  onDeleteNote: () => void;
  onDeletePhoto: (photoId: string) => void;
  onClose: () => void;
}

const AttachmentsModal:FC<AttachmentsModalProps> = ({
  isOpen,
  onClose,
  readOnly = false,
  title,
  attachments: {
    [AttachmentType.Notes]: notes,
    [AttachmentType.Photos]: photos,
  },
  onAddAttachment,
  onEditNote,
  onDeleteNote,
  onDeletePhoto,
}) => {
  const { t } = useTranslation();

  const [isNoteMode, setIsNoteMode] = useState<boolean>(false);

  const toggleIsNoteMode = () => {
    setIsNoteMode(!isNoteMode);
  };

  const handleAddNote = (noteText: string) => {
    if (!readOnly) {
      onAddAttachment({ toAttach: noteText, type: AttachmentType.Notes });
    }
  };

  const handleDeleteNote = () => {
    if (!readOnly) {
      onDeleteNote();
    }
  };

  const handleAddPhoto = (photo: Photo) => {
    if (!readOnly) {
      onAddAttachment({ toAttach: photo, type: AttachmentType.Photos });
    }
  };

  const handleClose = () => {
    setIsNoteMode(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '@media screen and (min-height: 520px)': {
            minHeight: '436px',
          },
          '@media screen and (max-height: 519px)': {
            height: '84vh',
            maxHeight: '436px',
            marginBottom: 1,
          },
        }}
      >
        {isNoteMode
          ? (
            <NoteEditor
              noteText={notes[0]?.text}
              onAddNote={handleAddNote}
              onEditNote={onEditNote}
              onDeleteNote={handleDeleteNote}
              onClose={toggleIsNoteMode}
            />
          ) : (
            <>
              <Box sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3,
              }}
              >
                <Text
                  fontLevel="title"
                  fontSize="large"
                  sx={{
                    color: (theme) => theme.palette.neutral.variation40,
                  }}
                >
                  {title}
                </Text>
                <CloseIcon
                  onClick={onClose}
                  sx={{
                    width: 24,
                    height: 24,
                    color: (theme) => theme.palette.neutral.variation40,
                    cursor: 'pointer',
                  }}
                />
              </Box>
              <ImagesEditor images={photos} onAddPhoto={handleAddPhoto} onDeletePhoto={onDeletePhoto} readOnly={readOnly} />
              {!readOnly && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.neutral.variation95,
                    borderRadius: '12px',
                    padding: 2,
                    marginBottom: 1,
                    cursor: 'pointer',
                  }}
                  onClick={toggleIsNoteMode}
                >
                  <Text
                    fontLevel="body"
                    fontSize="large"
                    sx={{
                      color: (theme) => theme.palette.neutral.variation30,
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('cloud_dvir__add_notes')}
                  </Text>
                  <AddIcon />
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {notes.map(({ timestamp, text, isDriverNote }) => (
                  <Note
                    noteText={text}
                    isDriverNote={isDriverNote}
                    createdAt={timestamp}
                    readOnly={readOnly}
                    key={timestamp}
                    onEdit={toggleIsNoteMode}
                    onDelete={onDeleteNote}
                  />
                ))}
              </Box>
            </>
          )}
      </Box>
    </Modal>
  );
};

export default AttachmentsModal;
