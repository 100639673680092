import { VehicleForm } from '../forms/useVehicleForm';
import { InspectionForm } from '../forms/useInspectionForm';
import { OverallForm, OverallFormFields } from '../forms/useOverallForm';
import { LaunchData } from '../../context/LaunchDataContext';
import {
  AttachmentsForm,
  AttachmentType,
  ItemAttachments,
  CategoriesEvents,
} from '../forms/useAttachments';
import dateUtils from '../../utils/date';
import { DriverData, EventsManagerContextValue } from '../../context/EventsManagerContext';
import { DvirStatus } from '../../types/types';
import {
  SubmissionEvent,
  InspectionValue,
  InspectorType,
  Submission,
  Photo,
  SubmissionType,
} from '../../types/submission';
import { DriverURLDataPoints } from '../../driver/constants';
import { removeCategoryNameSuffixByCategoryType, splitCategoryItemsByCategoryType } from './utils';
import { CategoryType } from '../../types/template';
import { Trailer } from '../../context/StoreContext';

const getVehicleFormFields = (vehicleForm: VehicleForm) => Object.entries(vehicleForm)
  .reduce((fields, [fieldName, { value }]) => ({ ...fields, [fieldName]: value }), {});

const getItemAttachments = ({
  attachmentsForm,
  categoryName,
  itemName,
  item,
}: {
  attachmentsForm: AttachmentsForm,
  categoryName: string,
  itemName: string,
  item,
  vin: string
}) => {
  const { [AttachmentType.Notes]: notes, [AttachmentType.Photos]: photos } = attachmentsForm[categoryName].items[itemName];
  const eventsNote = notes?.[0]?.text || '';

  return {
    attachmentItems: {
      ...item,
      events: [{
        ...item.events[0],
        ...(eventsNote && { note: eventsNote }),
        ...(photos.length > 0 && { photos: photos.map((photoItem) => photoItem.id) }),
      }],
    },
    attachmentFiles: photos,
  };
};

const getCategoriesEvents = ({
  inspectionForm,
  driverId,
  attachmentsForm,
  vin,
}: {
  inspectionForm: InspectionForm,
  driverId: string,
  attachmentsForm: AttachmentsForm,
  vin: string,
}) => Object.entries(inspectionForm).reduce((result: CategoriesEvents[], [categoryName, { items, categoryType }]) => {
  const categoryItems = Object.entries(items).reduce((acc: [string, ItemAttachments], [itemName, {
    isCritical,
    value,
    id,
  }]) => {
    const item = {
      name: itemName,
      id,
      critical: isCritical,
      events: [{
        inspectorId: driverId,
        safetyStatus: value,
        inspectorType: InspectorType.Driver,
      }],
    };

    const itemWithAttachments = getItemAttachments({
      attachmentsForm, categoryName, itemName, item, vin,
    });

    return acc.concat(itemWithAttachments);
  }, []);

  const category: CategoriesEvents = {
    name: removeCategoryNameSuffixByCategoryType(categoryName, categoryType),
    items: categoryItems?.flatMap((item: ItemAttachments) => item.attachmentItems) || [],
    files: categoryItems?.flatMap((item: ItemAttachments) => item.attachmentFiles) || [],
  };

  return result.concat(category);
}, []);

export const prepareFormDataForExistingSubmission = (
  dvirData: Submission,
  overallForm: OverallForm,
  driverData: DriverData,
  formStatus: DvirStatus,
): {
  formData: Submission,
  attachments: Array<any>
} => {
  const { driverName, driverId } = driverData;

  const eventsConverter = (events: Array<SubmissionEvent>): Array<SubmissionEvent> => events.map((event) => {
    const converted = {
      ...event,
    };
    if ('photos' in event) {
      converted.photos = event.photos !== undefined ? event.photos.map((photo) => (typeof photo === 'object' ? (photo as Photo).id : photo as string)) : [];
    }
    if (('signature' in event) && (event.signature !== undefined)) {
      converted.signature = typeof event.signature === 'object' ? event.signature.id : event.signature;
    }

    return converted;
  });

  const events: Array<SubmissionEvent> = eventsConverter(dvirData.events);
  const categories = dvirData.categories.map((category) => ({
    ...category,
    items: category.items.map((item) => ({
      ...item,
      events: eventsConverter(item.events),
    })),
  }));

  const formData = {
    ...dvirData,
    status: formStatus,
    events,
    driver: {
      id: driverId,
      name: driverName,
    },
    categories,
  };
  return {
    formData,
    attachments: [overallForm[OverallFormFields.Sign].value],
  };
};

export const getInspectionFormDefects = (inspectionForm: InspectionForm, overallValue: InspectionValue) => {
  const numberOfReportedDefects = Object.values(inspectionForm)
    .reduce(
      (total, categories) => total + Object.values(categories.items)
        .reduce(
          (count, { value }) => count + (value === InspectionValue.NeedsRepair ? 1 : 0),
          0,
        ),
      0,
    );

  const hasDefects = overallValue === InspectionValue.NeedsRepair || Boolean(numberOfReportedDefects);

  return { hasDefects, numberOfReportedDefects };
};

export const getCategoriesAndFilesData = (categoriesEvents, overallValue) => {
  const categoriesData = categoriesEvents.map(({ name, items }) => ({ name, items }));
  const filesData = categoriesEvents.reduce((data, { files }) => (files.length > 0 ? [...data, ...files] : data), []);
  const filesDataWithSign = filesData.concat(overallValue);

  return {
    categoriesData,
    filesDataWithSign,
  };
};

export const prepareFormData = ({
  vehicleForm,
  inspectionForm,
  overallForm,
  launchData: {
    context,
  },
  attachmentsForm,
  latitude,
  longitude,
  address,
  timeToLive,
  driverData,
  vin,
  vehicleNum,
  templateName,
  hasTrailer,
  trailerData,
  isVehicleDvirSubmit,
  isTrailerDvirSubmit,
}: {
  vehicleForm: VehicleForm,
  inspectionForm: InspectionForm,
  overallForm: OverallForm,
  launchData: LaunchData<typeof DriverURLDataPoints>,
  driverData: DriverData,
  attachmentsForm: AttachmentsForm,
  latitude: number,
  longitude: number,
  address: string,
  timeToLive?: number,
  vin: EventsManagerContextValue['vin'],
  vehicleNum: string;
  templateName: string;
  hasTrailer: boolean;
  trailerData: Trailer;
  isVehicleDvirSubmit: boolean;
  isTrailerDvirSubmit: boolean;
}) => {
  const { driverName, driverId } = driverData;

  const inspectionTripType = vehicleForm.inspectionTripType.value;

  let ttl = null;
  if (timeToLive) {
    ttl = dateUtils.add(Date.now(), { days: timeToLive });
    ttl = Math.floor(ttl / 1000); // converts to unix epoch
  }

  const baseFormData = {
    templateName,
    companyId: context,
    submissionDate: '',
    inspectionTripType,
    location: { latitude, longitude, address },
    expirationDate: ttl,
    driver: {
      id: driverId,
      name: driverName,
    },
    ...getVehicleFormFields(vehicleForm),
  };

  let vehicle = {};
  let trailer = {};

  const { [CategoryType.Trailer]: trailerInspectionForm, [CategoryType.Vehicle]: vehicleInspectionForm } = splitCategoryItemsByCategoryType(inspectionForm);

  if (isVehicleDvirSubmit) {
    const vehicleCategoriesEvents = getCategoriesEvents({
      inspectionForm: vehicleInspectionForm, attachmentsForm, driverId, vin,
    });

    const { categoriesData: vehiclesCategoriesData, filesDataWithSign: vehicleFilesDataWithSign } = getCategoriesAndFilesData(vehicleCategoriesEvents, overallForm[OverallFormFields.OverallVehicle].value);
    const { hasDefects: vehicleHasDefects, numberOfReportedDefects: vehicleNumberOfReportedDefects } = getInspectionFormDefects(vehicleInspectionForm, overallForm[OverallFormFields.OverallVehicle].value);

    vehicle = {
      formData: {
        ...baseFormData,
        vin,
        vehicleNum,
        status: vehicleHasDefects ? DvirStatus.AwaitingMechanic : DvirStatus.Completed,
        initialSafetyStatus: overallForm[OverallFormFields.OverallVehicle].value,
        numberOfReportedDefects: vehicleNumberOfReportedDefects,
        events: [
          {
            inspectorId: driverId,
            inspectorType: InspectorType.Driver,
            safetyStatus: overallForm[OverallFormFields.OverallVehicle].value,
            signature: overallForm[OverallFormFields.Sign].value.id,
          },
        ],
        submissionType: SubmissionType.Vehicle,
        categories: vehiclesCategoriesData,
      },
      attachments: vehicleFilesDataWithSign,
    };

    if (!hasTrailer) {
      return {
        vehicle,
      };
    }
  }

  if (isTrailerDvirSubmit) {
    const trailerCategoriesEvents = getCategoriesEvents({
      inspectionForm: trailerInspectionForm, attachmentsForm, driverId, vin,
    });

    const { categoriesData: trailerCategoriesData, filesDataWithSign: trailerFilesDataWithSign } = getCategoriesAndFilesData(trailerCategoriesEvents, overallForm[OverallFormFields.OverallTrailer].value);
    const { hasDefects: trailerHasDefects, numberOfReportedDefects: trailerNumberOfReportedDefects } = getInspectionFormDefects(trailerInspectionForm, overallForm[OverallFormFields.OverallTrailer].value);

    trailer = {
      formData: {
        ...baseFormData,
        vin: trailerData?.vin,
        vehicleNum: trailerData?.number ?? trailerData?.vin,
        status: trailerHasDefects ? DvirStatus.AwaitingMechanic : DvirStatus.Completed,
        numberOfReportedDefects: trailerNumberOfReportedDefects,
        initialSafetyStatus: overallForm[OverallFormFields.OverallTrailer].value,
        events: [
          {
            inspectorId: driverId,
            inspectorType: InspectorType.Driver,
            safetyStatus: overallForm[OverallFormFields.OverallTrailer].value,
            signature: overallForm[OverallFormFields.Sign].value.id,
          },
        ],
        submissionType: SubmissionType.Trailer,
        categories: trailerCategoriesData,
      },
      attachments: trailerFilesDataWithSign,
    };
  }

  return { vehicle, trailer };
};
