import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button, TextArea } from '../../../components';

const StyledNoteEditor = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledAddNoteButton = styled(Button)`
  align-self: flex-end;
`;

interface NoteEditorProps {
    defaultValue: string;
    isEditMode: boolean;
    onAddItem: (note: string) => void
}

const NoteEditor:FC<NoteEditorProps> = ({ defaultValue, isEditMode, onAddItem }) => {
  const [note, setNote] = useState(defaultValue);

  const { t } = useTranslation();

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleAddNote = () => {
    if (!note) {
      return;
    }

    onAddItem(note);
    setNote('');
  };

  return (
    <StyledNoteEditor>
      <TextArea fullWidth rows={4} onChange={handleNoteChange} value={note} />
      <StyledAddNoteButton onClick={handleAddNote} isRounded isFilled>
        {isEditMode ? t('cloud_dvir__edit') : t('cloud_dvir__add_notes')}
      </StyledAddNoteButton>
    </StyledNoteEditor>
  );
};

export default NoteEditor;
