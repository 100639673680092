import { useEffect, useState, useRef } from 'react';
import { initFieldState } from './constants';
import {
  SubmissionCategoryItem,
  SubmissionCategory,
  InspectionValue,
  Submission, Photo,
} from '../../types/submission';
import Template, { CategoryType } from '../../types/template';
import { addCategoryNameSuffixByCategoryType, getCurrentState } from '../utils/utils';
import { Note } from './useAttachments';

export type InspectionFieldState = {
  id: string;
  name: string;
  isCritical: boolean;
  notes: Note[];
  photos: Photo[];
  isError: boolean;
  value: InspectionValue;
};

export type InspectionFormItem = Record<SubmissionCategoryItem['name'], InspectionFieldState>;

export type InspectionCategoryFormItem = { items: InspectionFormItem, categoryType?: CategoryType }

export type InspectionForm = Record<SubmissionCategory['name'], InspectionCategoryFormItem>;

const transformTemplateToInitForm = (template: Template):InspectionForm => template.categories.reduce((res, category) => {
  const categoryName = addCategoryNameSuffixByCategoryType(category.name, category.categoryType);

  return {
    ...res,
    [categoryName]: {
      items: category.items.reduce((acc, item) => ({ ...acc, [item.name]: { ...initFieldState, id: item.id, isCritical: item.critical } }), {}),
      categoryType: category.categoryType,
    },
  };
}, {} as InspectionForm);

const transformSubmissionToInitForm = (submission: Submission): InspectionForm => submission.categories.reduce((res, category) => ({
  ...res,
  [category.name]: {
    items: category.items.reduce((acc, item) => ({
      ...acc,
      [item.name]:
            {
              value: getCurrentState(item.events),
              isError: false,
              id: item.id,
            },
    }), {}),
  },
}), {} as InspectionForm);

interface UseInspectionForm {
  form: InspectionForm,
  validate: () => boolean,
  handleFormChange: (categoryName: string, itemName: string, value: InspectionValue) => void,
  hasFormChanged: () => boolean,
  incompleteInspectionCategoryName: string,
}

const useInspectionForm = (template: Template, submission: Submission, isVehicleDvirSubmit: boolean, isTrailerDvirSubmit: boolean): UseInspectionForm => {
  const [form, setForm] = useState<InspectionForm>(null);
  const [incompleteInspectionCategoryName, setIncompleteInspectionCategoryName] = useState<string>('');
  const [isVehicleSubmit, setVehicleSubmit] = useState<boolean>(false);
  const [isTrailerSubmit, setTrailerSubmit] = useState<boolean>(false);

  const isFormDirty = useRef<boolean>(false);

  useEffect(() => {
    if (template) {
      const initForm = transformTemplateToInitForm(template);

      setForm(initForm);
    }
  }, [template]);

  useEffect(() => {
    if (submission) {
      const initForm = transformSubmissionToInitForm(submission);

      setForm(initForm);
    }
  }, [submission]);

  useEffect(() => {
    setVehicleSubmit(isVehicleDvirSubmit);
  }, [isVehicleDvirSubmit]);

  useEffect(() => {
    setTrailerSubmit(isTrailerDvirSubmit);
  }, [isTrailerDvirSubmit]);

  const validate = (): boolean => {
    let isValid = true;
    let incompleteCategoryName = '';

    setIncompleteInspectionCategoryName('');

    const validatedForm = Object.entries(form).reduce((res, [categoryName, category]) => {
      const isValidationRequired = (category.categoryType === CategoryType.Vehicle && isVehicleSubmit)
        || (category.categoryType === CategoryType.Trailer && isTrailerSubmit)
        || ((category.categoryType === CategoryType.Vehicle || category.categoryType === CategoryType.Trailer) && isVehicleSubmit && isTrailerSubmit);
      if (!isValidationRequired) {
        return true;
      }

      const inspectionItems = Object.entries(category.items).reduce((acc, [itemName, field]) => {
        if (!field.value) {
          isValid = false;

          if (!incompleteCategoryName) {
            incompleteCategoryName = categoryName;
          }

          return {
            ...acc,
            [itemName]: { ...field, isError: true },
          };
        }

        return { ...acc, [itemName]: field };
      }, {} as InspectionFormItem);

      return {
        ...res,
        [categoryName]: {
          ...category,
          items: inspectionItems,
        },
      };
    }, {});

    if (!isValid) {
      setForm(validatedForm);
      /** asynchronously updates state to reflect previous changes (the same category name could me incomplete twice) */
      setTimeout(() => setIncompleteInspectionCategoryName(incompleteCategoryName), 0);
    }

    return isValid;
  };

  const handleFormChange = (categoryName: string, itemName: string, value: InspectionValue) => {
    const { [categoryName]: category } = form;

    const updatedCategory = {
      ...category,
      items: {
        ...category.items,
        [itemName]: { ...category.items[itemName], value, isError: false },
      },
    };

    isFormDirty.current = true;

    setForm({ ...form, [categoryName]: updatedCategory });
  };

  const hasFormChanged = () => isFormDirty.current;

  return {
    form,
    validate,
    handleFormChange,
    hasFormChanged,
    incompleteInspectionCategoryName,
  };
};

export default useInspectionForm;
