import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import SignaturePad, { SignaturePadProps } from 'react-signature-canvas';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';
import b64toBlob from '../../../utils/b64toBlob';
import { createGUID } from '../../../submission/utils/utils';
import { MechanicReviewContext } from '../../context/MechanicReviewProvider';

/** SignaturePad doesn't accept directly className, so we could style it only using canvasProps.className */
const signaturePadClassName = 'signature-pad';

const StyledSignBoxWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [`.${signaturePadClassName}`]: {
    width: '100%',
    height: '360px',
    border: `2px solid ${theme.palette.neutral.variation80}`,
    borderRadius: '12px',
    backgroundColor: theme.palette.neutral.variation100,
  },
}));

const StyledButtonsWrapper = styled('div')`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  min-width: 90px;
`;

const SignBox = () => {
  const [isReadyToSign, setIsReadyToSign] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const {
    inspectorId,
    signReview,
    resetSignReview,
    submission,
  } = useContext(MechanicReviewContext);
  const { t } = useTranslation();

  const signatureDrawerRef = useRef<SignaturePadProps>();

  const restoreSignature = () => {
    const cachedSignature = localStorage.getItem(`signature_${inspectorId.replace(' ', '_')}`);
    if (cachedSignature) {
      signReview({ src: cachedSignature, id: createGUID(`SIGNATURE-${submission.vin}`) });
      setSignatureData(cachedSignature);
    }
  };

  useEffect(() => {
    if (inspectorId) {
      restoreSignature();
    }
  }, []);

  const handleTrimSign = () => {
    if (!signatureDrawerRef.current) {
      return;
    }

    const dataUrlBase64 = signatureDrawerRef.current
      .getTrimmedCanvas()
      .toDataURL('image/jpeg', 0.2)
      .replace('data:image/jpeg;base64,', '');

    const blob = b64toBlob(dataUrlBase64);

    signReview({ src: URL.createObjectURL(blob), id: createGUID(`SIGNATURE-${submission.vin}`) });
  };

  const handleClear = () => {
    setSignatureData(null);

    if (!signatureDrawerRef.current) {
      return;
    }

    setIsReadyToSign(false);
    signatureDrawerRef.current.clear();
    resetSignReview();
  };

  const validateSignature = () => {
    setIsReadyToSign(!signatureDrawerRef.current.isEmpty());
  };

  return (
    <StyledSignBoxWrapper>
      {signatureData ? (
        <img src={signatureData} className="dvir-signature-box" alt={t('cloud_dvir__sign')} />
      ) : (
        <SignaturePad
          canvasProps={{ className: signaturePadClassName }}
          ref={signatureDrawerRef}
          backgroundColor="#fff"
          onEnd={validateSignature}
        />
      )}
      <StyledButtonsWrapper>
        <StyledButton isFilled onClick={handleClear}>
          {t('cloud_dvir__clear')}
        </StyledButton>
        <StyledButton disabled={!isReadyToSign} variant="contained" isRounded isFilled onClick={handleTrimSign}>
          {t('cloud_dvir__sign')}
        </StyledButton>
      </StyledButtonsWrapper>
    </StyledSignBoxWrapper>
  );
};

export default SignBox;
