import React, { FC } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import {
  LabeledIconButton, Button, NavigationBar,
} from '../components';
import { SubmissionFormSteps } from './types';

const steps = [
  {
    key: SubmissionFormSteps.Inspect,
    label: 'cloud_dvir__inspection',
  },
  {
    key: SubmissionFormSteps.Sign,
    label: 'cloud_dvir__review_and_sign',
  },
];

interface SubmissionNavigationProps {
  step: SubmissionFormSteps;
  isSubmitDisabled?: boolean;
  onStepChange: (step: SubmissionFormSteps) => void;
  onExit: () => void;
  onSubmit: () => void;
}

const SubmissionNavigation:FC<SubmissionNavigationProps> = ({
  step, onStepChange, onExit, onSubmit, isSubmitDisabled = false,
}) => {
  const { t } = useTranslation();

  const navigationButtons = {
    exit: <LabeledIconButton
      viewType="secondary"
      size="medium"
      label={t('cloud_dvir__exit')}
      icon={<NavigateBeforeIcon />}
      onClick={onExit}
      variant="contained"
    />,
    signature: <LabeledIconButton
      size="medium"
      label={t('cloud_dvir__signature')}
      variant="contained"
      icon={<NavigateNextIcon />}
      onClick={() => onStepChange(SubmissionFormSteps.Sign)}
    />,
    inspection: <LabeledIconButton
      viewType="secondary"
      size="medium"
      label={t('cloud_dvir__inspection')}
      icon={<NavigateBeforeIcon />}
      onClick={() => onStepChange(SubmissionFormSteps.Inspect)}
      variant="contained"
    />,
    submit: <Button disabled={isSubmitDisabled} size="large" variant="contained" isRounded isFilled onClick={onSubmit}>Submit Report</Button>,
  };

  const [prevStep, nextStep] = step === SubmissionFormSteps.Inspect
    ? [navigationButtons.exit, navigationButtons.signature]
    : [navigationButtons.inspection, navigationButtons.submit];

  return <NavigationBar steps={steps} currentStep={step} prevStep={prevStep} nextStep={nextStep} />;
};

export default SubmissionNavigation;
