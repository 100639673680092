import React, { FC, useState } from 'react';
import { Box, ListItemButton, Collapse as MUICollapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface CollapseProps {
  isInitiallyOpen?: boolean;
  content: React.ReactNode | React.ReactNode[];
  title?: React.ReactNode | React.ReactNode[];
  className?: string;
  alwaysOpen?: boolean;
}

const Collapse:FC<CollapseProps> = ({
  alwaysOpen = false,
  isInitiallyOpen = false,
  content,
  title = null,
  className = '',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(alwaysOpen || isInitiallyOpen);

  const handleClick = () => {
    if (alwaysOpen) {
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <>
      <Box sx={{ height: '56px', borderRadius: '8px', marginBottom: '3px' }} className={className}>
        <ListItemButton onClick={handleClick} sx={{ height: '100%' }}>
          {title}
          {isOpen ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
        </ListItemButton>
      </Box>
      <MUICollapse
        sx={{
          marginBottom: 1,
        }}
        in={isOpen}
        timeout="auto"
        unmountOnExit
      >
        {content}
      </MUICollapse>
    </>
  );
};

export default Collapse;
