import { createTheme } from '@mui/material';
import customPalettes from './palettes';
import fontLevelBox from './fontLevelsBox';
import fontWeight from './fontWeight';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: customPalettes.neutral.variation99,
        },
      },
    },
  },
  palette: {
    ...customPalettes,
  },
  indent: 8,
  fontLevelBox,
  fontWeight,
  grid: {
    tablet: '600px',
  },
});

export default theme;
