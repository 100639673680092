import React, { FC } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import { FormControl, FormControlLabel } from '@mui/material';
import Text from '../text/Text';

const StyledRadioGroup = styled(RadioGroup)<{ orientation: 'horizontal' | 'vertical' }>`
  flex-direction: ${({ orientation }) => (orientation === 'horizontal' ? 'row' : 'column')};
  gap: 12px;
`;

const StyledRadioOption = styled('div')`
  display: flex;
  align-items: center;
`;

interface RadioOption {
  value: string;
  label: string;
  name: string;
}

interface RadioButtonsGroupProps {
  groupName: string;
  selectedValue: string;
  options: RadioOption[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  orientation?: 'horizontal' | 'vertical';
  isError?: boolean;
  disabled?: boolean;
}

const RadioButtonsGroup:FC<RadioButtonsGroupProps> = ({
  groupName,
  options,
  selectedValue = '',
  onChange,
  orientation = 'horizontal',
  isError = false,
  disabled = false,
}) => (
  <FormControl>
    <StyledRadioGroup name={groupName} orientation={orientation}>
      {options.map(({ value, label, name }) => (
        <StyledRadioOption key={`${value}-${name}`}>
          <FormControlLabel
            value={value}
            disabled={disabled}
            control={(
              <Radio
                checked={selectedValue === value}
                onChange={onChange}
                value={value}
                name={name}
                sx={{
                  color: (theme) => (isError ? theme.palette.red.variation40 : theme.palette.neutral.variation20),
                }}
              />
            )}
            label={(
              <Text fontLevel="body" fontSize="large">
                {label}
              </Text>
            )}
          />
        </StyledRadioOption>
      ))}
    </StyledRadioGroup>
  </FormControl>
);

export default RadioButtonsGroup;
