import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Submissions from './submissions/Submissions';
import MechanicReview from './review/MechanicReview';
import { Spinner } from '../components';
import { useAlert } from '../hooks';
import { ISubmissionItem } from './types';
import { Submission } from '../types/submission';

const SubmissionPage = () => {
  // temporary, since it is hardcoded
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submissions, setSubmissions] = useState<ISubmissionItem[]>([]);
  const [submissionToReview, setSubmissionToReview] = useState<Submission | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const alert = useAlert();

  useEffect(() => {
    setIsLoading(true);

    /** TODO: replace to fetch list of submissions */
    new Promise((res) => { setTimeout(res, 500); })
      .catch(() => {
        alert.error({ title: t('cloud_dvir__error_retrieving_dvir_history'), children: t('cloud_dvir__error_submit_dvir_descr') });
      })
      .finally(() => setIsLoading(false));
  }, []);

  // temporary, as it is hardcoded
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReview = (submission: ISubmissionItem) => {
    setIsLoading(true);

    /** TODO: replace to fetch submission by id */
    new Promise((res) => { setTimeout(res, 500); })
      .catch(() => {
        alert.error({ title: t('cloud_dvir__error_retrieving_dvir'), children: t('cloud_dvir__error_submit_dvir_descr') });
      })
      .finally(() => setIsLoading(false));
  };

  const handleExitReview = () => {
    setSubmissionToReview(null);
  };

  return (
    <>
      {isLoading && <Spinner />}
      {submissionToReview
        ? <MechanicReview onExit={handleExitReview} submission={submissionToReview} />
        : <Submissions submissions={submissions} onReview={handleReview} />}
    </>
  );
};

export default SubmissionPage;
