import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { Box } from '@mui/material';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import RestoreIcon from '@mui/icons-material/Restore';
import { useTranslation } from 'react-i18next';
import BoxItem from './BoxItem';
import DvirReview from './DvirReview';
import HistoryView from './HistoryView/HistoryView';
import HistoryItem from './HistoryItem';
import api from '../api';
import { API_VERSION } from '../api/paths';
import { DriverLaunchDataContext } from '../context/LaunchDataContext';
import { DvirHistoryData } from './types';
import { Spinner } from '../components';
import { EventsManagerContext } from '../context/EventsManagerContext';
import { PageMode } from '../pages';
import { EVENT_TYPE } from '../context/constants';
import { DvirStatus } from '../types/types';
import { useAlert } from '../hooks';
import { SubmissionType } from '../types/submission';
import EquipmentBar from './EquipmentBar';
import { StoreContext } from '../context/StoreContext';

const FlagIcon = <EmojiFlagsIcon sx={{ width: 36, height: 36, color: (theme) => theme.palette.neutral.variation40 }} />;
const ClockIcon = <RestoreIcon sx={{ width: 36, height: 36, color: (theme) => theme.palette.neutral.variation40 }} />;


interface HomeProps {
  onStartDvir: () => void;
  onReview: (dvir: DvirHistoryData, reviewType: PageMode) => void;
}

const Home: FC<HomeProps> = ({ onStartDvir, onStartTrailerDvir, onReview }) => {
  const { t } = useTranslation();
  const { context, submissionUrl } = useContext(DriverLaunchDataContext);
  const { driverData, vin } = useContext(EventsManagerContext);
  const { allowTrailers, isPreTripCompleted, isPostTripCompleted } = useContext(DriverLaunchDataContext);
  const {
    trailer,
    setTrailerVin,
    preTripCompleted,
    setPreTripCompleted,
    postTripCompleted,
    setPostTripCompleted,
  } = useContext(StoreContext);
  const alert = useAlert();

  const [dvirForReview, setDvirForReview] = useState<DvirHistoryData[]>();
  const [vehicleHistoryForReview, setVehicleHistoryForReview] = useState<DvirHistoryData[]>();
  const [trailerHistoryReview, setTrailerHistoryReview] = useState<DvirHistoryData[]>();
  const [dvirHistory, setDvirHistory] = useState<DvirHistoryData[]>([]);
  const [vehicleHistory, setVehicleHistory] = useState<DvirHistoryData[]>([]);
  const [trailerHistory, setTrailerHistory] = useState<DvirHistoryData[]>([]);

  const [isHistoryView, setIsHistoryView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  setPreTripCompleted(isPreTripCompleted === 'true');
  setPostTripCompleted(isPostTripCompleted === 'true');

  const concatHistory = (vehicleData: DvirHistoryData[], trailerData: DvirHistoryData[]) => {
    const history: DvirHistoryData[] = [];
    vehicleData?.forEach((item) => {
      history.push({ ...item, type: SubmissionType.Vehicle });
    });
    trailerData?.forEach((item) => {
      history.push({ ...item, type: SubmissionType.Trailer });
    });
    history.sort((a, b) => {
      if (a.submissionDate < b.submissionDate) return 1;
      if (a.submissionDate > b.submissionDate) return -1;
      return 0;
    });
    return history;
  };

  const getHomePageData = async () => {
    setIsLoading(true);

    await Promise.allSettled([
      api.get<DvirHistoryData[]>(`${submissionUrl}/${API_VERSION}/history/${context}/${vin}`, { vin })
        .then((data) => setVehicleHistory(data.reverse()))
        .catch(() => {
          alert.error({ title: t('cloud_dvir__error_retrieving_dvir_history'), children: t('cloud_dvir__error_submit_dvir_descr') });
        }),
      api.get<DvirHistoryData[]>(`${submissionUrl}/${API_VERSION}/history/${context}/${vin}?status=${DvirStatus.AwaitingSign}`, { vin })
        .then((data) => setVehicleHistoryForReview(data))
        .catch(() => {
          alert.error({ title: t('cloud_dvir__error_retrieving_dvir_history'), children: t('cloud_dvir__error_submit_dvir_descr') });
        }),
      ...(trailer?.vin) ? [
        api.get<DvirHistoryData[]>(`${submissionUrl}/${API_VERSION}/history/${context}/${trailer.vin}`, { vin: trailer.vin })
          .then((data) => setTrailerHistory(data.reverse()))
          .catch(() => {
            alert.error({ title: t('cloud_dvir__error_retrieving_dvir_history'), children: t('cloud_dvir__error_submit_dvir_descr') });
          }),
        api.get<DvirHistoryData[]>(`${submissionUrl}/${API_VERSION}/history/${context}/${trailer.vin}?status=${DvirStatus.AwaitingSign}`, { vin: trailer.vin })
          .then((data) => setTrailerHistoryReview(data))
          .catch(() => {
            alert.error({ title: t('cloud_dvir__error_retrieving_dvir_history'), children: t('cloud_dvir__error_submit_dvir_descr') });
          }),
      ] : [],
    ])
      .finally(() => {
        if (!trailer?.vin) {
          setTrailerHistory([]);
          setTrailerHistoryReview([]);
        }
        setIsLoading(false);
      });
  };

  const refreshData = (shouldUpdate) => {
    if (shouldUpdate && vin) {
      getHomePageData();
    }
  };

  const toggleHistoryView = () => {
    setIsHistoryView(!isHistoryView);
    refreshData(isHistoryView);
  };

  const handleRefreshHomePageDataEvent = (event) => {
    if (event.detail && event.detail.type === EVENT_TYPE.REFRESH_HOME_PAGE_DATA) {
      refreshData(true);
    }
  };

  useEffect(() => {
    setDvirHistory(concatHistory(vehicleHistory, trailerHistory));
    setDvirForReview(concatHistory(vehicleHistoryForReview, trailerHistoryReview));
  }, [vehicleHistory, trailerHistory, vehicleHistoryForReview, trailerHistoryReview]);

  useEffect(() => {
    if (driverData?.driverId) {
      const trailerVinStorageKey = `trailer_vin_${driverData?.driverId}`;
      const value = localStorage.getItem(trailerVinStorageKey);
      setTrailerVin(value);
    }
  }, [driverData]);

  useEffect(() => {
    refreshData(true);

    window.addEventListener(EVENT_TYPE.REFRESH_HOME_PAGE_DATA, handleRefreshHomePageDataEvent);

    return () => {
      window.removeEventListener(EVENT_TYPE.REFRESH_HOME_PAGE_DATA, handleRefreshHomePageDataEvent);
    };
  }, [vin, trailer]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isHistoryView) {
    return <HistoryView onExit={toggleHistoryView} onReview={onReview} dvirHistory={dvirHistory} />;
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: 4,
      paddingTop: 5,
      paddingLeft: 2,
      paddingRight: 2,
      paddingBottom: 5,
    }}
    >
      <EquipmentBar
        showTrailer={allowTrailers === 'true'}
        isPreTripCompleted={preTripCompleted}
        isPostTripCompleted={postTripCompleted}
        dvirForReview={dvirForReview}
        id="equipment-bar"
        onStartTrailerDvir={onStartTrailerDvir}
      />
      <BoxItem
        icon={FlagIcon}
        title={t('cloud_dvir__pending_review')}
        buttonText={t('cloud_dvir__start_dvir')}
        onClick={onStartDvir}
        disabled={dvirForReview !== undefined && dvirForReview.length > 0}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          paddingLeft: 2,
          paddingRight: 2,
        }}
        >
          {dvirForReview?.map((dvir) => (
            <DvirReview
              key={dvir.id}
              submissionDate={Date.parse(dvir.submissionDate)}
              driverName={dvir.driverName}
              type={dvir.type}
              vehicleNum={dvir.vehicleNum}
              id={dvir.id}
              onReview={() => onReview(dvir, PageMode.View)}
            />
          ))}
        </Box>
      </BoxItem>
      <BoxItem
        icon={ClockIcon}
        title={t('cloud_dvir__dvir_history')}
        buttonText={t('cloud_dvir__view_all')}
        onClick={toggleHistoryView}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {dvirHistory.map((dvirHistoryData) => (
            <HistoryItem
              key={dvirHistoryData.id}
              dvirHistoryData={dvirHistoryData}
              onClick={() => { onReview(dvirHistoryData, PageMode.HistoryView); }}
              isHomePage
            />
          ))}
        </Box>
      </BoxItem>
    </Box>
  );
};

export default Home;
