type TimeOptions = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export const msInSecond = 1000;
export const msInMinute = msInSecond * 60;
export const msInHour = msInMinute * 60;
export const msInDay = msInHour * 24;

const ms: TimeOptions = {
  seconds: msInSecond,
  minutes: msInMinute,
  hours: msInHour,
  days: msInDay,
};

const add = (date: Date | number, options: TimeOptions): number => {
  const dirtyDate = date instanceof Date ? date.getTime() : date;

  const finalDate = Object.entries(options).reduce((result, [unit, amount]) => {
    if (ms[unit] && amount) {
      return ms[unit] * amount + result;
    }

    return result;
  }, dirtyDate);

  return finalDate;
};

const dateUtils = {
  add,
};

export default dateUtils;
