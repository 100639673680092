import React from 'react';
import { EventsManagerProvider } from '../context/EventsManagerContext';
import { LaunchDataContextProvider } from '../context/LaunchDataContext';
import ContentSwitcher from './ContentSwitcher';
import { DriverURLDataPoints } from './constants';
import { DVIR_MODE } from '../constants';
import { DvirFailedSubmissionRetrierProvider } from '../context/DvirFailedSubmissionRetrier';
import { StoreProvider } from '../context/StoreContext';

const DriverDVIR = () => (
  <StoreProvider>
    <LaunchDataContextProvider dataPoints={DriverURLDataPoints} mode={DVIR_MODE.DRIVER}>
      <EventsManagerProvider>
        <DvirFailedSubmissionRetrierProvider>
          <ContentSwitcher />
        </DvirFailedSubmissionRetrierProvider>
      </EventsManagerProvider>
    </LaunchDataContextProvider>
  </StoreProvider>
);

export default DriverDVIR;
