import React, {
  useRef, useContext, useState, useEffect,
} from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import SignaturePad, { SignaturePadProps } from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { Modal, Text, Button } from '../../components';
import { StyledSignImage } from './StyledComponents';
import b64toBlob from '../../utils/b64toBlob';
import { createGUID } from '../utils/utils';
import { EventsManagerContext } from '../../context/EventsManagerContext';

/** SignaturePad doesn't accept directly className, so we could style it only using canvasProps.className */
const signaturePadClassName = 'signature-pad';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError: boolean }>(({ theme, isError }) => ({
  width: '100%',
  height: '100%',
  flexGrow: 1,

  [`.${signaturePadClassName}`]: {
    width: '100%',
    height: '360px',
    border: isError ? `2px solid ${theme.palette.red.variation50}` : `2px solid ${theme.palette.neutral.variation80}`,
    borderRadius: '12px',
    backgroundColor: theme.palette.neutral.variation100,
  },
}));

const StyledButton = styled(Button)`
  min-width: 90px;
`;

const SignModal = ({
  handleClose, onSign, sign, signId, onDeletePreviousSign,
}) => {
  const [isError, setIsError] = useState<boolean>(false);

  const { vin } = useContext(EventsManagerContext);

  const { t } = useTranslation();

  const signatureDrawerRef = useRef<SignaturePadProps>();

  const restoreSignature = () => {
    const cachedSignature = localStorage.getItem(`signature_${signId.replace(' ', '_')}`);
    if (cachedSignature) {
      onSign({ src: cachedSignature, id: createGUID(`SIGNATURE-${vin}`) });
    }
  };

  useEffect(() => {
    if (!sign?.src && signId) {
      restoreSignature();
    }
  }, []);

  const resetSign = () => {
    URL.revokeObjectURL(sign.src);
    onDeletePreviousSign();
  };

  const resetError = () => {
    setIsError(false);
  };

  const handleClear = () => {
    if (sign?.src) {
      resetSign();
      return;
    }

    if (!signatureDrawerRef.current) {
      return;
    }

    signatureDrawerRef.current.clear();
  };

  const handleTrimSign = () => {
    if (!signatureDrawerRef.current) {
      handleClose();

      return;
    }

    if (signatureDrawerRef.current.isEmpty()) {
      setIsError(true);
      return;
    }

    const dataUrlBase64 = signatureDrawerRef.current.getTrimmedCanvas().toDataURL('image/jpeg', 0.2).replace('data:image/jpeg;base64,', '');

    const blob = b64toBlob(dataUrlBase64);

    onSign({ src: URL.createObjectURL(blob), id: createGUID(`SIGNATURE-${vin}`) });
    handleClose();
  };

  return (
    <Modal open onClose={() => {}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '436px' }}>
        <Box sx={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3,
        }}
        >
          <Text
            fontLevel="title"
            fontSize="large"
            sx={{
              color: (theme) => theme.palette.neutral.variation40,
            }}
          >
            {t('cloud_dvir__signature')}
          </Text>
          <CloseIcon
            onClick={handleClose}
            sx={{
              width: 24,
              height: 24,
              color: (theme) => theme.palette.neutral.variation40,
              cursor: 'pointer',
            }}
          />
        </Box>
        <StyledBox isError={isError} onTouchStart={resetError} onMouseDown={resetError}>
          {sign?.src
            ? <StyledSignImage src={sign.src} />
            : (
              <SignaturePad
                canvasProps={{ className: signaturePadClassName }}
                ref={signatureDrawerRef}
                /** signature should be white-and-black */
                backgroundColor="#fff"
              />
            )}
          <Box sx={{
            display: 'flex', justifyContent: 'center', gap: 3, paddingTop: 2,
          }}
          >
            <StyledButton textTransform="uppercase" isFilled onClick={handleClear}>{sign?.src ? t('cloud_dvir__reset') : t('cloud_dvir__clear')}</StyledButton>
            <StyledButton textTransform="uppercase" isFilled onClick={handleTrimSign}>{sign?.src ? t('cloud_dvir__ok') : t('cloud_dvir__sign')}</StyledButton>
          </Box>
        </StyledBox>
      </Box>
    </Modal>
  );
};

export default SignModal;
