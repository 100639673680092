export type FontBox = {
  fontSize: string;
  lineHeight: string;
}

export type FontLevelBoxSizes = {
  large: FontBox;
  medium: FontBox;
  small: FontBox;
}

export type FontLevelBox = {
  display: FontLevelBoxSizes,
  headline: FontLevelBoxSizes,
  title: FontLevelBoxSizes,
  label: FontLevelBoxSizes,
  body: FontLevelBoxSizes,
}

const fontLevelsBox: FontLevelBox = {
  display: {
    large: { fontSize: '57px', lineHeight: '64px' },
    medium: { fontSize: '45px', lineHeight: '52px' },
    small: { fontSize: '36px', lineHeight: '44px' },
  },
  headline: {
    large: { fontSize: '32px', lineHeight: '40px' },
    medium: { fontSize: '28px', lineHeight: '36px' },
    small: { fontSize: '24px', lineHeight: '32px' },
  },
  title: {
    large: { fontSize: '22px', lineHeight: '28px' },
    medium: { fontSize: '16px', lineHeight: '24px' },
    small: { fontSize: '14px', lineHeight: '20px' },
  },
  label: {
    large: { fontSize: '14px', lineHeight: '20px' },
    medium: { fontSize: '12px', lineHeight: '16px' },
    small: { fontSize: '11px', lineHeight: '16px' },
  },
  body: {
    large: { fontSize: '16px', lineHeight: '24px' },
    medium: { fontSize: '14px', lineHeight: '20px' },
    small: { fontSize: '12px', lineHeight: '16px' },
  },
};

export default fontLevelsBox;
