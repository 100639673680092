import { Typography, TypographyProps as MUITypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type FontLevel = 'display' | 'headline' | 'title' | 'label' | 'body';
type FontSize = 'large' | 'medium' | 'small';
type FontWeight = 'normal' | 'lightBold' | 'bold' | 'bolder';

interface TextProps extends MUITypographyProps {
  fontLevel?: FontLevel;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  textAlign?: 'left' | 'right' | 'center'
}

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'fontLevel' && prop !== 'fontSize' && prop !== 'fontWeight' && prop !== 'textAlign',
})<TextProps>(({
  fontLevel = 'body', fontSize = 'medium', fontWeight = 'normal', textAlign = 'left', theme,
}) => ({
  ...theme.fontLevelBox[fontLevel][fontSize],
  fontWeight: theme.fontWeight[fontWeight],
  textAlign,
}));

export default Text;
