import { SubmissionType } from './submission';

export type Seasonal = {
  start: { day: number, month: number },
  end: { day: number, month: number },
}

export type TemplateCategoryItem = {
  name: string;
  id: string;
  critical?: boolean;
  seasonal?: Seasonal;
}

export enum CategoryType {
  Trailer = 'trailer',
  Vehicle = 'vehicle',
}

export type TemplateCategory = {
  name: string;
  items: TemplateCategoryItem[];
  categoryType: CategoryType;
}

interface Template {
  name: string;
  categories: TemplateCategory[];
  templateType?: SubmissionType;
  templateTypes?: SubmissionType[];
  /** timeToLive - specifies time to live for dvir form (units - days) */
  timeToLive: number;
}

export default Template;

export interface TemplateResponse extends Template {
  trailerTemplate?: Template;
}
