import React, { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '../components';
import Dvir from '../types/dvir';
import formatDate from '../utils/format-date';
import { SubmissionType } from '../types/submission';

interface DvirReviewProps extends Dvir {
  onReview: () => void;
}

const DvirReview: FC<DvirReviewProps> = ({
  onReview, driverName, submissionDate, type, vehicleNum,
}) => {
  const { t } = useTranslation();

  const handleReview = () => {
    onReview();
  };

  const submissionTimestamp = useMemo(() => formatDate(new Date(submissionDate)), [submissionDate]);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 1,
      backgroundColor: (theme) => theme.palette.green.variation95,
      borderRadius: '8px',
      padding: 3,
    }}
    >
      <Box>
        {type === SubmissionType.Vehicle && (
          <>
            <Text fontSize="medium" fontLevel="title" fontWeight="bold">
              {`${t('cloud_dvir__vehicle')} ${t('cloud_dvir__needs_review')}`}
            </Text>
            <Text fontSize="large" fontLevel="body">{`${t('cloud_dvir__vehicle_no')}: ${vehicleNum}`}</Text>
          </>
        )}
        {type === SubmissionType.Trailer && (
          <>
            <Text fontSize="medium" fontLevel="title" fontWeight="bold">
              {`${t('cloud_dvir__trailer')} ${t('cloud_dvir__needs_review')}`}
            </Text>
            <Text fontSize="large" fontLevel="body">{`${t('cloud_dvir__trailer_no')}: ${vehicleNum}`}</Text>
          </>
        )}
        <Text fontSize="large" fontLevel="body">{`${t('cloud_dvir__submitted')}: ${submissionTimestamp}`}</Text>
        <Text fontSize="large" fontLevel="body">{`${t('cloud_dvir__completed_by')}: ${driverName}`}</Text>
      </Box>
      <Button
        onClick={handleReview}
        textTransform="uppercase"
      >
        {t('cloud_dvir__review_btn')}
      </Button>
    </Box>
  );
};

export default DvirReview;
