import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components';
import SubmissionItem from './SubmissionItem';
import { ISubmissionItem } from '../types';

interface HistoryViewProps {
    submissions: ISubmissionItem[];
    onReview: (submission: ISubmissionItem) => void;
}

const Submissions:FC<HistoryViewProps> = ({ submissions, onReview }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{
        width: '100%', padding: 2, maxWidth: '800px', margin: '0 auto',
      }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px 0' }}>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <Text fontSize="large" fontLevel="title">{t('cloud_dvir__dvir_review')}</Text>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
          {submissions.map((submission) => (
            <SubmissionItem
              key={`${submission.submissionDate}-${submission.vehicleNum}`}
              submission={submission}
              onClick={onReview}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Submissions;
