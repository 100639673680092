import { S3Type } from '../forms/useAttachments';

class S3SignedLinkFileUploader {
  constructor() {
    throw new Error('S3FileUploader class cannot be instantiated.');
  }

  static uploadImage(signedUrl, blobUrl) {
    return this.upload(signedUrl, blobUrl, S3Type.JPEG);
  }

  static upload(signedUrl: string, blobUrl: string, type: string) {
    return new Promise((success, fail) => {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => this.fetch(signedUrl, blob, type, success, fail))
        .catch((error) => fail(error));
    });
  }

  static fetch(signedUrl, blob: Blob, type: string, success?: (uploadResponse: Response) => void, fail?: (reason?: unknown) => void) {
    return fetch(signedUrl, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': type,
      },
    })
      .then((uploadResponse) => {
        if (uploadResponse.ok && success) {
          success(uploadResponse);
        } else if (fail) {
          fail(uploadResponse);
        }
      })
      .catch((error) => {
        fail(error);
      });
  }
}

export default S3SignedLinkFileUploader;
