import React, { FC } from 'react';
import { SxProps, styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from 'react-i18next';
import Text from '../text/Text';
import camera from '../../utils/camera';

const StyledButton = styled('button')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 104px;
  height: 104px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.variation80}`};
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme, disabled }) => (disabled ? theme.palette.neutral.variation80 : theme.palette.neutral.variation0)};
`;

const StyledText = styled(Text)`
  color: inherit;
`;

interface AddPhotoProps {
  isDisabled?: boolean;
  onAddPhoto: (base64: string) => void;
  sx?: SxProps
}

const AddPhoto:FC<AddPhotoProps> = ({ isDisabled, onAddPhoto, sx = {} }) => {
  const { t } = useTranslation();

  const handleLaunchCamera = () => {
    const onSuccess = (data: string) => {
      onAddPhoto(data);
    };

    camera.getPicture(onSuccess);
  };

  return (
    <StyledButton
      disabled={isDisabled}
      type="button"
      onClick={handleLaunchCamera}
      sx={sx}
    >
      <AddPhotoAlternateIcon sx={{ color: 'inherit' }} />
      <StyledText fontLevel="body" fontSize="large">{t('cloud_dvir__add_photos')}</StyledText>
    </StyledButton>
  );
};

export default AddPhoto;
