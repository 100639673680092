import React, { FC } from 'react';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components';
import { hexToRGB } from '../../utils/css-utils';

interface NoteProps {
  noteText: string;
  isDriverNote?: boolean;
  readOnly?: boolean;
  createdAt: number | string;
  onEdit: () => void;
  onDelete: () => void;
  isOdd?: boolean;
}

const Note: FC<NoteProps> = ({
  noteText, isDriverNote = true, createdAt, onEdit, onDelete, readOnly = false, isOdd = false,
}) => {
  const { t } = useTranslation();

  const formattedDate = new Date(createdAt).toLocaleString('en-US');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 2,
        backgroundColor: (theme) => (isOdd ? hexToRGB(theme.palette.blue.variation80, 0.2) : hexToRGB(theme.palette.darkBlue.variation90, 0.2)),
        borderRadius: '12px',
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <Text
            fontLevel="label"
            fontSize="medium"
            fontWeight="lightBold"
            sx={{ color: (theme) => theme.palette.neutral.variation20 }}
          >
            {isDriverNote ? t('cloud_dvir__driver_note_from') : t('cloud_dvir__mechanic_note_from')}
          </Text>
          <Text
            fontLevel="label"
            fontSize="medium"
            sx={{ color: (theme) => theme.palette.neutral.variation30 }}
          >
            {formattedDate}
          </Text>
        </Box>
        <Text
          fontLevel="body"
          fontSize="large"
          sx={{ color: (theme) => theme.palette.neutral.variation10 }}
        >
          {noteText}
        </Text>
      </Box>
      {!readOnly && (
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <EditIcon
            onClick={onEdit}
            sx={{ cursor: 'pointer' }}
          />
          <DeleteIcon
            onClick={onDelete}
            sx={{ cursor: 'pointer' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Note;
