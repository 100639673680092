import React, { FC, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Chip, Text, Button } from '../../components';
import AttachmentsModal from '../attachments/AttachmentsModal';
import { InspectionValue } from '../../types/submission';
import { InspectionFieldState } from '../forms/useInspectionForm';
import { AttachmentFieldItem, AddAttachment } from '../forms/useAttachments';
import getChipStatusAndLabelByInspection from './utils';

const StyledChip = styled(Chip)`
  width: 100%;
`;

interface InspectionItemProps {
  title: string;
  name: string;
  okLabel?: string;
  hasCriticalIssue?: boolean;
  needsRepairLabel?: string;
  field: InspectionFieldState;
  readOnly?: boolean;
  onStatusSelect?: (itemName: string, value: InspectionValue) => void;
  withoutAttachments?: boolean;
  showNotApplicableOption?: boolean;
  attachments?: AttachmentFieldItem;
  onAddAttachment?: (attachmentOptions: Omit<AddAttachment, 'categoryName'>) => void;
  onEditNote?: (itemName: string, noteText: string) => void;
  onDeleteNote?: (itemName: string) => void;
  onDeletePhoto?: (itemName: string, photoId: string) => void;
  isDriverSignOff?: boolean;
}

const InspectionItem: FC<InspectionItemProps> = ({
  title,
  name,
  okLabel,
  needsRepairLabel,
  readOnly = false,
  hasCriticalIssue = false,
  field: { value, isError },
  withoutAttachments = false,
  showNotApplicableOption = true,
  onStatusSelect = () => {},
  attachments,
  onAddAttachment = () => {},
  onEditNote = () => {},
  onDeleteNote = () => {},
  onDeletePhoto = () => {},
  isDriverSignOff = false,
}) => {
  const { t } = useTranslation();

  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState<boolean>(false);

  const handleStatusChange = (v: InspectionValue) => {
    if (!readOnly) {
      onStatusSelect(name, v);
    }
  };

  const toggleAttachmentModal = () => {
    setIsAttachmentsModalOpen(!isAttachmentsModalOpen);
  };

  const handleAddAttachment = ({ toAttach, type }: Omit<AddAttachment, 'categoryName' | 'itemName'>) => {
    onAddAttachment({ itemName: name, toAttach, type });
  };

  const handleEditNote = (noteText: string) => {
    onEditNote(name, noteText);
  };

  const handleDeleteNote = () => {
    onDeleteNote(name);
  };

  const handleDeletePhoto = (photoId: string) => {
    onDeletePhoto(name, photoId);
  };

  const renderStatusChips = () => (
    <>
      <StyledChip
        label={okLabel ? t(okLabel) : t('cloud_dvir__ok')}
        icon={<CheckIcon fontSize="small" color="inherit" />}
        size="medium"
        disabled={hasCriticalIssue}
        status={value === InspectionValue.Ok ? 'success' : null}
        onClick={() => handleStatusChange(InspectionValue.Ok)}
      />
      <StyledChip
        label={needsRepairLabel ? t(needsRepairLabel) : t('cloud_dvir__needs_repair')}
        icon={<TaxiAlertIcon fontSize="small" color="inherit" />}
        size="medium"
        status={value === InspectionValue.NeedsRepair ? 'error' : null}
        onClick={() => handleStatusChange(InspectionValue.NeedsRepair)}
      />
      {showNotApplicableOption && (
        <StyledChip
          label={t('cloud_dvir__not_applicable')}
          icon={<RemoveCircleOutlineIcon fontSize="small" color="inherit" />}
          size="medium"
          status={value === InspectionValue.NotApplicable ? 'neutral' : null}
          onClick={() => handleStatusChange(InspectionValue.NotApplicable)}
        />
      )}
    </>
  );

  const renderReadOnlyStatusChip = () => {
    const statusLabels = {
      OK: okLabel,
      NEEDS_REPAIR: needsRepairLabel,
    };
    const { label, status, icon } = getChipStatusAndLabelByInspection(value, statusLabels);

    return <StyledChip label={t(label)} icon={icon} size="medium" status={status} onClick={() => {}} />;
  };

  const renderDriverReview = () => (
    <Box
      sx={{
        padding: 1.5,
        border: 1,
        borderRadius: '8px',
        backgroundColor: (theme) => theme.palette.neutral.variation100,
        borderColor: (theme) => (isError ? theme.palette.red.variation60 : theme.palette.neutral.variation90),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 3,
        }}
      >
        <Text>{title}</Text>
        {!withoutAttachments && (
          <Button size="small" startIcon={<AddIcon />} onClick={toggleAttachmentModal}>
            {t(readOnly ? 'cloud_dvir__view_photos_notes' : 'cloud_dvir__add_photos_notes')}
          </Button>
        )}
      </Box>
      {hasCriticalIssue && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: (theme) => theme.palette.red.variation30,
            paddingBottom: 3,
          }}
        >
          <Text>{t('cloud_dvir__approval_critical_alert')}</Text>
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '12px' }}>{readOnly ? renderReadOnlyStatusChip() : renderStatusChips()}</Box>
    </Box>
  );

  const renderDriverSignOff = () => (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        justifyContent: 'flex-start',
        padding: 1.5,
        border: 1,
        borderRadius: '8px',
        backgroundColor: (theme) => theme.palette.neutral.variation100,
        borderColor: (theme) => (isError ? theme.palette.red.variation60 : theme.palette.neutral.variation90),
      }}
    >
      <Text sx={{ width: '30%' }}>{title}</Text>
      <Box sx={{ width: '30%' }}>{renderReadOnlyStatusChip()}</Box>
      {!withoutAttachments && (
        <Button
          sx={{
            width: '40%',
          }}
          size="small"
          startIcon={<AddIcon />}
          onClick={toggleAttachmentModal}
        >
          {t(readOnly ? 'cloud_dvir__view_photos_notes' : 'cloud_dvir__add_photos_notes')}
        </Button>
      )}
    </Box>
  );

  return (
    <>
      {attachments && (
        <AttachmentsModal
          isOpen={isAttachmentsModalOpen}
          onClose={toggleAttachmentModal}
          readOnly={readOnly}
          title={title}
          attachments={attachments}
          onAddAttachment={handleAddAttachment}
          onEditNote={handleEditNote}
          onDeleteNote={handleDeleteNote}
          onDeletePhoto={handleDeletePhoto}
        />
      )}
      {isDriverSignOff ? renderDriverSignOff() : renderDriverReview()}
    </>
  );
};

export default InspectionItem;
