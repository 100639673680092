import Template, { Seasonal } from '../types/template';

const isDateWithinSeasonal = (seasonal: Seasonal): boolean => {
  if (!seasonal) {
    return true;
  }

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;

  const {
    start: { month: startMonth, day: startDay },
    end: { month: endMonth, day: endDay },
  } = seasonal;

  const sd = startMonth * 100 + startDay;
  const ed = endMonth * 100 + endDay;

  // seasonal period crosses over to the next year. compare dates in MMDD format
  if (sd > ed) {
    return (currentMonth > startMonth || currentMonth < endMonth)
        || (currentMonth === startMonth && currentDay >= startDay)
        || (currentMonth === endMonth && currentDay <= endDay);
  }

  // seasonal period stays within the same year
  return (currentMonth > startMonth || (currentMonth === startMonth && currentDay >= startDay))
        && (currentMonth < endMonth || (currentMonth === endMonth && currentDay <= endDay));
};

export const filterSeasonalCategoryItems = (template: Template): Template => {
  const relevantCategories = template.categories.reduce((categories, category) => {
    const categoryItems = category.items.reduce((items, item) => (isDateWithinSeasonal(item?.seasonal) ? [...items, item] : items), []);

    return categoryItems.length > 0 ? [...categories, { ...category, items: categoryItems }] : categories;
  }, []);

  return {
    ...template,
    categories: relevantCategories,
  };
};
