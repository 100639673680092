import React, { FC } from 'react';
import {
  Box, Button as MUIButton, ButtonProps as MUIButtonProps, Theme,
} from '@mui/material';
import { SxProps, styled } from '@mui/material/styles';
import Text from '../text/Text';
import { hexToRGB } from '../../utils/css-utils';

type ViewType = 'primary' | 'secondary';

const getBackgroundColor = ({
  theme, viewType, isHovered = false, isDisabled = false,
}: { theme: Theme, viewType: ViewType, isHovered?: boolean, isDisabled?: boolean}) => {
  let color: string;

  switch (viewType) {
    case 'primary':
      color = isHovered ? theme.palette.darkBlue.variation30 : theme.palette.darkBlue.variation50;
      break;

    case 'secondary':
      color = isHovered ? theme.palette.neutral.variation80 : theme.palette.neutral.variation90;
      break;

    default:
      color = isHovered ? theme.palette.darkBlue.variation30 : theme.palette.darkBlue.variation50;
  }

  return isDisabled ? hexToRGB(color, 0.35) : color;
};

const getColor = (theme: Theme, viewType: ViewType) => {
  switch (viewType) {
    case 'primary':
      return theme.palette.neutral.variation100;

    case 'secondary':
      return theme.palette.neutral.variation20;

    default:
      return theme.palette.darkBlue.variation100;
  }
};

const BUTTON_SIZE = {
  small: '32px;',
  medium: '40px',
  large: '48px',
};

const StyledIconButton = styled(MUIButton, {
  shouldForwardProp: (prop) => prop !== 'viewType',
})<MUIButtonProps & { viewType: ViewType}>(({
  theme, size, viewType, disabled,
}) => ({
  minWidth: 0,
  boxSizing: 'border-box',
  width: BUTTON_SIZE[size],
  height: BUTTON_SIZE[size],
  backgroundColor: getBackgroundColor({ theme, viewType, isDisabled: disabled }),
  borderRadius: '50%',
  color: getColor(theme, viewType),
  '&:hover': {
    backgroundColor: getBackgroundColor({ theme, viewType, isHovered: true }),
  },
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}));

interface LabeledIconButtonProps extends Omit<MUIButtonProps, 'startIcon' | 'endIcon'> {
  icon: React.ReactNode;
  label: string;
  viewType?: ViewType;
  wrapperStylesSx?: SxProps;
  onClick: () => void;
  disabled?: boolean;
}

const LabeledIconButton:FC<LabeledIconButtonProps> = ({
  icon, viewType = 'primary', wrapperStylesSx = {}, label, onClick, disabled = false, ...restProps
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        cursor: 'pointer',
        ...wrapperStylesSx,
      }}
    >
      <StyledIconButton
        disabled={disabled}
        viewType={viewType}
        startIcon={icon}
        {...restProps}
      />
      <Text
        sx={{
          color: (theme) => theme.palette.darkBlue.variation20,
        }}
        fontLevel="body"
        fontSize="small"
      >
        {label}
      </Text>
    </Box>
  );
};

export default LabeledIconButton;
