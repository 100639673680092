import React, { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Collapse, Text } from '../../../components';
import { InspectionValue, SubmissionCategory } from '../../../types/submission';
import CategoryItem from './CategoryItem';
import { MechanicReviewContext } from '../../context/MechanicReviewProvider';

const StyledCollapse = styled(Collapse)<{ hisFieldsToRepair: boolean }>`
  background-color: ${({ theme, hisFieldsToRepair }) => (hisFieldsToRepair ? theme.palette.blue.variation50 : theme.palette.darkBlue.variation95)};
  color: ${({ theme, hisFieldsToRepair }) => (hisFieldsToRepair ? theme.palette.neutral.variation100 : theme.palette.darkBlue.variation50)};
`;

const StyledRepairBadge = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.red.variation40};
  border-radius: 50%;
`;

const StyledCollapseTitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`;

const StyledCollapseContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface CategoryProps {
    category: SubmissionCategory;
}

const Category:FC<CategoryProps> = ({ category: { name, items } }) => {
  const { submissionUpdates } = useContext(MechanicReviewContext);

  const remainingAmountItemsToRepair = useMemo(() => {
    const remainingItems = items.filter((item) => item.events[0].safetyStatus === InspectionValue.NeedsRepair && !submissionUpdates[item.id]?.safetyStatus);

    return remainingItems.length;
  }, [items, submissionUpdates]);

  const hasCategoryFieldsToRepair = remainingAmountItemsToRepair > 0;

  return (
    <StyledCollapse
      alwaysOpen={hasCategoryFieldsToRepair}
      hisFieldsToRepair={hasCategoryFieldsToRepair}
      title={(
        <StyledCollapseTitle>
          <Text
            sx={{ textTransform: 'capitalize' }}
            fontLevel="title"
            fontSize="medium"
            fontWeight="lightBold"
          >
            {name.toLowerCase()}
          </Text>

          {Boolean(remainingAmountItemsToRepair) && (
          <StyledRepairBadge>
            <Text fontLevel="title" fontSize="medium" fontWeight="bold">
              {remainingAmountItemsToRepair}
            </Text>
          </StyledRepairBadge>
          )}
        </StyledCollapseTitle>
      )}
      content={(
        <StyledCollapseContent>
          {items.map((item) => <CategoryItem key={item.id} item={item} />)}
        </StyledCollapseContent>
      )}
    />
  );
};

export default Category;
