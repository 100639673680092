import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../shared/ViewTitle';
import { Checkbox, Text } from '../../../components';
import SignBox from './SignBox';

const StyledCheckBoxWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 12px;
`;

const SignReview = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ViewTitle title={t('cloud_dvir__signature')} />
      <div>
        <Text fontLevel="title" fontSize="medium" sx={{ color: (theme) => theme.palette.neutral.variation40 }}>
          {t('cloud_dvir__mechanic_signature_description')}
        </Text>
        <StyledCheckBoxWrapper>
          <Checkbox label={t('cloud_dvir__condition_satisfactory_label')} />
          <Checkbox label={t('cloud_dvir__defects_corrected_label')} />
          <Checkbox label={t('cloud_dvir__repair_not_required_label')} />
        </StyledCheckBoxWrapper>
      </div>

      <SignBox />
    </div>
  );
};

export default SignReview;
