import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Text from '../text/Text';

export type Step = {
  key: string;
  label: string;
}

const StyledStepItem = styled('div')<{ isActive: boolean }>`
  width: 80px;
  height: 4px;
  border-radius: 8px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.palette.blue.variation50 : theme.palette.neutral.variation60)};
`;

interface StepperProps {
  steps: Step[];
  currentStepKey: string;
}

const Stepper:FC<StepperProps> = ({ steps, currentStepKey }) => {
  const { t } = useTranslation();

  const activeStep = steps.find(({ key }) => key === currentStepKey);

  return (
    <Box>
      <Text
        sx={{
          textAlign: 'center',
          marginBottom: 2,
        }}
        fontLevel="title"
        fontSize="medium"
        fontWeight="lightBold"
      >
        {t(activeStep.label)}
      </Text>
      <Box
        sx={{
          display: 'flex',
          gap: 0.5,
        }}
      >
        {steps.map(({ key }) => <StyledStepItem key={key} isActive={key === currentStepKey} />)}
      </Box>
    </Box>
  );
};

export default Stepper;
