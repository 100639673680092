import React, {
  memo, createContext, useMemo, useState, useCallback,
} from 'react';

interface StoreProviderProps {
    children: React.ReactNode
}

export interface Trailer {
    vin: string;
    number: string;
}

export interface IStoreContext {
    trailer: Trailer;
    setTrailerVin?: (vin: string) => void;
    setTrailerNumber?: (number: string) => void;
    preTripCompleted: boolean;
    setPreTripCompleted?: (preTripCompleted: boolean) => void;
    postTripCompleted: boolean;
    setPostTripCompleted?: (postTripCompleted: boolean) => void;
}

export const StoreContext = createContext<IStoreContext>({
  trailer: null,
  preTripCompleted: false,
  postTripCompleted: false,
});

export const StoreProvider = memo(({ children }: StoreProviderProps) => {
  const [trailer, setTrailer] = useState<Trailer>(null);
  const [isPreTripCompleted, setIsPreTripCompleted] = useState(false);
  const [isPostTripCompleted, setIsPostTripCompleted] = useState(false);

  const setTrailerVin = useCallback((vin: string) => {
    setTrailer((prev) => ({ ...prev, vin }));
  }, []);

  const setPreTripCompleted = useCallback((preTripCompleted: boolean) => {
    if (preTripCompleted) {
      setIsPreTripCompleted(preTripCompleted);
    }
  }, []);

  const setPostTripCompleted = useCallback((postTripCompleted: boolean) => {
    if (postTripCompleted) {
      setIsPostTripCompleted(postTripCompleted);
    }
  }, []);

  const setTrailerNumber = useCallback((number: string) => {
    setTrailer((prev) => ({ ...prev, number }));
  }, []);

  const value = useMemo(() => ({
    trailer,
    setTrailerVin,
    setTrailerNumber,
    preTripCompleted: isPreTripCompleted,
    setPreTripCompleted,
    postTripCompleted: isPostTripCompleted,
    setPostTripCompleted,
  }), [
    trailer,
    setTrailerVin,
    setTrailerNumber,
    isPreTripCompleted,
    setPreTripCompleted,
    isPostTripCompleted,
    setPostTripCompleted,
  ]);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
});
