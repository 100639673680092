import React, { FC, useContext, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InspectionItem from '../shared/InspectionItem';
import { Button } from '../../components';
import SignModal from './SignModal';
import { StyledSignImage } from './StyledComponents';
import { InspectionValue, SubmissionType } from '../../types/submission';
import { OverallForm, OverallFormFields } from '../forms/useOverallForm';
import { InspectionFieldState } from '../forms/useInspectionForm';
import { EventsManagerContext } from '../../context/EventsManagerContext';

interface SignProps {
  overallForm: OverallForm;
  isSignReadOnly: boolean;
  isOverallInspectionReadOnly: boolean;
  submissionType: SubmissionType;
  onFormChange: (name: string, value: InspectionValue | string) => void;
  hasTrailer: boolean;
  isVehicleDvirSubmit: boolean;
  isTrailerDvirSubmit: boolean;
}

const Sign: FC<SignProps> = ({
  overallForm, onFormChange, isSignReadOnly, isOverallInspectionReadOnly, hasTrailer, submissionType, isVehicleDvirSubmit, isTrailerDvirSubmit,
}) => {
  const { t } = useTranslation();

  const { driverData } = useContext(EventsManagerContext);

  const [isSignDrawerOpen, setIsSignDrawerOpen] = useState<boolean>(false);

  const handleChange = (fieldName: string, value: InspectionValue) => {
    onFormChange(fieldName, value);
  };

  const handleSign = (sign) => {
    onFormChange(OverallFormFields.Sign, sign);
  };

  const handleDeletePreviousSign = () => {
    onFormChange(OverallFormFields.Sign, null);
  };

  const sign = overallForm.sign.value;

  const renderInspectionItems = () => {
    if (isOverallInspectionReadOnly) {
      const isVehicle = submissionType === SubmissionType.Vehicle;
      const overallFormField = isVehicle ? OverallFormFields.OverallVehicle : OverallFormFields.OverallTrailer;
      const hasCriticalIssue = overallForm.hasCriticalIssue[overallFormField];

      return (
        <InspectionItem
          title={isVehicle ? t('cloud_dvir__determine_the_overall_condition_of_the_vehicle') : t('cloud_dvir__determine_the_overall_condition_of_the_trailer')}
          name={overallFormField}
          okLabel="cloud_dvir__table_ok"
          needsRepairLabel="cloud_dvir__table_needs_repair"
          hasCriticalIssue={hasCriticalIssue}
          field={overallForm[isVehicle ? OverallFormFields.OverallVehicle : OverallFormFields.OverallTrailer] as unknown as InspectionFieldState}
          onStatusSelect={handleChange}
          readOnly
          withoutAttachments
          showNotApplicableOption={false}
        />
      );
    }

    return (
      <>
        {isVehicleDvirSubmit && (
        <InspectionItem
          title={t('cloud_dvir__determine_the_overall_condition_of_the_vehicle')}
          name={OverallFormFields.OverallVehicle}
          okLabel="cloud_dvir__table_ok"
          needsRepairLabel="cloud_dvir__table_needs_repair"
          hasCriticalIssue={overallForm.hasCriticalIssue[OverallFormFields.OverallVehicle]}
          field={overallForm[OverallFormFields.OverallVehicle] as unknown as InspectionFieldState}
          onStatusSelect={handleChange}
          readOnly={isOverallInspectionReadOnly}
          withoutAttachments
          showNotApplicableOption={false}
        />
        )}
        {(hasTrailer || isTrailerDvirSubmit) && (
        <InspectionItem
          title={t('cloud_dvir__determine_the_overall_condition_of_the_trailer')}
          name={OverallFormFields.OverallTrailer}
          okLabel="cloud_dvir__table_ok"
          needsRepairLabel="cloud_dvir__table_needs_repair"
          hasCriticalIssue={overallForm.hasCriticalIssue[OverallFormFields.OverallTrailer]}
          field={overallForm[OverallFormFields.OverallTrailer] as unknown as InspectionFieldState}
          onStatusSelect={handleChange}
          readOnly={isOverallInspectionReadOnly}
          withoutAttachments
          showNotApplicableOption={false}
        />
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        marginTop: 3,
      }}
    >
      {isSignDrawerOpen && (
        <SignModal
          handleClose={() => setIsSignDrawerOpen(false)}
          onSign={handleSign}
          onDeletePreviousSign={handleDeletePreviousSign}
          sign={sign}
          signId={driverData?.driverId}
        />
      )}
      {renderInspectionItems()}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 1,
          gap: 3,
          borderRadius: '8px',
        }}
      >
        {sign?.src && <StyledSignImage src={sign.src} />}
        <Button
          size="large"
          variant="contained"
          viewType={overallForm.sign.value?.src ? 'success' : 'primary'}
          isRounded
          isFilled
          onClick={() => setIsSignDrawerOpen(true)}
          disabled={isSignReadOnly}
          sx={{ width: '100%' }}
        >
          {overallForm.sign.value?.src && (
            <CheckIcon sx={{ marginRight: '4px', width: '20px', height: '20px' }} color="inherit" />
          )}
          {t('cloud_dvir__sign')}
        </Button>
      </Box>
    </Box>
  );
};

export default Sign;
