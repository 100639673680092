import React, { FC } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  LabeledIconButton, Text,
} from '../../components';
import HistoryItem from '../HistoryItem';
import { DvirHistoryData } from '../types';
import { PageMode } from '../../pages';

interface HistoryViewProps {
  dvirHistory: DvirHistoryData[];
  onExit: () => void;
  onReview: (dvir: DvirHistoryData, reviewType: PageMode) => void;
}

const HistoryView:FC<HistoryViewProps> = ({ dvirHistory, onExit, onReview }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ backgroundColor: (theme) => theme.palette.neutral.variation100 }}>
        <Box sx={{
          padding: 2, width: '100%', maxWidth: (theme) => theme.grid.tablet, margin: '0 auto',
        }}
        >
          <LabeledIconButton
            viewType="secondary"
            size="medium"
            label={t('cloud_dvir__exit')}
            icon={<NavigateBeforeIcon />}
            onClick={onExit}
            variant="contained"
            wrapperStylesSx={{ display: 'inline-flex' }}
          />
        </Box>
      </Box>

      <Box sx={{
        width: '100%', padding: 2, maxWidth: (theme) => theme.grid.tablet, margin: '0 auto',
      }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px 16px' }}>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <RestoreIcon sx={{ width: 36, height: 36, color: (theme) => theme.palette.neutral.variation40 }} />
            <Text fontSize="large" fontLevel="title">{t('cloud_dvir__dvir_history')}</Text>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
          {dvirHistory.map((dvirHistoryData) => (
            <HistoryItem
              key={dvirHistoryData.id}
              dvirHistoryData={dvirHistoryData}
              onClick={() => { onReview(dvirHistoryData, PageMode.HistoryView); }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default HistoryView;
