export interface CustomPalettesOptions {
  variation0?: string;
  variation10: string;
  variation20: string;
  variation30: string;
  variation40: string;
  variation50: string;
  variation60: string;
  variation70: string;
  variation80: string;
  variation90: string;
  variation95: string;
  variation99?: string;
  variation100: string;
}

const palettes = {
  neutral: {
    variation0: '#000000',
    variation10: '#1C1B1F',
    variation20: '#313033',
    variation30: '#484649',
    variation40: '#605D62',
    variation50: '#787579',
    variation60: '#939094',
    variation70: '#AEAAAE',
    variation80: '#C9C5CA',
    variation90: '#E6E1E5',
    variation95: '#F4EFF4',
    variation99: '#FFFBFE',
    variation100: '#FFFFFF',
  },
  darkBlue: {
    variation10: '#002B54',
    variation20: '#023C6C',
    variation30: '#094678',
    variation40: '#164F84',
    variation50: '#1F578C',
    variation60: '#496E9A',
    variation70: '#6B88AB',
    variation80: '#94A9C3',
    variation90: '#BECBDB',
    variation95: '#E5EAF0',
    variation100: '#FFFFFF',
  },
  blue: {
    variation10: '#124E88',
    variation20: '#196EAA',
    variation30: '#1C7FBE',
    variation40: '#2191D2',
    variation50: '#249FE0',
    variation60: '#38ADE5',
    variation70: '#55BBE9',
    variation80: '#82CEF0',
    variation90: '#B3E1F6',
    variation95: '#E1F4FC',
    variation100: '#FFFFFF',
  },
  aqua: {
    variation10: '#1B5D58',
    variation20: '#208080',
    variation30: '#229497',
    variation40: '#24A9AF',
    variation50: '#26B9C1',
    variation60: '#3AC4C7',
    variation70: '#57CECF',
    variation80: '#84DDDC',
    variation90: '#B3EAE9',
    variation95: '#E0F7F6',
    variation100: '#FFFFFF',
  },
  green: {
    variation10: '#005D29',
    variation20: '#067D3D',
    variation30: '#138E48',
    variation40: '#1EA054',
    variation50: '#27AF5D',
    variation60: '#4FBB75',
    variation70: '#71C78D',
    variation80: '#9BD6AD',
    variation90: '#C2E6CD',
    variation95: '#E6F5EB',
    variation100: '#FFFFFF',
  },
  red: {
    variation10: '#A62416',
    variation20: '#B52D21',
    variation30: '#C33427',
    variation40: '#D43D2D',
    variation50: '#E2462E',
    variation60: '#E05448',
    variation70: '#D9716C',
    variation80: '#E69894',
    variation90: '#FACBCE',
    variation95: '#FDEAEC',
    variation100: '#FFFFFF',
  },
  orange: {
    variation10: '#DF4E10',
    variation20: '#E96811',
    variation30: '#F07812',
    variation40: '#F68714',
    variation50: '#FA9315',
    variation60: '#FBA22D',
    variation70: '#FCB350',
    variation80: '#FDC981',
    variation90: '#FEDEB3',
    variation95: '#FFF2E0',
    variation100: '#FFFFFF',
  },
  yellow: {
    variation10: '#E77F13',
    variation20: '#EBA625',
    variation30: '#EDBE2F',
    variation40: '#EFD539',
    variation50: '#EDE53D',
    variation60: '#F1EA5A',
    variation70: '#F4EE78',
    variation80: '#F6F39E',
    variation90: '#FAF8C4',
    variation95: '#FDFDE7',
    variation100: '#FFFFFF',
  },
};

export default palettes;
