import React, { FC } from 'react';
import { Modal as MUIModal, ModalProps as MUIModalProps, Box } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  maxWidth: 540,
  width: '100%',
  boxShadow: 24,
  backgroundColor: (theme) => theme.palette.neutral.variation100,
  borderRadius: '12px',
  '@media screen and (min-height: 520px)': {
    padding: 3,
  },
  '@media screen and (min-width: 260px ) and ( max-height: 520px)': {
    padding: 2,
  },
  '@media screen and (max-height: 260px)': {
    padding: 1,
  },
};

const Modal:FC<MUIModalProps> = ({ open, onClose, children }) => (
  <MUIModal open={open} onClose={onClose} sx={{ margin: '0 16px' }}>
    <Box sx={style}>
      {open && children}
    </Box>
  </MUIModal>
);

export default Modal;
