import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ChipProps } from '../../components/chip/Chip';
import { InspectionValue } from '../../types/submission';

const getChipStatusAndLabelByInspection = (inspection: InspectionValue, statusLabels: any): { label: string, status: ChipProps['status'], icon: JSX.Element} => {
  if (inspection === InspectionValue.RepairsMade) {
    return {
      status: 'success',
      label: 'cloud_dvir__repairs_made',
      icon: <CheckIcon fontSize="small" color="inherit" />,
    };
  }

  if (inspection === InspectionValue.RepairsNotNecessary) {
    return {
      status: 'success',
      label: 'cloud_dvir__repairs_not_necessary',
      icon: <CheckIcon fontSize="small" color="inherit" />,
    };
  }

  if (inspection === InspectionValue.Ok) {
    return {
      status: 'success',
      label: statusLabels.OK || 'cloud_dvir__ok',
      icon: <CheckIcon fontSize="small" color="inherit" />,
    };
  }

  if (inspection === InspectionValue.NeedsRepair) {
    return {
      status: 'error',
      label: statusLabels.NEEDS_REPAIR || 'cloud_dvir__needs_repair',
      icon: <TaxiAlertIcon fontSize="small" color="inherit" />,
    };
  }

  return {
    status: 'neutral',
    label: 'cloud_dvir__not_applicable',
    icon: <RemoveCircleOutlineIcon fontSize="small" color="inherit" />,
  };
};

export default getChipStatusAndLabelByInspection;
