export const DriverURLDataPoints = {
  TruckNumber: 'truckNumber',
  Context: 'context',
  TemplateUrl: 'templateUrl',
  SubmissionUrl: 'submissionUrl',
  TemplateId: 'templateId',
  AuxVersion: 'auxVersion',
  AllowTrailers: 'allowTrailers',
  isPreTripCompleted: 'isPreTripCompleted',
  isPostTripCompleted: 'isPostTripCompleted',
} as const;
