import React from 'react';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import Text from '../text/Text';

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.palette.neutral.variation40};
  }
`;

const Checkbox = ({
  label, className = '', checkboxProps = {}, ...rest
}) => (
  <StyledFormControlLabel
    control={<MUICheckbox {...checkboxProps} />}
    label={(
      <Text fontLevel="body" fontSize="large">
        {label}
      </Text>
              )}
    className={className}
    {...rest}
  />
);

export default Checkbox;
