const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Opnieuw';
_fc_props.default = 'Standaard';
_fc_props.origin = 'Vertrek';
_fc_props.loading = 'Laden';
_fc_props.list = 'Lijst';
_fc_props.send = 'Verzend';
_fc_props.error = 'Fout';
_fc_props.save = 'Bewaar';
_fc_props.delete = 'Verwijder';
_fc_props.message = 'Bericht';
_fc_props.logout = 'Afmelden';
_fc_props.add = 'Voeg Toe';
_fc_props.remove = 'Verwijder';
_fc_props.done = 'Klaar';
_fc_props.other = 'Anders';
_fc_props.interval = 'Interval';
_fc_props.time = 'Tijd';
_fc_props.speed = 'Snelheid';
_fc_props.longitude = 'Lengtegraad';
_fc_props.latitude = 'Breedtegraad';
_fc_props.x_minutes = '{0} Min';
_fc_props.general = 'AngemeenAlgemeen';
_fc_props.messages = 'Berichten';
_fc_props.gps_fixes = 'GPS Oplossingen';
_fc_props.device_id = 'Toestel-ID';
_fc_props.vehicle = 'Truck';
_fc_props.employee = 'Werknemer';
_fc_props.version = 'Versie';
_fc_props.statuses = 'Statussen';
_fc_props.edit = 'Wijzig';
_fc_props.cancel = 'Annuleer';
_fc_props.yes = 'Ja';
_fc_props.none = 'Geen';
_fc_props.never = 'Nooit';
_fc_props.name = 'Naam';
_fc_props.true = 'WAAR';
_fc_props.status = 'Status';
_fc_props.distance = 'Afstand';
_fc_props.ok = 'OK';
_fc_props.login = 'Aanmelden';
_fc_props.next = 'Volgende';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'n.v.t.';
_fc_props.back = 'Terug';
_fc_props.select = 'Kies';
_fc_props.dispatch = 'Centrale';
_fc_props.messaging = 'Berichtgeving';
_fc_props.plants = 'Centrales';
_fc_props.last_plants = 'Laatste centrales';
_fc_props.vehicle_types = 'Truck types';
_fc_props.employee_types = 'Werknemer types';
_fc_props.employee_groups = 'Werknemers groepen';
_fc_props.equipment_groups = 'Apparatuur groepen';
_fc_props.job = 'Werk';
_fc_props.order = 'Order';
_fc_props.ticket = 'ticket #';
_fc_props.small = 'Klein';
_fc_props.medium = 'Middel';
_fc_props.large = 'Groot';
_fc_props.reports = 'Rapporten';
_fc_props.payroll = 'Loonlijst';
_fc_props.support = 'Ondersteuning';
_fc_props.username = 'Gebruikersnaam';
_fc_props.all = 'Alle';
_fc_props.question = 'Vragen';
_fc_props.select_employee = 'Kies werknemer';
_fc_props.phone = 'Telefoon';
_fc_props.january = 'Januari';
_fc_props.february = 'Februari';
_fc_props.march = 'Maart';
_fc_props.april = 'April';
_fc_props.may = 'Mei';
_fc_props.june = 'Juni';
_fc_props.july = 'Juli';
_fc_props.august = 'Augustus';
_fc_props.september = 'September';
_fc_props.october = 'Oktober';
_fc_props.november = 'November';
_fc_props.december = 'December';
_fc_props.are_you_sure_you_want_to_delete = 'Weet u zeker dat u wilt verwijderen?';
_fc_props.unknown = 'Onbekend';
_fc_props.select_all = 'Kies Alles';
_fc_props.equipment = 'Apparatuur';
_fc_props.region = 'Regio';
_fc_props.no_data = 'Geen Data';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Adres';
_fc_props.retrieving_address = 'Adres ophalen...';
_fc_props.address_not_found = 'Adres niet gevonden';
_fc_props.active_time = 'Actieve Tijd';
_fc_props.destination_time = 'Aankomsttijd';
_fc_props.complete_time = 'Volledige tijd';
_fc_props.status_report = 'Statusrapport';
_fc_props.export_report = 'Rapport exporteren';
_fc_props.download_report = 'Download Rapport';
_fc_props.view_report = 'Bekijk Rapport';
_fc_props.duration = 'Duur';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Startdatum/-tijd';
_fc_props.select_start_date = 'Kies startdatum';
_fc_props.end_date_time = 'Einddatum/-tijd';
_fc_props.select_end_date = 'Kies einddatum';
_fc_props.trip_time = 'Trajecttijd';
_fc_props.travel_time = 'Reistijd';
_fc_props.description = 'Beschrijving';
_fc_props.map_controls = 'Kaart bediening';
_fc_props.road = 'Weg';
_fc_props.aerial = 'Vanuit de lucht';
_fc_props.hybrid = 'Hybride';
_fc_props.trip = 'Traject';
_fc_props.map = 'Kaart';
_fc_props.plant = 'Centrale';
_fc_props.select_date = 'Kies Datum';
_fc_props.save_settings = 'Instellingen opslaan';
_fc_props.false = 'FOUT';
_fc_props.delete_confirmation = 'Bevestig verwijdering';
_fc_props.last_name = 'Achternaam';
_fc_props.none_selected = 'Niets gekozen';
_fc_props.start_date = 'Startdatum';
_fc_props.group_by = 'Groepeer Per';
_fc_props.equipment_employee = 'Uitrusting / Werknemer';
_fc_props.inactive = 'Niet actief';
_fc_props.active = 'Actief';
_fc_props.note_checking_may_affect_performance = 'Let op: controle kan de prestatie be‹nvloeden';
_fc_props.show_distancefuel_used = 'Toon afstand/brandstofverbruik';
_fc_props.mins = 'Min';
_fc_props.threshold_time = 'Tijd Drempelwaarde';
_fc_props.include_voided_tickets = 'Inclusief vervallen bonnen';
_fc_props.note_all_times_listed_are_in_minutes = 'Let op: Alle opgegeven tijden zijn aangegeven in minuten';
_fc_props.please_select_a_job_order_or_equipment = 'Selecteer een Job, Order, Plant, Equipment of Employee';
_fc_props.device = 'Toestel';
_fc_props.select_equipment = 'Kies apparaat';
_fc_props.all_equipment_types = 'Alle Uitrustingstypes';
_fc_props.units = {};
_fc_props.units.liters = 'Liters';
_fc_props.type = 'Type';
_fc_props.no_data_available = 'Geen data beschikbaar';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Afstand en brandstof per regio';
_fc_props.menu_settings_title_settings = 'Instellingen';
_fc_props.menu_settings_title_alerts = 'Alarmen';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Apparatuur';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots & Regio\'s';
_fc_props.days = '{0} dag(en)';
_fc_props.home = 'Home';
_fc_props.find = 'Vind';
_fc_props.close_this_panel = 'Sluit dit panel';
_fc_props.start = 'Start';
_fc_props.end = 'Einde';
_fc_props.location_details = 'Locatiedetails';
_fc_props.first_name = 'Voornaam';
_fc_props.material_per_hour = 'Materiaal Per Uur';
_fc_props.equipment_pound = 'Uitrusting #';
_fc_props.equipment_type = 'Uitrustingstype';
_fc_props.options = 'Opties';
_fc_props.zoom_on_cursor_position = 'Zoom In Cursorpositie';
_fc_props.display_equipment_identifier = 'Toon Uitrustingsidentificatie';
_fc_props.view_equipment = 'Bekijk Uitrusting';
_fc_props.view_hotspots = 'Bekijk Hotspots';
_fc_props.view_regions = 'Bekijk Regio\'s';
_fc_props.use_standard_filters = 'Gebruik Standaard Filters';
_fc_props.home_plant = 'Thuisbasis centrale';
_fc_props.last_plant = 'Laatste centrale';
_fc_props.equip_types = 'Uitr. Types';
_fc_props.equip_groups = 'Uitr. Groepen';
_fc_props.orders = 'Orders';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Gebruik Uitrustingsfilter';
_fc_props.all_selected = 'Alles Geselecteerd';
_fc_props.of = 'Of';
_fc_props.your_map_session_has_expired = 'Uw kaartsessie is verlopen, laad de pagina opnieuw.';
_fc_props.equipment_short = 'Uitr.';
_fc_props.job_info_for = 'Jobinfo voor';
_fc_props.order_info_for = 'Orderinfo voor';
_fc_props.ticket_info_for_vehicle = 'Boninfo voor truck';
_fc_props.inbox = 'Inbox';
_fc_props.outbox = 'Outbox';
_fc_props.alerts = 'Alarmen';
_fc_props.critical = 'Kritieke';
_fc_props.important = 'Belangrijk';
_fc_props.informational = 'Informatief';
_fc_props.view_routes = 'Bekijk Routes';
_fc_props.hours_size = 'Uren Afmeting';
_fc_props.used_count = 'Gebruikte Teller';
_fc_props.last_login = 'Laatste aanmelding';
_fc_props.last_vehicle = 'Laatste truck';
_fc_props.regions = 'Regio\'s';
_fc_props.driver = 'Chauffeur';
_fc_props.js_delete = 'Verwijder';
_fc_props.move = 'Verplaats';
_fc_props.edit_hotspot = 'Wijzig Hotspot';
_fc_props.adjust_shape = 'Pas Vorm aan';
_fc_props.edit_region = 'Bewerk Regio';
_fc_props.enter_a_start_address = 'Gelieve een startadres in te voeren';
_fc_props.select_a_start_hotspot = 'Gelieve een start-hotspot te selecteren';
_fc_props.failed_to_find_start_hotspot = 'Kon start-hotspot niet vinden';
_fc_props.invalid_latitudelongitude = 'Ongeldige Breedte-/Lengtegraad';
_fc_props.enter_an_end_address = 'Gelieve een eindadres in te voeren';
_fc_props.select_an_end_hotspot = 'Gelieve een eind-hotspot te selecteren';
_fc_props.failed_to_find_end_hotspot = 'Kon eind-hotspot niet vinden';
_fc_props.print = 'Afdrukken';
_fc_props.these_directions_are_for_informational_purposes = 'Deze richtingaanwijzigen zijn louter informatief. Er zijn geen garanties met betrekking tot de volledigheid of juistheid. Werkzaamheden, verkeersdrukte of andere voorvallen kunnen ertoe leiden dat de huidige omstandigheden afwijken van deze resultaten.';
_fc_props.enter_an_address = 'Gelieve een adres in te voeren';
_fc_props.select_a_hotspot = 'Gelieve een Hotspot te selecteren';
_fc_props.failed_to_find_hotspot = 'Kon geen Hotspot vinden';
_fc_props.select_a_vehicle = 'Gelieve een voertuig te selecteren';
_fc_props.failed_to_find_vehicle = 'Kon geen Voertuig vinden';
_fc_props.failed_to_find_address = 'Kon geen adres vinden';
_fc_props.failed_to_find_vehicles = 'Kon geen voertuigen vinden met uw zoekopdracht.';
_fc_props.get_address = 'Verkrijg Adres';
_fc_props.results_for = 'Resultaten voor';
_fc_props.error_retrieving_results = 'Fout bij het ophalen van de Resultaten';
_fc_props.no_results_found = 'Geen Resultaten Gevonden';
_fc_props.address_is_required = 'Adres is Vereist';
_fc_props.city_is_required = 'Stad is Vereist';
_fc_props.set_location = 'Stel Locatie in';
_fc_props.set_area_of_interest = 'Stel Interessegebied in';
_fc_props.center_map_here = 'Centreer Map Hier';
_fc_props.zoom_to_street_level = 'Zoom in op Straatniveau';
_fc_props.set_start = 'Stel Start in';
_fc_props.set_end = 'Stel Einde in';
_fc_props.delete_node = 'Verwijder Knooppunt';
_fc_props.create_a_hotspot = 'Maak een Hotspot aan';
_fc_props.create_a_region = 'Maak een Regio aan';
_fc_props.toggle_shapes = 'Schakel Vormen';
_fc_props.refresh_map = 'Ververs Map';
_fc_props.more_options = 'Meer Opties';
_fc_props.recenter_map = 'Kaart opnieuw centreren';
_fc_props.zoom_to_vehicle = 'Zoom in op Voertuig';
_fc_props.apply_filters = 'Pas Filters Toe';
_fc_props.grid_view = 'Rasterweergave';
_fc_props.list_view = 'Lijstoverzicht';
_fc_props.click_to_sort = 'Klik om te sorteren';
_fc_props.message_history = 'Berichten historie';
_fc_props.equipment_types = 'Uitrustings types';
_fc_props.send_message = 'Verzend Bericht';
_fc_props.last_load = 'Laatste Lading';
_fc_props.last_load_on = 'Laatste Lading Op';
_fc_props.manual_logout = 'Handmatig afmelden';
_fc_props.manual_login = 'Handmatig aanmelden';
_fc_props.no_employees_logged_in = 'Geen aangemelde werknemers';
_fc_props.select_phone = 'Kies Telefoon';
_fc_props.you_must_select_a_phone = 'Kies een telefoon, apparaat en werknemer';
_fc_props.are_you_sure_you_want_to_logout = 'Weet u zeker dat u wil afmelden';
_fc_props.fix_time = 'Reparatie tijd';
_fc_props.to_next_fix = 'Tot volgende reparatie';
_fc_props.minutes_to_next_fix = 'Minuten tot volgende reparatie';
_fc_props.liters = 'Liters';
_fc_props.destination = 'Bestemming';
_fc_props.return = 'Terug';
_fc_props.product = 'Product';
_fc_props.no_records_found = 'Geen gegevens gevonden.';
_fc_props.vehicle_type = 'Truck type';
_fc_props.view_in_map = 'Bekijk alles op kaart';
_fc_props.permanent = 'Permanent';
_fc_props.expire_time = 'Verlooptijd';
_fc_props.please_select_x_for_tickets_marked_active = 'Gelieve {0} te selecteren voor Tickets gemarkeerd als ACTIEF';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Meerdere Tickets als ACTIEF gemarkeerd voor dezelfde {0}';
_fc_props.failure_connecting_to_servlet = 'Fout bij verbinden met servlet.';
_fc_props.status_time_summary = 'Status Tijdsoverzicht';
_fc_props.view_in_map_one = 'Bekijk op kaart';
_fc_props.question_report = 'Vragen rapport';
_fc_props.end_date = 'Einddatum';
_fc_props.trailer_number = 'Trailer nummer';
_fc_props.region_panel = 'Regio Panel';
_fc_props.hotspot_panel = 'Hotspot Panel';
_fc_props.shape = 'Vorm';
_fc_props.circle = 'Cirkel';
_fc_props.polygon = 'Polygoon';
_fc_props.click_the_map_to_set_the_location = 'Klik op de map om de gewenste locatie in te stellen.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Klik op de map om het hotspotcentrum in te stellen.';
_fc_props.click_the_map_to_set_the_region_center = 'Klik op de map om het regiocentrum in te stellen.';
_fc_props.click_and_drag_points = 'Klik en sleep punten om de vorm aan te passen. Klik OPSLAAN om de aanpassingen op te slaan.';
_fc_props.size = 'Grootte';
_fc_props.are_you_sure_you_want_to_move_this = 'Bent u zeker dat u dit wenst te verplaatsen';
_fc_props.are_you_sure_you_want_to_delete_this = 'Bent u zeker dat u dit wenst te verwijderen';
_fc_props.please_enter_a_valid_size = 'Vul een geldig formaat';
_fc_props.failed_to_save = 'Opslaan Mislukt.';
_fc_props.failed_to_move = 'Verplaatsen Mislukt.';
_fc_props.failed_to_delete = 'Verwijderen mislukt.';
_fc_props.x_hours = '{0} uren';
_fc_props.hide_hotspots = 'Verberg Hotspots';
_fc_props.show_regions = 'Toon Regios';
_fc_props.show_hotspots = 'Toon Hotspots';
_fc_props.hide_regions = 'Verberg Regios';
_fc_props.numeric = 'Numeriek';
_fc_props.message_type = 'Bericht type';
_fc_props.text = 'Tekst';
_fc_props.yes_no = 'Ja/Nee';
_fc_props.responses = 'Antwoorden';
_fc_props.disabled = 'Uitgeschakeld';
_fc_props.group_name = 'Groepsnaam';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Bent u zeker dat u het \'{0}\' formaat wenst te verwijderen?';
_fc_props.phone_number = 'Telefoonnummer';
_fc_props.interface_pound = 'Interface #';
_fc_props.field = 'Veld';
_fc_props.default_label = 'Standaard label';
_fc_props.custom_label = 'Aangepast label';
_fc_props.you_must_enter_a_label = 'U moet een Label invoeren.';
_fc_props.enabled = 'Ingeschakeld';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Bonnen integreren';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence off open Hotspots';
_fc_props.settings.associate_open_hotspots_wticket = 'Koppel open hotspots aan bon';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Sta geofencing off toe bij open hotspots of alleen bij hotspots gekoppeld aan chauffeursbonnen.';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Of een open hotspot waar de truck is wel of niet gekoppeld mag worden aan bonnen.';
_fc_props.settings.void_ticket_without_destination = 'Bonnen zonder bestemming worden ongeldig gemaakt';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'Als een bon wordt ontvangen terwijl een andere bon nog actief is, wordt de actieve bon ongeldig gemaakt als er geen bestemming op staat.';
_fc_props.settings.autocreate_hotspots = 'Auto-aanmaak hotspots';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Of hotspots wel of niet automatisch worden aangemaakt voor het afleveradres als een bon wordt ontvangen.';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Auto-activeer bon bij ontvangst';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Is de bon actief als die is ontvangen door TrackIt (standaard \'aan\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Sluit actieve bonnen automatisch bij ontvangst van minuten';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Hoe lang moet de huidige actieve bon actief zijn geweest voordat het automatisch gesloten wordt(Standaard \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Bonnen maximum tijd in minuten voor activeren';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Hoe oud kan een bon in minuten zijn, voordat hij geactiveerd wordt door TrackIt (Standaard \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Max dagen hotspot niet in gebruik';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Stelt het max aantal dagen in dat een Hotspot ongebruikt kan blijven en nog opnieuw geactiveerd kan worden.';
_fc_props.settings.radius_multiplier = 'Radius vermenigvuldigingsfactor';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Met hoeveel moet de radius vermenigvuldigd worden nadat een bon is ontvangen van het dispatch-systeem';
_fc_props.settings.ticket_message_date_format = 'Bon bericht datum formaat';
_fc_props.am = 'am';
_fc_props.pm = 'pm';
_fc_props.settings.ticket_message = 'Bon bericht';
_fc_props.settings.add_ticket_element = 'Voeg bon-element toe';
_fc_props.driver_performance = 'Chauffeur-prestatie';
_fc_props.update_failed_can_not_connect = 'Update mislukt: Kan geen verbinding maken met de servlet.';
_fc_props.unknown_error = 'Onbekende Fout';
_fc_props.selected = 'Gekozen';
_fc_props.web_service = 'Webservice';
_fc_props.error_processing_request = 'Fout verwerken aanvraag.';
_fc_props.email_address = 'Emailadres';
_fc_props.id = 'ID kaart';
_fc_props.last_used = 'Laatst gebruikt';
_fc_props.error_invalid_vehiclenum = 'Foutmelding ongeldig voertuignummer';
_fc_props.error_vehicle_is_not_logged_in = 'Fout: truck is niet aangemeld.';
_fc_props.users = 'Gebruikers';
_fc_props.you_dont_have_access_to_this_page = 'U heeft geen toegang om deze pagina te bekijken. Neem contact op met uw systeembeheerder voor toegang.';
_fc_props.previous_week = 'Vorige week';
_fc_props.status_breakdown = 'Status uitsplitsing';
_fc_props.select_plant_or_employee_group = 'U moet of een fabriek of een werknemersgroep verwijderen.';
_fc_props.cannot_select_plant_and_employee_group = 'U kan geen fabriek EN een werknemersgroep selecteren.';
_fc_props.odometer_must_be_a_number = 'Startodometer moet een cijfer zijn';
_fc_props.ending_odometer_must_be_a_number = 'Eindodometer moet een cijfer zijn';
_fc_props.beginning_hour_meter_must_be_a_number = 'Beginuur Meter moet een nummer zijn';
_fc_props.ending_hour_meter_must_be_a_number = 'Einduur Meter moet een nummer zijn';
_fc_props.error_loading_status_change_page = 'Foutmelding laadstatus pagina-aanpassing.';
_fc_props.x_is_an_invalid_time_format = '{0} is een ongeldig tijdformaat, gebruik JJJJ-MM-DD UU:MM:SS';
_fc_props.x_for_schedule_login = '{0} voor geplande login';
_fc_props.x_for_schedule_logout = '{0} voor geplande logout';
_fc_props.x_for_actual_login = '{0} voor huidige login';
_fc_props.x_for_actual_logout = '{0} voor huidige logout';
_fc_props.by_refreshing_the_time_card_detail = 'Door het Tijdkaartdetail te vernieuwen zullen alle aanpassingen verloren gaan, wenst u toch verder te gaan?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Weet u zeker dat u {0} wilt verwijderen?';
_fc_props.settings.command_mobile_ticket_url = 'Command Mobile Ticket-URL';
_fc_props.year = 'Jaar';
_fc_props.make = 'Merk';
_fc_props.model = 'Model';
_fc_props.settings.command_mobile_ticket_password = 'Command Mobile Ticket Password';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command Mobile Ticket Auth Token';
_fc_props.target = 'Doel';
_fc_props.number_of_digits = 'Aantal cijfers';
_fc_props.number_of_integer_digits = 'Aantal Integer cijfers';
_fc_props.number_of_fractional_digits = 'Aantal cijfers achter de komma';
_fc_props.search = 'Zoeken';
_fc_props.settings.command_mobile_ticket_date_order = 'Command Mobile Ticket Date Order';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Mobile Ticket Date Length';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Mobile Ticket Date Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (Slash)';
_fc_props.settings.command_mobile_ticket_dash = '#NAME?';
_fc_props.settings.command_mobile_ticket_period = '. (punt)';
_fc_props.settings.command_mobile_ticket_space = '(spatie)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command Mobile Ticket Time Length';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Time Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (dubbele punt)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command Mobile Ticket Time Format';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Gebruik interface nummer';
_fc_props.mechanic = 'Monteur';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Aanvraag-interval (seconden)';
_fc_props.driving_time = 'Driving Time';
_fc_props.find_near_plants = 'Zoek Near Planten';
_fc_props.please_create_hot_spot_first = 'Gelieve eerst hotspot creëren';
_fc_props.please_enter_hot_spot_description = 'Vul hot spot beschrijving';
_fc_props.please_select_a_hot_spot_type = 'Selecteer een hot spot soort';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Stuur email naar chauffeur';
_fc_props.directions_not_found = 'Ongeldige {0} Adres. Routebeschrijving Not Found.';
_fc_props.ws_user = 'Gebruiker';
_fc_props.ws_service = 'Service';
_fc_props.ws_method = 'Methode';
_fc_props.ws_params = 'Parameters';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Oorsprong en Bestemming';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Hulp';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Gebruik Alt Employee Number';
_fc_props.settings.command_mobile_ticket_android_url = 'Android Mobile Ticket URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Ticket Secret';
_fc_props.employee_x_has_no_logout_on_y = 'Employee {0} heeft geen afmelding voor een shift te beginnen op {1}.';
_fc_props.phone_number_has_duplicates = 'Telefoonnummers dubbele vermeldingen';
_fc_props.ounce = 'ons';
_fc_props.water_extra = 'Extra water';
_fc_props.always = 'Altijd';
_fc_props.work_types_must_be_unique = 'Work Types moet uniek zijn';
_fc_props.manufacturer = 'Fabrikant';
_fc_props.need_to_enter_seconds = 'Moet seconden invoeren.';
_fc_props.copy = 'Kopiëren';
_fc_props.create_in_existing_required = 'Creëren in bestaande moet worden geselecteerd om roaming hotspot te maken.';
_fc_props.break = 'Pauze';
_fc_props.add_export_data_type = 'Voeg Export Data Type toe';
_fc_props.edit_export_data_type = 'Edit Export Data Type';
_fc_props.delete_export_data_type = 'Verwijder Export Data Type';
_fc_props.export_data_type = 'Gegevens type exporteren';
_fc_props.error_updating_export_data_type = 'Fout bij het bijwerken van het type exporttype';
_fc_props.settings.address_key = 'Adres sleutel';
_fc_props.settings.address_key_hover = 'Adres sleutel opties';
_fc_props.settings.address_key_options = 'Adres sleutel opties';
_fc_props.more_filter_options = 'Meer filteropties';
_fc_props.unauthorized_mapit_app = 'Deze versie van MapIt is niet geautoriseerd. Update MapIt zo snel mogelijk.';
_fc_props.zello_timeout = 'Time-out vond plaats door contact op te nemen met Zello.';
_fc_props.user_agreement_text = 'Door u aan te melden, gaat u akkoord met de';
_fc_props.user_agreement = 'gebruikersovereenkomst';
_fc_props.x_mins_left = '{0} min. (S) over';
_fc_props.continuous_location_tracking_colon = 'Continuous Location Tracking:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Hauler kan geen nieuw apparaat registreren.';
_fc_props.timecard_does_not_contain_shift = 'Employee Time Card bevat niet de verschafte dienst (Time Card Info)';
_fc_props.timecard_not_found = 'Employee Time Card niet gevonden';
_fc_props.vehicle_must_be_assigned_for_clt = 'Voertuig moet worden toegewezen aan tracklocaties.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Voertuig was niet toegewezen continu volgen zal worden uitgeschakeld.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Verbroken verbinding met EDC. Controleer of uw WiFi en / of Bluetooth zijn ingeschakeld.';
_fc_props.acknowledge_all = 'Erken Alles';
_fc_props.expand = 'Uitbreiden';
_fc_props.collapse = 'Ineenstorting';
_fc_props.expand_all = 'Alles uitvouwen';
_fc_props.collapse_all = 'Alles inklappen';
_fc_props.last_update = 'Laatste update';
_fc_props.acknowledge_alert = 'Erken Alert';
_fc_props.acknowledge_message = 'Bericht bevestigen';
_fc_props.acknowledge_all_alerts = 'Erken alle waarschuwingen';
_fc_props.acknowledge_all_messages = 'Alle berichten bevestigen';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Tolerantie moet een geldig geheel getal zijn tussen -99 en 99 (beide inbegrepen)';
_fc_props.gps_permission_required = 'GPS-toestemming vereist';
_fc_props.permissions_required = 'Toestemmingen vereist';
_fc_props.change_user = 'Verander gebruiker';
_fc_props.account_not_registered_with_carrier = 'Dit account is niet geregistreerd bij een koerier';
_fc_props.slump_loss = 'Slump Loss';
_fc_props.slump_loss_help = 'Slump Loss Help';
_fc_props.did_not_receive_any_edc_devices = 'Geen EDC-apparaten ontvangen.';
_fc_props.edc_record_not_found = 'EDC-record is niet gevonden';
_fc_props.disable_hotspot_prompt = 'Tik op "OK" wanneer u wordt gevraagd om uw hotspot uit te schakelen.';
_fc_props.edc_device_is_in_use = 'EDC {0} is in gebruik.';
_fc_props.an_unknown_exception_has_occurred = 'Er is een onbekende uitzondering opgetreden';
_fc_props.login_has_timed_out = 'Inloggen is verlopen';
_fc_props.use_paper_logs = 'Gebruik papieren logboeken';
_fc_props.eld_login_failed = 'Uren van Service login mislukt';
_fc_props.setup_time_off_type = 'Stel een time-off-type in.';
_fc_props.view_load_properties = 'Bekijk laadeigenschappen';
_fc_props.couldnt_download_edc_firmware = 'Kan EDC-firmware niet downloaden.';
_fc_props.password_incorrect = 'Fout wachtwoord';
_fc_props.edc_configuration_password_prompt = 'Vanwege de status van dit apparaat moet u het installateurswachtwoord opgeven om te configureren.';
_fc_props.edc_configuration_check_failed = 'Validatie van EDC-serverrecords mislukt. Controleer foutmeldingen voor meer informatie.';
_fc_props.configuration_issues_prompt = 'De volgende problemen zijn gedetecteerd tijdens het configureren van de geselecteerde EDC voor de opgegeven truck';
_fc_props.checking_password = 'Bezig met controleren van wachtwoord';
_fc_props.reset_password_instructions_sent = 'Instructies voor het opnieuw instellen van uw wachtwoord verzonden naar e-mailadres {0}.';
_fc_props.reset_password_failed = 'Reset wachtwoord mislukt, probeer opnieuw.';
_fc_props.forgot_password = 'Wachtwoord vergeten?';
_fc_props.enter_valid_email = 'Ongeldig e-mailadres, voer een geldig e-mailadres in en probeer het opnieuw.';
_fc_props.issues_while_updating_edc_record = 'De EDC is succesvol geconfigureerd. Er zijn echter problemen met de records voor de EDC op de TrackIt-server waardoor deze mogelijk niet werkt. Neem contact op met ondersteuning om de informatie te corrigeren.';
_fc_props.sending = 'Bezig met verzenden...';
_fc_props.minimum_ratio = 'Minimale verhouding';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Drum-RPM niet binnen bereik';
_fc_props.probe_failure = 'Sonde mislukt';
_fc_props.unknown_probe_data_failure = 'Onbekende fout met sondegegevens';
_fc_props.no_loaded_status_meaning = 'Geen geladen status';
_fc_props.no_depart_status_meaning = 'Geen vertrekstatus';
_fc_props.no_arrive_status_meaning = 'Geen aankomststatus';
_fc_props.no_begin_work_status_meaning = 'Geen begin werkstatus';
_fc_props.no_fully_mixed_status_meaning = 'Geen volledig gemengde status';
_fc_props.no_begin_work_or_at_job_status = 'Begin met werken of Bij taakstatus niet aanwezig';
_fc_props.driver_didnt_follow_procedure = 'Bestuurder heeft procedure niet gevolgd (drum niet vertraagd)';
_fc_props.probe_malfunction = 'Sondestoring (opbouw, interne storing, enz.)';
_fc_props.no_data_from_probe = 'Geen gegevens van sonde (gegevensverlies)';
_fc_props.missing_probe_calibration = 'Geen sondekalibratiegegevens';
_fc_props.probe_pressure_not_in_range = 'Sondedruk niet binnen bereik';
_fc_props.arrival = 'Aankomst';
_fc_props.small_load = 'KLEINE LADING';
_fc_props.dry_slump = 'DROGE SLUMP';
_fc_props.probe_remixing_turns = 'remixing';
_fc_props.turns_needed_to_remix = 'Water toegevoegd - Extra menging nodig';
_fc_props.probe_remixing_finished = 'Remixen voltooid';
_fc_props.probe_battery_low_alert = 'Sonde Batterij bijna leeg';
_fc_props.probe_battery_low_message = 'Sondebatterij bijna leeg';
_fc_props.probe_battery_low = 'Sondebatterij bijna leeg';
_fc_props.alert_when_probe_battery_low = 'Waarschuwing wanneer de batterij van de sonde bijna leeg is';
_fc_props.probe_sensor_fatal_failure = 'Dodelijke storing sensor';
_fc_props.probe_thermistor_failure = 'Thermistorstoring';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor vast in beton';
_fc_props.probe_sensor_over_weight = 'Sensor overgewicht';
_fc_props.probe_batteries_low = 'Batterij bijna leeg';
_fc_props.no_probe_data = 'Geen sondegegevens:';
_fc_props.check_probe = 'Controleer sonde';
_fc_props.service_probe = 'Serviceprobe';
_fc_props.below_x = 'Hieronder {0}';
_fc_props.above_x = 'Boven {0}';
_fc_props.probe_serial_number_invalid_format = 'Het serienummer van de sonde heeft geen geldige indeling, zoals weergegeven in de tijdelijke aanduiding voor het veld';
_fc_props.talk_dnd_permission_prompt = 'Overleg vereist GEEN DISTURB toestemming.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Status te snel gewijzigd';
_fc_props.is_empty = 'Is leeg';
_fc_props.slump_increased_plasticizer_question = 'Is er weekmaker toegevoegd?';
_fc_props.slump_increased_water_added_question = 'Hoeveel ongemeterd water werd er toegevoegd?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Monitoring van sonde en leveringscyclus worden niet samen op hetzelfde apparaat ondersteund.';
_fc_props.no_data_from_sensor = 'Geen gegevens van sensor (gegevensverlies)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Systeem type';
_fc_props.magnet_count = 'Magneet tellen';
_fc_props.sensors_setup = 'Sensoren instellen';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM vereist dat het MAC-adres van de I / O-box, het drumtype, het DCM-type en het aantal magneten zijn ingesteld.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Geladen hoeveelheid';
_fc_props.drs_current_volume = 'Huidige volume';
_fc_props.drs_current_water_cement_ratio = 'Huidige water / cementverhouding';
_fc_props.drs_target_water_cement_ratio = 'Streefwaarde water / cement';
_fc_props.drs_total_revolutions = 'Totaal';
_fc_props.drs_current_volume_lp_small = 'Actueel';
_fc_props.drum_revolutions = 'Trommelomwentelingen';
_fc_props.drs_total_revolutions_lp_small = 'Totaal omwentelingen';
_fc_props.drs_charging_revolutions_lp_small = 'Mengen';
_fc_props.drs_discharging_revolutions_lp_small = 'Lossen';
_fc_props.drs_target_wc_lp_small = 'Doel W / C';
_fc_props.drs_current_wc_lp_small = 'Huidige W / C';
_fc_props.drs_ticketed_volume_lp_small = 'Geladen hoeveelheid';
_fc_props.android_location_disclosure = 'TrackIt gebruikt fijne en grove locatiegegevens om locatiegegevens op de achtergrond te verzamelen, zelfs als ze niet in gebruik zijn, om automatische status en realtime kaartzichtbaarheid voor verzending mogelijk te maken.';
_fc_props.android_location_usage = 'Locatiebepaling';
_fc_props.end_pour_trigger_threshold = 'Einde storttrigger-drempel';
_fc_props.drs_wiring_error = 'Bedradingsfout';
_fc_props.drs_wiring_pulse_error = 'Bedradingspulsfout';
_fc_props.drs_wiring_magnet_error = 'Fout in bedradingsmagneet';
_fc_props.drs_magnet_missing_error = 'Magneet ontbreekt fout';
_fc_props.android_location_disclosure_3p = 'TrackIt verzamelt locatiegegevens op de achtergrond om automatische statusbepaling en real-time kaartzichtbaarheid voor verzending mogelijk te maken. Wanneer de verzender is uitgeklokt, wordt de locatie niet gedeeld.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Selecteer Full MDM of Trackit Remote Support';
_fc_props.move_up = 'Ga omhoog';
_fc_props.move_down = 'Naar beneden verplaatsen';
_fc_props.driver_not_found = 'Stuurprogramma niet gevonden.';
_fc_props.multiple = 'Meerdere';
_fc_props.water_meter_installed = 'Watermeter geïnstalleerd';
_fc_props.delete_waypoint = 'Waypoint verwijderen';
_fc_props.wash_sensor_installed = 'Wassensor geïnstalleerd';
_fc_props.washing_start_time = 'Starttijd wassen';
_fc_props.end_washing = 'Einde wassen';
_fc_props.variable_rpms = 'Var RPM\'s';
_fc_props.no_slump = 'Geen inzinking';
_fc_props.end_washing_duration = 'Einde wasduur';
_fc_props.privacy_policy = 'Privacybeleid';
_fc_props.privacy_policy_text = 'ik heb de ... gelezen en ga akkoord met';
_fc_props.login_fail_accept_privacy_policy = 'Het privacybeleid moet worden geaccepteerd om door te gaan.';
_fc_props.rotation_sensor = 'Rotatiesensor';
_fc_props.pto_sensor = 'Aftakassensor';
_fc_props.pto_sensor_installed = 'Aftakassensor geïnstalleerd';
_fc_props.polarity_reversed = 'Omgekeerde polariteit';
_fc_props.pto_sensor_type = 'Type aftakassensor';
_fc_props.sensor_type = 'Sensortype:';
_fc_props.prompt_select_pto_sensor_type = 'U moet een "PTO-sensortype" selecteren als u een "PTO-sensor" hebt geselecteerd als uw hoofdsensortype.';
_fc_props.priming_turns_at_full_load = 'Priming draait bij volledige belasting';
_fc_props.priming_turns_at_ten_percent_load = 'Priming draait bij 10% belasting';
_fc_props.weight_units = 'Gewichtseenheden';
_fc_props.settings.send_status = 'Status/locatie naar Event Bridge verzenden';
_fc_props.volume_difference = 'Volumeverschil';
_fc_props.mobile_ticket_iframe_url = 'Mobiel ticket IFrame-URL';
_fc_props.mobile_ticket_user = 'Mobiele ticketgebruiker';
_fc_props.mobile_ticket_password = 'Wachtwoord mobiel ticket';
_fc_props.current_truck_status = 'Huidige vrachtwagenstatus';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Getriggerd door maximale omwentelingen';
_fc_props.alerts_by_type = 'Waarschuwingen per type';
_fc_props.water_flow_meter = 'Waterstroommeter';
_fc_props.flow_meter_no_pulses_warning = 'Geen pulsen - Waarschuwing';
_fc_props.flow_meter_no_pulses_critical = 'Geen pulsen - kritiek';
_fc_props.flow_meter_excessive_pulses = 'Overmatige pulsen';
_fc_props.flow_meter_constantly_flowing = 'Voortdurend stromend';
_fc_props.flow_meter_good = 'Mooi zo';
_fc_props.whats_new = 'Wat is er nieuw';
_fc_props.whats_new_widget_text = 'Wist je dat we TrackIt minstens één keer per maand updaten? Ontvang de laatste updates en tips.';
_fc_props.get_the_scoop = 'Haal de primeur';
_fc_props.trackit_insights = 'Trackit-inzichten';
_fc_props.business_analytics_blurb = 'Bedrijfsanalyses die uw bedrijfsresultaten verbeteren, nu inbegrepen bij TrackIt';
_fc_props.find_out_more = 'Meer te weten komen';
_fc_props.daily_breakdown = 'Dagelijkse verdeling';
_fc_props.question_summary = 'Vraag Samenvatting';
_fc_props.trip_summary = 'Reisoverzicht';
_fc_props.manage_employees = 'Beheer medewerkers';
_fc_props.manage_hotspots = 'Hotspots beheren';
_fc_props.manage_devices = 'Apparaten beheren';
_fc_props.manage_equipment = 'Apparatuur beheren';
_fc_props.manage_users = 'Gebruikers beheren';
_fc_props.manage_statuses = 'Statussen beheren';
_fc_props.manage_alerts = 'Meldingen beheren';
_fc_props.popular_report_links = 'Populaire rapportlinks';
_fc_props.popular_settings_links = 'Links naar populaire instellingen';
_fc_props.sidemenu_settings = 'Instellingen';
_fc_props.loads_per_driver = 'Ladingen per bestuurder';
_fc_props.please_select_application = 'Selecteer ten minste één toepassing';
_fc_props.external_app_processing = 'Verwerking op externe app';
_fc_props.external_app_sent = 'Verzonden naar externe app';
_fc_props.external_app_received = 'Ontvangen door externe app';
_fc_props.wait_time_at_job = 'Wachttijd bij Job';
_fc_props.wait_time_at_plant = 'Wachttijd bij Plant';
_fc_props.loading_time = 'Laad tijd';
_fc_props.unloading_time = 'Lostijd';
_fc_props.pre_post_trip = 'Voor/Na een rit';
_fc_props.update_thresholds = 'Drempels bijwerken';
_fc_props.green = 'Groente';
_fc_props.yellow = 'Geel';
_fc_props.red = 'rood';
_fc_props.between = 'Tussen';
_fc_props.num_of_loads = '# van ladingen';
_fc_props.num_of_drivers = '# stuurprogramma\'s';
_fc_props.status_meaning_not_setup = 'Status Betekenis niet ingesteld';
_fc_props.or_less = 'Of minder';
_fc_props.or_greater = 'Of groter';
_fc_props.probe_self_diagnostic_state = 'Zelfdiagnostische status sonde';
_fc_props.update_current_truck_status_settings = 'Huidige instellingen voor vrachtwagenstatus bijwerken';
_fc_props.default_behavior = 'Standaard (Top 10 statussen)';
_fc_props.combine_similar_statuses = 'Combineer dezelfde statussen van alle typen (loops)';
_fc_props.use_other_category = 'Gebruik \'Overige\' categorie';
_fc_props.selected_statuses = 'Geselecteerde statussen';
_fc_props.ideas = 'Ideeën';
_fc_props.air_content = 'Lucht inhoud';
_fc_props.air_measured_time = 'Lucht gemeten tijd';
_fc_props.ten_max = '10 Max';
_fc_props.loads_per_plant = 'Belastingen per plant';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Ongeldige stroommeterpuls per volume.';
_fc_props.coming_soon_title = 'Binnenkort meer widgets';
_fc_props.submit_your_ideas = 'Dien uw ideeën in op ons Ideeënportaal met behulp van de gloeilamp aan uw linkernavigatiemenu.';
_fc_props.selected_plants = 'Geselecteerde planten';
_fc_props.update_loads_per_plant_settings = 'Belastingen per fabriek bijwerken';
_fc_props.default_top_10_plants = 'Standaard (Top 10 planten)';
_fc_props.phone_number_will_be_uploaded = 'Apparaat-ID wordt geüpload voor registratiedoeleinden.';
_fc_props.include_deleted = 'Opnemen Verwijderd';
_fc_props.primary_status_type = 'Primair statustype';
_fc_props.additional_status_trigger = 'Extra statustriggers';
_fc_props.update_tickets_destination = 'Bestemming bijwerken op alle gekoppelde tickets';
_fc_props.settings.send_destination_changes_to_connex = 'Bestemmingswijzigingen verzenden naar Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Is het ticket actief wanneer het door TrackIt wordt ontvangen (standaard \'altijd\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Automatisch volgend ticket activeren bij voltooiing van vorige ticket';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Moet het volgende ticket automatisch activeren bij het voltooien van het ticket (standaard \'true\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status naar inactieve status als er geen actief ticket is';
_fc_props.break_down = 'Afbreken';
_fc_props.idle = 'Inactief';
_fc_props.pto_work_maximum_speed_limit = 'Maximale snelheidslimiet werken';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Associatiestatuswijziging die een ticket met dat ticket voltooit';
_fc_props.approaching = 'nadert';
_fc_props.performing_task = 'Taak uitvoeren';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Weet u zeker dat u Geogate {0} wilt verwijderen?';
_fc_props.trackit_destination_changed_to_0 = 'Ticket {0} bijwerken. Bestemming gewijzigd.';
_fc_props.trackit_return_destination_changed_to_0 = 'Ticket {0} bijwerken. Retour gewijzigd.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Geogate toevoegen';
_fc_props.driver_enabled = 'Stuurprogramma ingeschakeld';
_fc_props.dispatcher_enabled = 'Verzender ingeschakeld';
_fc_props.runsheet_number = 'Runsheetnummer';
_fc_props.manual_load = 'Handmatig laden';
_fc_props.edit_geogate = 'Geogate bewerken';
_fc_props.handling = 'Behandeling';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate-paneel';
_fc_props.vehicle_is_not_logged_in = 'Bericht niet verzonden (voertuig is niet ingelogd)';
_fc_props.air = 'Lucht';
_fc_props.create_a_geogate = 'Maak een Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Klik op de kaart om de geogate-punten in te stellen.';
_fc_props.clock_out = 'Uit klokken';
_fc_props.handling_operation = 'Bediening:';
_fc_props.view_geogates = 'Geogates bekijken';
_fc_props.select_a_geogate = 'Selecteer een Geogate';
_fc_props.failed_to_find_geogate = 'Kan Geogate niet vinden';
_fc_props.adjust_geogate = 'Geogate aanpassen';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Vervaldatum Tijd Standaard Extra dagen';
_fc_props.menu_settings_title_retention = 'Dataretentie';
_fc_props.category = 'Categorie';
_fc_props.telemetry = 'Telemetrie';
_fc_props.ticket_data = 'Ticketgegevens';
_fc_props._30_days = '30 dagen';
_fc_props._6_months = '6 maanden';
_fc_props._1_year = '1 jaar';
_fc_props._3_years = '3 jaar';
_fc_props.new_group = 'Nieuwe groep';
_fc_props.selected_vehicles = 'Geselecteerde voertuigen';
_fc_props.group_name_is_required_field = 'Groepsnaam is verplicht veld';
_fc_props.at_least_vehicle_should_be_selected = 'Er moet minimaal 1 voertuig worden geselecteerd';
_fc_props.groups = 'Groepen';
_fc_props.trucks = 'Vrachtwagens';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate is verwijderd. Vernieuw de pagina.';
_fc_props.air_timestamp = 'Luchttijdstempel';
_fc_props.fail = 'MISLUKKING';
_fc_props.stuck = 'ZIT VAST';
_fc_props.dirty = 'VIES';
_fc_props.seasonal = 'Seizoensgebonden';
_fc_props.category_details = 'Categoriedetails';
_fc_props.alert_events = 'Alarmgebeurtenissen';
_fc_props.driver_performance_infractions = 'Overtredingen van bestuurdersprestaties';
_fc_props.telemetry_details = 'Locatiegegevens, hotspots, tijd in hotspots, tijd in regio\'s, DCM-gegevens, sondegegevens, motorgegevens';
_fc_props.ticket_data_details = 'Ticketgegevens';
_fc_props.general_details = 'Statuswijzigingen, prikkaarten, schema\'s';
_fc_props.wash = 'Wassen';
_fc_props.filter_options = 'Filteropties';
_fc_props.home_plants = 'Thuis Planten';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'Voor DCM PTO moet het PTO-sensortype worden ingesteld.';
_fc_props.invalid_io_box_mac_address = 'Ongeldig I/O Box Mac-adres';
_fc_props.access_denied = 'Toegang geweigerd';
_fc_props.wash_events = 'Was Evenementen';
_fc_props.min_speed = 'Minimale snelheid';
_fc_props.temperature_change = 'Temperatuurverandering';
_fc_props.degrees_cap = 'Graden';
_fc_props.washout_tracking = 'Washout-tracking';
_fc_props.approaching_wash = 'Wassen nadert';
_fc_props.arrival_wash = 'Aankomst Wassen';
_fc_props.performing_wash = 'Was uitvoeren';
_fc_props.wash_complete = 'Was voltooid';
_fc_props.must_be_positive_or_zero = 'Moet positief of nul zijn!';
_fc_props.min_speed_greater_than_max = 'De minimale snelheid moet lager zijn dan de maximale snelheid!';
_fc_props.clock_in = 'Intikken';
_fc_props.mix_water_at_x_to_y_for_t = 'Meng water met {0} tot {1} RPM\'s voor {2} omwentelingen.';
_fc_props.discharge_water_for_x_revolutions = 'Water afvoeren voor {0} omwentelingen.';
_fc_props.eod_drum_wash_complete = 'Trommelwas voltooid! <br> Stoptrommel met sonde bovenaan.';
_fc_props.washing_complete = 'Begeleide uitwas voltooid';
_fc_props.eod_washout_no_data_available = 'Sensorgegevens niet beschikbaar, voltooi het wasproces.';
_fc_props.eod_washout_no_data_available_title = 'Begeleide wash-out niet beschikbaar';
_fc_props.menu_reports_title_ticket_time_summary = 'Samenvatting tickettijd';
_fc_props.menu_reports_description_ticket_time_summary = 'Bekijk tickettijdinformatie voor een bestelling of voertuig';
_fc_props.menu_reports_title_summary = 'Samenvatting';
_fc_props.menu_reports_title_ticket_summary = 'Ticketoverzicht';
_fc_props.menu_reports_description_summary1 = 'Bekijk een samenvatting van de tijd in hotspots';
_fc_props.menu_dispatch_title_main = 'Voornaamst';
_fc_props.menu_dispatch_title_login_registry = 'Inlogregister';
_fc_props.menu_reports_title_main = 'Voornaamst';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Weet u zeker dat u dit exportgegevenstype wilt verwijderen?';
_fc_props.dvir_status_paper_logs = 'Papieren logboeken';
_fc_props.menu_reports_title_billing_report = 'Factureringsrapport';
_fc_props.menu_reports_description_billing_report = 'Factureringsrapport';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status naar ticketstatus bij werk activeren';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status naar ticketstatus bij werk activeren (standaard \'False\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'ponden';
_fc_props.units_weight_kilogram_label = 'Kilogrammen';
_fc_props.settings_weight = 'Gewicht';
_fc_props.units_pounds = 'ponden';
_fc_props.units_kilograms = 'Kilogrammen';
_fc_props.geo_gate_edit_modal_description = 'Beschrijving';
_fc_props.geo_gate_edit_modal_start_point = 'Startpunt';
_fc_props.geo_gate_edit_modal_end_point = 'Eindpunt';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Apparatuurtype';
_fc_props.geo_gate_edit_modal_heading_south = 'Zuid naar Noord';
_fc_props.geo_gate_edit_modal_heading_north = 'Noord naar Zuid';
_fc_props.geo_gate_edit_modal_driver_message = 'Stuurprogramma bericht';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Verzend bericht';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Vervaldatum Tijd';
_fc_props.geo_gate_edit_modal_permanent = 'permanent';
_fc_props.geo_gate_edit_modal_critical = 'kritisch';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Fout bij het opslaan van Geogate: veld Beschrijving moet zijn ingevuld.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Fout bij het opslaan van Geogate: veld Startpunt moet zijn ingevuld.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Fout bij het opslaan van Geogate: veld Startpunt bevat ongeldig gegevensformaat.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Fout bij opslaan van Geogate: veld Eindpunt bevat ongeldig gegevensformaat.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Fout bij het opslaan van Geogate: veld Vervaldatum Tijd moet zijn ingevuld.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Fout bij het opslaan van Geogate: Vervaldatum Tijdveld bevat ongeldig gegevensformaat.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Fout bij het opslaan van Geogate: Noord naar Zuid - veld Verzendbericht moet zijn ingevuld';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Fout bij het opslaan van Geogate: geen wijzigingen gedetecteerd';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Fout bij opslaan Geogate: Vervaldatum Tijdveld bevat verlopen datum-tijd';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Fout bij het opslaan van Geogate: het veld Beschrijving mag alleen letters en cijfers bevatten';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Fout bij het opslaan van Geogate: het veld Apparatuurtype moet zijn ingevuld';
_fc_props.geo_gate_table_message_label_north_to_south = 'Noord naar Zuid';
_fc_props.geo_gate_table_message_label_south_to_north = 'Zuid naar Noord';
_fc_props.geo_gate_table_message_label_both = 'Beide';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Lengte-en breedtegraad';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Als u dit aanvinkt, wordt de instelling voor het beperken van berichten over het verplaatsen van apparaten overschreven';
_fc_props.geo_gate_edit_modal_seasonal = 'Seizoensgebonden';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Fout bij het opslaan van Geogate: Seizoensdatums moeten worden ingevuld';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Fout bij het opslaan van Geogate: Seizoensdatumvelden bevatten een ongeldig gegevensformaat';
_fc_props.test_js_test_javascript = 'JavaScript testen';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometers';
_fc_props.units_miles = 'mijlen';
_fc_props.units_gallons_abbr = 'gal';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Fout bij het opslaan van Geogate: Zuid naar Noord - veld Bericht bestuurder moet zijn ingevuld';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Fout bij het opslaan van Geogate: Zuid naar Noord - veld Verzendbericht moet zijn ingevuld';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Fout bij het opslaan van Geogate: Noord naar Zuid - veld Bericht bestuurder moet zijn ingevuld';
_fc_props.geo_gate_heading = 'Rubriek';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Fout bij het opslaan van Geogate: veld Eindpunt moet zijn ingevuld';
_fc_props.units_mile = 'Mijl';
_fc_props.units_kilometer = 'Kilometer';
_fc_props.cloud_dvir__exit = 'Uitgang';
_fc_props.cloud_dvir__signature = 'Handtekening';
_fc_props.cloud_dvir__inspection = 'Inspectie';
_fc_props.cloud_dvir__add_photos_notes = 'Foto\'s/notities toevoegen';
_fc_props.cloud_dvir__odometer_reading = 'Kilometerstand';
_fc_props.cloud_dvir__engine_hours = 'Motoruren';
_fc_props.cloud_dvir__truck_details = 'Vrachtwagengegevens';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Bepaal de algehele staat van het voertuig';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Volledige naam en handtekening van de bestuurder';
_fc_props.cloud_dvir__sign = 'Teken';
_fc_props.cloud_dvir__driver_note_from = 'Bestuurder Opmerking van';
_fc_props.cloud_dvir__add_notes = 'Notities toevoegen';
_fc_props.cloud_dvir__driver_notes = 'Bestuurder notities';
_fc_props.cloud_dvir__done = 'Klaar';
_fc_props.cloud_dvir__needs_repair = 'Heeft reparatie nodig';
_fc_props.cloud_dvir__not_applicable = 'Niet toepasbaar';
_fc_props.cloud_dvir__review_and_sign = 'Beoordelen & ondertekenen';
_fc_props.cloud_dvir__add_photos = 'Voeg foto toe';
_fc_props.cloud_dvir__upload_photo_limit = 'Je kunt maximaal 3 foto\'s uploaden';
_fc_props.cloud_dvir__mismatch_title = 'Niet-overeenkomende waarschuwing';
_fc_props.cloud_dvir__ok = 'OK';
_fc_props.cloud_dvir_template = 'Cloud DVIR-sjabloon';
_fc_props.cloud_dvir__needs_review = 'DVIR moet worden beoordeeld';
_fc_props.cloud_dvir__submitted = 'Ingediend';
_fc_props.cloud_dvir__completed_by = 'Afgemaakt door';
_fc_props.cloud_dvir__review_btn = 'Beoordeling';
_fc_props.cloud_dvir__truck_number = 'Vrachtwagen {0}';
_fc_props.cloud_dvir__pending_review = 'In afwachting van beoordeling';
_fc_props.cloud_dvir__start_dvir = 'Start DVIR';
_fc_props.cloud_dvir__dvir_history = 'DVIR-geschiedenis';
_fc_props.cloud_dvir__view_all = 'Bekijk alles';
_fc_props.load_zone = 'Laad zone';
_fc_props.view_load_zones = 'Bekijk laadzones';
_fc_props.edit_load_zone = 'Laadzone bewerken';
_fc_props.create_a_load_zone = 'Maak een laadzone';
_fc_props.load_zone_panel = 'Load Zone-paneel';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Klik op de kaart om het midden van de laadzone in te stellen.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Deze inspectie heeft gebreken aangegeven en u hebt geselecteerd dat dit voertuig veilig te bedienen is. Gelieve te valideren alvorens verder te gaan';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Bij deze inspectie zijn geen defecten aangegeven en u hebt geselecteerd dat dit voertuig onveilig is om te besturen. Gelieve te valideren alvorens verder te gaan.';
_fc_props._90_days = '90 dagen';
_fc_props.cloud_dvir = 'Cloud-DVIR';
_fc_props.cloud_dvir_details = 'Cloud DVIR-inzendingen';
_fc_props.show_load_zones = 'laadzones tonen';
_fc_props.hide_load_zones = 'laadzones verbergen';
_fc_props.no = 'Nee';
_fc_props.cloud_dvir__reset = 'Resetten';
_fc_props.cloud_dvir__clear = 'Duidelijk';
_fc_props.mark_read = 'Markeer gelezen';
_fc_props.mark_unread = 'Markeer als ongelezen';
_fc_props.read = 'Lezen';
_fc_props.unread = 'Ongelezen';
_fc_props.air_sensor_serial_number = 'Serienummer luchtsensor';
_fc_props.air_sensor_mac_address = 'MAC-adres luchtsensor';
_fc_props.air_sensor_serial_number_invalid_format = 'Het serienummer van de luchtsensor heeft geen geldig formaat: (alfanumeriek en maximaal 32 tekens lang, met streepjes)';
_fc_props.air_sensor_mac_address_invalid_format = 'Het MAC-adres van de luchtsensor heeft geen geldig hexadecimaal formaat zoals weergegeven in de tijdelijke aanduiding voor het veld';
_fc_props.air_sensor_license = 'Luchtsensor';
_fc_props.adjust_route_point = 'Routepunt aanpassen';
_fc_props.route_point_panel = 'Routepuntpaneel';
_fc_props.route_point = 'Routepunt';
_fc_props.cloud_dvir__pre_trip = 'Voorreis';
_fc_props.cloud_dvir__post_trip = 'Post-reis';
_fc_props.cloud_dvir__other_insction_type = 'Ander';
_fc_props.cloud_dvir__dvir_completed = 'Voltooid';
_fc_props.cloud_dvir__awaiting_mechanic = 'Wachten op monteur';
_fc_props.cloud_dvir__awaiting_driver = 'In afwachting van afmelding van de bestuurder';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Kan het serienummer van de luchtsensor niet controleren. Neem contact op met de ondersteuning voor aanvullende hulp.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Dit serienummer van de luchtsensor bestaat al. Gebruik een ander serienummer';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Kan het MAC-adres van de luchtsensor niet controleren. Neem contact op met de ondersteuning voor hulp';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Fout bij het indienen van DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Probeer het opnieuw';
_fc_props.ticket_integration_descr = 'Ticketintegratie maakt het mogelijk om ticketintegratie-instellingen in te stellen, evenals het startscherm / laadscherm / hardmount-display';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR succesvol verzonden';
_fc_props.cloud_dvir__inspection_trip_type = 'Type inspectiereis';
_fc_props.menu_dvir_title_mechanic = 'Monteur';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Firmware luchtsensor';
_fc_props.when_no_active_ticket = 'Wanneer er geen actief ticket is';
_fc_props.used_by = 'Gebruikt door';
_fc_props.fields = 'Velden';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Weet u zeker dat u Ticketindeling \'{0}\' wilt verwijderen?';
_fc_props.menu_settings_title_ticket_layout = 'Ticketindeling';
_fc_props.add_ticket_layout = 'Voeg ticketindeling toe';
_fc_props.edit_ticket_layout = 'Ticketindeling bewerken';
_fc_props.ticket_layout_edit_modal_name = 'Naam';
_fc_props.ticket_layout_edit_modal_default = 'Standaard';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Fout bij opslaan van ticketindeling: het naamveld moet worden ingevuld.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Fout bij opslaan van ticketindeling: het naamveld mag alleen letters en cijfers bevatten';
_fc_props.ticket_layout_edit_modal_fields = 'Velden';
_fc_props.ticket_layout_edit_modal_usage = 'Gebruik';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Ticketindeling is verwijderd. Gelieve de pagina te vernieuwen.';
_fc_props.more_lower_case = 'meer';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Fout bij opslaan van ticketindeling: een of meer velden moeten zijn geselecteerd.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Fout bij opslaan ticketindeling: Er moeten een of meer statussen worden geselecteerd.';
_fc_props.cloud_dvir__ad_hoc = 'AD hoc';
_fc_props.radius_multiplier_should_be = 'Radius Multiplier moet een zwevend getal zijn';
_fc_props.ticket_max_activate_age_err = 'Ticket Max. Activeer leeftijdsminuten moet een geheel getal zijn.';
_fc_props.max_hotspot_unused_days_errs = 'Max. aantal ongebruikte dagen hotspot moet een geheel getal zijn.';
_fc_props.begin_pour_air_content = 'Begin met het gieten van luchtinhoud';
_fc_props.arrival_air_content = 'Aankomstluchtinhoud';
_fc_props.fully_mixed_air_content = 'Volledig gemengde luchtinhoud';
_fc_props.departure_air_content = 'Vertrekluchtinhoud';
_fc_props.popup_enabled = 'Pop-up ingeschakeld';
_fc_props.popup_disabled = 'Pop-up uitgeschakeld';
_fc_props.cloud_dvir__leave_confirmation_title = 'Waarschuwing';
_fc_props.cloud_dvir__leave_confirmation_description = 'Als u de pagina nu verlaat, gaan alle wijzigingen verloren.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Bevestigen';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Annuleren';
_fc_props.air_bt_ratio = 'Lucht BT-verhouding';
_fc_props.cloud_dvir__view_photos_notes = 'Bekijk foto\'s/notities';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Als u de status toevoegt, ontstaat er een leeg gebruik in een andere ticketlay-out. Wilt u doorgaan met de toevoeging?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Voertuig heeft geen luchtsensorlicentie.';
_fc_props.air_sensor_installed = 'Luchtsensor geïnstalleerd';
_fc_props.load_card = 'Kaart laden';
_fc_props.cloud_dvir__inspection_profile = 'Inspectieprofiel';
_fc_props.cloud_dvir__view_details = 'Details bekijken';
_fc_props.cloud_dvir__certify_repairs = 'Certificeer reparaties';
_fc_props.cloud_dvir__repairs_made = 'Reparaties uitgevoerd';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparaties niet nodig';
_fc_props.cloud_dvir__mechanic_note_from = 'Monteur Opmerking van';
_fc_props.cloud_dvir__mechanic_assignment = 'Monteur opdracht';
_fc_props.cloud_dvir__mechanic_signature_description = 'Markeer alle toepasselijke items hieronder en teken dat aangeeft dat het voertuig correct is geïnspecteerd.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'De staat van dit voertuig is bevredigend';
_fc_props.cloud_dvir__defects_corrected_label = 'Defecten zijn gecorrigeerd';
_fc_props.cloud_dvir__repair_not_required_label = 'Reparatie is niet vereist voor een veilige werking van het voertuig';
_fc_props.safety = 'Veiligheid';
_fc_props.cloud_dvir__awaiting_sign = 'In afwachting van teken';
_fc_props.cloud_dvir__dvir_review = 'DVIR-beoordeling';
_fc_props.settings.mobile_auto_start_navigation = 'Navigatie automatisch starten op mobiel';
_fc_props.cloud_dvir__edit = 'Bewerking';
_fc_props.delete_custom_label = 'Aangepast label verwijderen';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Uw versie van TrackIt is verouderd en sommige DVIR-functies werken mogelijk niet correct. Update TrackIt voordat u doorgaat.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Er is iets misgegaan tijdens het uploaden van de handtekening. Probeer het opnieuw';
_fc_props.max_hotspot_unused_days_error = 'Max. hotspot-ongebruikte dagen heeft een maximum van 1000 dagen.';
_fc_props.menu_dvir_title_admin_templates = 'Sjablonen';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'beheerder';
_fc_props.cloud_dvir__admin_templates = 'Sjablonen';
_fc_props.form_name = 'Formulier naam';
_fc_props.form_status = 'Formulierstatus';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Downloaden';
_fc_props.cloud_dvir__option_view = 'Weergave';
_fc_props.cloud_dvir__inspectortype_driver = 'Bestuurder';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Bekijk/bewerk/maak aangepaste DVIR-formulieren en inzendingen';
_fc_props.menu.dvir.description.mechanic = 'Bekijk/bewerk bruikbare DVIR-inzendingen';
_fc_props.not_in_use = 'Niet in gebruik';
_fc_props.logged_in = 'ingelogd';
_fc_props.reviewing_driver = 'Bestuurder beoordelen';
_fc_props.cloud_dvir__report_title = 'Inspectierapport van het voertuig van de bestuurder';
_fc_props.cloud_dvir__report_signed = 'ondertekend';
_fc_props.cloud_dvir__report_page_counter = 'Pagina {0} van {1}';
_fc_props.cloud_dvir__report_defects = 'Defecten';
_fc_props.cloud_dvir__report_comments = 'Opmerkingen';
_fc_props.cloud_dvir__report_inspection_table_title = 'Voertuiginspectie';
_fc_props.cloud_dvir__report_driver_table_title = 'Bestuurdersinformatie';
_fc_props.cloud_dvir__report_carrier = 'Vervoerder';
_fc_props.cloud_dvir__report_truck = 'Vrachtauto';
_fc_props.cloud_dvir__report_location = 'Plaats';
_fc_props.cloud_dvir__report_odometer = 'Kilometerteller';
_fc_props.cloud_dvir__report_hours = 'Uur';
_fc_props.cloud_dvir__report_no_comments = 'Geen commentaar';
_fc_props.include_unlicensed_vehicles = 'Inclusief voertuigen zonder licentie';
_fc_props.cloud_dvir__search_by_form_name = 'Zoek op formuliernaam';
_fc_props.cloud_dvir__create_dvir = 'Maak een DVIR';
_fc_props.cloud_dvir__update_dvir = 'DVIR bijwerken';
_fc_props.cloud_dvir__form_name = 'Formulier naam';
_fc_props.cloud_dvir__form_description = 'Formulierbeschrijving';
_fc_props.cloud_dvir__form_status = 'Formulierstatus';
_fc_props.information = 'Informatie';
_fc_props.cloud_dvir__search_categories = 'Zoek categorieën';
_fc_props.cloud_dvir__create_new_category = 'Maak een nieuwe categorie';
_fc_props.category_name = 'categorie naam';
_fc_props.sub_category = 'Subcategorie';
_fc_props.sub_category_name = 'Naam van subcategorie';
_fc_props.delete_category = 'Categorie verwijderen';
_fc_props.cloud_dvir__dvir_information = 'DVIR-informatie';
_fc_props.cloud_dvir__dvir_choose_categories = 'Kies Categorieën';
_fc_props.cloud_dvir__create_category = 'Categorie maken';
_fc_props.cloud_dvir__update_category = 'Categorie bijwerken';
_fc_props.delete_route_point = 'Routepunt verwijderen';
_fc_props.view_route_points = 'Routepunten bekijken';
_fc_props.ignore_cancel_preload = 'Negeer de annulering, u bent vooraf geladen. Je homerun wordt binnenkort getoond';
_fc_props.cloud_dvir__assigned_mechanic = 'Toegewezen monteur';
_fc_props.cloud_dvir__add_mechanic = 'Voeg een monteur toe';
_fc_props.contact_type = 'Contact type';
_fc_props.cloud_dvir__send_email_notifications = 'E-mailmeldingen verzenden';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Fout bij het ophalen van DVIR-geschiedenisrecords.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Fout bij ophalen van DVIR-record.';
_fc_props.cloud_dvir__error_saving_submission = 'Fout bij opslaan van inzending. Probeer het nog eens.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Fout bij het ophalen van de lijst met mechanismen.';
_fc_props.cloud_dvir__template_saved = 'Sjabloon opgeslagen';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Fout bij het ophalen van de lijst met sjablonen.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Fout bij opslaan van sjabloon. Probeer het nog eens.';
_fc_props.dispatch_group = 'Verzendingsgroep';
_fc_props.save_adjust = 'Routepunt opslaan en aanpassen';
_fc_props.reset_route_point = 'Routepunt opnieuw instellen';
_fc_props.no_records_stub_adjust_filters = 'Probeer uw zoek- of filterinstellingen aan te passen om het aantal resultaten te vergroten.';
_fc_props.no_dvir_records = 'Allemaal bezig met DVIR\'s!';
_fc_props.no_dvir_records_sub_title = 'Je hebt veel bereikt';
_fc_props.settings.master_data_connex_x_api_key = 'Stamgegevens Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'Geen inzinkingswaarde verkregen voor: {0}';
_fc_props.please_select_a_sensor_status_type = 'Selecteer de sensorstatustypen om deze waarschuwing te activeren';
_fc_props.settings.master_data_connex_token = 'Stamgegevens Connex-token';
_fc_props.error_validating_master_data_connex_credentials = 'Fout bij het valideren van Master Data Connex-referenties';
_fc_props.settings.master_data_connex_base_url = 'Stamgegevens Connex Basis-URL';
_fc_props.settings.master_data_connex_exp_time = 'Vervaltijd van stamgegevens Connex';
_fc_props.menu_settings_title_dispatch_group = 'Verzendgroepen';
_fc_props.settings.dispatch_groups = 'Verzendgroepen';
_fc_props.dispatch_group_name = 'Naam verzendgroep';
_fc_props.dispatch_group_plants = 'Groepsplanten verzenden';
_fc_props.dispatch_group_plants_mapping_message = 'Geef aan welke fabrieken deel zullen uitmaken van deze Dispatch Group.';
_fc_props.dispatch_group_users_mapping_message = 'Geef aan welke gebruikers deel zullen uitmaken van deze Dispatch Group.';
_fc_props.dispatch_group_plant_disassociation_message = 'Planten moeten zich onder één verzendgroep tegelijk bevinden';
_fc_props.dispatch_group_search_by_plant = 'Zoeken op plantnaam';
_fc_props.dispatch_group_search_by_username = 'Zoek op gebruikersnaam';
_fc_props.search_by_dispatch_group_name = 'Zoeken op verzendgroepnaam';
_fc_props.create_dispatch_group = 'Maak een verzendgroep aan';
_fc_props.update_dispatch_group = 'Verzendgroep bijwerken';
_fc_props.dispatch_group_saved = 'Verzendgroep opgeslagen';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Als u deze verzendgroep verwijdert, worden er standaard verzendgroepen aangemaakt voor de gekoppelde vestigingen.';
_fc_props.error_saving_dispatch_group = 'Fout bij opslaan van verzendgroep. Probeer het nog eens.';
_fc_props.please_enter_a_valid_plant_num = 'Voer een geldig installatienummer in.';
_fc_props.cloud_dvir__report_no_defects = 'Geen gebreken';
_fc_props.shoud_select_dispatch_group = 'U moet minimaal één verzendgroep selecteren';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Kan niet verwijderen omdat deze verzendgroep gebruikers bevat die alleen aan deze verzendgroep zijn gekoppeld.';
_fc_props.cloud_dvir__username = 'Gebruikersnaam';
_fc_props.cloud_dvir__password = 'Wachtwoord';
_fc_props.cloud_dvir__login = 'Log in';
_fc_props.cloud_dvir__mechanic_sign_in = 'Monteur aanmelden';
_fc_props.delete_dispatch_group = 'Verzendgroep verwijderen';
_fc_props.cloud_dvir__driver = 'Bestuurder';
_fc_props.cloud_dvir__equipment_pound = 'Apparatuur #';
_fc_props.cloud_dvir__mechanic = 'Monteur';
_fc_props.dispatch_group_user_only_unassociate_message = 'Kan gebruiker niet verwijderen omdat gebruikers aan ten minste één verzendgroep moeten zijn gekoppeld.';
_fc_props.cloud_dvir__time_period_filter_today = 'Vandaag';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Gisteren';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Laatste 7 dagen';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Laatste 30 dagen';
_fc_props.cloud_dvir__date_range_filter = 'Filter op datum';
_fc_props.cloud_dvir__download = 'Downloaden';
_fc_props.dispatch_group_is_empty = 'Verzendgroep mag niet leeg zijn.';
_fc_props.dispatch_group_with_empty_plants = 'Er moet ten minste één vestiging aan de verzendgroep zijn gekoppeld.';
_fc_props.dispatch_group_with_empty_users = 'Er moet ten minste één gebruiker aan de verzendgroep zijn gekoppeld.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Kan de verzendgroep niet verwijderen omdat deze gebruikers bevat die alleen aan deze verzendgroep zijn gekoppeld.';
_fc_props.dispatch_group_not_exists = 'Verzendgroep bestaat niet.';
_fc_props.dispatch_group_contains_one_plant = 'Kan niet worden verwijderd omdat deze verzendgroep slechts één plant bevat.';
_fc_props.dispatch_group_users = 'Verzendgroepgebruikers';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Fout bij ophalen van DVIR-record';
_fc_props.cloud_dvir__assign_mechanic = 'Monteur toewijzen';
_fc_props.cloud_dvir__submit_review = 'Review versturen';
_fc_props.error_deleting_dispatch_group = 'Fout bij verwijderen van verzendgroep.';
_fc_props.error_retrieving_dispatch_groups = 'Fout bij het ophalen van de lijst met verzendgroepen.';
_fc_props.cloud_dvir__undo_selection = 'Selectie ongedaan maken';
_fc_props.enable_master_data = 'Schakel stamgegevens in';
_fc_props.manually_sync = 'Handmatig synchroniseren';
_fc_props.master_data_connex_token_expiration = 'Stamgegevens Connex-token verlopen';
_fc_props.cloud_dvir__time_period_filter_custom = 'Aangepast';
_fc_props.settings.zello_session_timeout_minutes = 'Time-out van Zello-sessie (minuten)';
_fc_props.menu_settings_title_master_data = 'Stamgegevens';
_fc_props.settings.master_data = 'Stamgegevens';
_fc_props.error_retrieving_master_data = 'Fout bij ophalen van stamgegevens';
_fc_props.master_data_sync_completed = 'Synchronisatie van hoofdgegevens voltooid';
_fc_props.master_data_successfully_updated = 'Gegevens succesvol bijgewerkt';
_fc_props.master_data_failed_to_update = 'Opslaan mislukt.';
_fc_props.master_data_sync_initiated = 'Synchronisatie van hoofdgegevens gestart';
_fc_props.master_data_sync_failed = 'Synchronisatie van hoofdgegevens mislukt';
_fc_props.enabled_excluding_origin_on_deliveries = 'Waar (exclusief herkomst op bezorgbonnen)';
_fc_props.enabled_including_origin_on_deliveries = 'Waar (inclusief herkomst op bezorgbonnen)';
_fc_props.cloud_dvir__i_will_decide_later = 'Ik zal later beslissen';
_fc_props.returning = 'Terugkerend';
_fc_props.leftover_concrete = 'Overgebleven beton';
_fc_props.leftover_concrete_on_vehicle_x = 'Overgebleven beton op voertuig {0}';
_fc_props.leftover_concrete_alert = 'Waarschuwing voor overgebleven beton';
_fc_props.wc_ratio_exceeded = 'W/C-verhouding overschreden';
_fc_props.search_by_site_name = 'Zoek op sitenaam';
_fc_props.sites = 'Sites';
_fc_props.token_expiration = 'Vervaldatum van token';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API-sleutelbeheer';
_fc_props.settings.connex_api_key_management = 'Connex API-sleutelbeheer';
_fc_props.error_retrieving_connex_api_key_data = 'Fout bij het ophalen van connex API-sleutelgegevens';
_fc_props.qr_code = 'QR code';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Chauffeur en interface informeren bij het betreden van de verkeerde fabriek';
_fc_props.enable_driver_message = 'Stuurprogrammabericht inschakelen';
_fc_props.enable_dispatch_message = 'Schakel verzendbericht in';
_fc_props.enable_sending_message_to_interface = 'Schakel het verzenden van berichten naar Interface in';
_fc_props.ticket_qr_code = 'Ticket QR-code';
_fc_props.leftover_concrete_alert_description = 'Waarschuw wanneer er betonresten worden gevonden na het storten.';
_fc_props.minimum_leftover_concrete_amount = 'Min. overgebleven betonhoeveelheid';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Fout bij valideren van Master Data Connex-referenties niet geautoriseerd';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Fout bij het valideren van de machtigingen voor Connex-referenties voor mastergegevens';
_fc_props.error_validating_master_data_connex_url_not_found = 'Fout bij valideren van Master Data Connex URL niet gevonden';
_fc_props.error_validating_master_data_connex_unknown_error = 'Fout bij valideren van mastergegevens Connex Onbekende fout';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Synchronisatie van hoofdgegevens mislukt. Synchroniseer het voertuigtype.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Synchronisatie van hoofdgegevens mislukt. Voertuig synchroniseren.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Synchronisatie van stamgegevens mislukt Installatie synchroniseren.';
_fc_props.master_data_sync_failed_data_base_connection = 'Synchronisatie van hoofdgegevens mislukte verbinding met database.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Fout bij het valideren van Master Data Connex Credentials Bearer Token Toestemming is onvoldoende';
_fc_props.settings.back_date_arrive_job = 'Terug datum aankomst baan';
_fc_props.device_notifications = 'Meldingen';
_fc_props.loading_number = 'Nummer laden';
_fc_props.leftover_concrete_volume_temp_age = 'Overgebleven volume={0}, Temp={1}, Leeftijd={2}';
_fc_props.total_ordered = 'Totale bestelde hoeveelheid';
_fc_props.total_ticketed = 'Geleverd Besteld';
_fc_props.load_number = 'Laadnummer';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Min. overgebleven beton moet worden ingevuld en groter dan {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Fout bij valideren van stamgegevens. Referenties kunnen niet hetzelfde zijn.';
_fc_props.use_aws_for_talk = 'Gebruik AWS voor praten';
_fc_props.air_readings_not_supported = 'Luchtmetingen worden niet ondersteund';
_fc_props.air_not_supported = 'Lucht niet ondersteund';
_fc_props.mix_not_supported = 'Mix niet ondersteund';
_fc_props.air_default_and_none_not_supported = 'Kan standaard niet selecteren en wordt tegelijkertijd niet ondersteund!';
_fc_props.notifications_enabled = 'Meldingen ingeschakeld';
_fc_props.prevent_status_change = 'Voorkom statuswijziging';
_fc_props.message_to_interface = 'Bericht aan Interface';
_fc_props.prevent_wrong_plant = 'Voorkom verkeerde planten';
_fc_props.previous_tickets = 'Vorige kaartjes';
_fc_props.audit_ticket_number = 'Ticket nummer';
_fc_props.audit_date_created = 'Datum gecreeërd';
_fc_props.audit_vehicle_number = 'Voertuignummer';
_fc_props.cloud_dvir__table_ok = 'Veilig te bedienen';
_fc_props.cloud_dvir__table_needs_repair = 'Onveilig om te gebruiken';
_fc_props.missing_air_calibration = 'Geen luchtkalibratiegegevens';
_fc_props.reported_defects = 'Gemelde gebreken';
_fc_props.address_latitude = 'Adres Breedtegraad';
_fc_props.address_longitude = 'Adres Lengtegraad';
_fc_props.routing_latitude = 'Routeringsbreedte';
_fc_props.routing_longitude = 'Lengtegraad van route';
_fc_props.category_name_must_be_unique = 'Categorienaam moet uniek zijn';
_fc_props.subcategory_name_must_be_unique = 'De naam van de subcategorie moet uniek zijn';
_fc_props.menu_settings_tracking_device = 'Volgapparaat';
_fc_props.menu_settings_title_tracking_device = 'Volgapparaat';
_fc_props.menu_settings_title_personal_device_registration = 'Registratie van persoonlijke apparaten';
_fc_props.registration_code = 'Registratiecode';
_fc_props.creation_date = 'Creatie';
_fc_props.expiration_date = 'Vervaldatum';
_fc_props.trackitadmin_personal_device_registration = 'Registratie van persoonlijke apparaten';
_fc_props.create_registration_code = 'Registratiecode toevoegen';
_fc_props.remove_registration_code = 'Registratiecode verwijderen';
_fc_props.add_a_registration_code = 'Voeg een registratiecode toe';
_fc_props.select_number_of_registration_codes_to_generate = 'Selecteer het aantal registratiecodes dat u wilt genereren.';
_fc_props.registration_code_count = 'Aantal registratiecodes';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Weet u zeker dat u de geselecteerde geregistreerde code wilt verwijderen?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Weet u zeker dat u de geselecteerde geregistreerde codes wilt verwijderen?';
_fc_props.add_personal_device = 'Persoonlijk apparaat toevoegen';
_fc_props.menu_settings_title_personal_device = 'Persoonlijk apparaat';
_fc_props.cloud_dvir__approval_critical_alert = 'Het voertuig heeft een kritiek defect. Kan niet worden gemarkeerd als veilig om te gebruiken.';
_fc_props.export_registration_codes = 'Registratiecodes exporteren';
_fc_props.download_format = 'Formaat downloaden';
_fc_props.specify_download_format_x = 'Geef het downloadformaat op om {0} registratiecodes te exporteren';
_fc_props.personal_device_download = 'Downloaden';
_fc_props.add_personal_device_input_message = 'Voer hieronder een persoonlijke apparaat-ID in. Na het opslaan wordt de vervaldatum automatisch toegepast';
_fc_props.error_creating_personal_device = 'Fout bij het maken van een persoonlijk apparaat';
_fc_props.device_already_exists = 'Persoonlijk apparaat bestaat al';
_fc_props.export = 'Exporteren';
_fc_props.error_can_not_change_connex_eid = 'Kan connex eid niet wijzigen omdat deze momenteel wordt gebruikt voor stamgegevens';
_fc_props.remove_personal_device = 'Persoonlijk apparaat verwijderen';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Weet u zeker dat u het geselecteerde persoonlijke apparaat wilt verwijderen?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Weet u zeker dat u de geselecteerde persoonlijke apparaten wilt verwijderen?';
_fc_props.error_deleting_personal_device = 'Fout bij verwijderen van persoonlijk apparaat';
_fc_props.customerid = 'Klanten ID';
_fc_props.customername = 'klantnaam';
_fc_props.ordernumber = 'Bestellingsnummer';
_fc_props.orderdescription = 'Bestelbeschrijving';
_fc_props.locationid = 'Locatie-ID';
_fc_props.locationdescription = 'Locatie Beschrijving';
_fc_props.productid = 'Product-ID';
_fc_props.productdescription = 'Productomschrijving';
_fc_props.vehicleid = 'Voertuig-ID';
_fc_props.vehicledescription = 'Voertuig Beschrijving';
_fc_props.ticketnumber = 'Ticket #';
_fc_props.ticketdate = 'Ticketdatum/-tijd';
_fc_props.drivername = 'Naam van de bestuurder';
_fc_props.arriveregion = 'Aankomstregio (TIJD)';
_fc_props.leaveregion = 'Regio verlaten (TIME)';
_fc_props.regionduration = 'Regioduur';
_fc_props.menu_reports_title_prevailing_wage = 'Het heersende loon';
_fc_props.slump_calibration_x_not_found_for_y = 'Slump-kalibratie "{0}" niet gevonden voor ticket {1}';
_fc_props.slump_calibration_not_found = 'Slump-kalibratie niet gevonden';
_fc_props.slump_calibration_not_found_description = 'Waarschuw wanneer er geen slumpt-kalibratie is gevonden';
_fc_props.error_getting_personal_device_list = 'Fout bij ophalen van persoonlijke apparaatlijst';
_fc_props.batch_summary_date_range_note = '*LET OP: Dit rapport is beperkt tot 3 dagen als er geen filters worden toegepast. Het datumbereik kan maximaal 31 dagen duren als het wordt gefilterd op apparatuur (maximaal 10), fabriek (maximaal 1), product (maximaal 1) of functie (maximaal 1)';
_fc_props.select_registration_code_expiration_time_days = 'Selecteer het aantal dagen totdat de registratiecodes verlopen en klik vervolgens op Opslaan.';
_fc_props.add_personal_device_expiration = 'Vervaldatum (in dagen)';
_fc_props.cloud_dvir__vehicle_no = 'Voertuignr.';
_fc_props.cloud_dvir__trailer_no = 'Aanhangwagen Nee';
_fc_props.cloud_dvir__vehicle = 'Voertuig';
_fc_props.cloud_dvir__trailer = 'Aanhangwagen';
_fc_props.cloud_dvir__truck_number_label = 'Vrachtwagennummer';
_fc_props.cloud_dvir__trailer_number = 'Aanhangwagennummer';
_fc_props.cloud_dvir__has_trailer = 'Niet rijden met een aanhanger';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Voer hier uw aanhangernummer in';
_fc_props.work_order = 'Werkopdracht';
_fc_props.unable_to_determine_slump = 'Kan de inzinking niet bepalen';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Bepaal de algehele staat van de trailer';
_fc_props.defaultTrailer = 'Standaardtrailer';
_fc_props.trailer_type_column_billable = 'Factureerbaar';
_fc_props.trailer_type_column_notes = 'Notities';
_fc_props.trailer_type_column_date = 'Datum';
_fc_props.trailer_type_column_order = 'Volgorde';
_fc_props.trailer_type_column_ticket = 'Ticket';
_fc_props.trailer_type_column_project = 'Project';
_fc_props.trailer_type_column_customer = 'Klant';
_fc_props.trailer_type_column_location = 'Locatie';
_fc_props.trailer_type_column_vehicle_type = 'Voertuigtype';
_fc_props.trailer_type_column_product = 'Product';
_fc_props.trailer_type_action_rebill = 'Opnieuw factureren';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Synchronisatie van stamgegevens mislukt, er is geen standaardapparatuurtype ingesteld';
_fc_props.trailer_type = 'Aanhangwagentype';
_fc_props.trailer_type_is_required = 'Aanhangwagentype is vereist';
_fc_props.trailer_number_already_exists = 'Trailernummer bestaat al';
_fc_props.plate_number_already_exists = 'Kenteken bestaat al';
_fc_props.trailer_vin_already_exists = 'Trailer Vin bestaat al';
_fc_props.trailer_does_not_exist = 'Trailer bestaat niet';
_fc_props.trailer_number_is_required = 'Aanhangwagennummer is vereist';
_fc_props.plate_number_is_required = 'Kenteken is vereist';
_fc_props.plate_number = 'Nummerplaat';
_fc_props.menu_settings_title_trailer = 'Aanhangwagen';
_fc_props.add_trailer = 'Trailer toevoegen';
_fc_props.edit_trailer = 'Bewerken Trailer';
_fc_props.menu_settings_title_equipment = 'Apparatuur';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Negeer nieuwe tickets van Command Cloud die ouder zijn dan X minuten';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Negeer nieuwe tickets van Command Cloud met een verzenddatum/tijd ouder dan X minuten (standaard is dit leeg en uitgeschakeld)';
_fc_props.edit_load_card = 'Bewerken Laadkaart';
_fc_props.default_layout = 'Standaard lay-out';
_fc_props.load_card_empty_fields = 'Fout bij het opslaan van de laadkaart: Eén of meer velden moeten geselecteerd zijn.';
_fc_props.trailers = 'Aanhangwagens';
_fc_props.error_failed_to_save_data = 'Fout: Gegevens konden niet worden opgeslagen';
_fc_props.slump_increased_plasticizer_added_question = 'Hoeveel weekmaker is toegevoegd?';
_fc_props.add_trailer_type = 'Voeg trailertype toe';
_fc_props.menu_settings_title_trailer_type = 'Aanhangwagentype';
_fc_props.edit_trailer_type = 'Bewerk trailertype';
_fc_props.single_use_codes = 'Codes voor eenmalig gebruik';
_fc_props.multiple_use_codes = 'Codes voor meervoudig gebruik';
_fc_props.trailer_type_description_is_required = 'Beschrijving van het type aanhanger is vereist';
_fc_props.trailer_saved = 'Trailer opgeslagen';
_fc_props.cloud_dvir__trailer_only_dvir = 'Alleen trailer DVIR';

export default _fc_props;
