import { useState, useMemo } from 'react';
import { initFieldState } from './constants';
import { FieldState } from '../types';
import { InspectionValue } from '../../types/submission';

export enum OverallFormFields {
  OverallVehicle = 'overall_vehicle',
  OverallTrailer = 'overall_trailer',
  Sign = 'sign'
}

/** src is reference to blob */
export type Sign = { src: string, id: string }

export type OverallForm = {
  [OverallFormFields.OverallVehicle]: FieldState<InspectionValue>,
  [OverallFormFields.OverallTrailer]: FieldState<InspectionValue>,
  hasCriticalIssue: {
    [OverallFormFields.OverallVehicle]: boolean,
    [OverallFormFields.OverallTrailer]: boolean,
  },
  [OverallFormFields.Sign]: FieldState<Sign>
 };

const transformInitForm = ():OverallForm => Object.values(OverallFormFields).reduce((form, fieldName) => ({ ...form, [fieldName]: initFieldState }), {} as OverallForm);

const useOverallForm = ({ hasTrailer, isVehicleDvirSubmit, isTrailerDvirSubmit }) => {
  const [form, setForm] = useState<OverallForm>(() => transformInitForm());

  const handleFormChange = (name: string, value: InspectionValue | string) => {
    const updatedField = { value, isError: false };

    setForm({ ...form, [name]: updatedField });
  };

  const isFormFilled = useMemo(() => {
    const requiredFields = [
      ...(isVehicleDvirSubmit ? [form[OverallFormFields.OverallVehicle]] : []),
      form[OverallFormFields.Sign],
      ...(hasTrailer && isTrailerDvirSubmit ? [form[OverallFormFields.OverallTrailer]] : []),
    ];

    return requiredFields.filter(({ value }) => value).length === requiredFields.length;
  }, [form]);

  return { form, handleFormChange, isFormFilled };
};

export default useOverallForm;
