export enum Pages {
  Home = 'home',
  SubmissionForm = 'submission_form',
}

export enum PageMode {
  New = 'new',
  View = 'view',
  HistoryView = 'historyView',
}
