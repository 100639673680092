export const isFloat = (num: number): boolean => num % 1 !== 0;

export const convertToFixed = (num: string | number, digits = 2): string => {
  const strNumber = num.toString().replace(',', '.');

  if (isFloat(Number(strNumber))) {
    return Number(strNumber).toFixed(digits);
  }

  return strNumber;
};
