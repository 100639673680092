import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReviewNavigation from './ReviewNavigation';
import MechanicInspection from './inspection/MechanicInspection';
import SignReview from './sign/SignReview';
import AssignMechanic from './asssign-mechanic/AssignMechanic';
import { MechanicReviewProvider } from '../context/MechanicReviewProvider';
import { useAlert } from '../../hooks';
import { ReviewFormSteps } from '../types';
import { Submission } from '../../types/submission';
import { MechanicOptions } from './types';

interface MechanicReviewProps {
    onExit: () => void;
    submission: Submission;
}

const MechanicReview:FC<MechanicReviewProps> = ({ submission, onExit }) => {
  const [step, setStep] = useState<ReviewFormSteps>(ReviewFormSteps.Inspection);
  // temporary, since it is hardcoded
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mechanicOptions, setMechanicOptions] = useState<MechanicOptions>([]);

  const { t } = useTranslation();
  const alert = useAlert();

  useEffect(() => {
    /** TODO: replace to GET mechanics */
    new Promise((res) => { setTimeout(res, 500); })
      .catch(() => {
        alert.error({ title: t('cloud_dvir__error_retrieving_mechanics'), children: t('cloud_dvir__error_submit_dvir_descr') });
      });
  }, []);

  const renderContent = () => {
    switch (step) {
      case ReviewFormSteps.Inspection: {
        return <MechanicInspection submission={submission} />;
      }
      case ReviewFormSteps.AssignMechanic: {
        return <AssignMechanic mechanicOptions={mechanicOptions} />;
      }
      default: {
        return <SignReview />;
      }
    }
  };

  return (
    <MechanicReviewProvider submission={submission}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <ReviewNavigation
          step={step}
          onStepChange={setStep}
          onExit={onExit}
        />
        <Box sx={{
          width: '100%',
          height: '100%',
          backgroundColor: (theme) => theme.palette.neutral.variation99,
        }}
        >
          <Box sx={{
            width: '100%',
            maxWidth: '800px',
            margin: '0 auto',
            paddingTop: 6,
            paddingLeft: 2,
            paddingRight: 2,
          }}
          >
            {renderContent()}
          </Box>
        </Box>
      </Box>
    </MechanicReviewProvider>
  );
};

export default MechanicReview;
