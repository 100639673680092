import LogRequest from '../api/model/auxRequest/LogRequest';
import VersionManager from './VersionManager';

export const Log = {
  d(...objects) {
    this.postLogMessage('d', objects);
  },
  e(...objects) {
    this.postLogMessage('e', objects);
  },
  i(...objects) {
    this.postLogMessage('i', objects);
  },
  v(...objects) {
    this.postLogMessage('v', objects);
  },
  w(...objects) {
    this.postLogMessage('w', objects);
  },
  postLogMessage(level, objects) {
    const logRequest: LogRequest = new LogRequest(level, objects);
    VersionManager.auxFunctionsMap.log(logRequest);
  },
};
