import React, { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Text } from '../components';

const StyledBoxItem = styled(Box)(({ theme }) => ({
  minWidth: '240px',
  flex: 1,
  maxHeight: '470px',
  overflow: 'hidden',
  paddingBottom: `${theme.indent * 4}px`,
  borderRadius: 12,
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 2,
  padding: `${theme.indent * 3}px ${theme.indent * 2}px`,
}));

const StyleButtonDisabled = {
  '&.Mui-disabled': {
    background: '#eaeaea',
    color: '#c0c0c0',
  },
};

interface BoxItemProps {
  icon: React.ReactNode;
  title: string;
  buttonText: string;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}

const BoxItem:FC<BoxItemProps> = ({
  icon, title, buttonText, onClick, children, disabled,
}) => (
  <StyledBoxItem>
    <StyledHeader>
      <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
        {icon}
        <Text fontSize="large" fontLevel="title">{title}</Text>
      </Box>
      <Button disabled={disabled} textTransform="uppercase" isFilled onClick={onClick} sx={StyleButtonDisabled}>{buttonText}</Button>
    </StyledHeader>
    {children}
  </StyledBoxItem>
);

export default BoxItem;
