import React, {
  FC, memo, useState, createContext, useMemo, useContext,
} from 'react';
import { LoginData, MechanicLoginData } from '../types';
import { MechanicLaunchDataContext } from '../../context/LaunchDataContext';

interface AuthenticationProviderProps {
    children: React.ReactNode | React.ReactNode[];
}

interface ContextValue {
  login: (loginData: LoginData) => void;
  logout: () => void;
  isAuthorized: boolean;
  isLoading: boolean;
  mechanicLoginData: MechanicLoginData | null;
}

export const AuthenticationContext = createContext<ContextValue>({
  login: () => {},
  logout: () => {},
  isAuthorized: false,
  isLoading: false,
  mechanicLoginData: null,
});

export const AuthenticationProvider:FC<AuthenticationProviderProps> = memo(({ children }) => {
  const { context, loginUrl } = useContext(MechanicLaunchDataContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [mechanicLoginData, setMechanicLoginData] = useState<MechanicLoginData | null>(null);

  const logout = () => {
    setIsAuthorized(false);
    setMechanicLoginData(null);
  };

  const login = (loginData: LoginData) => {
    console.log('loginData: ', loginData);
    console.log('loginUrl: ', loginUrl);
    console.log('context: ', context);

    setIsLoading(true);

    /** TODO: replace me during auth integration */
    new Promise((resolve) => { setTimeout(resolve, 500); })
      .then(() => {
        setIsAuthorized(true);
        setMechanicLoginData({ employeeId: 'HARDCODED' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const value = useMemo(() => ({
    isAuthorized, isLoading, mechanicLoginData, logout, login,
  }), [isAuthorized, isLoading, mechanicLoginData, logout, login]);

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
});

export default AuthenticationProvider;
