import React, { FC } from 'react';
import { Theme, styled } from '@mui/material/styles';
import MUIAlert, { AlertProps as MUIAlertProps } from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

const getBorderColor = (theme: Theme, severity: MUIAlertProps['severity']) => {
  switch (severity) {
    case 'error': {
      return theme.palette.red.variation10;
    }
    case 'warning': {
      return theme.palette.yellow.variation10;
    }
    case 'success': {
      return theme.palette.green.variation10;
    }
    case 'info': {
      return theme.palette.blue.variation10;
    }
    default:
      return theme.palette.blue.variation10;
  }
};

export interface AlertProps extends MUIAlertProps {
  title?: string;
}

const StyledAlert = styled(MUIAlert)<MUIAlertProps>(({ theme, severity }) => ({
  border: `2px solid ${getBorderColor(theme, severity)}`,
}));

const Alert:FC<AlertProps> = ({ title, children, ...rest }) => (
  <StyledAlert {...rest}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {children}
  </StyledAlert>
);

export default Alert;
