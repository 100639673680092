import React, {
  createContext, useEffect, useCallback, useRef, useState, RefObject,
} from 'react';
import { createPortal } from 'react-dom';
import { Box } from '@mui/material';
import { Alert } from '../components';
import { AlertProps } from '../components/alert/Alert';

export type AlertContextProps = AlertProps & {
  hideAfterSeconds?: number;
}

export type AlertContextAPI = {
  success: (alertProps: AlertContextProps) => void;
  info: (alertProps: AlertContextProps) => void;
  warning: (alertProps: AlertContextProps) => void;
  error: (alertProps: AlertContextProps) => void;
  hide: (alertProps: AlertContextProps) => void;
}

const initialValue = {
  success: () => {},
  info: () => {},
  warning: () => {},
  error: () => {},
  hide: () => {},
};

export const AlertContext = createContext<RefObject<AlertContextAPI>>({ current: initialValue });

export const AlertContextProvider = ({ children }) => {
  const [alert, setAlert] = useState<AlertContextProps | null>(null);

  const root = useRef<HTMLDivElement | null>(null);
  const alertContext = useRef<AlertContextAPI>(initialValue);
  const timeoutId = useRef(null);

  useEffect(() => {
    const rootEl = document.createElement('div');
    document.body.append(rootEl);

    root.current = rootEl;

    return () => {
      if (root.current) {
        document.body.removeChild(root.current);
      }
    };
  }, []);

  const hide = useCallback(() => {
    setAlert(null);

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  const show = (alertProps: AlertContextProps) => {
    setAlert(alertProps);

    if (alertProps.hideAfterSeconds) {
      timeoutId.current = setTimeout(hide, alertProps.hideAfterSeconds);
    }
  };

  const success = useCallback(
    (alertProps: Omit<AlertContextProps, 'severity'>) => show({ ...alertProps, severity: 'success' }),
    [],
  );

  const warning = useCallback(
    (alertProps: Omit<AlertContextProps, 'severity'>) => show({ ...alertProps, severity: 'warning' }),
    [],
  );

  const info = useCallback(
    (alertProps: Omit<AlertContextProps, 'severity'>) => show({ ...alertProps, severity: 'info' }),
    [],
  );

  const error = useCallback(
    (alertProps: Omit<AlertContextProps, 'severity'>) => show({ ...alertProps, severity: 'error' }),
    [],
  );

  alertContext.current = {
    success,
    info,
    warning,
    error,
    hide,
  };

  const renderAlert = () => {
    if (alert) {
      return (
        <Box sx={{
          position: 'fixed', top: '50px', left: '50%', transform: 'translateX(-50%)', width: '80%', zIndex: 10,
        }}
        >
          <Alert {...alert} onClose={hide} />
        </Box>
      );
    }

    return null;
  };

  return (
    <AlertContext.Provider value={alertContext}>
      {children}
      {root.current && createPortal(renderAlert(), root.current)}
    </AlertContext.Provider>
  );
};
