import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotateSpin = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const StyledOverlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10,
  background: 'rgba(0, 0, 0, 0.25)',
  width: '100%',
  height: '100%',
});

const StyledCentered = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

const SpinnedSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  animation: `${spin} 1.5s infinite`,
  width: 40,
  height: 40,
  color: theme.palette.darkBlue.variation10,
}));

const SpinnedBottomGear = styled(SettingsIcon)(({ theme }) => ({
  animation: `${rotateSpin} 1.5s infinite`,
  position: 'absolute',
  top: '48%',
  left: '48%',
  width: 40,
  height: 40,
  color: theme.palette.neutral.variation50,
}));

const Spinner = () => (
  <StyledOverlay>
    <StyledCentered>
      <Box sx={{ display: 'inline-block', position: 'relative' }}>
        <SpinnedSettingsIcon />
        <SpinnedBottomGear />
      </Box>
    </StyledCentered>
  </StyledOverlay>
);

export default Spinner;
