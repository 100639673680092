const _fc_props = {};
_fc_props.ante_meridiem = 'Rano';
_fc_props.post_meridiem = 'Po południu';
_fc_props.retry = 'Spróbuj ponownie';
_fc_props.default = 'Domyślne';
_fc_props.origin = 'Pochodzenie';
_fc_props.loading = 'Wczytywanie';
_fc_props.list = 'Lista';
_fc_props.send = 'Wyślij';
_fc_props.error = 'Błąd';
_fc_props.save = 'Zapisz';
_fc_props.delete = 'Usuń';
_fc_props.message = 'Wiadomość';
_fc_props.logout = 'Wyloguj';
_fc_props.add = 'Dodaj';
_fc_props.remove = 'Usuń';
_fc_props.done = 'Gotowe';
_fc_props.other = 'Inne';
_fc_props.interval = 'Odstęp';
_fc_props.time = 'Czas';
_fc_props.speed = 'Prędkość';
_fc_props.longitude = 'Długość geograficzna';
_fc_props.latitude = 'Szerokość geograficzna';
_fc_props.x_minutes = '{0} Min.';
_fc_props.general = 'Ogólne';
_fc_props.messages = 'Wiadomości';
_fc_props.gps_fixes = 'Łatki GPS';
_fc_props.device_id = 'ID urządzenia';
_fc_props.vehicle = 'Pojazd';
_fc_props.employee = 'Pracownik';
_fc_props.version = 'Wersja';
_fc_props.statuses = 'Statusy';
_fc_props.edit = 'Edytuj';
_fc_props.cancel = 'Anuluj';
_fc_props.yes = 'Tak';
_fc_props.none = 'Żadne';
_fc_props.never = 'Nigdy';
_fc_props.name = 'Nazwa';
_fc_props.true = 'Tak';
_fc_props.status = 'Status';
_fc_props.distance = 'Odległość';
_fc_props.ok = 'Ok';
_fc_props.login = 'Logowanie';
_fc_props.next = 'Następne';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Wstecz';
_fc_props.select = 'Wybierz';
_fc_props.dispatch = 'Centrum spedycji';
_fc_props.messaging = 'Komunikacja';
_fc_props.plants = 'Fabryki';
_fc_props.last_plants = 'Ostatnie fabryki';
_fc_props.vehicle_types = 'Rodzaje pojazdów';
_fc_props.employee_types = 'Rodzaje pracowników';
_fc_props.employee_groups = 'Grupy pracowników';
_fc_props.equipment_groups = 'Grupy sprzętu';
_fc_props.job = 'Zlecenie';
_fc_props.order = 'Zamówienie';
_fc_props.ticket = 'Ticket #';
_fc_props.small = 'Małe';
_fc_props.medium = 'Średnie';
_fc_props.large = 'Duże';
_fc_props.reports = 'Raport';
_fc_props.payroll = 'Lista płac';
_fc_props.support = 'Pomoc';
_fc_props.username = 'Nazwa użytkownika';
_fc_props.all = 'Wszystkie';
_fc_props.question = 'Pytanie';
_fc_props.select_employee = 'Wybierz pracownika';
_fc_props.phone = 'Telefon';
_fc_props.january = 'Styczeń';
_fc_props.february = 'Luty';
_fc_props.march = 'Marzec';
_fc_props.april = 'Kwiecień';
_fc_props.may = 'Maj';
_fc_props.june = 'Czerwiec';
_fc_props.july = 'Lipiec';
_fc_props.august = 'Sierpień';
_fc_props.september = 'Wrzesień';
_fc_props.october = 'Październik';
_fc_props.november = 'Listopad';
_fc_props.december = 'Grudzień';
_fc_props.are_you_sure_you_want_to_delete = 'Czy na pewno chcesz usunąć';
_fc_props.unknown = 'Nieznany';
_fc_props.select_all = 'Wybierz wszystko';
_fc_props.equipment = 'Sprzęt';
_fc_props.region = 'Rejon';
_fc_props.no_data = 'Brak danych';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Adres';
_fc_props.retrieving_address = 'Pobieranie adresu...';
_fc_props.address_not_found = 'Nie odnaleziono adresu';
_fc_props.active_time = 'Czas aktywności';
_fc_props.destination_time = 'Czas dotarcia';
_fc_props.complete_time = 'Czas całkowity';
_fc_props.status_report = 'Raport o statusach';
_fc_props.export_report = 'Eksportuj raport';
_fc_props.download_report = 'Pobierz raport';
_fc_props.view_report = 'Przeglądaj raport';
_fc_props.duration = 'Czas trwania';
_fc_props.min = 'Min.';
_fc_props.start_date_time = 'Data/godzina rozpoczęcia';
_fc_props.select_start_date = 'Wybierz datę rozpoczęcia';
_fc_props.end_date_time = 'Data/godzina zakończenia';
_fc_props.select_end_date = 'Wybierz datę zakończenia';
_fc_props.trip_time = 'Czas podróży';
_fc_props.travel_time = 'Czas podróży';
_fc_props.description = 'Opis';
_fc_props.map_controls = 'Przyciski mapy';
_fc_props.road = 'Droga';
_fc_props.aerial = 'Satelita';
_fc_props.hybrid = 'Hybryda';
_fc_props.trip = 'Podróż';
_fc_props.map = 'Mapa';
_fc_props.plant = 'Fabryka';
_fc_props.select_date = 'Wybierz datę';
_fc_props.save_settings = 'Zapisz ustawienia';
_fc_props.false = 'Błąd';
_fc_props.delete_confirmation = 'Usuń potwierdzenie';
_fc_props.last_name = 'Nazwisko';
_fc_props.none_selected = 'Nic nie wybrano';
_fc_props.start_date = 'Data rozpoczęcia';
_fc_props.group_by = 'Grupowanie według';
_fc_props.equipment_employee = 'Sprzętu / Pracownika';
_fc_props.inactive = 'Nieaktywny';
_fc_props.active = 'Aktywny';
_fc_props.note_checking_may_affect_performance = 'Notatki: Sprawdzanie może wpłynąć na wydajność';
_fc_props.show_distancefuel_used = 'Pokaż odległość/zużyte paliwo';
_fc_props.mins = 'Min.';
_fc_props.threshold_time = 'Czas progu';
_fc_props.include_voided_tickets = 'Wlicz unieważnione listy przewozowe';
_fc_props.note_all_times_listed_are_in_minutes = 'Notatki: Wszystkie czasy podane są w minutach';
_fc_props.please_select_a_job_order_or_equipment = 'Wybierz zadanie, zamówienie, zakład, sprzęt lub pracownika';
_fc_props.device = 'Urządzenie';
_fc_props.select_equipment = 'Wybierz sprzęt';
_fc_props.all_equipment_types = 'Wszystkie rodzaje sprzętu';
_fc_props.units = {};
_fc_props.units.liters = 'Litry';
_fc_props.type = 'Rodzaj';
_fc_props.no_data_available = 'Brak dostępnych danych';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Odległość i paliwo według rejonu';
_fc_props.menu_settings_title_settings = 'Ustawienia';
_fc_props.menu_settings_title_alerts = 'Powiadomienia';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Sprzęt';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspoty i rejony';
_fc_props.days = '{0} dni';
_fc_props.home = 'Główna';
_fc_props.find = 'Znajdź';
_fc_props.close_this_panel = 'zamknij ten pulpit';
_fc_props.start = 'Start';
_fc_props.end = 'Koniec';
_fc_props.location_details = 'Szczegóły lokalizacji';
_fc_props.first_name = 'Imię';
_fc_props.material_per_hour = 'Materiał na godzinę';
_fc_props.equipment_pound = 'Sprzęt nr';
_fc_props.equipment_type = 'Rodzaj sprzętu';
_fc_props.options = 'Opcje';
_fc_props.zoom_on_cursor_position = 'Przybliż w miejscu gdzie znajduje się kursor';
_fc_props.display_equipment_identifier = 'Wyświetl identyfikator sprzętu';
_fc_props.view_equipment = 'Zobacz sprzęt';
_fc_props.view_hotspots = 'Zobacz geofence';
_fc_props.view_regions = 'Zobacz rejony';
_fc_props.use_standard_filters = 'Używaj filtrów standardowych';
_fc_props.home_plant = 'Fabryka macierzysta';
_fc_props.last_plant = 'Ostatnia fabryka';
_fc_props.equip_types = 'Sprzęt Rodzaje';
_fc_props.equip_groups = 'Sprzęt Grupy';
_fc_props.orders = 'Zamówienia';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Używaj filtra urządzeń';
_fc_props.all_selected = 'Wszystkie wybrane';
_fc_props.of = 'Z';
_fc_props.your_map_session_has_expired = 'Twoja sesja wygasła, odśwież stronę.';
_fc_props.equipment_short = 'Sprzęt';
_fc_props.job_info_for = 'Informacje o zleceniu dla';
_fc_props.order_info_for = 'Informacje o zamówieniu dla';
_fc_props.ticket_info_for_vehicle = 'Informacje o liście przewozowym dla pojazdu';
_fc_props.inbox = 'Skrzynka odbiorcza';
_fc_props.outbox = 'Skrzynka nadawcza';
_fc_props.alerts = 'Powiadomienia';
_fc_props.critical = 'Krytyczne';
_fc_props.important = 'Ważne';
_fc_props.informational = 'Informacyjne';
_fc_props.view_routes = 'Przeglądaj trasy';
_fc_props.hours_size = 'Rozmiar godzin';
_fc_props.used_count = 'Liczba użytych';
_fc_props.last_login = 'Ostatnie logowanie';
_fc_props.last_vehicle = 'Poprzedni pojazd';
_fc_props.regions = 'Rejony';
_fc_props.driver = 'Kierowca';
_fc_props.js_delete = 'Usuń';
_fc_props.move = 'Przenieś';
_fc_props.edit_hotspot = 'Edytuj Geofence';
_fc_props.adjust_shape = 'Popraw kształt';
_fc_props.edit_region = 'Edytuj rejon';
_fc_props.enter_a_start_address = 'Proszę wprowadzić adres początkowy';
_fc_props.select_a_start_hotspot = 'Proszę wybrać początkowy geofence';
_fc_props.failed_to_find_start_hotspot = 'Nie udało się odnaleźć początkowego geofence.';
_fc_props.invalid_latitudelongitude = 'Nieprawidłowa szerokość/długość';
_fc_props.enter_an_end_address = 'Proszę wprowadzić adres końcowy';
_fc_props.select_an_end_hotspot = 'Proszę wybrać końcowy geofence';
_fc_props.failed_to_find_end_hotspot = 'Nie udało się odnaleźć końcowego geofence.';
_fc_props.print = 'Drukuj';
_fc_props.these_directions_are_for_informational_purposes = 'Te wskazówki służą wyłącznie celom informacyjnym. Nie gwarantujemy ich dokładności i kompletności. Prace budowlane, korki, czy inne wydarzenia, mogą powodować różnice między warunkami faktycznymi a niniejszymi wynikami.';
_fc_props.enter_an_address = 'Wprowadź adres';
_fc_props.select_a_hotspot = 'Proszę wybrać geofence';
_fc_props.failed_to_find_hotspot = 'Nie udało się odnaleźć geofence.';
_fc_props.select_a_vehicle = 'Proszę wybrać pojazd';
_fc_props.failed_to_find_vehicle = 'Nie udało się odnaleźć pojazdu.';
_fc_props.failed_to_find_address = 'Nie udało się odnaleźć adresu.';
_fc_props.failed_to_find_vehicles = 'Nie udało się odnaleźć pojazdów w kryteriach wyszukiwania.';
_fc_props.get_address = 'Znajdź adres';
_fc_props.results_for = 'Wyniki dla';
_fc_props.error_retrieving_results = 'Błąd przy pozyskiwaniu wyników';
_fc_props.no_results_found = 'Nie odnaleziono wyników';
_fc_props.address_is_required = 'Adres jest wymagany';
_fc_props.city_is_required = 'Miasto jest wymagane';
_fc_props.set_location = 'Ustal lokalizację';
_fc_props.set_area_of_interest = 'Ustal obszar zainteresowania';
_fc_props.center_map_here = 'Wyśrodkuj mapę do tej pozycji';
_fc_props.zoom_to_street_level = 'Przybliż do poziomu ulicy';
_fc_props.set_start = 'Ustal początek';
_fc_props.set_end = 'Ustal koniec';
_fc_props.delete_node = 'Usuń notatkę';
_fc_props.create_a_hotspot = 'Utwórz geofence';
_fc_props.create_a_region = 'Utwórz rejon';
_fc_props.toggle_shapes = 'Przełączaj kształty';
_fc_props.refresh_map = 'Odśwież mapę';
_fc_props.more_options = 'Więcej opcji';
_fc_props.recenter_map = 'Ponownie wyśrodkuj mapę';
_fc_props.zoom_to_vehicle = 'Przybliż pojazd';
_fc_props.apply_filters = 'Zastosuj filtry';
_fc_props.grid_view = 'Widok tabeli';
_fc_props.list_view = 'Widok listy';
_fc_props.click_to_sort = 'Kliknij by posortować';
_fc_props.message_history = 'Historia wiadomości';
_fc_props.equipment_types = 'Rodzaje sprzętu';
_fc_props.send_message = 'Wyślij wiadomość';
_fc_props.last_load = 'Ostatni załadunek';
_fc_props.last_load_on = 'Ostatni załadunek w';
_fc_props.manual_logout = 'Ręczne wylogowanie';
_fc_props.manual_login = 'Ręczne logowanie';
_fc_props.no_employees_logged_in = 'Brak zalogowanych pracowników';
_fc_props.select_phone = 'Wybierz telefon';
_fc_props.you_must_select_a_phone = 'Musisz wybrać telefon, sprzęt oraz pracownika';
_fc_props.are_you_sure_you_want_to_logout = 'Czy na pewno chcesz się wylogować?';
_fc_props.fix_time = 'Czas lokalizacji';
_fc_props.to_next_fix = 'do kolejnej lokalizacji';
_fc_props.minutes_to_next_fix = 'Minut do kolejnej lokalizacji';
_fc_props.liters = 'Litry';
_fc_props.destination = 'Cel';
_fc_props.return = 'Powrót';
_fc_props.product = 'Produkt';
_fc_props.no_records_found = 'Brak danych.';
_fc_props.vehicle_type = 'Rodzaj pojazdu';
_fc_props.view_in_map = 'Zobacz wszystko na mapie';
_fc_props.permanent = 'Stały';
_fc_props.expire_time = 'Data ważności';
_fc_props.please_select_x_for_tickets_marked_active = 'Proszę wybrać {0} dla listów przewozowych oznaczonych, jako AKTYWNE';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Wiele listów przewozowych oznaczonych, jako AKTYWNE dla tego samego {0}';
_fc_props.failure_connecting_to_servlet = 'Połączenie z serwletem nie powiodło się.';
_fc_props.status_time_summary = 'Podsumowanie czasu dla statusu';
_fc_props.view_in_map_one = 'Zobacz na mapie';
_fc_props.question_report = 'Raport zapytań';
_fc_props.end_date = 'Data końcowa';
_fc_props.trailer_number = 'Numer przyczepy';
_fc_props.region_panel = 'Panel rejonu';
_fc_props.hotspot_panel = 'Panel geofence';
_fc_props.shape = 'Kształt';
_fc_props.circle = 'Okrąg';
_fc_props.polygon = 'Wielokąt';
_fc_props.click_the_map_to_set_the_location = 'Kliknij na mapę by ustalić pożądaną lokalizację.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Kliknij na mapę by ustalić centrum geofence.';
_fc_props.click_the_map_to_set_the_region_center = 'Kliknij na mapę by ustalić centrum rejonu.';
_fc_props.click_and_drag_points = 'Klikaj i przeciągaj punkty, by edytować kształt. Kliknij ZAPISZ, by zachować zmiany.';
_fc_props.size = 'Rozmiar';
_fc_props.are_you_sure_you_want_to_move_this = 'Czy na pewno chcesz przenieść ten element';
_fc_props.are_you_sure_you_want_to_delete_this = 'Czy na pewno chcesz usunąć ten element';
_fc_props.please_enter_a_valid_size = 'Proszę podać poprawny rozmiar';
_fc_props.failed_to_save = 'Nie udało się zapisać.';
_fc_props.failed_to_move = 'Nie udało się przenieść.';
_fc_props.failed_to_delete = 'Usuwanie nie powiodło się.';
_fc_props.x_hours = '{0} godzin';
_fc_props.hide_hotspots = 'Ukryj geofence';
_fc_props.show_regions = 'Pokaż rejony';
_fc_props.show_hotspots = 'Pokaż geofence';
_fc_props.hide_regions = 'Ukryj rejony';
_fc_props.numeric = 'Liczba';
_fc_props.message_type = 'Rodzaj wiadomości';
_fc_props.text = 'Tekst';
_fc_props.yes_no = 'Tak/Nie';
_fc_props.responses = 'Odpowiedzi';
_fc_props.disabled = 'Wyłączone';
_fc_props.group_name = 'Nazwa grupy';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Czy na pewno chcesz usunąć format „{0}”?';
_fc_props.phone_number = 'Numer telefonu';
_fc_props.interface_pound = 'Nr interfejsu';
_fc_props.field = 'Pole';
_fc_props.default_label = 'Etykieta domyślna';
_fc_props.custom_label = 'Etykieta niestandardowa';
_fc_props.you_must_enter_a_label = 'Musisz wprowadzić etykietę.';
_fc_props.enabled = 'Aktywowano';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Integracja listu przewozowego';
_fc_props.settings.geofence_off_open_hotspots = 'Opuść geofence dla otwartego hotspotu';
_fc_props.settings.associate_open_hotspots_wticket = 'Powiąż otwarte geofence z listem przewozowym';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Zezwól na opuszczanie geofence przy otwartych hotspotach lub przy hotspotach powiązanych z listem przewozowym kierowcy';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Czy tworzyć powiązanie między otwartymi geofence i listami przewozowymi gdy pojazd wkracza do geofence';
_fc_props.settings.void_ticket_without_destination = 'Unieważniony bilet przewozowy bez celu';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'jeżeli list przewozowy zostanie otrzymany podczas gdy inny jest już aktywny i jeśli aktywny list nie posiada celu, zostanie on unieważniony.';
_fc_props.settings.autocreate_hotspots = 'Automatyczne tworzenie geofence';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Czy automatycznie tworzyć geofence na podstawie adresu docelowego po otrzymaniu listu przewozowego';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Automatyczna aktywacja listu przewozowego przy otrzymaniu';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Czy list przewozowy jest aktywny po otrzymaniu go przez TrackIt (Domyślnie \'prawda\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Automatyczne zamknięcie aktywnego listu przewozowego po otrzymaniu, w minutach';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Jak długo bieżący, aktywny list przewozowy musi być aktywny przed automatycznym zamknięciem (Domyślne \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Maks. czas aktywacji listu przewozowego w minutach';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Ile czasu (w minutach) musi istnieć list przewozowy przed aktywacją w TrackIt (Domyślnie \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Maks. ilość dni nieaktywności dla geofence';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Ustala maksymalną ilość dni, podczas których geofence może być nieaktywne, ale wciąż przywracalne';
_fc_props.settings.radius_multiplier = 'Mnożnik promienia';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'O ile razy zwiększyć promień, gdy system spedycji przydzieli list przewozowy';
_fc_props.settings.ticket_message_date_format = 'Format daty dla wiadomości listu przewozowego';
_fc_props.am = 'rano';
_fc_props.pm = 'po południu';
_fc_props.settings.ticket_message = 'Wiadomości listu przewozowego';
_fc_props.settings.add_ticket_element = 'Dodaj element listu przewozowego';
_fc_props.driver_performance = 'Wydajność kierowcy';
_fc_props.update_failed_can_not_connect = 'Aktualizacja nie powiodła się: Nie można połączyć się z serwletem.';
_fc_props.unknown_error = 'Nieznany błąd';
_fc_props.selected = 'Wybrano';
_fc_props.web_service = 'Usługa internetowa';
_fc_props.error_processing_request = 'Błąd przy przetwarzaniu żądania.';
_fc_props.email_address = 'Adres e-mail';
_fc_props.id = 'ID';
_fc_props.last_used = 'Ostatnio używane';
_fc_props.error_invalid_vehiclenum = 'Błąd Nieprawidłowy numer pojazdu';
_fc_props.error_vehicle_is_not_logged_in = 'Błąd: Pojazd nie jest zalogowany.';
_fc_props.users = 'Użytkownicy';
_fc_props.you_dont_have_access_to_this_page = 'Nie masz dostępu do tej strony. Aby uzyskać dostęp, skontaktuj się z administratorem systemu.';
_fc_props.previous_week = 'Poprzedni tydzień';
_fc_props.status_breakdown = 'Zestawienie statusów';
_fc_props.select_plant_or_employee_group = 'Musisz wybrać fabrykę lub grupę pracowników.';
_fc_props.cannot_select_plant_and_employee_group = 'Nie można wybrać jednocześnie fabryki oraz grupy pracowników.';
_fc_props.odometer_must_be_a_number = 'Początkowy stan drogomierza musi być liczbą';
_fc_props.ending_odometer_must_be_a_number = 'Końcowy stan drogomierza musi być liczbą';
_fc_props.beginning_hour_meter_must_be_a_number = 'Początkowy stan licznika godzinowego musi być liczbą';
_fc_props.ending_hour_meter_must_be_a_number = 'Końcowy stan licznika godzinowego musi być liczbą';
_fc_props.error_loading_status_change_page = 'Błąd przy wczytywaniu strony zmiany statusu.';
_fc_props.x_is_an_invalid_time_format = '{0} jest nieprawidłowym formatem czasu, musi być RRRR-MM-DD GG:MM:SS';
_fc_props.x_for_schedule_login = '{0} dla planowanego logowania';
_fc_props.x_for_schedule_logout = '{0} dla planowanego wylogowania';
_fc_props.x_for_actual_login = '{0} dla faktycznego logowania';
_fc_props.x_for_actual_logout = '{0} dla faktycznego wylogowania';
_fc_props.by_refreshing_the_time_card_detail = 'Odświeżając szczegóły grafiku utracisz wszelkie zmiany, kontynuować?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Czy na pewno chcesz usunąć {0}?';
_fc_props.settings.command_mobile_ticket_url = 'URL mobilnego listu przewozowego Command';
_fc_props.year = 'Rok';
_fc_props.make = 'Marka';
_fc_props.model = 'Model';
_fc_props.settings.command_mobile_ticket_password = 'Hasło polecenie Komórka biletów';
_fc_props.settings.command_mobile_ticket_auth_token = 'Komenda Komórka biletów Auth Reklamowe';
_fc_props.target = 'Cel';
_fc_props.number_of_digits = 'Liczba cyfr';
_fc_props.number_of_integer_digits = 'Cyfry liczby Integer';
_fc_props.number_of_fractional_digits = 'Liczba cyfr ułamkowych';
_fc_props.search = 'Poszukiwanie';
_fc_props.settings.command_mobile_ticket_date_order = 'Komenda Komórka biletów Data Zamówienie';
_fc_props.settings.command_mobile_ticket_date_length = 'Komenda Komórka biletów Data Długość';
_fc_props.settings.command_mobile_ticket_date_separator = 'Bilet Data polecenie separator komórkowy';
_fc_props.settings.command_mobile_ticket_slash = '/ (Ukośnik)';
_fc_props.settings.command_mobile_ticket_dash = '- (Kreska)';
_fc_props.settings.command_mobile_ticket_period = ', (Kropka)';
_fc_props.settings.command_mobile_ticket_space = '(Spacja)';
_fc_props.settings.command_mobile_ticket_time_length = 'Komenda Komórka Czas biletów Długość';
_fc_props.settings.command_mobile_ticket_time_separator = 'Czas polecenie separator komórkowy biletów';
_fc_props.settings.command_mobile_ticket_colon = ': (Dwukropek)';
_fc_props.settings.command_mobile_ticket_time_format = 'Format polecenie Komórka Czas biletów';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Korzystanie Ilość Interfejs';
_fc_props.mechanic = 'Mechanik';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Poll Interval (sekund)';
_fc_props.driving_time = 'Czas jazdy';
_fc_props.find_near_plants = 'Znajdź w pobliżu Rośliny';
_fc_props.please_create_hot_spot_first = 'Proszę stworzyć hotspot pierwszy';
_fc_props.please_enter_hot_spot_description = 'Podaj gorące opis spot';
_fc_props.please_select_a_hot_spot_type = 'Proszę wybrać rodzaj hot spot';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Wyślij sterownika E-mail';
_fc_props.directions_not_found = 'Nieprawidłowy adres {0}. Wskazówki Not Found.';
_fc_props.ws_user = 'Użytkownik';
_fc_props.ws_service = 'Usługa';
_fc_props.ws_method = 'Metoda';
_fc_props.ws_params = 'params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Pochodzenie i przeznaczenie';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Pomoc';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Użyj Alt numer pracownika';
_fc_props.settings.command_mobile_ticket_android_url = 'URL biletów komórkowych Android';
_fc_props.settings.command_mobile_ticket_android_secret = 'Tajny biletów komórkowych Android';
_fc_props.employee_x_has_no_logout_on_y = 'Pracownik {0} ma wylogowania dla przesunięcia od dnia {1}.';
_fc_props.phone_number_has_duplicates = 'Numery telefonów mają zduplikowane wpisy';
_fc_props.ounce = 'Uncja';
_fc_props.water_extra = 'Woda Extra';
_fc_props.always = 'Zawsze';
_fc_props.work_types_must_be_unique = 'Rodzaje pracy musi być unikalna';
_fc_props.manufacturer = 'Producent';
_fc_props.need_to_enter_seconds = 'Trzeba wprowadzić sekundy.';
_fc_props.copy = 'Kopiuj';
_fc_props.create_in_existing_required = 'Utwórz w istniejącym należy wybrać, aby utworzyć punkt dostępu do roamingu.';
_fc_props.break = 'Przerwa';
_fc_props.add_export_data_type = 'Dodaj typ danych eksportowych';
_fc_props.edit_export_data_type = 'Edytuj typ danych eksportowych';
_fc_props.delete_export_data_type = 'Usuń typ danych eksportowych';
_fc_props.export_data_type = 'Eksportuj typ danych';
_fc_props.error_updating_export_data_type = 'Wystąpił błąd podczas aktualizacji typu danych eksportowych';
_fc_props.settings.address_key = 'Klucz adresowy';
_fc_props.settings.address_key_hover = 'Kluczowe opcje adresu';
_fc_props.settings.address_key_options = 'Kluczowe opcje adresu';
_fc_props.more_filter_options = 'Więcej opcji filtrów';
_fc_props.unauthorized_mapit_app = 'Ta wersja MapIt nie jest autoryzowana, należy jak najszybciej zaktualizować MapIt.';
_fc_props.zello_timeout = 'Przekroczono limit czasu kontaktu z Zello.';
_fc_props.user_agreement_text = 'Logując się, zgadzasz się na';
_fc_props.user_agreement = 'Zgoda użytkownika';
_fc_props.x_mins_left = 'Zostało {0} min';
_fc_props.continuous_location_tracking_colon = 'Ciągłe śledzenie lokalizacji:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Huler nie może zarejestrować nowego urządzenia.';
_fc_props.timecard_does_not_contain_shift = 'Karta czasu pracy nie zawiera podanej zmiany (informacje o karcie czasu)';
_fc_props.timecard_not_found = 'Nie znaleziono karty czasu pracy';
_fc_props.vehicle_must_be_assigned_for_clt = 'Pojazd musi być przypisany do śledzenia lokalizacji.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Pojazd został cofnięty, śledzenie ciągłe zostanie wyłączone.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Utracono połączenie z EDC. Sprawdź połączenie Wi-Fi i / lub Bluetooth.';
_fc_props.acknowledge_all = 'Potwierdź wszystko';
_fc_props.expand = 'Rozszerzać';
_fc_props.collapse = 'Zawalić się';
_fc_props.expand_all = 'Rozwiń wszystkie';
_fc_props.collapse_all = 'Zwinąć wszystkie';
_fc_props.last_update = 'Ostatnia aktualizacja';
_fc_props.acknowledge_alert = 'Potwierdź alert';
_fc_props.acknowledge_message = 'Potwierdź wiadomość';
_fc_props.acknowledge_all_alerts = 'Potwierdź wszystkie alerty';
_fc_props.acknowledge_all_messages = 'Potwierdź wszystkie wiadomości';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Tolerancja musi być poprawną liczbą całkowitą z zakresu od -99 do 99 (w obu przypadkach)';
_fc_props.gps_permission_required = 'Wymagana zgoda na GPS';
_fc_props.permissions_required = 'Wymagane uprawnienia';
_fc_props.change_user = 'Zmień użytkownika';
_fc_props.account_not_registered_with_carrier = 'To konto nie jest zarejestrowane u operatora';
_fc_props.slump_loss = 'Strata zrzutu';
_fc_props.slump_loss_help = 'Pomoc w utracie ciepła';
_fc_props.did_not_receive_any_edc_devices = 'Nie otrzymałem żadnych urządzeń EDC.';
_fc_props.edc_record_not_found = 'Nie znaleziono rekordu EDC';
_fc_props.disable_hotspot_prompt = 'Po wyświetleniu monitu naciśnij przycisk „OK”, aby wyłączyć hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} jest w użyciu.';
_fc_props.an_unknown_exception_has_occurred = 'Wystąpił nieznany wyjątek';
_fc_props.login_has_timed_out = 'Upłynął limit czasu logowania';
_fc_props.use_paper_logs = 'Użyj dzienników papieru';
_fc_props.eld_login_failed = 'Logowanie godziny usługi nie powiodło się';
_fc_props.setup_time_off_type = 'Ustaw typ wyłączenia czasu.';
_fc_props.view_load_properties = 'Wyświetl właściwości obciążenia';
_fc_props.couldnt_download_edc_firmware = 'Nie można pobrać oprogramowania EDC.';
_fc_props.password_incorrect = 'Hasło niepoprawne';
_fc_props.edc_configuration_password_prompt = 'Ze względu na stan tego urządzenia musisz podać hasło instalatora do skonfigurowania.';
_fc_props.edc_configuration_check_failed = 'Sprawdzanie poprawności rekordów serwera EDC nie powiodło się. Szczegóły sprawdź komunikaty o błędach.';
_fc_props.configuration_issues_prompt = 'Wykryto następujące problemy podczas konfigurowania wybranego EDC dla określonej ciężarówki';
_fc_props.checking_password = 'Sprawdzanie hasła';
_fc_props.reset_password_instructions_sent = 'Instrukcje resetowania hasła wysłane na adres e-mail {0}.';
_fc_props.reset_password_failed = 'Resetowanie hasła nie powiodło się, spróbuj ponownie.';
_fc_props.forgot_password = 'Zapomniałeś hasła?';
_fc_props.enter_valid_email = 'Nieprawidłowy adres e-mail, wprowadź prawidłowy adres e-mail i spróbuj ponownie.';
_fc_props.issues_while_updating_edc_record = 'EDC zostało pomyślnie skonfigurowane. Występują jednak problemy z rekordami EDC na serwerze TrackIt, które mogą powodować, że nie działa. Zadzwoń do pomocy technicznej, aby poprawić informacje.';
_fc_props.sending = 'Wysyłanie...';
_fc_props.minimum_ratio = 'Minimalny stosunek';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Prędkość obrotowa bębna poza zakresem';
_fc_props.probe_failure = 'Awaria sondy';
_fc_props.unknown_probe_data_failure = 'Nieznana awaria danych sondy';
_fc_props.no_loaded_status_meaning = 'Status braku załadowania';
_fc_props.no_depart_status_meaning = 'Brak statusu odlotu';
_fc_props.no_arrive_status_meaning = 'Brak statusu przyjazdu';
_fc_props.no_begin_work_status_meaning = 'Brak statusu Rozpocznij pracę';
_fc_props.no_fully_mixed_status_meaning = 'Brak statusu w pełni mieszanego';
_fc_props.no_begin_work_or_at_job_status = 'Rozpocznij pracę lub statusy pracy nieobecne';
_fc_props.driver_didnt_follow_procedure = 'Kierowca nie zastosował się do procedury (Nie zwalnia bębna)';
_fc_props.probe_malfunction = 'Awaria sondy (nagromadzenie, awaria wewnętrzna itp.)';
_fc_props.no_data_from_probe = 'Brak danych z sondy (utrata danych)';
_fc_props.missing_probe_calibration = 'Brak danych kalibracyjnych sondy';
_fc_props.probe_pressure_not_in_range = 'Ciśnienie sondy poza zakresem';
_fc_props.arrival = 'Przyjazd';
_fc_props.small_load = 'MAŁY ŁADUNEK';
_fc_props.dry_slump = 'OSUSZANIE SUCHE';
_fc_props.probe_remixing_turns = 'Remiksowanie';
_fc_props.turns_needed_to_remix = 'Dodana woda - wymagane dodatkowe mieszanie';
_fc_props.probe_remixing_finished = 'Remiksowanie zakończone';
_fc_props.probe_battery_low_alert = 'Alarm niskiego poziomu baterii sondy';
_fc_props.probe_battery_low_message = 'Niski poziom naładowania baterii sondy';
_fc_props.probe_battery_low = 'Niski poziom naładowania baterii sondy';
_fc_props.alert_when_probe_battery_low = 'Ostrzegaj, gdy poziom naładowania baterii sondy jest niski';
_fc_props.probe_sensor_fatal_failure = 'Błąd krytyczny czujnika';
_fc_props.probe_thermistor_failure = 'Awaria termistora';
_fc_props.probe_sensor_stuck_in_concrete = 'Czujnik utknął w betonie';
_fc_props.probe_sensor_over_weight = 'Czujnik ponad masą';
_fc_props.probe_batteries_low = 'Niski poziom baterii';
_fc_props.no_probe_data = 'Brak danych sondy:';
_fc_props.check_probe = 'Sprawdź sondę';
_fc_props.service_probe = 'Sonda serwisowa';
_fc_props.below_x = 'Poniżej {0}';
_fc_props.above_x = 'Powyżej {0}';
_fc_props.probe_serial_number_invalid_format = 'Numer seryjny sondy nie ma prawidłowego formatu, jaki jest wyświetlany w symbolu zastępczym pola';
_fc_props.talk_dnd_permission_prompt = 'Rozmowa wymaga uprawnień NIE ZRÓŻNICOWAĆ.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Stan zmienił się zbyt szybko';
_fc_props.is_empty = 'Jest pusty';
_fc_props.slump_increased_plasticizer_question = 'Czy dodano plastyfikator?';
_fc_props.slump_increased_water_added_question = 'Ile wody bez licznika dodano?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Monitorowanie sondy i cyklu dostarczania nie jest obsługiwane razem na tym samym urządzeniu.';
_fc_props.no_data_from_sensor = 'Brak danych z czujnika (utrata danych)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Rodzaj systemu';
_fc_props.magnet_count = 'Liczba magnesów';
_fc_props.sensors_setup = 'Konfiguracja czujników';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM wymaga ustawienia adresu MAC skrzynki we / wy, typu bębna, typu DCM i liczby magnesów.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Wolumen biletowany';
_fc_props.drs_current_volume = 'Bieżąca głośność';
_fc_props.drs_current_water_cement_ratio = 'Aktualny stosunek woda / cement';
_fc_props.drs_target_water_cement_ratio = 'Docelowy stosunek woda / cement';
_fc_props.drs_total_revolutions = 'Całkowity';
_fc_props.drs_current_volume_lp_small = 'obecny';
_fc_props.drum_revolutions = 'Rewolucje bębna';
_fc_props.drs_total_revolutions_lp_small = 'Całkowity';
_fc_props.drs_charging_revolutions_lp_small = 'Ładowanie';
_fc_props.drs_discharging_revolutions_lp_small = 'Rozładowywanie';
_fc_props.drs_target_wc_lp_small = 'Docelowy W / C';
_fc_props.drs_current_wc_lp_small = 'Aktualny W / C';
_fc_props.drs_ticketed_volume_lp_small = 'Biletowane';
_fc_props.android_location_disclosure = 'TrackIt używa dokładnej i przybliżonej lokalizacji do zbierania danych o lokalizacji w tle, nawet gdy nie jest używany, aby umożliwić automatyczne ustalanie i widoczność mapy w czasie rzeczywistym do wysyłki.';
_fc_props.android_location_usage = 'Ujawnienie lokalizacji';
_fc_props.end_pour_trigger_threshold = 'Próg wyzwalania zakończenia nalewania';
_fc_props.drs_wiring_error = 'Błąd okablowania';
_fc_props.drs_wiring_pulse_error = 'Błąd impulsu okablowania';
_fc_props.drs_wiring_magnet_error = 'Błąd magnesu w okablowaniu';
_fc_props.drs_magnet_missing_error = 'Błąd braku magnesu';
_fc_props.android_location_disclosure_3p = 'TrackIt zbiera dane o lokalizacji w tle, aby umożliwić automatyczne określanie statusu i widoczność mapy w czasie rzeczywistym do wysyłki. Po wyrejestrowaniu z nadawcy lokalizacja nie jest udostępniana.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Wybierz opcję Full MDM lub Trackit Remote Support';
_fc_props.move_up = 'Podnieść';
_fc_props.move_down = 'Padnij';
_fc_props.driver_not_found = 'Nie znaleziono sterownika.';
_fc_props.multiple = 'Wielokrotność';
_fc_props.water_meter_installed = 'Zainstalowany wodomierz';
_fc_props.delete_waypoint = 'Usuń punkt pośredni';
_fc_props.wash_sensor_installed = 'Zainstalowany czujnik mycia';
_fc_props.washing_start_time = 'Czas rozpoczęcia prania';
_fc_props.end_washing = 'Koniec mycia';
_fc_props.variable_rpms = 'Różne RPM';
_fc_props.no_slump = 'Bez załamania';
_fc_props.end_washing_duration = 'Koniec zmywania';
_fc_props.privacy_policy = 'Polityka prywatności';
_fc_props.privacy_policy_text = 'przeczytałem i akceptuję';
_fc_props.login_fail_accept_privacy_policy = 'Aby kontynuować, należy zaakceptować Politykę prywatności.';
_fc_props.rotation_sensor = 'Czujnik obrotu';
_fc_props.pto_sensor = 'Czujnik WOM';
_fc_props.pto_sensor_installed = 'Zainstalowany czujnik WOM';
_fc_props.polarity_reversed = 'Odwrotna polaryzacja';
_fc_props.pto_sensor_type = 'Typ czujnika WOM';
_fc_props.sensor_type = 'Typ czujnika';
_fc_props.prompt_select_pto_sensor_type = 'Musisz wybrać „Typ czujnika PTO”, jeśli wybrałeś „Czujnik PTO” jako główny typ czujnika.';
_fc_props.priming_turns_at_full_load = 'Zalewanie zakrętów przy pełnym obciążeniu';
_fc_props.priming_turns_at_ten_percent_load = 'Zalewanie obraca się przy 10% obciążeniu';
_fc_props.weight_units = 'Jednostki wagi';
_fc_props.settings.send_status = 'Wyślij status/lokalizację do mostka zdarzeń';
_fc_props.volume_difference = 'Różnica głośności';
_fc_props.mobile_ticket_iframe_url = 'Adres URL ramki IFramki biletu mobilnego';
_fc_props.mobile_ticket_user = 'Użytkownik biletu mobilnego';
_fc_props.mobile_ticket_password = 'Hasło biletu mobilnego';
_fc_props.current_truck_status = 'Aktualny stan ciężarówki';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Wyzwalane przez maksymalne obroty';
_fc_props.alerts_by_type = 'Alerty według typu';
_fc_props.water_flow_meter = 'Miernik przepływu wody';
_fc_props.flow_meter_no_pulses_warning = 'Brak impulsów — ostrzeżenie';
_fc_props.flow_meter_no_pulses_critical = 'Brak impulsów — krytyczne';
_fc_props.flow_meter_excessive_pulses = 'Nadmierne impulsy';
_fc_props.flow_meter_constantly_flowing = 'Ciągle płynie';
_fc_props.flow_meter_good = 'Dobry';
_fc_props.whats_new = 'Co nowego';
_fc_props.whats_new_widget_text = 'Czy wiesz, że aktualizujemy TrackIt co najmniej raz w miesiącu? Otrzymuj najnowsze aktualizacje i wskazówki.';
_fc_props.get_the_scoop = 'Zdobądź czerpak';
_fc_props.trackit_insights = 'Informacje o Trackit';
_fc_props.business_analytics_blurb = 'Analizy biznesowe, które poprawiają wyniki finansowe, teraz zawarte w TrackIt';
_fc_props.find_out_more = 'Dowiedz się więcej';
_fc_props.daily_breakdown = 'Dzienny podział';
_fc_props.question_summary = 'Podsumowanie pytania';
_fc_props.trip_summary = 'Podsumowanie podróży';
_fc_props.manage_employees = 'Zarządzaj pracownikami';
_fc_props.manage_hotspots = 'Zarządzaj hotspotami';
_fc_props.manage_devices = 'Zarządzaj urządzeniami';
_fc_props.manage_equipment = 'Zarządzaj sprzętem';
_fc_props.manage_users = 'Zarządzaj użytkownikami';
_fc_props.manage_statuses = 'Zarządzaj statusami';
_fc_props.manage_alerts = 'Zarządzaj alertami';
_fc_props.popular_report_links = 'Popularne linki do raportów';
_fc_props.popular_settings_links = 'Linki do popularnych ustawień';
_fc_props.sidemenu_settings = 'Ustawienia';
_fc_props.loads_per_driver = 'Ładunki na kierowcę';
_fc_props.please_select_application = 'Wybierz co najmniej jedną aplikację';
_fc_props.external_app_processing = 'Przetwarzanie w aplikacji zewnętrznej';
_fc_props.external_app_sent = 'Wysłano do aplikacji zewnętrznej';
_fc_props.external_app_received = 'Otrzymane przez aplikację zewnętrzną';
_fc_props.wait_time_at_job = 'Czas oczekiwania w pracy';
_fc_props.wait_time_at_plant = 'Czas oczekiwania w zakładzie';
_fc_props.loading_time = 'Czas ładowania';
_fc_props.unloading_time = 'Czas rozładunku';
_fc_props.pre_post_trip = 'Przegląd przed/po podróży';
_fc_props.update_thresholds = 'Zaktualizuj progi';
_fc_props.green = 'Zielony';
_fc_props.yellow = 'Żółty';
_fc_props.red = 'czerwony';
_fc_props.between = 'Między';
_fc_props.num_of_loads = 'Liczba ładunków';
_fc_props.num_of_drivers = 'Liczba kierowców';
_fc_props.status_meaning_not_setup = 'Status Znaczenie nie skonfigurowany';
_fc_props.or_less = 'Lub mniej';
_fc_props.or_greater = 'Albo lepszy';
_fc_props.probe_self_diagnostic_state = 'Stan samodiagnozy sondy';
_fc_props.update_current_truck_status_settings = 'Zaktualizuj bieżące ustawienia stanu ciężarówki';
_fc_props.default_behavior = 'Domyślne (10 najlepszych stanów)';
_fc_props.combine_similar_statuses = 'Połącz te same statusy ze wszystkich typów (pętle)';
_fc_props.use_other_category = 'Użyj kategorii „Inne”';
_fc_props.selected_statuses = 'Wybrane statusy';
_fc_props.ideas = 'Pomysły';
_fc_props.air_content = 'Zawartość powietrza';
_fc_props.air_measured_time = 'Czas pomiaru powietrza';
_fc_props.ten_max = '10 maks';
_fc_props.loads_per_plant = 'Ładunki na roślinę';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Nieprawidłowy Impuls przepływomierza na objętość.';
_fc_props.coming_soon_title = 'Więcej widżetów już wkrótce';
_fc_props.submit_your_ideas = 'Prześlij swoje pomysły do naszego Portalu pomysłów za pomocą żarówki w lewym menu nawigacyjnym.';
_fc_props.selected_plants = 'Wybrane rośliny';
_fc_props.update_loads_per_plant_settings = 'Zaktualizuj obciążenia na ustawienia zakładu';
_fc_props.default_top_10_plants = 'Domyślne (10 najlepszych roślin)';
_fc_props.phone_number_will_be_uploaded = 'Identyfikator urządzenia zostanie przesłany do celów rejestracji.';
_fc_props.include_deleted = 'Uwzględnij usunięte';
_fc_props.primary_status_type = 'Podstawowy typ statusu';
_fc_props.additional_status_trigger = 'Dodatkowe wyzwalacze stanu';
_fc_props.update_tickets_destination = 'Zaktualizuj miejsce docelowe na wszystkich powiązanych biletach';
_fc_props.settings.send_destination_changes_to_connex = 'Wyślij zmiany miejsca docelowego do Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Czy bilet jest aktywny, gdy zostanie odebrany przez TrackIt (domyślnie „zawsze”)';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Automatycznie aktywuj następny bilet po wypełnieniu poprzedniego biletu';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Powinien automatycznie aktywować następny bilet po zakończeniu biletu (domyślnie „prawda”)';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Stan do stanu bezczynności, gdy brak aktywnego biletu';
_fc_props.break_down = 'Załamanie';
_fc_props.idle = 'Bezczynny';
_fc_props.pto_work_maximum_speed_limit = 'Maksymalna prędkość pracy';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Zmiana statusu skojarzenia, która uzupełnia zgłoszenie z tym zgłoszeniem';
_fc_props.approaching = 'Zbliżający się';
_fc_props.performing_task = 'Wykonywanie zadania';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Czy na pewno chcesz usunąć Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Zaktualizuj bilet {0}. Zmieniono miejsce docelowe.';
_fc_props.trackit_return_destination_changed_to_0 = 'Zaktualizuj bilet {0}. Zmieniono zwrot.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Dodaj Geogate';
_fc_props.driver_enabled = 'Sterownik włączony';
_fc_props.dispatcher_enabled = 'Dyspozytor włączony';
_fc_props.runsheet_number = 'Numer arkusza roboczego';
_fc_props.manual_load = 'Obciążenie ręczne';
_fc_props.edit_geogate = 'Edytuj geobramę';
_fc_props.handling = 'Obsługiwanie';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Panel Geogate';
_fc_props.vehicle_is_not_logged_in = 'Wiadomość nie została wysłana (pojazd nie jest zalogowany)';
_fc_props.air = 'Powietrze';
_fc_props.create_a_geogate = 'Utwórz geobramę';
_fc_props.click_the_map_to_set_the_geogate_points = 'Kliknij mapę, aby ustawić punkty geobramki.';
_fc_props.clock_out = 'Wyjście zegara';
_fc_props.handling_operation = 'Obsługa operacji';
_fc_props.view_geogates = 'Zobacz Geogate';
_fc_props.select_a_geogate = 'Wybierz Geogate';
_fc_props.failed_to_find_geogate = 'Nie udało się znaleźć Geogate';
_fc_props.adjust_geogate = 'Dostosuj geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Data wygaśnięcia Godzina Domyślne dodatkowe dni';
_fc_props.menu_settings_title_retention = 'Przechowywanie danych';
_fc_props.category = 'Kategoria';
_fc_props.telemetry = 'Telemetria';
_fc_props.ticket_data = 'Dane biletu';
_fc_props._30_days = '30 dni';
_fc_props._6_months = '6 miesięcy';
_fc_props._1_year = '1 rok';
_fc_props._3_years = '3 lata';
_fc_props.new_group = 'Nowa grupa';
_fc_props.selected_vehicles = 'Wybrane pojazdy';
_fc_props.group_name_is_required_field = 'Nazwa grupy jest wymagana pole';
_fc_props.at_least_vehicle_should_be_selected = 'Należy wybrać co najmniej 1 pojazd';
_fc_props.groups = 'Grupy';
_fc_props.trucks = 'Samochody ciężarowe';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate została usunięta. Odśwież stronę.';
_fc_props.air_timestamp = 'Sygnatura czasowa powietrza';
_fc_props.fail = 'PONIEŚĆ PORAŻKĘ';
_fc_props.stuck = 'ZABLOKOWANY';
_fc_props.dirty = 'BRUDNY';
_fc_props.seasonal = 'Sezonowy';
_fc_props.category_details = 'Szczegóły kategorii';
_fc_props.alert_events = 'Zdarzenia alarmowe';
_fc_props.driver_performance_infractions = 'Naruszenia wydajności kierowcy';
_fc_props.telemetry_details = 'Dane lokalizacji, hotspoty, czas w hotspotach, czas w regionach, dane DCM, dane sondy, dane silnika';
_fc_props.ticket_data_details = 'Dane biletu';
_fc_props.general_details = 'Zmiany statusu, karty czasu pracy, harmonogramy';
_fc_props.wash = 'Myć się';
_fc_props.filter_options = 'Opcje filtra';
_fc_props.home_plants = 'Rośliny domowe';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO wymaga ustawienia typu czujnika PTO.';
_fc_props.invalid_io_box_mac_address = 'Nieprawidłowy adres Mac urządzenia we/wy';
_fc_props.access_denied = 'Brak dostępu';
_fc_props.wash_events = 'Wydarzenia prania';
_fc_props.min_speed = 'Minimalna prędkość';
_fc_props.temperature_change = 'Zmiana temperatury';
_fc_props.degrees_cap = 'Stopni';
_fc_props.washout_tracking = 'Śledzenie wypłukiwania';
_fc_props.approaching_wash = 'Zbliża się Wash';
_fc_props.arrival_wash = 'Przyjazd Pranie';
_fc_props.performing_wash = 'Wykonywanie prania';
_fc_props.wash_complete = 'Pranie zakończone';
_fc_props.must_be_positive_or_zero = 'Musi być dodatni lub zerowy!';
_fc_props.min_speed_greater_than_max = 'Minimalna prędkość musi być mniejsza niż maksymalna prędkość!';
_fc_props.clock_in = 'Zegar w';
_fc_props.mix_water_at_x_to_y_for_t = 'Mieszaj wodę przy obrotach od {0} do {1} przez {2} obrotów.';
_fc_props.discharge_water_for_x_revolutions = 'Spuszczaj wodę przez {0} obrotów.';
_fc_props.eod_drum_wash_complete = 'Mycie bębna zakończone! <br> Zatrzymaj bęben z sondą u góry.';
_fc_props.washing_complete = 'Wymywanie z przewodnikiem zakończone';
_fc_props.eod_washout_no_data_available = 'Dane czujnika są niedostępne, proszę dokończyć proces prania.';
_fc_props.eod_washout_no_data_available_title = 'Wymywanie z przewodnikiem niedostępne';
_fc_props.menu_reports_title_ticket_time_summary = 'Podsumowanie czasu biletu';
_fc_props.menu_reports_description_ticket_time_summary = 'Zobacz informacje o czasie biletu dla zamówienia lub pojazdu';
_fc_props.menu_reports_title_summary = 'Streszczenie';
_fc_props.menu_reports_title_ticket_summary = 'Podsumowanie biletów';
_fc_props.menu_reports_description_summary1 = 'Zobacz podsumowanie czasu w hotspotach';
_fc_props.menu_dispatch_title_main = 'Główny';
_fc_props.menu_dispatch_title_login_registry = 'Rejestr logowania';
_fc_props.menu_reports_title_main = 'Główny';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Czy na pewno chcesz usunąć ten typ danych eksportu?';
_fc_props.dvir_status_paper_logs = 'dzienniki papierowe';
_fc_props.menu_reports_title_billing_report = 'Raport rozliczeniowy';
_fc_props.menu_reports_description_billing_report = 'Raport rozliczeniowy';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status Do Stanu Zgłoszenia Przy Aktywacji Pracy';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status do statusu biletu przy aktywacji pracy (domyślnie „False”)';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'funty';
_fc_props.units_weight_kilogram_label = 'Kilogramy';
_fc_props.settings_weight = 'Waga';
_fc_props.units_pounds = 'funty';
_fc_props.units_kilograms = 'Kilogramy';
_fc_props.geo_gate_edit_modal_description = 'Opis';
_fc_props.geo_gate_edit_modal_start_point = 'Punkt startu';
_fc_props.geo_gate_edit_modal_end_point = 'Punkt końcowy';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Rodzaj wyposażenia';
_fc_props.geo_gate_edit_modal_heading_south = 'Z południa na północ';
_fc_props.geo_gate_edit_modal_heading_north = 'z północy na południe';
_fc_props.geo_gate_edit_modal_driver_message = 'Wiadomość dla kierowcy';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Wyślij wiadomość';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Data ważności Godzina';
_fc_props.geo_gate_edit_modal_permanent = 'Stały';
_fc_props.geo_gate_edit_modal_critical = 'Krytyczny';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Błąd podczas zapisywania Geogate: Pole opisu musi być wypełnione.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Błąd podczas zapisywania Geogate: pole Punkt początkowy musi być wypełnione.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Błąd podczas zapisywania Geogate: Pole Punkt początkowy zawiera nieprawidłowy format danych.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Błąd podczas zapisywania Geogate: Pole punktu końcowego zawiera nieprawidłowy format danych.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Błąd podczas zapisywania Geogate: pole Data wygaśnięcia Czas musi być wypełnione.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Błąd podczas zapisywania Geogate: pole daty ważności i godziny zawiera nieprawidłowy format danych.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Błąd podczas zapisywania Geogate: z północy na południe — pole wiadomości do wysyłki musi być wypełnione';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Błąd zapisywania Geogate: Nie wykryto żadnych zmian';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Błąd podczas zapisywania Geogate: pole Data wygaśnięcia Czas zawiera datę i godzinę wygaśnięcia';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Błąd podczas zapisywania Geogate: Pole opisu może zawierać tylko litery i cyfry';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Błąd podczas zapisywania Geogate: pole Typ sprzętu musi być wypełnione';
_fc_props.geo_gate_table_message_label_north_to_south = 'z północy na południe';
_fc_props.geo_gate_table_message_label_south_to_north = 'Z południa na północ';
_fc_props.geo_gate_table_message_label_both = 'Obydwa';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Długości i szerokości geograficznej';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Zaznaczenie tego spowoduje zastąpienie ustawień ograniczenia wiadomości o ruchu urządzenia';
_fc_props.geo_gate_edit_modal_seasonal = 'Sezonowy';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Błąd podczas zapisywania Geogate: Daty sezonowe muszą być wypełnione';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Błąd podczas zapisywania Geogate: Pola daty sezonowej zawierają nieprawidłowy format danych';
_fc_props.test_js_test_javascript = 'Przetestuj Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometry';
_fc_props.units_miles = 'mile';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'Ł';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Błąd podczas zapisywania Geogate: z południa na północ — pole Wiadomość dla kierowcy musi być wypełnione';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Błąd podczas zapisywania Geogate: z południa na północ — pole wiadomości do wysyłki musi być wypełnione';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Błąd podczas zapisywania Geogate: z północy na południe — pole Wiadomość dla kierowcy musi być wypełnione';
_fc_props.geo_gate_heading = 'Nagłówek';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Błąd podczas zapisywania Geogate: pole Punkt końcowy musi być wypełnione';
_fc_props.units_mile = 'Mila';
_fc_props.units_kilometer = 'Kilometr';
_fc_props.cloud_dvir__exit = 'Wyjście';
_fc_props.cloud_dvir__signature = 'Podpis';
_fc_props.cloud_dvir__inspection = 'Kontrola';
_fc_props.cloud_dvir__add_photos_notes = 'Dodaj zdjęcia/notatki';
_fc_props.cloud_dvir__odometer_reading = 'Odczyt licznika kilometrów';
_fc_props.cloud_dvir__engine_hours = 'Godziny pracy silnika';
_fc_props.cloud_dvir__truck_details = 'Szczegóły ciężarówki';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Określ ogólny stan pojazdu';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Imię i nazwisko oraz podpis kierowcy';
_fc_props.cloud_dvir__sign = 'Podpisać';
_fc_props.cloud_dvir__driver_note_from = 'Uwaga kierowcy od';
_fc_props.cloud_dvir__add_notes = 'Dodaj notatki';
_fc_props.cloud_dvir__driver_notes = 'Uwagi kierowcy';
_fc_props.cloud_dvir__done = 'Zrobione';
_fc_props.cloud_dvir__needs_repair = 'Wymaga naprawy';
_fc_props.cloud_dvir__not_applicable = 'Nie dotyczy';
_fc_props.cloud_dvir__review_and_sign = 'Przejrzyj i podpisz';
_fc_props.cloud_dvir__add_photos = 'Dodaj zdjęcie';
_fc_props.cloud_dvir__upload_photo_limit = 'Możesz przesłać maksymalnie 3 zdjęcia';
_fc_props.cloud_dvir__mismatch_title = 'Alert niezgodności';
_fc_props.cloud_dvir__ok = 'OK';
_fc_props.cloud_dvir_template = 'Szablon Cloud DVIR';
_fc_props.cloud_dvir__needs_review = 'DVIR wymaga przeglądu';
_fc_props.cloud_dvir__submitted = 'Złożony';
_fc_props.cloud_dvir__completed_by = 'Ukończony przez';
_fc_props.cloud_dvir__review_btn = 'Recenzja';
_fc_props.cloud_dvir__truck_number = 'Ciężarówka {0}';
_fc_props.cloud_dvir__pending_review = 'W oczekiwaniu na opinię';
_fc_props.cloud_dvir__start_dvir = 'Uruchom DVIR';
_fc_props.cloud_dvir__dvir_history = 'Historia DVIR';
_fc_props.cloud_dvir__view_all = 'Pokaż wszystkie';
_fc_props.load_zone = 'Strefa obciążenia';
_fc_props.view_load_zones = 'Wyświetl strefy obciążenia';
_fc_props.edit_load_zone = 'Edytuj strefę obciążenia';
_fc_props.create_a_load_zone = 'Utwórz strefę obciążenia';
_fc_props.load_zone_panel = 'Panel strefy obciążenia';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Kliknij mapę, aby ustawić środek strefy obciążenia.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Ta kontrola wykazała wady i wybrałeś, że ten pojazd jest bezpieczny w eksploatacji. Potwierdź, zanim przejdziesz dalej';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Ta kontrola nie wykazała żadnych usterek i wybrałeś, że ten pojazd nie jest bezpieczny w obsłudze. Potwierdź, zanim przejdziesz dalej.';
_fc_props._90_days = '90 dni';
_fc_props.cloud_dvir = 'Cloud DVIR';
_fc_props.cloud_dvir_details = 'Zgłoszenia Cloud DVIR';
_fc_props.show_load_zones = 'pokaż strefy obciążenia';
_fc_props.hide_load_zones = 'ukryć strefy obciążenia';
_fc_props.no = 'Nie';
_fc_props.cloud_dvir__reset = 'Resetowanie';
_fc_props.cloud_dvir__clear = 'Jasne';
_fc_props.mark_read = 'Oznacz przeczytane';
_fc_props.mark_unread = 'Zaznacz jako nieprzeczytane';
_fc_props.read = 'Czytać';
_fc_props.unread = 'nieprzeczytane';
_fc_props.air_sensor_serial_number = 'Numer seryjny czujnika powietrza';
_fc_props.air_sensor_mac_address = 'Adres MAC czujnika powietrza';
_fc_props.air_sensor_serial_number_invalid_format = 'Numer seryjny czujnika powietrza ma nieprawidłowy format: (alfanumeryczny i maksymalnie 32 znaki z myślnikami)';
_fc_props.air_sensor_mac_address_invalid_format = 'Adres MAC czujnika powietrza nie jest w prawidłowym formacie szesnastkowym, jak jest wyświetlany w symbolu zastępczym tego pola';
_fc_props.air_sensor_license = 'Czujnik powietrza';
_fc_props.adjust_route_point = 'Dostosuj punkt trasy';
_fc_props.route_point_panel = 'Panel punktów trasy';
_fc_props.route_point = 'Punkt trasy';
_fc_props.cloud_dvir__pre_trip = 'Przed podróżą';
_fc_props.cloud_dvir__post_trip = 'Po wycieczce';
_fc_props.cloud_dvir__other_insction_type = 'Inny';
_fc_props.cloud_dvir__dvir_completed = 'Zakończony';
_fc_props.cloud_dvir__awaiting_mechanic = 'Oczekiwanie na mechanika';
_fc_props.cloud_dvir__awaiting_driver = 'Oczekiwanie na zatwierdzenie kierowcy';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Nie można sprawdzić numeru seryjnego czujnika powietrza. Skontaktuj się z pomocą techniczną, aby uzyskać dodatkową pomoc.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Ten numer seryjny czujnika powietrza już istnieje. Użyj innego numeru seryjnego';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Nie można sprawdzić adresu MAC czujnika powietrza. Aby uzyskać pomoc, skontaktuj się z pomocą techniczną';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Błąd podczas przesyłania DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Proszę spróbuj ponownie';
_fc_props.ticket_integration_descr = 'Integracja biletów umożliwia ustawienie ustawień integracji biletów, a także ekranu głównego / ekranu ładowania / wyświetlacza montowanego na stałe';
_fc_props.cloud_dvir__success_submit_dvir_title = 'Pomyślnie przesłano DVIR';
_fc_props.cloud_dvir__inspection_trip_type = 'Typ podróży kontrolnej';
_fc_props.menu_dvir_title_mechanic = 'Mechanik';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Oprogramowanie sprzętowe czujnika powietrza';
_fc_props.when_no_active_ticket = 'Gdy nie ma aktywnego biletu';
_fc_props.used_by = 'Używany przez';
_fc_props.fields = 'Pola';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Czy na pewno chcesz usunąć układ biletu „{0}”?';
_fc_props.menu_settings_title_ticket_layout = 'Układ biletu';
_fc_props.add_ticket_layout = 'Dodaj układ biletu';
_fc_props.edit_ticket_layout = 'Edytuj układ biletu';
_fc_props.ticket_layout_edit_modal_name = 'Nazwa';
_fc_props.ticket_layout_edit_modal_default = 'Domyślny';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Błąd podczas zapisywania układu biletu: Pole nazwy musi być wypełnione.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Błąd podczas zapisywania układu biletu: Pole nazwy może zawierać tylko litery i cyfry';
_fc_props.ticket_layout_edit_modal_fields = 'Pola';
_fc_props.ticket_layout_edit_modal_usage = 'Stosowanie';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Układ biletu został usunięty. Proszę odświeżyć stronę.';
_fc_props.more_lower_case = 'więcej';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Błąd podczas zapisywania układu biletu: Należy zaznaczyć jedno lub więcej pól.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Błąd podczas zapisywania układu biletu: Należy wybrać jeden lub więcej statusów.';
_fc_props.cloud_dvir__ad_hoc = 'Doraźnie';
_fc_props.radius_multiplier_should_be = 'Mnożnik promienia powinien być liczbą zmiennoprzecinkową';
_fc_props.ticket_max_activate_age_err = 'Minuty maksymalnego wieku aktywacji biletu powinny być liczbą całkowitą.';
_fc_props.max_hotspot_unused_days_errs = 'Maksymalna liczba nieużywanych dni hotspotu powinna być liczbą całkowitą.';
_fc_props.begin_pour_air_content = 'Rozpocznij wlewanie zawartości powietrza';
_fc_props.arrival_air_content = 'Zawartość powietrza przylotowego';
_fc_props.fully_mixed_air_content = 'W pełni wymieszana zawartość powietrza';
_fc_props.departure_air_content = 'Zawartość powietrza wylotowego';
_fc_props.popup_enabled = 'Wyskakujące okienko włączone';
_fc_props.popup_disabled = 'Wyskakujące okienko wyłączone';
_fc_props.cloud_dvir__leave_confirmation_title = 'Ostrzeżenie';
_fc_props.cloud_dvir__leave_confirmation_description = 'Wyjście ze strony w tym momencie spowoduje utratę wszystkich zmian.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Potwierdzać';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Anulować';
_fc_props.air_bt_ratio = 'Współczynnik BT powietrza';
_fc_props.cloud_dvir__view_photos_notes = 'Wyświetl zdjęcia/notatki';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Dodanie statusu spowoduje utworzenie pustego miejsca w innym układzie zgłoszenia. Czy chcesz kontynuować dodawanie?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Pojazd nie posiada licencji na czujnik powietrza.';
_fc_props.air_sensor_installed = 'Zainstalowany czujnik powietrza';
_fc_props.load_card = 'Załaduj kartę';
_fc_props.cloud_dvir__inspection_profile = 'Profil inspekcji';
_fc_props.cloud_dvir__view_details = 'Pokaż szczegóły';
_fc_props.cloud_dvir__certify_repairs = 'Certyfikuj naprawy';
_fc_props.cloud_dvir__repairs_made = 'Wykonane naprawy';
_fc_props.cloud_dvir__repairs_not_necessary = 'Naprawy niepotrzebne';
_fc_props.cloud_dvir__mechanic_note_from = 'Notatka mechanika od';
_fc_props.cloud_dvir__mechanic_assignment = 'Przydział mechanika';
_fc_props.cloud_dvir__mechanic_signature_description = 'Zaznacz poniżej wszystkie odpowiednie pozycje i znak wskazujący, że pojazd został prawidłowo sprawdzony.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Stan tego pojazdu jest zadowalający';
_fc_props.cloud_dvir__defects_corrected_label = 'Usterki zostały poprawione';
_fc_props.cloud_dvir__repair_not_required_label = 'Naprawa nie jest wymagana dla bezpiecznej eksploatacji pojazdu';
_fc_props.safety = 'Bezpieczeństwo';
_fc_props.cloud_dvir__awaiting_sign = 'Oczekiwanie na znak';
_fc_props.cloud_dvir__dvir_review = 'Recenzja DVIR';
_fc_props.settings.mobile_auto_start_navigation = 'Automatyczne uruchamianie nawigacji na urządzeniu mobilnym';
_fc_props.cloud_dvir__edit = 'Edytować';
_fc_props.delete_custom_label = 'Usuń etykietę niestandardową';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Twoja wersja TrackIt jest nieaktualna i niektóre funkcje DVIR mogą nie działać poprawnie. Przed kontynuowaniem zaktualizuj TrackIt.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Coś poszło nie tak podczas przesyłania podpisu. Proszę spróbuj ponownie';
_fc_props.max_hotspot_unused_days_error = 'Maksymalna liczba niewykorzystanych dni hotspotu wynosi maksymalnie 1000 dni.';
_fc_props.menu_dvir_title_admin_templates = 'Szablony';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Admin';
_fc_props.cloud_dvir__admin_templates = 'Szablony';
_fc_props.form_name = 'Nazwa formularza';
_fc_props.form_status = 'Stan formularza';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Pobierać';
_fc_props.cloud_dvir__option_view = 'Pogląd';
_fc_props.cloud_dvir__inspectortype_driver = 'Kierowca';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Przeglądaj/edytuj/twórz niestandardowe formularze i zgłoszenia DVIR';
_fc_props.menu.dvir.description.mechanic = 'Przeglądaj/edytuj przydatne zgłoszenia DVIR';
_fc_props.not_in_use = 'Nie w użyciu';
_fc_props.logged_in = 'Zalogowany';
_fc_props.reviewing_driver = 'Recenzja sterownika';
_fc_props.cloud_dvir__report_title = 'Protokół kontroli pojazdu kierowcy';
_fc_props.cloud_dvir__report_signed = 'podpisany';
_fc_props.cloud_dvir__report_page_counter = 'Strona {0} z {1}';
_fc_props.cloud_dvir__report_defects = 'Wady';
_fc_props.cloud_dvir__report_comments = 'Uwagi';
_fc_props.cloud_dvir__report_inspection_table_title = 'Kontrola pojazdu';
_fc_props.cloud_dvir__report_driver_table_title = 'Informacje o kierowcy';
_fc_props.cloud_dvir__report_carrier = 'Przewoźnik';
_fc_props.cloud_dvir__report_truck = 'Ciężarówka';
_fc_props.cloud_dvir__report_location = 'Lokalizacja';
_fc_props.cloud_dvir__report_odometer = 'Drogomierz';
_fc_props.cloud_dvir__report_hours = 'godziny';
_fc_props.cloud_dvir__report_no_comments = 'Bez komentarza';
_fc_props.include_unlicensed_vehicles = 'Uwzględnij pojazdy nielicencjonowane';
_fc_props.cloud_dvir__search_by_form_name = 'Wyszukaj według nazwy formularza';
_fc_props.cloud_dvir__create_dvir = 'Utwórz DVIR';
_fc_props.cloud_dvir__update_dvir = 'Zaktualizuj DVIR';
_fc_props.cloud_dvir__form_name = 'Nazwa formularza';
_fc_props.cloud_dvir__form_description = 'Opis formularza';
_fc_props.cloud_dvir__form_status = 'Stan formularza';
_fc_props.information = 'Informacja';
_fc_props.cloud_dvir__search_categories = 'Kategorie wyszukiwania';
_fc_props.cloud_dvir__create_new_category = 'Utwórz nową kategorię';
_fc_props.category_name = 'Nazwa Kategorii';
_fc_props.sub_category = 'Podkategoria';
_fc_props.sub_category_name = 'Nazwa podkategorii';
_fc_props.delete_category = 'Usuń kategorię';
_fc_props.cloud_dvir__dvir_information = 'Informacje DVIR';
_fc_props.cloud_dvir__dvir_choose_categories = 'Wybierz Kategorie';
_fc_props.cloud_dvir__create_category = 'Utwórz kategorię';
_fc_props.cloud_dvir__update_category = 'Aktualizuj kategorię';
_fc_props.delete_route_point = 'Usuń punkt trasy';
_fc_props.view_route_points = 'Wyświetl punkty trasy';
_fc_props.ignore_cancel_preload = 'Zignoruj anulowanie, jesteś wstępnie załadowany. Twój home run zostanie wkrótce wyświetlony';
_fc_props.cloud_dvir__assigned_mechanic = 'Przydzielony mechanik';
_fc_props.cloud_dvir__add_mechanic = 'Dodaj mechanika';
_fc_props.contact_type = 'Typ kontaktu';
_fc_props.cloud_dvir__send_email_notifications = 'Wysyłaj powiadomienia e-mailem';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Błąd podczas pobierania zapisów historii DVIR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Błąd podczas pobierania nagrania DVIR.';
_fc_props.cloud_dvir__error_saving_submission = 'Błąd podczas zapisywania przesłanego zgłoszenia. Spróbuj ponownie.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Błąd podczas pobierania listy mechaników.';
_fc_props.cloud_dvir__template_saved = 'Szablon został zapisany';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Błąd podczas pobierania listy szablonów.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Błąd podczas zapisywania szablonu. Spróbuj ponownie.';
_fc_props.dispatch_group = 'Grupa wysyłkowa';
_fc_props.save_adjust = 'Zapisz i dostosuj punkt trasy';
_fc_props.reset_route_point = 'Zresetuj punkt trasy';
_fc_props.no_records_stub_adjust_filters = 'Spróbuj dostosować ustawienia wyszukiwania lub filtrów, aby zwiększyć liczbę wyników.';
_fc_props.no_dvir_records = 'Wszystko złapane na rejestratorach DVIR!';
_fc_props.no_dvir_records_sub_title = 'Osiągnąłeś wiele';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'Nie uzyskano odczytu spadku dla: {0}';
_fc_props.please_select_a_sensor_status_type = 'Aby uruchomić ten alert, wybierz typy stanu czujnika';
_fc_props.settings.master_data_connex_token = 'Token danych głównych Connex';
_fc_props.error_validating_master_data_connex_credentials = 'Błąd podczas sprawdzania poświadczeń Master Data Connex';
_fc_props.settings.master_data_connex_base_url = 'Podstawowy adres URL bazy danych Connex';
_fc_props.settings.master_data_connex_exp_time = 'Czas ważności danych podstawowych Connex';
_fc_props.menu_settings_title_dispatch_group = 'Grupy wysyłkowe';
_fc_props.settings.dispatch_groups = 'Grupy wysyłkowe';
_fc_props.dispatch_group_name = 'Nazwa grupy wysyłkowej';
_fc_props.dispatch_group_plants = 'Zakłady grupy wysyłkowej';
_fc_props.dispatch_group_plants_mapping_message = 'Wskaż, które zakłady będą częścią tej Grupy Wysyłkowej.';
_fc_props.dispatch_group_users_mapping_message = 'Wskaż, którzy użytkownicy będą częścią tej grupy wysyłkowej.';
_fc_props.dispatch_group_plant_disassociation_message = 'Zakład powinien znajdować się w ramach jednej grupy wysyłkowej na raz';
_fc_props.dispatch_group_search_by_plant = 'Szukaj według nazwy rośliny';
_fc_props.dispatch_group_search_by_username = 'Wyszukaj według nazwy użytkownika';
_fc_props.search_by_dispatch_group_name = 'Wyszukaj według nazwy grupy wysyłkowej';
_fc_props.create_dispatch_group = 'Utwórz grupę wysyłkową';
_fc_props.update_dispatch_group = 'Zaktualizuj grupę wysyłkową';
_fc_props.dispatch_group_saved = 'Grupa wysyłkowa została zapisana';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Usunięcie tej grupy wysyłkowej spowoduje utworzenie domyślnych grup wysyłkowych dla powiązanych zakładów.';
_fc_props.error_saving_dispatch_group = 'Błąd podczas zapisywania grupy wysyłkowej. Spróbuj ponownie.';
_fc_props.please_enter_a_valid_plant_num = 'Proszę wprowadzić prawidłowy numer zakładu.';
_fc_props.cloud_dvir__report_no_defects = 'Żadnych wad';
_fc_props.shoud_select_dispatch_group = 'Musisz wybrać co najmniej jedną grupę wysyłkową';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Nie można usunąć, ponieważ ta grupa wysyłkowa zawiera użytkowników powiązanych tylko z tą grupą wysyłkową.';
_fc_props.cloud_dvir__username = 'Nazwa użytkownika';
_fc_props.cloud_dvir__password = 'Hasło';
_fc_props.cloud_dvir__login = 'Zaloguj sie';
_fc_props.cloud_dvir__mechanic_sign_in = 'Zaloguj się do mechanika';
_fc_props.delete_dispatch_group = 'Usuń grupę wysyłkową';
_fc_props.cloud_dvir__driver = 'Kierowca';
_fc_props.cloud_dvir__equipment_pound = 'Sprzęt #';
_fc_props.cloud_dvir__mechanic = 'Mechanik';
_fc_props.dispatch_group_user_only_unassociate_message = 'Nie można usunąć użytkownika, ponieważ użytkownicy muszą być powiązani z co najmniej jedną grupą wysyłkową.';
_fc_props.cloud_dvir__time_period_filter_today = 'Dzisiaj';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Wczoraj';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Ostatnie 7 dni';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Ostatnie 30 dni';
_fc_props.cloud_dvir__date_range_filter = 'Filtruj według daty';
_fc_props.cloud_dvir__download = 'Pobierać';
_fc_props.dispatch_group_is_empty = 'Grupa wysyłkowa nie może być pusta.';
_fc_props.dispatch_group_with_empty_plants = 'Co najmniej jeden zakład musi być powiązany z grupą wysyłkową.';
_fc_props.dispatch_group_with_empty_users = 'Co najmniej jeden użytkownik musi być powiązany z grupą wysyłkową.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Nie można usunąć grupy wysyłkowej, ponieważ zawiera ona użytkowników powiązanych tylko z tą grupą wysyłkową.';
_fc_props.dispatch_group_not_exists = 'Grupa wysyłkowa nie istnieje.';
_fc_props.dispatch_group_contains_one_plant = 'Nie można usunąć, ponieważ ta grupa wysyłkowa zawiera tylko jedną fabrykę.';
_fc_props.dispatch_group_users = 'Użytkownicy grupy wysyłkowej';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Błąd podczas pobierania nagrania DVIR';
_fc_props.cloud_dvir__assign_mechanic = 'Przypisz mechanika';
_fc_props.cloud_dvir__submit_review = 'Dodaj recenzję';
_fc_props.error_deleting_dispatch_group = 'Błąd podczas usuwania grupy wysyłkowej.';
_fc_props.error_retrieving_dispatch_groups = 'Błąd podczas pobierania listy grup wysyłkowych.';
_fc_props.cloud_dvir__undo_selection = 'Cofnij wybór';
_fc_props.enable_master_data = 'Włącz dane podstawowe';
_fc_props.manually_sync = 'Synchronizacja ręczna';
_fc_props.master_data_connex_token_expiration = 'Wygaśnięcie tokena danych głównych Connex';
_fc_props.cloud_dvir__time_period_filter_custom = 'Zwyczaj';
_fc_props.settings.zello_session_timeout_minutes = 'Limit czasu sesji Zello (minuty)';
_fc_props.menu_settings_title_master_data = 'Dane główne';
_fc_props.settings.master_data = 'Dane główne';
_fc_props.error_retrieving_master_data = 'Błąd podczas pobierania danych podstawowych';
_fc_props.master_data_sync_completed = 'Synchronizacja danych głównych została ukończona';
_fc_props.master_data_successfully_updated = 'Dane zostały pomyślnie zaktualizowane';
_fc_props.master_data_failed_to_update = 'Nie udało się zapisać.';
_fc_props.master_data_sync_initiated = 'Rozpoczęto synchronizację danych głównych';
_fc_props.master_data_sync_failed = 'Synchronizacja danych głównych nie powiodła się';
_fc_props.enabled_excluding_origin_on_deliveries = 'Prawda (z wyłączeniem pochodzenia na biletach dostawy)';
_fc_props.enabled_including_origin_on_deliveries = 'Prawda (w tym pochodzenie na biletach dostawy)';
_fc_props.cloud_dvir__i_will_decide_later = 'Zdecyduję później';
_fc_props.returning = 'Powracający';
_fc_props.leftover_concrete = 'Resztki betonu';
_fc_props.leftover_concrete_on_vehicle_x = 'Resztki betonu na pojeździe {0}';
_fc_props.leftover_concrete_alert = 'Alarm dotyczący pozostałości betonu';
_fc_props.wc_ratio_exceeded = 'Przekroczono współczynnik W/C';
_fc_props.search_by_site_name = 'Wyszukaj według nazwy witryny';
_fc_props.sites = 'Witryny';
_fc_props.token_expiration = 'Wygaśnięcie tokenu';
_fc_props.menu_settings_title_connex_api_key_management = 'Zarządzanie kluczami Connex API';
_fc_props.settings.connex_api_key_management = 'Zarządzanie kluczami Connex API';
_fc_props.error_retrieving_connex_api_key_data = 'Błąd podczas pobierania kluczowych danych Connex API';
_fc_props.qr_code = 'Kod QR';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informowanie kierowcy i interfejsu w przypadku wejścia do niewłaściwego zakładu';
_fc_props.enable_driver_message = 'Włącz komunikat sterownika';
_fc_props.enable_dispatch_message = 'Włącz wiadomość wysyłkową';
_fc_props.enable_sending_message_to_interface = 'Włącz wysyłanie wiadomości do interfejsu';
_fc_props.ticket_qr_code = 'Kod QR biletu';
_fc_props.leftover_concrete_alert_description = 'Ostrzegaj, gdy po wylaniu zostaną znalezione resztki betonu.';
_fc_props.minimum_leftover_concrete_amount = 'Minimalna ilość pozostałego betonu';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Błąd podczas sprawdzania danych uwierzytelniających Master Data Connex. Brak autoryzacji';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Błąd podczas sprawdzania uprawnień poświadczeń Master Data Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Błąd podczas sprawdzania danych głównych Connex Url Nie znaleziono';
_fc_props.error_validating_master_data_connex_unknown_error = 'Błąd podczas sprawdzania danych głównych Connex Nieznany błąd';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Synchronizacja danych głównych nie powiodła się. Synchronizuj typ pojazdu.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Synchronizacja danych głównych nie powiodła się. Synchronizuj pojazd.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Synchronizacja danych głównych nie powiodła się. Synchronizacja instalacji.';
_fc_props.master_data_sync_failed_data_base_connection = 'Synchronizacja danych głównych nie powiodła się, połączenie z bazą danych.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Błąd podczas sprawdzania danych podstawowych Connex. Uprawnienie tokena okaziciela jest niewystarczające';
_fc_props.settings.back_date_arrive_job = 'Praca z datą przybycia';
_fc_props.device_notifications = 'Powiadomienia';
_fc_props.loading_number = 'Numer ładowania';
_fc_props.leftover_concrete_volume_temp_age = 'Pozostała objętość={0}, temperatura={1}, wiek={2}';
_fc_props.total_ordered = 'Całkowita zamówiona ilość';
_fc_props.total_ticketed = 'Dostarczono Zamówiono';
_fc_props.load_number = 'Numer obciążenia';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Minimalna ilość pozostałego betonu musi zostać uzupełniona i większa niż {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Błąd podczas sprawdzania danych podstawowych. Dane uwierzytelniające nie mogą być takie same.';
_fc_props.use_aws_for_talk = 'Użyj AWS do rozmów';
_fc_props.air_readings_not_supported = 'Odczyty powietrza nie są obsługiwane';
_fc_props.air_not_supported = 'Nieobsługiwane powietrze';
_fc_props.mix_not_supported = 'Mieszanka nie jest obsługiwana';
_fc_props.air_default_and_none_not_supported = 'Nie można wybrać domyślnego i jednocześnie nieobsługiwanego!';
_fc_props.notifications_enabled = 'Powiadomienia włączone';
_fc_props.prevent_status_change = 'Zapobiegaj zmianie statusu';
_fc_props.message_to_interface = 'Wiadomość do interfejsu';
_fc_props.prevent_wrong_plant = 'Zapobiegaj niewłaściwej roślinie';
_fc_props.previous_tickets = 'Poprzednie bilety';
_fc_props.audit_ticket_number = 'Numer biletu';
_fc_props.audit_date_created = 'Data utworzenia';
_fc_props.audit_vehicle_number = 'Numer pojazdu';
_fc_props.cloud_dvir__table_ok = 'Bezpieczny w obsłudze';
_fc_props.cloud_dvir__table_needs_repair = 'Niebezpieczne w obsłudze';
_fc_props.missing_air_calibration = 'Brak danych kalibracji powietrza';
_fc_props.reported_defects = 'Zgłoszone wady';
_fc_props.address_latitude = 'Adres Współrzędne';
_fc_props.address_longitude = 'Długość geograficzna adresu';
_fc_props.routing_latitude = 'Szerokość trasowania';
_fc_props.routing_longitude = 'Długość geograficzna trasy';
_fc_props.category_name_must_be_unique = 'Nazwa kategorii musi być unikalna';
_fc_props.subcategory_name_must_be_unique = 'Nazwa podkategorii musi być unikalna';
_fc_props.menu_settings_tracking_device = 'Urządzenie namierzające';
_fc_props.menu_settings_title_tracking_device = 'Urządzenie namierzające';
_fc_props.menu_settings_title_personal_device_registration = 'Rejestracja urządzenia osobistego';
_fc_props.registration_code = 'Kod rejestracyjny';
_fc_props.creation_date = 'kreacja';
_fc_props.expiration_date = 'Wygaśnięcie';
_fc_props.trackitadmin_personal_device_registration = 'Rejestracja urządzenia osobistego';
_fc_props.create_registration_code = 'Dodaj kod rejestracyjny';
_fc_props.remove_registration_code = 'Usuń kod rejestracyjny';
_fc_props.add_a_registration_code = 'Dodaj kod rejestracyjny';
_fc_props.select_number_of_registration_codes_to_generate = 'Wybierz liczbę kodów rejestracyjnych, które chcesz wygenerować.';
_fc_props.registration_code_count = 'Liczba kodów rejestracyjnych';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Czy na pewno chcesz usunąć wybrany zarejestrowany kod?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Czy na pewno chcesz usunąć wybrane zarejestrowane kody?';
_fc_props.add_personal_device = 'Dodaj urządzenie osobiste';
_fc_props.menu_settings_title_personal_device = 'Urządzenie osobiste';
_fc_props.cloud_dvir__approval_critical_alert = 'Pojazd ma poważną wadę. Nie można oznaczyć jako bezpieczny w obsłudze.';
_fc_props.export_registration_codes = 'Eksportuj kody rejestracyjne';
_fc_props.download_format = 'Pobierz format';
_fc_props.specify_download_format_x = 'Określ format pobierania, aby wyeksportować kody rejestracyjne {0}';
_fc_props.personal_device_download = 'Pobierać';
_fc_props.add_personal_device_input_message = 'Wprowadź poniżej osobisty identyfikator urządzenia. Wygaśnięcie zostanie zastosowane automatycznie po zapisaniu';
_fc_props.error_creating_personal_device = 'Błąd podczas tworzenia urządzenia osobistego';
_fc_props.device_already_exists = 'Urządzenie osobiste już istnieje';
_fc_props.export = 'Eksport';
_fc_props.error_can_not_change_connex_eid = 'Nie można zmienić identyfikatora Connex, ponieważ jest on aktualnie używany dla danych podstawowych';
_fc_props.remove_personal_device = 'Usuń urządzenie osobiste';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Czy na pewno chcesz usunąć wybrane urządzenie osobiste?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Czy na pewno chcesz usunąć wybrane urządzenia osobiste?';
_fc_props.error_deleting_personal_device = 'Błąd podczas usuwania urządzenia osobistego';
_fc_props.customerid = 'Identyfikator klienta';
_fc_props.customername = 'Nazwa klienta';
_fc_props.ordernumber = 'Numer zamówienia';
_fc_props.orderdescription = 'Opis zamówienia';
_fc_props.locationid = 'Identyfikator lokalizacji';
_fc_props.locationdescription = 'opis lokalizacji';
_fc_props.productid = 'ID produktu';
_fc_props.productdescription = 'Opis produktu';
_fc_props.vehicleid = 'Identyfikator pojazdu';
_fc_props.vehicledescription = 'Opis Pojazdu';
_fc_props.ticketnumber = 'Numer biletu';
_fc_props.ticketdate = 'Data/godzina biletu';
_fc_props.drivername = 'Imię kierowcy';
_fc_props.arriveregion = 'Region przybycia (CZAS)';
_fc_props.leaveregion = 'Opuść region (CZAS)';
_fc_props.regionduration = 'Czas trwania regionu';
_fc_props.menu_reports_title_prevailing_wage = 'Przeważająca płaca';
_fc_props.slump_calibration_x_not_found_for_y = 'Nie znaleziono kalibracji opadu „{0}” dla biletu {1}';
_fc_props.slump_calibration_not_found = 'Nie znaleziono kalibracji opadu';
_fc_props.slump_calibration_not_found_description = 'Powiadomienie, gdy nie znaleziono kalibracji opadu';
_fc_props.error_getting_personal_device_list = 'Błąd podczas pobierania osobistej listy urządzeń';
_fc_props.batch_summary_date_range_note = '*UWAGA: ten raport jest ograniczony do 3 dni, jeśli nie zastosowano żadnych filtrów. Zakres dat może sięgać maksymalnie 31 dni w przypadku filtrowania według sprzętu (maksymalnie 10), zakładu (maksymalnie 1), produktu (maksymalnie 1) lub zadania (maksymalnie 1)';
_fc_props.select_registration_code_expiration_time_days = 'Wybierz liczbę dni do wygaśnięcia kodów rejestracyjnych, a następnie kliknij Zapisz.';
_fc_props.add_personal_device_expiration = 'Wygaśnięcie (w dniach)';
_fc_props.cloud_dvir__vehicle_no = 'Numer pojazdu';
_fc_props.cloud_dvir__trailer_no = 'Przyczepa nr';
_fc_props.cloud_dvir__vehicle = 'Pojazd';
_fc_props.cloud_dvir__trailer = 'Przyczepa';
_fc_props.cloud_dvir__truck_number_label = 'Numer ciężarówki';
_fc_props.cloud_dvir__trailer_number = 'Numer przyczepy';
_fc_props.cloud_dvir__has_trailer = 'Nie jadę z przyczepą';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Wpisz tutaj numer swojej przyczepy';
_fc_props.work_order = 'Zlecenie pracy';
_fc_props.unable_to_determine_slump = 'Nie można określić spadku';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Określ ogólny stan przyczepy';
_fc_props.defaultTrailer = 'Domyślny zwiastun';
_fc_props.trailer_type_column_billable = 'Rozliczalny';
_fc_props.trailer_type_column_notes = 'Notatki';
_fc_props.trailer_type_column_date = 'Data';
_fc_props.trailer_type_column_order = 'Zamówienie';
_fc_props.trailer_type_column_ticket = 'Bilet';
_fc_props.trailer_type_column_project = 'Projekt';
_fc_props.trailer_type_column_customer = 'Klient';
_fc_props.trailer_type_column_location = 'Lokalizacja';
_fc_props.trailer_type_column_vehicle_type = 'Typ pojazdu';
_fc_props.trailer_type_column_product = 'Produkt';
_fc_props.trailer_type_action_rebill = 'Ponowne rozliczenie';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Synchronizacja danych głównych nie powiodła się, nie ustawiono domyślnego typu sprzętu';
_fc_props.trailer_type = 'Typ przyczepy';
_fc_props.trailer_type_is_required = 'Wymagany jest typ przyczepy';
_fc_props.trailer_number_already_exists = 'Numer przyczepy już istnieje';
_fc_props.plate_number_already_exists = 'Numer rejestracyjny już istnieje';
_fc_props.trailer_vin_already_exists = 'Trailer Vin już istnieje';
_fc_props.trailer_does_not_exist = 'Przyczepa nie istnieje';
_fc_props.trailer_number_is_required = 'Numer przyczepy jest wymagany';
_fc_props.plate_number_is_required = 'Numer rejestracyjny jest wymagany';
_fc_props.plate_number = 'Numer rejestracyjny';
_fc_props.menu_settings_title_trailer = 'Przyczepa';
_fc_props.add_trailer = 'Dodaj zwiastun';
_fc_props.edit_trailer = 'Edytuj zwiastun';
_fc_props.menu_settings_title_equipment = 'Sprzęt';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignoruj nowe zgłoszenia z Command Cloud starsze niż X minut';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignoruj nowe zgłoszenia z Command Cloud z datą i godziną wysyłki starszą niż X minut (domyślnie pole jest puste i wyłączone)';
_fc_props.edit_load_card = 'Edytuj kartę ładowania';
_fc_props.default_layout = 'Układ domyślny';
_fc_props.load_card_empty_fields = 'Błąd podczas zapisywania karty ładowania: Należy wybrać co najmniej jedno pole.';
_fc_props.trailers = 'Przyczepy';
_fc_props.error_failed_to_save_data = 'Błąd: Nie udało się zapisać danych';
_fc_props.slump_increased_plasticizer_added_question = 'Ile dodano plastyfikatora?';
_fc_props.add_trailer_type = 'Dodaj typ przyczepy';
_fc_props.menu_settings_title_trailer_type = 'Typ przyczepy';
_fc_props.edit_trailer_type = 'Edytuj typ przyczepy';
_fc_props.single_use_codes = 'Kody jednorazowego użytku';
_fc_props.multiple_use_codes = 'Kody wielokrotnego użytku';
_fc_props.trailer_type_description_is_required = 'Opis typu przyczepy jest wymagany';
_fc_props.trailer_saved = 'Przyczepa zapisana';
_fc_props.cloud_dvir__trailer_only_dvir = 'Tylko przyczepa DVIR';

export default _fc_props;
