import { CLOUD_DVIR_TAG } from '../Constants';

class GetTokenRequest {
  readonly dataModelNumber: number = 1;

  tag: string;

  eventUUID: string;

  method: string;

  requestUrl: string;

  additionalParams: Record<string, any>;

  constructor(eventUUID: string, method: string, requestUrl: string, additionalParams: Record<string, any>) {
    this.tag = CLOUD_DVIR_TAG;
    this.eventUUID = eventUUID;
    this.method = method;
    this.requestUrl = requestUrl;
    this.additionalParams = additionalParams;
  }
}

export default GetTokenRequest;
