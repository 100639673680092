import { useState, useEffect, useRef } from 'react';
import { initFieldState } from './constants';
import { FieldState } from '../types';
import { convertToFixed } from '../../utils/numbers';
import { EngineData } from '../../context/EventsManagerContext';
import { InspectionTripType } from '../../types/types';

export const VehicleFormFields = {
  odometer: { name: 'odometer', optional: false },
  engineHours: { name: 'engineHours', optional: false },
  inspectionTripType: { name: 'inspectionTripType', optional: false },
};

export const VehicleFormLabels = {
  [VehicleFormFields.odometer.name]: 'cloud_dvir__odometer_reading',
  [VehicleFormFields.engineHours.name]: 'cloud_dvir__engine_hours',
  [VehicleFormFields.inspectionTripType.name]: 'cloud_dvir__inspection_trip_type',
};

export const InspectionTripTypeLabels = {
  PRE_TRIP: 'cloud_dvir__pre_trip',
  POST_TRIP: 'cloud_dvir__post_trip',
  OTHER: 'cloud_dvir__ad_hoc',
};

export type VehicleFormFieldState = FieldState<string>;

export type VehicleForm = Record<keyof typeof VehicleFormFields, VehicleFormFieldState>;

const transformInitForm = (inspectionTripType: InspectionTripType):VehicleForm => Object.values(VehicleFormFields)
  .reduce((form, field) => ({
    ...form,
    [field.name]: { ...initFieldState, value: field.name === VehicleFormFields.inspectionTripType.name ? inspectionTripType : '' },
  }), {} as VehicleForm);

interface UseVehicleForm {
  form: VehicleForm;
  validate: () => boolean;
  handleFormChange: (name: string, value: string) => void;
  hasFormChanged: () => boolean,
}

const useVehicleForm = (engineData: EngineData, inspectionTripType: InspectionTripType): UseVehicleForm => {
  const [form, setForm] = useState<VehicleForm>(() => transformInitForm(inspectionTripType));

  const isFormDirty = useRef<boolean>(false);

  const handleEngineDataChange = (data) => {
    setForm((prevForm) => {
      if (prevForm.engineHours.value !== '' || prevForm.odometer.value !== '') {
        return prevForm;
      }
      return Object.entries(prevForm)
        .reduce((result, [name, field]) => {
          if ([VehicleFormFields.odometer.name, VehicleFormFields.engineHours.name].includes(name)) {
            const fieldValue = data[name] || field.value;
            return {
              ...result,
              [name]: { ...field, value: convertToFixed(fieldValue, 2), isError: false },
            };
          }

          return {
            ...result,
            [name]: field,
          };
        }, {} as VehicleForm);
    });
  };

  const handleInspectionTripTypeChange = (data) => {
    setForm((prevForm) => Object.entries(prevForm)
      .reduce((result, [name, field]) => {
        if ([VehicleFormFields.inspectionTripType.name].includes(name)) {
          return {
            ...result,
            [name]: { ...field, value: data, isError: false },
          };
        }

        return {
          ...result,
          [name]: field,
        };
      }, {} as VehicleForm));
  };

  useEffect(() => {
    if (engineData && (engineData.engineHours || engineData.odometer)) {
      handleEngineDataChange(engineData);
    }
  }, [engineData]);

  useEffect(() => {
    if (inspectionTripType) {
      handleInspectionTripTypeChange(inspectionTripType);
    }
  }, [inspectionTripType]);

  const validate = (): boolean => {
    let isValid = true;

    const validatedForm = Object.entries(form).reduce((res, [name, field]) => {
      const fieldDescription = VehicleFormFields[name];
      if (!fieldDescription.optional && !field.value) {
        isValid = false;

        return {
          ...res,
          [name]: { ...field, isError: true },
        };
      }

      return { ...res, [name]: field };
    }, {} as VehicleForm);

    if (!isValid) {
      setForm(validatedForm);
    }

    return isValid;
  };

  const handleFormChange = (name: string, value: string) => {
    const updatedField: FieldState<string> = { value, isError: false };

    isFormDirty.current = true;

    setForm({ ...form, [name]: updatedField });
  };

  const hasFormChanged = () => isFormDirty.current;

  return {
    form, validate, handleFormChange, hasFormChanged,
  };
};

export default useVehicleForm;
