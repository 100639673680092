import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '../../components';

const StyledTextArea = styled('textarea')(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.neutral.variation80}`,
  borderRadius: '12px',
  flexGrow: 1,
  outline: 'none',
  padding: '12px',
}));

const StyledButton = styled(Button)(() => ({
  alignSelf: 'flex-end',
  borderRadius: '4px',
  textTransform: 'uppercase',
}));

interface NoteEditorProps {
  noteText?: string;
  onAddNote: (noteText: string) => void;
  onEditNote: (noteText: string) => void;
  onDeleteNote: () => void;
  onClose: () => void;
}

const NoteEditor:FC<NoteEditorProps> = ({
  noteText = '', onAddNote, onEditNote, onDeleteNote, onClose,
}) => {
  const { t } = useTranslation();

  const [note, setNote] = useState<string>(noteText);

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(value);
  };

  const handleSubmit = () => {
    if (!note.trim()) {
      onDeleteNote();
      onClose();
      return;
    }

    if (noteText) {
      onEditNote(note);
    } else {
      onAddNote(note);
    }

    onClose();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media screen and (min-height: 520px)': {
            marginBottom: 3,
          },
          '@media screen and (min-width: 260px ) and ( max-height: 520px)': {
            marginBottom: 2,
          },
          '@media screen and (max-height: 260px)': {
            marginBottom: 1,
          },
        }}
      >
        <Text
          fontLevel="title"
          fontSize="large"
          sx={{ color: (theme) => theme.palette.neutral.variation40 }}
        >
          {t('cloud_dvir__driver_notes')}
        </Text>
        <ArrowBackIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </Box>

      <Box sx={{
        display: 'flex',
        '@media screen and (min-height: 520px)': {
          marginBottom: 3,
        },
        '@media screen and (min-width: 260px ) and ( max-height: 520px)': {
          marginBottom: 2,
        },
        '@media screen and (max-height: 260px)': {
          marginBottom: 1,
        },
        flexGrow: 1,
      }}
      >
        <StyledTextArea onChange={handleChange} value={note} />
      </Box>
      <StyledButton size="large" variant="contained" isRounded onClick={handleSubmit}>
        {t('cloud_dvir__done')}
      </StyledButton>
    </Box>
  );
};

export default NoteEditor;
