import React, { FC } from 'react';
import { Box, Theme, useTheme } from '@mui/material';
import Text from '../text/Text';

export type BadgeStatus = 'success' | 'info' | 'warning' | 'error';

const getBadgeColor = (theme: Theme, status: BadgeStatus) => {
  switch (status) {
    case 'success': {
      return theme.palette.green.variation30;
    }
    case 'info': {
      return theme.palette.blue.variation30;
    }
    case 'warning': {
      return theme.palette.orange.variation30;
    }
    case 'error': {
      return theme.palette.red.variation50;
    }
    default:
      return theme.palette.green.variation30;
  }
};

interface StatusBadgeProps {
  status: BadgeStatus;
  text: string;
}

const StatusBadge:FC<StatusBadgeProps> = ({ status, text }) => {
  const theme = useTheme();
  const badgeColor = getBadgeColor(theme, status);

  return (
    <Box sx={{
      padding: '8px 16px',
      borderRadius: '8px',
      backgroundColor: badgeColor,
    }}
    >
      <Text
        fontLevel="body"
        fontWeight="lightBold"
        color={theme.palette.neutral.variation100}
      >
        {text}
      </Text>
    </Box>
  );
};

export default StatusBadge;
